import { Typography, useTheme } from '@mui/material'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router'
import { CreateJobButton } from 'src/modules/campaign/components/my-jobs/components/create-job-button'
import { ECampaignRoutes } from 'src/modules/campaign/routes.enum'
import { generate } from 'src/router'
import { OverlayService } from 'src/services'
import { Illustration } from './illustration'
import Style from './style.module.scss'

interface IProps {
  toOnboarding?: boolean
}

export const ViewJobCompleted: FC<IProps> = (props) => {
  const theme = useTheme()
  const router = useHistory()
  const handleCreateJobButton = useMemo(() => {
    if (props.toOnboarding) {
      return () => {
        router.push(generate(ECampaignRoutes.CAMPAIGNS_PUBLIC_ONBOARDING_NEW))
        OverlayService.reset()
      }
    }
  }, [props.toOnboarding, router])

  return (
    <div className={Style.container}>
      <Illustration/>
      <div className={Style.content}>
        <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} textAlign="center">
          Let's kick-start your hiring journey.
        </Typography>
        <span className="meta-regular color-neutral-theme-900 text-center">
          Create and share your job post, and let candidates apply effortlessly.
        </span>
      </div>
      <CreateJobButton onClick={handleCreateJobButton}/>
    </div>
  )
}
