import qs, { IStringifyOptions } from 'qs'
import { ValidationError } from 'yup'

/**
 * alphabeticalSort option of qs module
 * @param {String} a
 * @param {String} b
 */
export const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b)
}

// =================================================================================
/**
 * Check input is Object or not
 * @param {Any} obj
 * @return {Boolean}
 */
export const isObject = <T>(obj: T): boolean =>
  obj && typeof obj === 'object' && !Array.isArray(obj)

// =================================================================================
/**
 * Valid input is an Object
 * @param {Any} arr
 * @return {Object}
 */
export const ensureObject = <T, D>(obj: T, defaultValue?: D) =>
  isObject(obj) ? obj : isObject(defaultValue) ? defaultValue : {}

// =================================================================================
/**
 * Valid input is an Object
 * @param {Any} arr
 * @return {Object}
 */
export const ensureArray = <T>(array: T) =>
  Array.isArray(array) ? array : []

// =================================================================================
/**
 * Compare two object, true if match
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Boolean}
 */
export const compareObj = <T, K>(obj1: T, obj2: K): boolean => {
  const options: IStringifyOptions = {
    arrayFormat: 'repeat',
    sort: alphabeticalSort
  }
  return (
    qs.stringify(ensureObject(obj1), options) ===
    qs.stringify(ensureObject(obj2), options)
  )
}

// ------------------------------------------------------------------------------------

export const convertErrorYup = <T>(error: ValidationError): T => {
  const errors: T = {} as T
  error.inner.forEach((err: ValidationError) => {
    if (err.path && !errors[err.path as keyof T]) {
      (errors as any)[err.path] = err.message
    }
  })
  return errors
}

export const shortenFilename = (filename?: string, length = 20) => {
  if (!filename) return filename
  if (filename.length < length) return filename
  return `${filename.slice(0, length)}...`
}

export const safeParseUrl = (url?: string) => {
  try {
    if (!url) {
      return url
    }

    if (!url.startsWith('http') || !url.startsWith('https')) {
      url = `https://${url}`
    }

    const urlObj = new URL(url)
    return urlObj.toString()
  } catch (error) {
    return url
  }
}

export function formatMoney(value = 0, currency?: string) {
  const moneyValue = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return currency ? `${currency}${moneyValue}` : moneyValue
}

export function truncateText(text: string, maxLength: number, lastChars = 0) {
  const ellipsis = '...'

  if (text.length <= maxLength) {
    return text // No truncation needed
  }

  const firstChars = lastChars ? maxLength - ellipsis.length - lastChars : Math.ceil((maxLength - ellipsis.length) / 2)
  const _lastChars = lastChars || Math.floor((maxLength - ellipsis.length) / 2)

  const truncatedText = text.substr(0, firstChars) + ellipsis + text.substr(text.length - _lastChars)

  return truncatedText
}

export const isValidWebsiteUrl = (url?: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return url && !!pattern.test(url)
}

export const isEmptyBriefDescription = (str?: string) => {
  const regex = /^(<p><br><\/p>)*$/
  return regex.test(str || '')
}

export const decodeJWTPayload = (jwtToken: string) => {
  try {
    const encodedPayload = jwtToken.split('.')[1]
    const payloadString = window.atob(encodedPayload)
    return JSON.parse(payloadString)
  } catch (err) {
    console.log('failed to decode base 64 of jwt token', err)
    return {}
  }
}
