import clsx from 'clsx'
import { ChangeEvent, FC, useRef } from 'react'
import Style from './style.module.scss'

interface IProps {
  label?: string | JSX.Element
  labelClassName?: string
  switchClassName?: string
  checked?: boolean
  htmlId?: string

  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Switch: FC<IProps> = ({ htmlId, label, labelClassName, checked = false, onChange, switchClassName }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className={clsx(Style.container, switchClassName)}>
      <label id={htmlId} className={Style.toggleSwitch}>
        <input type="checkbox" ref={inputRef} checked={checked} onChange={onChange}/>
        <span className={Style.slider}/>
      </label>
      <span className={clsx(Style.label, labelClassName)} onClick={() => inputRef.current?.click()}>
        {label && (
          label
        )}
      </span>
    </div>
  )
}
