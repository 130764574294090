import { EUserGuide } from 'src/enums'
import { IUserStatModel } from 'src/interfaces'
import { AUTH_SET_GUIDE, AUTH_SET_STATS, TAuthAction } from '../types'

export const setAuthStats = (value: IUserStatModel): TAuthAction => ({
  type: AUTH_SET_STATS,
  value
})

export const firstTimeUploadPFV = (value: {
  [EUserGuide.FIRST_TIME_UPLOAD_PFV]: boolean
}): TAuthAction => ({
  type: AUTH_SET_GUIDE,
  value
})

export const setFirstTimeClickCredit = (value: {
  [EUserGuide.FIRST_TIME_CLICK_CREDIT]: boolean
}): TAuthAction => ({
  type: AUTH_SET_GUIDE,
  value
})

export const setOnboarding = (value: {
  [EUserGuide.ONBOARDING_CAMPAIGN]?: boolean
  [EUserGuide.ONBOARDING_TALENT]?: boolean
}): TAuthAction => ({
  type: AUTH_SET_GUIDE,
  value
})
