import { Box, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Divider, StripeProvider, useAnalytic } from 'src/components'
import { ETrackingEvent, EUpgradeView } from 'src/enums'
import { IconArrowLeft, IconMinus, IconPlus } from 'src/icons'
import { IUpgradePlan } from '../store'
import { Stripe } from './stripe'
import { STAddCredit, STContainer, STHeader, STInfo, STTotal } from './styled'

interface IProps {
  setView: Dispatch<SetStateAction<number>>
  plan: IUpgradePlan | null
}

export const Payment: FC<IProps> = ({ setView, plan }) => {
  const [quantity, setQuantity] = useState(1)
  const { eventHandler } = useAnalytic('store_pay')

  const handleAddCredit = (type: string) => {
    if (type === 'minus') {
      return quantity > 1 && setQuantity(quantity => quantity - 1)
    }

    return setQuantity(quantity => quantity + 1)
  }

  return (
    <STContainer>
      <STHeader>
        <IconArrowLeft onClick={() => setView(EUpgradeView.STORE)}/>
        <Typography variant="h4">Check your bill</Typography>
      </STHeader>
      <Divider mt={4} mb={4}/>
      <STInfo>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1-regular">{plan?.title}</Typography>
          <Typography variant="h5">${plan?.price}</Typography>
        </Box>
        <STAddCredit>
          <IconMinus onClick={eventHandler(ETrackingEvent.BTN_CREDIT_DEC, () => handleAddCredit('minus'))}/>
          <Typography variant="body1-regular">{quantity}</Typography>
          <IconPlus onClick={eventHandler(ETrackingEvent.BTN_CREDIT_INC, () => handleAddCredit('plus'))}/>
        </STAddCredit>
      </STInfo>
      <Divider mt={4} mb={2.5}/>
      <STTotal>
        <Box>
          <Typography variant="body1-regular">Intro Credits total</Typography>
          <Typography variant="h5">{(plan?.intros || 0) * quantity}</Typography>
        </Box>
        <Box>
          <Typography variant="body1-regular">Invoice total</Typography>
          <Typography variant="h5">${(plan?.price || 0) * quantity}</Typography>
        </Box>
      </STTotal>
      <Divider mt={2.5} mb={4}/>
      <Typography variant="h5">Add payment information</Typography>
      <StripeProvider>
        <Stripe setView={setView} plan={plan} quantity={quantity}/>
      </StripeProvider>
    </STContainer>
  )
}
