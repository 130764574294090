import { FC, ReactNode, createContext, useCallback, useContext, useRef } from 'react'

interface ICacheContext {
  data: Map<number, number>
  set: (videoId: number, playAt: number) => void
  reset: () => void
}

const CacheContext = createContext<ICacheContext>({
  data: new Map(),
  set: () => {},
  reset: () => {}
})

export const useCache = () => {
  return useContext(CacheContext)
}

export const CacheProvider: FC<{ children: ReactNode; data: Map<number, number> }> = (props) => {
  const map = useRef<Map<number, number>>(props.data)
  const handleSet = useCallback((videoId: number, playAt: number) => {
    map.current.set(videoId, playAt)
  }, [])

  const handleReset = useCallback(() => {
    Array.from(map.current.keys()).forEach((key) => {
      map.current.delete(key)
    })
  }, [])

  return (
    <CacheContext.Provider
      value={{
        data: map.current,
        set: handleSet,
        reset: handleReset
      }}
    >
      {props.children}
    </CacheContext.Provider>
  )
}
