import { ICandidateState, TCandidateAction } from '../types'

export const initState: ICandidateState = {
  candidate: null
}

export const reducer = (state = initState, action: TCandidateAction) => {
  switch (action.type) {
    default:
      return state
  }
}
