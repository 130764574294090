import { isEmpty } from 'lodash'
import { IUserModel, IUserProfile } from 'src/interfaces'
import { AUTH_SET_CREDENTIALS, IProfileState, PROFILE_COUNT_CREDIT, PROFILE_TRAITS, TProfileAction } from '../types'

export const initState: IProfileState = {
  info: {} as IUserProfile,
  countCredits: 0,
  traits: {
    isNull: false,
    characteristics: [],
    motivations: []
  }
}

export const reducer = (state = initState, action: TProfileAction): IProfileState => {
  switch (action.type) {
    case AUTH_SET_CREDENTIALS:
    {
      const isNull = isEmpty((action?.value as IUserModel)?.traitFilter)
      const traitsFromDb = (action?.value as IUserModel)?.traitFilter?.traits

      return {
        ...state,
        traits: {
          isNull,
          characteristics: traitsFromDb?.slice(0, 4) || [],
          motivations: traitsFromDb?.slice(4) || []
        }
      }
    }
    case PROFILE_TRAITS:
      return { ...state, traits: (action?.value ?? {}) as IProfileState['traits'] }
    case PROFILE_COUNT_CREDIT:
      return { ...state, countCredits: Number(action.value) }
    default:
      return state
  }
}
