/**
 * ''.capitalize polyfill
 */
if (!String.prototype.capitalize) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(String.prototype, 'capitalize', {
    value() {
      return this.charAt(0).toUpperCase() + this.slice(1)
    },
    enumerable: false
  })
}

export { }
