import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  width: 415px;
  height: 100%;
  border-radius: 32px;
  padding: ${theme.spacing(5)};
  background-color: ${theme.colors['--color-neutral-theme-50']};

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
  
  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    padding: ${theme.spacing(3)};
  }
`)
