import { Dispatch, FC, SetStateAction } from 'react'
import { INCLUDE_TEXTS, IUpgradePlan, useAnalytic, usePlan } from 'src/components'
import { EUpgradeView } from 'src/enums'
import { IconCheckedCircleFilled } from 'src/icons'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CardRegular, CardSaving, CardSingle } from 'src/components/upgrade/cards'
import 'swiper/css'
import Style from './style.module.scss'

interface IStoreProps {
  setView: Dispatch<SetStateAction<number>>
  setPlan: Dispatch<SetStateAction<IUpgradePlan | null>>
}

export const StoreMobile: FC<IStoreProps> = ({ setPlan, setView }) => {
  const { eventHandler } = useAnalytic('store')

  const handleSelectPlan = async (plan: IUpgradePlan) => {
    setPlan(plan)
    setView(EUpgradeView.PAYMENT)
  }

  const plans = usePlan()

  return (
    <div className={Style.container}>
      <div className={Style.top}>
        <div className={Style.topInfo}>
          <span className={Style.topInfo_heading}>With 1 Intro Credit, You Can: </span>
          <div className={Style.topInfo_packages}>
            {INCLUDE_TEXTS.map((text, index) => (
              <span className={Style.topInfo_package} key={index}>
                <IconCheckedCircleFilled className={Style.topInfo_icon}/>
                {text}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={Style.bottom}>
        <Swiper
          slidesPerView={1.3}
          centeredSlides
          spaceBetween={16}
          className={Style.swiper}
          initialSlide={1}
        >
          <SwiperSlide>
            <CardSingle
              className={Style.cardWidth}
              onClick={eventHandler(plans[0].event, () => handleSelectPlan(plans[0]))}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardRegular
              className={Style.cardWidth}
              onClick={eventHandler(plans[1].event, () => handleSelectPlan(plans[1]))}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardSaving
              className={Style.cardWidth}
              onClick={eventHandler(plans[2].event, () => handleSelectPlan(plans[2]))}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}
