import { BehaviorSubject } from 'rxjs'
import { ManifestApi } from 'src/api'
import { ESettingKeys } from 'src/enums'
import { WithOutNextComplete } from 'types/rxjs'

export const SettingsService = new (
  class {
    private readonly _settings$ = new BehaviorSubject<{ [key in ESettingKeys]?: any }>({})
    get settings$(): WithOutNextComplete<typeof this._settings$> {
      return this._settings$
    }

    private setSettings(settings: { [key: string]: any }) {
      this._settings$.next(settings)
    }

    load() {
      return ManifestApi.get().then((response) => {
        const { data } = response
        this.setSettings({
          ...data,
          [ESettingKeys.APP_ENABLE_SUBTITLE]: data[ESettingKeys.APP_ENABLE_SUBTITLE] === 'true'
        })
        return response
      })
    }
  }
)()
