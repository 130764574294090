import { InputAdornment, Typography, useTheme } from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ETrackingEvent } from 'src/enums'
import { useAppSelector } from 'src/hooks'
import { IconGiftBlack } from 'src/icons'
import { getProfile } from 'src/store/selectors'
import { useAnalytic } from '../analytic-provider'
import { Input } from '../input'
import Style from './style.module.scss'

export const Invite: FC = () => {
  const theme = useTheme()
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const profile = useAppSelector(getProfile)
  const { eventHandler } = useAnalytic('invite')

  const [link, setLink] = useState({
    text: `${window.location.host}/sign-up?inviteToken=${profile.lookupId}`,
    title: 'Copy link'
  })

  const handleCopy = useCallback(() => {
    setLink(prevState => ({ ...prevState, title: 'Copied' }))

    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(() => setLink(prevState => ({ ...prevState, title: 'Copy link' })), 1000)
  }, [])

  return (
    <div className={Style.container}>
      <div className={Style.top}>
        <IconGiftBlack/>
        <Typography variant="body1-bold">Invite Friends</Typography>
        <Typography variant="body2-regular">Help us spread the cheer! Anyone who uses the link below will get 5 free credits. And you will receive 5 free credits too!!!</Typography>
      </div>

      <Input
        label=""
        value={link.text}
        autoComplete="off"
        sx={{ marginBottom: 0 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard
                text={link.text}
                onCopy={eventHandler(ETrackingEvent.BTN_COPY_LINK, handleCopy)}
              >
                <Typography
                  variant="body1-bold"
                  color={theme.colors['--color-cyan-600']}
                  sx={{ cursor: 'pointer' }}
                >
                  {link.title}
                </Typography>
              </CopyToClipboard>
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}
