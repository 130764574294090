import {
  Box,
  Typography,
  useTheme
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  VideoPlayer
} from 'src/components'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import { IconVideo } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { ERecordFor, RecordingService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { getVideoSource } from 'src/utils'
import { PromotionBanner } from '../promotion-banner'
import Style from './style.module.scss'

const buttonSx = {
  height: 'auto',
  minHeight: '32px',
  borderRadius: '12px'
}

interface IProps {
  video?: IVideoModel
}

export const VVC: FC<IProps> = ({ video }) => {
  const theme = useTheme()
  const profile = useAppSelector(getCredentials)
  const router = useHistory()
  const [showingBanner, setShowingBanner] = useState(true)
  const mapThumbnails = useBehaviorMapper(RecordingService.mapThumbnails$)
  const imageSrcFromThumbnailCache = mapThumbnails.get(video?.id?.toString())

  useEffect(() => {
    setShowingBanner(!profile.guide?.firstTimeUploadPfv)
  }, [profile, setShowingBanner])

  const goToRecorderPage = () => {
    router.push(generate(ERoutes.RECORDING, { type: ERecordFor.PFV }))
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="meta-bold" fontSize={14} color={theme.colors['--color-neutral-theme-700']}>My Video Vibe Check<sup style={{ fontSize: '8px' }}>TM</sup> (VVC)</Typography>
      </Box>

      {video
        ? (
          <div className={Style.vvc_wrapper}>
            <div className={Style.vvc_videoWrapper}>
              <VideoPlayer
                forceRender={!imageSrcFromThumbnailCache?.url && !video.urlVideoImageThumbnail}
                className={Style.vvc_video}
                videoId={video.id}
                tracks={video.tracks}
                image={imageSrcFromThumbnailCache?.url || video.urlVideoImageThumbnail}
                animatedImage={video.urlVideoAnimatedImage}
                shouldFlipBg={imageSrcFromThumbnailCache?.flip}
                url={getVideoSource(video)}
                showProcessing
              />
            </div>

            <Button
              onClick={goToRecorderPage}
              order="secondary"
              sx={buttonSx}
            >
              Add New Video
            </Button>

          </div>
        )
        : (
          <div className={Style.emptyVideoContainer}>
            <div className={Style.emptyVideoContainer_Content}>
              <IconVideo width="69px" height="69px"/>
              <Typography
                variant="body2-bold"
                color={theme.colors['--color-neutral-theme-250']}
                textAlign="center"
                maxWidth={296}
              >
                “Show your human side<sup>TM</sup>“ with a video so talent can vibe you.
              </Typography>
            </div>

            {!showingBanner && (
              <Box>
                <Button
                  onClick={goToRecorderPage}
                  sx={{ borderRadius: 3, height: 'auto', paddingY: '8px', paddingX: '16px' }}
                >Add New Video
                </Button>
              </Box>
            )}

            {showingBanner
              ? (
                <div className={Style.promotionBanner}>
                  <PromotionBanner
                    onClose={() => setShowingBanner(false)}
                    onOk={goToRecorderPage}
                  />
                </div>
              )
              : (
                null
              )}
          </div>
        )}
    </Box>

  )
}
