import { ECandidateReaction, ETrackingEvent } from 'src/enums'

export const mapReactionToEventTracking = (reaction: ECandidateReaction) => {
  switch (reaction) {
    case ECandidateReaction.LIKE:
      return ETrackingEvent.BTN_VIDEO_LIKE
    case ECandidateReaction.SKIP:
      return ETrackingEvent.BTN_VIDEO_SKIP
    case ECandidateReaction.SUPER_LIKE:
      return ETrackingEvent.BTN_VIDEO_SUPERLIKE
    default:
      return undefined
  }
}
