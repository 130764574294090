import { FC } from 'react'
import { IItemProps } from '../../type'

import clsx from 'clsx'
import { isUndefined } from 'lodash'
import { IconArrowDown } from 'src/icons'
import { EarnCredit } from '../earn-money-animation'
import Style from './style.module.scss'

export const Item: FC<IItemProps & { showEarnMoney?: boolean }> = ({
  isActive,
  activeIcon: ActiveIcon,
  icon: Icon,
  title,
  showEarnMoney = false,
  isMinimized,
  isExpand,
  onClick,
  customMinimized: CustomMinimized
}) => {
  return isMinimized && CustomMinimized
    ? (
      <CustomMinimized
        className={clsx(
          'hover-color-neutral-theme-50',
          isActive ? 'color-neutral-theme-50' : 'color-neutral-theme-400'
        )}
        onClick={onClick}
      />
    )
    : (
      <div
        onClick={onClick}
        className={clsx(
          'group',
          Style.container,
          isActive && !isExpand && 'bg-color-neutral-theme-600',
          isMinimized && Style.containerMinimize
        )}
      >
        {isActive
          ? (
            <ActiveIcon
              width={24}
              className="svg-color-neutral-theme-50 fx-shrink-0"
            />
          )
          : (
            <Icon
              width={24}
              className="group-hover-svg-color-neutral-theme-50 svg-color-neutral-theme-300 fx-shrink-0"
            />
          )}

        <div
          className={clsx(
            'fx flex-row fx-ai-center fx-jc-space-between fx-1 relative',
            isMinimized && 'd-none'
          )}
        >
          <span
            className={clsx(
              'body1-bold',
              isActive
                ? 'color-neutral-theme-50'
                : 'color-neutral-theme-300 group-hover-color-neutral-theme-50'
            )}
          >
            {title}
          </span>
          {showEarnMoney && (
            <div className={clsx('absolute r-1', Style.earnMoneyAnimation)}>
              <EarnCredit play/>
            </div>
          )}
          <IconArrowDown
            className={clsx(
              Style.arrow,
              isUndefined(isExpand) && 'd-none',
              isExpand && 'rotate-180',
              'svg-color-neutral-theme-400'
            )}
          />
        </div>
      </div>
    )
}
