
export const allowedAudioTypes = [
  'audio/3gp',
  'audio/aa',
  'audio/aac',
  'audio/aax',
  'audio/act',
  'audio/aiff',
  'audio/alac',
  'audio/amr',
  'audio/ape',
  'audio/au',
  'audio/awb',
  'audio/dss',
  'audio/dvf',
  'audio/flac',
  'audio/gsm',
  'audio/iklax',
  'audio/ivs',
  'audio/m4a',
  'audio/m4p',
  'audio/nmf',
  'audio/movpkg',
  'audio/mp3',
  'audio/mpc',
  'audio/msv',
  'audio/nmf',
  'audio/ogg',
  'audio/oga',
  'audio/mogg',
  'audio/opus',
  'audio/ra',
  'audio/rm',
  'audio/rf64',
  'audio/sln',
  'audio/tta',
  'audio/voc',
  'audio/vox',
  'audio/wav',
  'audio/wma',
  'audio/wv',
  'audio/8svx',
  'audio/cda',
  'audio/mpeg'
]

const allowedVideoTypes = [
  'video/webm',
  'video/mkv',
  'video/flv',
  'video/vob',
  'video/gif',
  'video/mov',
  'video/mp4',
  'video/m4v',
  'video/3gp'
]

export class FileUtils {
  static readFilePromise(
    file: File | Blob,
    output: 'text' | 'base64' | 'binary' | 'buffer' = 'base64'
  ) {
    return new Promise<FileReader['result']>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function (e: ProgressEvent<FileReader>) {
        resolve(e.target?.result ?? null)
      }

      switch (output) {
        case 'text':
          return reader.readAsText(file)
        case 'base64':
          return reader.readAsDataURL(file)
        case 'buffer':
          return reader.readAsArrayBuffer(file)
        case 'binary':
          return reader.readAsBinaryString(file)
      }
    })
  }

  static isAudioFile(file: File) {
    return allowedAudioTypes.some((audioType) => file.type.includes(audioType))
  }

  static isVideoFile(file: File) {
    return allowedVideoTypes.some((videoType) => file.type.includes(videoType))
  }

  static download(url: string, filename: string) {
    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
  }
}
