import { Subject } from 'rxjs'
import { EMessage } from 'src/enums'
import { IMessage } from 'src/interfaces'

export const SnackbarService = new (
  class {
    private id = 0

    readonly message$ = new Subject<IMessage>()

    push(message: string | IMessage) {
      if (!message || (typeof message === 'object' && !message.content)) {
        return
      }

      const defaultMessage: IMessage = {
        id: ++this.id,
        open: true,
        severity: EMessage.SUCCESS,
        position: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 5000,
        content: message as string,
        actionText: 'Close'
      }

      if (typeof message === 'string') {
        return this.message$.next(defaultMessage)
      }

      this.message$.next({
        ...defaultMessage,
        ...message
      })
    }
  }
)()

window.addEventListener('offline', (e) => {
  SnackbarService.push({
    position: {
      vertical: 'top',
      horizontal: 'center'
    },
    severity: EMessage.ERROR,
    content: 'You are offline.'
  })
})

window.addEventListener('online', (e) => {
  SnackbarService.push({
    position: {
      vertical: 'top',
      horizontal: 'center'
    },
    severity: EMessage.SUCCESS,
    content: 'Internet connection restored.'
  })
})

window.addEventListener('message', (event) => {
  if (event.data === 'hadNewUpdate') {
    SnackbarService.push({
      position: {
        vertical: 'top',
        horizontal: 'center'
      },
      onClick() {
        window.location.reload()
      },
      severity: EMessage.INFO,
      content: 'A new version is available, please reload your browser.',
      actionText: 'Update'
    })
  }
})
