import { FC } from 'react'
import { BannerStartVibeTalent } from 'src/components/banner-start-vibe-talent'
import { useAppSelector } from 'src/hooks'
import { CommentCardEmptyImg } from 'src/images'
import { getLayoutIsLoading } from 'src/store/selectors'
import { CreateJobButton } from '../create-job-button'

interface IProps {
  tab: number
  withBanner?: boolean
  onBannerClick?: () => void
  onCreateJobClick?: () => void
}

const tabs = [
  'Start Creating Your First Job Listing!',
  'You Don’t Have Any Draft'
]

export const EmptyJob: FC<IProps> = (props) => {
  const isLayoutLoading = useAppSelector(getLayoutIsLoading)

  return (
    <div className="fx flex-column fx-ai-center fx-jc-center fx-1">
      <div className="fx flex-column fx-ai-center fx-jc-center fx-1">
        <img src={CommentCardEmptyImg} alt="no comment" height="169"/>
        <h5
          className="color-neutral-theme-250 text-center"
          style={{ marginTop: '-14px', marginBottom: '32px' }}
        >
          {tabs[props.tab]}
        </h5>
        {!isLayoutLoading && (
          <CreateJobButton {...(props.onCreateJobClick ? { onClick: props.onCreateJobClick } : {})}/>
        )}
      </div>

      {props.withBanner && (<BannerStartVibeTalent onBannerClick={props.onBannerClick}/>)}
    </div>
  )
}
