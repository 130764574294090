import { FC, useCallback, useEffect, useState } from 'react'
import { InvoiceApi } from 'src/api'
import { Button } from 'src/components'
import { EMessage } from 'src/enums'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { IInvoiceModel, IPaginationResponse } from 'src/interfaces'
import { CreditService, SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutPageTitle, setLayoutShouldShowShareButtonHeaderMobile } from 'src/store/actions'
import { getPaymentIntentId } from 'src/store/selectors'
import { ensureArray, formatCurrency, formatDateTime, getApiErrorMessage } from 'src/utils'
import {
  STContainer,
  STDateText, STFooter,
  STLabelText,
  STRow,
  STTransactionItem,
  STTransactionList, STValueText,
  STViewDetailText
} from './styled'

export const PaymentHistoryMobile: FC = () => {
  const dispatch = useAppDispatch()
  const paymentIntentId = useAppSelector(getPaymentIntentId)
  const [data, setData] = useState<IPaginationResponse<IInvoiceModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const loadPayment = useCallback(async (page = 1, limit = 10) => {
    try {
      dispatch(setLayoutLoading(true))

      const { data } = await InvoiceApi.pagination({
        page,
        limit
      })

      setData({
        page: data.page,
        total: data.total,
        pages: data.pages,
        rows: ensureArray(data.rows)
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally { dispatch(setLayoutLoading(false)) }
  }, [dispatch])

  useEffect(() => {
    dispatch(setLayoutPageTitle('Payment History'))
    dispatch(setLayoutShouldShowShareButtonHeaderMobile(false))
    loadPayment()

    return () => {
      dispatch(setLayoutShouldShowShareButtonHeaderMobile(true))
    }
  }, [dispatch, loadPayment, paymentIntentId])

  const handlePDF = async (id: number) => {
    try {
      dispatch(setLayoutLoading(true))

      const { data } = await InvoiceApi.detail(id)
      window.open(data.pdfUrl)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally { dispatch(setLayoutLoading(false)) }
  }

  return (
    <STContainer>
      <STTransactionList>
        {data?.rows?.map((item, index) => (
          <STTransactionItem key={index}>
            <STRow>
              <STDateText>
                {item.createdAt && formatDateTime(item.createdAt)}
              </STDateText>
              <STViewDetailText onClick={() => handlePDF(item.id)}>
                View invoices
              </STViewDetailText>
            </STRow>
            <STRow>
              <STLabelText>
                Description
              </STLabelText>
              <STValueText>
                Introduction Credits - {item.metadata?.credits} Units
              </STValueText>
            </STRow>
            <STRow>
              <STLabelText>
                Qty
              </STLabelText>
              <STValueText>
                {item.metadata?.items?.[0]?.qty}
              </STValueText>
            </STRow>
            <STRow>
              <STLabelText>
                Price
              </STLabelText>
              <STValueText>
                {item.metadata && `$${formatCurrency(item.metadata?.amount)}.00`}
              </STValueText>
            </STRow>
            <STRow>
              <STLabelText>
                Status
              </STLabelText>
              <STValueText>
                Paid
              </STValueText>
            </STRow>
          </STTransactionItem>
        ))}
      </STTransactionList>
      <STFooter>
        <Button
          sx={{ width: '100%' }}
          onClick={() => CreditService.setShowBuyMoreCreditsMobileModal(true)}
        >Buy More Credits
        </Button>
      </STFooter>
    </STContainer>
  )
}
