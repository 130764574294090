import { IVibesState } from '../types'
import { READ_COMMENT_ON_VIDEO } from '../types/vibes'

export const initState: IVibesState = {
  readCommentOnVideoId: undefined
}

export const reducer = (state = initState, action: {
  type: typeof READ_COMMENT_ON_VIDEO
  value: IVibesState
}) => {
  switch (action.type) {
    case READ_COMMENT_ON_VIDEO:
      return {
        ...state,
        ...action.value
      }
    default:
      return state
  }
}
