import { ECampaignStatus } from 'src/enums'
import {
  ICampaignDraftModel,
  ICampaignModel,
  ICampaignOwnerModel,
  ICampaignPublicModel,
  ICampaignSubmissionModel,
  IMinimumSubmissionData,
  IPaginationQuery,
  TAxiosResponseData,
  TAxiosResponsePagination
} from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

interface ICampaignUpload {
  logoUrl?: string
  jdFileUrl?: string | null
  uploadVideoKey?: string
  uploadAudioBgKey?: string
  thumbnailOffset?: number
}

export class CampaignApi {
  static readonly _prefix = '/campaigns'
  static cacheCompanyOwners = new Map<string, { owners: ICampaignOwnerModel[]; total: number }>()

  static readonly SUMMARY_EFFECT_FIELDS = [
    'jobTitle',
    'jdFile',
    'jdFileUrl',
    'location',
    'briefDescription',
    'salaryRange',
    'salaryRate',
    'salaryValue'
  ] as (keyof ICampaignModel)[]

  static getList(query?: { status: ECampaignStatus }): TAxiosResponseData<(ICampaignModel & { submissions: IMinimumSubmissionData[]; logoUrl?: string; draftId?: number; slug?: string })[]> {
    return axiosHiringApi.get(`${this._prefix}`, { params: query })
  }

  static archive(id: number): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/${id}`)
  }

  static count(): TAxiosResponseData<{ count: number; campaign: number; campaignDraft: number }> {
    return axiosHiringApi.get(`${this._prefix}/count`)
  }

  static create(campaign: Omit<ICampaignModel, 'id'> & ICampaignUpload & { draftId?: number }): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.post(`${this._prefix}`, campaign)
  }

  static saveDraft(campaign: ICampaignDraftModel & ICampaignUpload): TAxiosResponseData<{ id: number }> {
    return axiosHiringApi.post(`${this._prefix}/draft`, campaign)
  }

  static getDraftDetail(id: number): TAxiosResponseData<ICampaignDraftModel> {
    return axiosHiringApi.get(`${this._prefix}/draft/${id}`)
  }

  static deleteDraft(id: number): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/draft/${id}`)
  }

  static preCreate(campaign: Omit<ICampaignModel, 'id'> & ICampaignUpload): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}?dryRun=true`, campaign)
  }

  static getOne(id: number): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}`)
  }

  static update(id: number, campaign: Omit<ICampaignModel, 'id'>): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}`, campaign)
  }

  static fetchSummary(payload: {
    jobTitle?: string
    jdFile?: File | Blob
    jdFileUrl?: string
    location?: string
    briefDescription?: string
    salaryRange?: ICampaignModel['salaryRange']
    salaryRate?: ICampaignModel['salaryRate']
    salaryValue?: ICampaignModel['salaryValue']
  }): TAxiosResponseData<{ summary: string }> {
    const formData = new FormData()
    for (const field of CampaignApi.SUMMARY_EFFECT_FIELDS) {
      const value = payload[field as keyof typeof payload]
      if (value) {
        formData.append(
          field,
          value instanceof File
            ? value
            : typeof value === 'string'
              ? value
              : JSON.stringify(value)
        )
      }
    }

    return axiosHiringApi.post(`${this._prefix}/summary`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  static updateSummary(id: number, jobSummary: string): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}/summary`, { jobSummary })
  }

  private static questions: { id: number; text: string }[]
  static getQuestions(): TAxiosResponseData<{ id: number; text: string }[]> {
    if (this.questions) {
      return Promise.resolve({
        data: this.questions
      }) as TAxiosResponseData<{ id: number; text: string }[]>
    }

    return axiosHiringApi.get(`${this._prefix}/questions`)
      .then(res => {
        this.questions = res.data
        return res
      })
  }

  static preUpdate(id: number, campaign: Omit<ICampaignModel, 'id'>): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}?dryRun=true`, campaign)
  }

  static inviteEmails(id: number, payload: { emails: string[]; message?: string }): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/${id}/invite-emails`, payload)
  }

  static paginateSubmission(
    campaignId: number,
    params: IPaginationQuery
  ): TAxiosResponsePagination<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${campaignId}/submission`, { params })
  }

  static getDetailSubmission(
    campaignId: number,
    submissionId: number
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${campaignId}/submission/${submissionId}`)
  }

  static writeNote(
    campaignId: number,
    submissionId: number,
    content: string
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.post(`${this._prefix}/${campaignId}/submission/${submissionId}/note`, { content })
  }

  static action(
    params: {
      campaignId: number
      submissionId: number
      action: string
      videoId: number
    }
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.put(`${this._prefix}/${params.campaignId}/submission/${params.submissionId}/${params.action}`, { videoId: params.videoId })
  }

  static publicCampaign(hash: string): TAxiosResponseData<ICampaignPublicModel & { sameCompany?: boolean }> {
    return axiosHiringApi.get(`${this._prefix}/${hash}`)
  }

  static publicPaginateSubmission(hash: string, params: IPaginationQuery): TAxiosResponsePagination<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${hash}/submission`, { params })
  }

  static publicGetDetailSubmission(
    hash: string,
    submissionId: number
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${hash}/submission/${submissionId}`)
  }

  static verifyAccount(
    hash: string,
    payload: { email: string; sessionId?: string }
  ): TAxiosResponseData<{ accountExisted: boolean; sameCompany: boolean } | undefined> {
    return axiosHiringApi.post(`${this._prefix}/${hash}/verify-account`, payload)
  }

  static getSharedOwners(id: number, companyDomain: string): TAxiosResponseData<{ owners: ICampaignOwnerModel[]; total: number }> {
    if (this.cacheCompanyOwners.has(companyDomain)) {
      return Promise.resolve({
        data: this.cacheCompanyOwners.get(companyDomain)
      }) as TAxiosResponseData<{ owners: ICampaignOwnerModel[]; total: number }>
    }

    return axiosHiringApi.get(`${this._prefix}/${id}/shared-owners`).then(res => {
      this.cacheCompanyOwners.set(companyDomain, res.data)
      return res
    })
  }

  static getSeedSubmissions(): TAxiosResponseData<ICampaignSubmissionModel[]> {
    return axiosHiringApi.get(`${this._prefix}/onboarding-submissions`)
  }

  static createOnboarding(campaign: ICampaignDraftModel & ICampaignUpload): TAxiosResponseData<{ slug: string }> {
    return axiosHiringApi.post(`${this._prefix}/onboarding`, campaign)
  }
}
