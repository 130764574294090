import { Checkbox } from 'src/components'
import { INotificationSettingRecord } from 'src/interfaces'

interface IRenderColumnProps {
  onChange: (type: INotificationSettingRecord['type'], place: 'email' | 'notification', value: boolean) => void
  width?: string
}

export const RenderColumns = ({ onChange, width }: IRenderColumnProps) => {
  return [
    {
      field: 'type',
      title: 'Configure how you would like to be notified',
      render: (data: INotificationSettingRecord) => mapTypeToString(data.type)
    },
    {
      field: 'notification',
      title: 'In KnowMe',
      render: (data: INotificationSettingRecord) => data.notification !== undefined && data.type !== 'weekly_summary_report' ? <Checkbox checked={data.notification} onChange={(_, newValue) => onChange(data.type, 'notification', newValue)}/> : null,
      width
    },
    {
      field: 'email',
      title: 'Via Email',
      render: (data: INotificationSettingRecord) => data.email !== undefined ? <Checkbox checked={data.email} onChange={(_, newValue) => onChange(data.type, 'email', newValue)}/> : null,
      width
    }
  ]
}

export const mapTypeToString = (type: INotificationSettingRecord['type']) => {
  switch (type) {
    case 'candidate_response_campaign_invitation':
      return 'When applicants respond to campaign invitation'
    case 'candidate_update_linkedin':
      return 'When applicants update their LinkedIn URL'
    case 'hm_invitation_accepted':
      return 'When Hiring Manager invitation accepted'
    case 'hm_comment_on_candidate':
      return 'When colleague has commented on applicant'
    case 'candidate_superlike_back':
      return 'When talent responds with a Superlike back'
    case 'weekly_summary_report':
      return 'Weekly summary report'
    default:
      return ''
  }
}
