export const AUTH_FALLBACK_KEY = '_fall_back_url'
export const FORMAT_DATE_TIME = 'MM/DD/YYYY'
export const MISMATCH_PASSWORD = 'The Password you provided doesn’t match. Wanna try that again?'
export const WRONG_EMAIL_DOMAIN = 'Must be a company email.'
export const BUTTON_BACK_ID = '_header-button-back'

export * from './campaign'
export * from './credit'
export * from './env'
export * from './error-code'
export * from './mui-colors'
export * from './mui-theme'
export * from './mui-typography'
export * from './re'
export * from './upload'
export * from './url'
