import { Avatar } from '@mui/material'
import clsx from 'clsx'
import { FC, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useUnsubscribeEffect } from 'src/hooks'
import { ICampaignOwnerModel } from 'src/interfaces'
import { getApiErrorMessage, getFirstName } from 'src/utils'
import Style from './style.module.scss'

const avatarSx = { width: 14, height: 14 }

export const CampaignOwnersTooltip: FC<{ id: number; companyDomain: string }> = (props) => {
  const [data, setData] = useState<{ owners: ICampaignOwnerModel[]; total: number }>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useUnsubscribeEffect(unsubscribe$ => {
    setLoading(true)

    from(CampaignApi.getSharedOwners(props.id, props.companyDomain))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false)),
        catchError((error) => {
          setError(getApiErrorMessage(error))
          return EMPTY
        })
      )
      .subscribe(res => {
        setData(res.data)
      })
  }, [])

  const owners = useMemo(() => {
    return data?.owners?.filter(owner => !!getFirstName(owner))?.map(owner => owner) || []
  }, [data])

  return (
    <div className={Style.container}>
      {loading && <div className={Style.loading}>Loading...</div>}
      {error && <div className={Style.error}>{error}</div>}
      {!loading && !error && (
        <>
          <span className={clsx('meta-bold color-neutral-50', Style.owners)}>Multiple owners: </span>
          {owners.map((owner, index) => (
            <div key={index} className="fx flex-row fx-wrap gap-1 fx-ai-center">
              <Avatar sx={avatarSx} className={Style.avatar} src={owner.pfp?.url}/> {`${getFirstName(owner)}${index === owners?.length - 1 ? '' : ', '}`}
            </div>
          ))}
        </>
      )}
    </div>
  )
}
