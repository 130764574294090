import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

export const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 2,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.colors['--color-neutral-theme-200']
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: value === 100 ? theme.colors['--color-positive-500'] : theme.colors['--color-primary-500']
  }
}))
