import { Box, Fade, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, from, takeUntil, tap, timer } from 'rxjs'
import { CandidateApi } from 'src/api'
import { Comment, Divider, IProgress, NoTabletOrMobile, OnlyMobile, ReactionAndShare, Share, TabletAndMobile, Tabs, Textarea, VideoPlayer, useAnalytic } from 'src/components'
import { useCache } from 'src/components/video-player/cache.context'
import { ECandidateReaction, EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useElementSize, useSubscribeToGoBack, useUnsubscribeCallback, useUserOtherInfo } from 'src/hooks'
import {
  IconEmail,
  IconLinkedInInfo,
  IconPhone
} from 'src/icons'
import { ICandidateModel, ITab } from 'src/interfaces'
import { CandidateInfoEducationSection, CandidateInfoExperiencesSection, CandidateSkillsNChars } from 'src/partials'
import { OverlayService, SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutPageTitle, setLayoutShouldShowLoading } from 'src/store/actions'
import { formatDateTime, getApiErrorMessage, getVideoSource } from 'src/utils'
import Style from './style.module.scss'

const reactionMobileBoxSx = { display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '16px' }

const tabs: ITab[] = [
  { title: 'Profile' },
  { title: 'Activity' }
]

interface IIntrosDetailProps {
  id: number
  tab?: number
  onBack?: () => void
}

const PAGE_TITLE = 'Intros/Details'

export const IntrosDetail: FC<IIntrosDetailProps> = ({ id, onBack, tab: selectedTabId = 0 }) => {
  const theme = useTheme()
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { eventHandler, setScreen } = useAnalytic('')
  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)

  const [tab, setTab] = useState(selectedTabId)
  const [forceRender, setForceRender] = useState(0)

  const [isPrivateNotesSaved, setIsPrivateNotesSaved] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')

  const [data, setData] = useState<ICandidateModel>()
  const linkedinWorkingExperiences = useMemo(() => data?.linkedinWorkingExperiences || [], [data])
  const linkedinEducations = useMemo(() => data?.linkedinEducations || [], [data])
  const cache = useCache()
  const otherInfo = useUserOtherInfo(data)

  useEffect(() => {
    if (tab !== selectedTabId) {
      setTab(selectedTabId)
    }
  }, [tab, selectedTabId])

  const handleBack = useCallback(() => {
    onBack?.()
  }, [onBack])

  useSubscribeToGoBack({
    showArrow: true,
    title: PAGE_TITLE,
    handler: handleBack
  })

  const handleSetTab = (tabId: number) => {
    eventHandler({
      key: tabId === 0 ? ETrackingEvent.BTN_DETAIL_PROFILE : ETrackingEvent.BTN_DETAIL_COMMENTS,
      contextData: { candidateId: id }
    })()

    history.replace(`/intros?detailCandidateId=${id}&detailTab=${tabId}`)
  }

  const isSuperNova = data?.reaction?.reaction === ECandidateReaction.SUPER_NOVA
  const isSuperLike = data?.reaction?.reaction === ECandidateReaction.SUPER_LIKE
  const isLike = data?.reaction?.reaction === ECandidateReaction.LIKE

  const handleOpenShare = () => {
    if (!data) return

    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={data?.video?.id} candidateId={data.id} setForceRender={setForceRender}/>
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSavePrivateNotes = useCallback(debounce((unsubscribe1$, candidateId, content) => {
    from(CandidateApi.note(candidateId, { content }))
      .pipe(
        takeUntil(unsubscribe1$),
        tap(() => {
          setIsPrivateNotesSaved(true)
          eventHandler({
            key: ETrackingEvent.INPUT_PRIVATE_NOTES,
            contextData: { candidateId: id }
          })()
        }),
        catchError((error: AxiosError) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        })
      )
      .subscribe(() => {
        timer(3000).pipe(takeUntil(unsubscribe1$)).subscribe(() => {
          setIsPrivateNotesSaved(false)
        })
      })
  }, 300), [])

  const handlePrivateNotes = useUnsubscribeCallback(async (_unsubscribe$, e) => {
    try {
      if (!data) return

      e.persist()
      setIsPrivateNotesSaved(false)
      setNote(e.target.value)
      handleSavePrivateNotes(_unsubscribe$, data.id, e.target.value)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    }
  }, [data, handleSavePrivateNotes])

  const loadIntro = useCallback(async () => {
    try {
      dispatch(setLayoutLoading(true))
      dispatch(setLayoutShouldShowLoading(true))
      const { data } = await CandidateApi.detail(+id, 'interview')
      // data.linkedinWorkingExperiences = LinkedinUtil.checkValidData(data.linkedinWorkingExperiences)

      setData(data)
      setNote(data.privateNote?.content || '')
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
      dispatch(setLayoutShouldShowLoading(false))
    }
  }, [dispatch, id])

  useEffect(() => {
    dispatch(setLayoutPageTitle(PAGE_TITLE))

    if (id) {
      loadIntro()
    }
  }, [id, dispatch, loadIntro])

  useEffect(() => {
    if (!tab) {
      setScreen('intro_profile')
    } else {
      setScreen('share_comments')
    }
  }, [tab, setScreen])

  const [countWatched, setCountWatched] = useState<boolean>(false)
  const handleWatchedVideo = useCallback(async (videoId: number, playing: boolean) => {
    try {
      if (!playing) {
        return
      }

      if (countWatched) {
        return
      }

      setCountWatched(true)
      await CandidateApi.play(videoId)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'Whoops! Network Error'
      })
    }
  }, [countWatched, setCountWatched])

  const handleProgress = useCallback((progress: IProgress) => {
    if (data?.video.id) {
      cache.set(data?.video?.id, progress.playedSeconds)
    }
  }, [data, cache])

  return (
    <div
      ref={containerRef}
      className={Style.introsDetailScreen}
    >
      {data?.id && (
        <div
          className={Style.candidateDetail}
          style={{ [(elWidth / elHeight) > (1076 / 826) ? 'height' : 'width']: '100%' }}
        >
          <div className={clsx('p-4 fx fx-column gap-4', Style.videoContainer, { [Style.isSuperNova]: isSuperNova })}>
            <VideoPlayer
              className={Style.videoPlayer}
              wrapperClassName={Style.videoPlayerWrapper}
              image={data?.video?.urlVideoImageThumbnail}
              videoId={data?.video?.id}
              tracks={data?.video?.tracks}
              playAt={cache.data.get(data?.video?.id || -1)}
              onProgress={handleProgress}
              trackingEvent
              animatedImage={data?.video?.urlVideoAnimatedImage}
              url={getVideoSource(data?.video)}
              style={{ borderRadius: '16px' }}
              onPlayingChange={(playing) => handleWatchedVideo(data?.video?.id, playing)}
              mimeType={data.video?.internalSourceMetadata?.mimeType}
            />
            <TabletAndMobile>
              <ReactionAndShare
                sx={reactionMobileBoxSx}
                isLike={isLike}
                isSuperLike={isSuperLike}
                isSuperNova={isSuperNova}
                onShareClick={handleOpenShare}
                withText
              />
            </TabletAndMobile>
          </div>
          <OnlyMobile>
            <div className={Style.mobileDivider}/>
          </OnlyMobile>
          <div className={clsx('fx fx-column', Style.infoContainer, { [Style.infoContainer_showingComment]: tab === 1 })}>
            <div className={clsx('fx fx-ai-center fx-jc-space-between px-6', { [Style.isSuperNovaHeader]: isSuperNova }, Style.tabSpacing)}>
              <NoTabletOrMobile>
                <ReactionAndShare
                  isSuperLike={isSuperLike}
                  isLike={isLike}
                  isSuperNova={isSuperNova}
                  onShareClick={handleOpenShare}
                />
              </NoTabletOrMobile>
              <Tabs tab={tab} tabs={tabs} setTab={handleSetTab}/>
            </div>
            <Divider/>

            {tab === 0 && (
              <div className={clsx(Style.STInfo, { [Style.STInfo_superNova]: isSuperNova })}>
                <Box paddingX={3} paddingY={2}>
                  <h5 className={Style.FullNameTitle}>{data?.fullName}</h5>
                  {/* <p className={Style.LabelMethod}>Meet {data?.fullName} using these methods: </p> */}
                  <div className={Style.STPersonal}>
                    <Box>
                      <IconPhone/>
                      <Typography variant="body1-regular">{data?.userPhoneNo ? <a className={Style.ALink} href={`tel:+${data?.userPhoneNo}`}>{data?.userPhoneNo}</a> : 'Working On It'}</Typography>
                    </Box>
                    {data?.email && (
                      <Box>
                        <IconEmail width={20} height={20}/>
                        <Typography variant="body1-regular"><a className={Style.ALink} href={`mailto:${data.email}`}>{data.email}</a></Typography>
                      </Box>
                    )}
                    {data?.linkedInUrl && (
                      <Box>
                        <IconLinkedInInfo/><Typography variant="body1-regular"><a className={Style.ALink} target="_blank" href={data.linkedInUrl} rel="noreferrer">{data.linkedInUrl}</a></Typography>
                      </Box>
                    )}
                  </div>

                </Box>
                <Divider/>
                <div className={Style.STAvailabelDate}>
                  <p className={Style.LabelText}>Available Date
                  </p>
                  <Typography
                    variant="body2-bold"
                    color={theme.colors['--color-positive-500']}
                  >{data?.createdAt && formatDateTime(data?.createdAt)}
                  </Typography>
                </div>

                {(otherInfo.shouldShowOtherInfo) && (
                  <>
                    <Divider/>
                    <div className={Style.STAvailabelDate}>
                      <div className="fx flex-column gap-1">
                        <span className={Style.LabelText}>Others</span>
                        <div className={Style.STSubmissionInfoUL}>
                          {otherInfo.workInterestsText && (
                            <span className="body2-regular color-neutral-theme-400">{otherInfo.workInterestsText}</span>
                          )}

                          {otherInfo.willingToRelocateText && (
                            <span className="body2-regular color-neutral-theme-400">{otherInfo.willingToRelocateText}</span>)}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <Divider/>
                <div className={Style.STNotes}>
                  <p className={Style.LabelText}>Private Notes</p>
                  <Textarea
                    label=""
                    placeholder="Only you can see your private notes"
                    value={note}
                    onChange={handlePrivateNotes}
                    autoComplete="off"
                    disableResize
                    minRows={6}
                    maxRows={6}
                    style={{ fontSize: '14px', marginTop: '8px' }}
                  />
                  <Fade in={isPrivateNotesSaved}>
                    <Typography mt="4px" variant="body2" color={theme.colors['--color-neutral-theme-300']}>Note saved</Typography>
                  </Fade>
                </div>

                {linkedinWorkingExperiences.length > 0 && (
                  <CandidateInfoExperiencesSection linkedinWorkingExperiences={linkedinWorkingExperiences}/>
                )}

                {linkedinEducations.length > 0 && (
                  <CandidateInfoEducationSection linkedinEducations={linkedinEducations}/>
                )}

                <CandidateSkillsNChars personalAttributes={data?.personalAttributes}/>

                <div className={Style.Line}/>

                <div className={Style.STIntroduceDate}>
                  <Typography
                    variant="body2-regular"
                    color={theme.colors['--color-neutral-theme-300']}
                  >
                    You were introduced
                    to {data?.fullName} on {data?.interviewed?.createdAt && formatDateTime(data?.interviewed?.createdAt)}
                  </Typography>
                </div>
              </div>
            )}

            {tab === 1 && (
              <Comment
                className="fx-1"
                showCommentInput
                forceRender={forceRender}
                candidateId={data.id}
                videoId={data?.video?.id}
                reaction={data.reaction}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
