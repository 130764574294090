import { Box, Dialog, IconButton, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { IUpgradePlan } from 'src/components'
import { EUpgradeView } from 'src/enums'
import { IconClose } from 'src/icons'
import { Payment } from './components/payment'
import { PaymentSuccess } from './components/payment-success'
import { StoreMobile } from './components/store'
import { VIEW_TITLES } from './constant'
import Style from './style.module.scss'

export interface IBuyMoreCreditsProps {
  open: boolean
  onClose: () => void
}

export const BuyMoreCreditsMobile: FC<IBuyMoreCreditsProps> = (props) => {
  const [view, setView] = useState(0)
  const [plan, setPlan] = useState<IUpgradePlan | null>(null)

  const renderView = useMemo(() => {
    switch (view) {
      case EUpgradeView.PAYMENT:
        return <Payment setView={setView} plan={plan}/>

      case EUpgradeView.PAYMENT_SUCCESS:
        return <PaymentSuccess onClose={props.onClose}/>

      default:
        return <StoreMobile setPlan={setPlan} setView={setView}/>
    }
  }, [view, plan, setView, setPlan, props.onClose])

  useEffect(() => {
    setView(0)
    setPlan(null)
  }, [props.open])

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <div className={Style.container}>
        <div className={Style.header}>
          <IconButton onClick={props.onClose}>
            <IconClose/>
          </IconButton>
          <span className={Style.headerTitle}>
            {VIEW_TITLES.get(view)}
          </span>
          <Box width={24}>{null}</Box>
        </div>
        <div>
          {renderView}
        </div>
      </div>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props}/>
})
