import axios from 'axios'
import { EErrorCode } from 'src/constants'
import { OverlayService } from 'src/services'
import { getApiErrorCode } from './axios.utils'

export const insufficientCreditInterceptor: Parameters<typeof axios.interceptors.response.use>[1] = (error) => {
  const errorCode = getApiErrorCode(error)
  if (errorCode === EErrorCode.INSUFFICIENT_CREDITS) {
    OverlayService.insufficientCredits()
  }

  throw error
}
