import { TCandidateSagaAction } from '../saga/candidate'

/* layout actions */
export const CANDIDATE_EDIT = Symbol('CANDIDATE_EDIT')

/**
 * state
 */
export interface ICandidateState {
  candidate: null
}

/**
 * actions
 */
export type TCandidateAction = TCandidateSagaAction
