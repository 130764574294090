import { styled } from '@mui/material'

export const STAction = styled('div', {
  label: 'Action'
})<{ isMobile: boolean }>(({ isMobile, theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2.5)};
  ${isMobile && 'justify-content: center;'}
`)

export const STFloatAction = styled('div', {
  label: 'STFloatAction'
})<{ isMobile: boolean }>(({ isMobile, theme }) => `
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  height: 88px;
  background: ${theme.colors['--color-neutral-theme-50']};
  padding: ${isMobile ? theme.spacing(3, 4) : theme.spacing(0, 4)};
  transition: all 1s ease;
  box-shadow: 0px -4px 10px rgb(0 0 0 / 15%);

  display: flex;
  gap: ${theme.spacing(2.5)};
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-end'};
`)
