import clsx, { ClassValue } from 'clsx'
import { ComponentProps, FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useUnsubscribe } from 'src/hooks'
import { IFirebaseNotification, NotificationService } from 'src/services'
import { Badge } from '../badge'

export const NoticeBadge: FC<{
  id?: string
  watchKey: keyof IFirebaseNotification
  className?: ClassValue
  children?: ReactNode
  type?: ComponentProps<typeof Badge>['type']
  notRenderIfZero?: boolean

  onNotificationChange?: (newNotification: boolean) => void
}> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const [count, setCount] = useState(0)
  const keyIsNumber = useMemo(() => ['countNotifications', 'countCredits'], [])

  useEffect(() => {
    NotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        if (keyIsNumber.includes(props.watchKey)) {
          const numberNotification = data[props.watchKey] as number ?? 0
          setCount(numberNotification)
          props.onNotificationChange?.(!!numberNotification)
        }

        if (Array.isArray(data[props.watchKey])) {
          const arrayNotification = data[props.watchKey] as number[]
          setCount(arrayNotification.length)
        }
      })
  }, [keyIsNumber, props, unsubscribe$])

  if (props.notRenderIfZero && count === 0) {
    return null
  }

  return (
    <Badge {...props} className={clsx(props.className)} content={count}>
      {props.children}
    </Badge>
  )
}
