import { FC } from 'react'
import { useIfMobileL, useIfTablet } from 'src/hooks'
import { PaymentHistory } from './list'
import { PaymentHistoryMobile } from './list-mobile'

export const LayoutSwitcher: FC = () => {
  const isTablet = useIfTablet()
  const isMobile = useIfMobileL()

  if (isTablet || isMobile) {
    return <PaymentHistoryMobile/>
  }

  return <PaymentHistory/>
}
