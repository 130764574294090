import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { Checkbox } from 'src/components'
import { INotificationSettingRecord } from 'src/interfaces'
import { mapTypeToString } from './columns'
import Style from './style.module.scss'

interface IProps {
  data: INotificationSettingRecord[]
  onChange: (type: INotificationSettingRecord['type'], place: 'email' | 'notification', value: boolean) => void
}

export const MobileView: FC<IProps> = ({ data, onChange }) => {
  const theme = useTheme()

  return (
    <Box className={Style.BoxMoblieContainer}>
      <Typography variant="h5">Configure how you would like to be notified</Typography>
      <Box className={Style.BoxMoblieWrapper}>
        <Typography variant="body1-bold" color={theme.colors['--color-neutral-theme-900']}>In KnowMe</Typography>
        {data.map((item, index) => (
          <Box className={Style.record} key={index + '_inApp'}>
            <Box className={Style.record_text}>
              {mapTypeToString(item.type)}
            </Box>
            <Box className={Style.record_checkbox}>
              <Checkbox
                checked={item.notification}
                onChange={(_, newValue) => onChange(item.type, 'notification', newValue)}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={Style.BoxMoblieWrapper}>
        <Typography variant="body1-bold" color={theme.colors['--color-neutral-theme-900']}>Via Email</Typography>
        {data.map((item, index) => (
          <Box className={Style.record} key={index + '_email'}>
            <Box className={Style.record_text}>
              {mapTypeToString(item.type)}
            </Box>
            <Box className={Style.record_checkbox}>
              <Checkbox
                checked={item.email}
                onChange={(_, newValue) => onChange(item.type, 'email', newValue)}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
