import { FC } from 'react'
import {
  Button
} from 'src/components'
import { IconEditRegular } from 'src/icons'
import { TARGET_PUBLIC_GUEST_EDIT_JOB } from 'src/services/tour/guest-manage-job-tour.service'
import Style from './style.module.scss'

interface IProps {
  onClick?: () => void
}

export const ButtonEdit: FC<IProps> = (props) => {
  return (
    <>
      <Button
        type="button"
        order="tertiary"
        className={Style.button}
        onClick={props.onClick}
        id={TARGET_PUBLIC_GUEST_EDIT_JOB.getValue()}
      >
        <IconEditRegular/>
      </Button>
    </>
  )
}
