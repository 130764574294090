import { Typography } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import Style from './style.module.scss'

export const Label: FC<ComponentProps<typeof Typography>> = (props) => {
  return (
    <Typography
      variant="meta-bold"
      {...props}
      className={clsx(Style.label, props.className)}
    />
  )
}
