import { IAvatarUploaded, IUserModel, IVideoModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class ProfileApi {
  static readonly _prefix = '/profile'

  /**
   * Get authenticated profile
   */
  static detail(): TAxiosResponseData<IUserModel> {
    return axiosHiringApi.get(this._prefix)
  }

  /**
   * Update profile
   */
  static update(payload: IUserModel): TAxiosResponseData<void> {
    return axiosHiringApi.post(this._prefix, payload)
  }

  /**
   * Update basic profile
   */
  static updateBasic(payload: {
    firstName?: string
    lastName?: string
    username?: string
    email?: string
  }): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/basic`, payload)
  }

  /**
   * Update public profile
   */
  static updatePublic(payload: {
    companyKey?: string
    companyName?: string
    companyUrls?: string[]
    scheduleUrl?: string
    bio?: string
  }): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/public`, payload)
  }

  /**
   * Upload avatar
   */
  static uploadAvatar(payload: { key: string } | FormData): TAxiosResponseData<{ pfp: IAvatarUploaded }> {
    return axiosHiringApi.post(`${this._prefix}/avatar`, payload)
  }

  /**
   * Upload video
   */
  static uploadVideo(payload: {
    key: string
    photoKey?: string
    transcription?: string
    thumbnailOffset?: number
  }): TAxiosResponseData<{ pfv: IVideoModel }> {
    return axiosHiringApi.post(`${this._prefix}/video`, payload)
  }

  /**
   * Upload video
   */
  static uploadOnboardingVideo(payload: {
    key: string
    photoKey?: string
    transcription?: string
    thumbnailOffset?: number
  }): TAxiosResponseData<{ pfv: IVideoModel }> {
    return axiosHiringApi.post(`${this._prefix}/onboarding-video`, payload)
  }

  /**
   * Remove video
   */
  static delete(): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/video`)
  }
}
