import { useHistory } from 'react-router'
import { BehaviorSubject, finalize, from, of, switchMap, take } from 'rxjs'
import { RestTourRepository, TourRepository } from 'src/api/tour'
import { VVCPopup } from 'src/components'
import { EMessage } from 'src/enums'
import { IPlaceholder } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { WithOutNextComplete } from 'types/rxjs'
import { OverlayService } from '../overlay.service'
import { SnackbarService } from '../snackbar.service'

type TourIDs = 'create-job' | 'share-job'
type GuestViewIDs = 'guest-view-job' | 'guest-manage-job'
type GuestOnboardingIDs = 'guest-onboarding-vibe-applicants' | 'guest-onboarding-create-job'

export interface IMission {
  id: TourIDs | GuestViewIDs | GuestOnboardingIDs
  title: string
  checked: boolean
  description: string
  creditAmount: number
  disabled: boolean | string
}

export class PopupTourService {
  public static adapter: TourRepository = RestTourRepository

  private static _isShowingPopup$ = new BehaviorSubject(false)
  private static _missions$ = new BehaviorSubject<IMission[]>([])
  private static _loadingMissions$ = new BehaviorSubject(false)
  private static _loadingFinish$ = new BehaviorSubject(false)
  private static _coinFlying$ = new BehaviorSubject(false)
  private static _currentMissionId$ = new BehaviorSubject<IMission['id'] | undefined>(undefined)
  private static _showingVVCPopup$ = new BehaviorSubject<boolean>(false)
  private static _pfvFiles$ = new BehaviorSubject<{ file: File; placeholder?: IPlaceholder; thumbnailOffset?: number } | null>(null)

  public static get isShowingPopup$(): WithOutNextComplete<typeof PopupTourService._isShowingPopup$> {
    return PopupTourService._isShowingPopup$
  }

  public static get missions$(): WithOutNextComplete<typeof PopupTourService._missions$> {
    return PopupTourService._missions$
  }

  public static get loadingMission$(): WithOutNextComplete<typeof PopupTourService._loadingMissions$> {
    return PopupTourService._loadingMissions$
  }

  public static get loadingFinish$(): WithOutNextComplete<typeof PopupTourService._loadingFinish$> {
    return PopupTourService._loadingFinish$
  }

  public static get coinFlying$(): WithOutNextComplete<typeof PopupTourService._coinFlying$> {
    return PopupTourService._coinFlying$
  }

  public static get currentMissionId$(): WithOutNextComplete<typeof PopupTourService._currentMissionId$> {
    return PopupTourService._currentMissionId$
  }

  public static get showingVVCPopup$(): WithOutNextComplete<typeof PopupTourService._showingVVCPopup$> {
    return PopupTourService._showingVVCPopup$
  }

  public static get pfvFiles(): WithOutNextComplete<typeof PopupTourService._pfvFiles$> {
    return PopupTourService._pfvFiles$
  }

  public static show() {
    this._isShowingPopup$.next(true)
    this._currentMissionId$.next(undefined)
    this.fetchMissions()
  }

  public static hide() {
    if (this._isShowingPopup$.getValue()) {
      this._isShowingPopup$.next(false)
    }
  }

  public static fetchMissionIfShowing() {
    if (this._isShowingPopup$.getValue()) {
      this.fetchMissions()
    }

    return this
  }

  public static fetchMissions() {
    this._loadingMissions$.next(true)
    from(PopupTourService.adapter.get())
      .pipe(
        take(1),
        finalize(() => this._loadingMissions$.next(false))
      )
      .subscribe((missions) => {
        this._missions$.next(missions)
      })
  }

  public static startFlyingCoin() {
    this._coinFlying$.next(true)
  }

  public static stopFlyingCoin() {
    this._coinFlying$.next(false)
  }

  public static showVVCPopup() {
    OverlayService.setOverlay(VVCPopup.getOverlayState())
    this._showingVVCPopup$.next(true)
  }

  public static hideVVCPopup() {
    this._showingVVCPopup$.next(false)
    this._pfvFiles$.next(null)
    return this
  }

  public static setPfvFile(file: { file: File; placeholder?: IPlaceholder; thumbnailOffset?: number } | null) {
    this._pfvFiles$.next(file)
    return this
  }

  /**
   * Call api to mark the current mission as completed,
   * This also show the Tour Popup component, stop the current tour, add credits.. (if any)
   */
  public static completeMission(id: IMission['id']) {
    this._loadingMissions$.next(true)
    this._loadingFinish$.next(true)

    return from(PopupTourService.adapter.finish(id))
      .pipe(
        take(1),
        switchMap((res) => {
          // by opening the tour popup, we indirectly stop the current tour
          // see: https://github.com/knowme/knowme-hiring-web/blob/289458abc04ab56c90b2bbee96e58d3713459640/src/components/tour/components/popup/index.tsx#L58
          this._isShowingPopup$.next(true)

          this._currentMissionId$.next(undefined)

          return from(PopupTourService.adapter.get())
            .pipe(
              switchMap((missions) => {
                this._missions$.next(missions)

                return of(res)
              })
            )
        }),
        switchMap((finishedMission) => {
          if (finishedMission?.creditAmount) {
            this.startFlyingCoin()
            SnackbarService.push({
              position: { vertical: 'top', horizontal: 'center' },
              severity: EMessage.SUCCESS,
              content: `${finishedMission?.creditAmount} ${finishedMission?.creditAmount > 1 ? 'credits' : 'credit'} earned`
            })
          }

          return of(null)
        }),
        finalize(() => {
          this._loadingMissions$.next(false)
          this._loadingFinish$.next(false)
        })
      )
  }

  public static onTriggerMission(id: IMission['id'], history?: ReturnType<typeof useHistory>) {
    if (['create-job', 'share-job'].includes(id)) {
      history?.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
    }

    if (id === 'guest-view-job') {
      const location = history?.location.pathname
      const paths = location?.split('/').filter(Boolean)
      const path = `/${paths?.[0]}/${paths?.[1]}`
      history?.push(path)
    }

    if (id === 'guest-manage-job') {
      const location = history?.location.pathname
      const paths = location?.split('/').filter(Boolean)
      const path = `/${paths?.[0]}/${paths?.[1]}/submissions`
      history?.push(path)
    }

    if (id === 'guest-onboarding-vibe-applicants') {
      history?.push(generate(ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING_SUBMISSIONS))
    }

    if (id === 'guest-onboarding-create-job') {
      history?.push(generate(ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING_NEW))
    }

    this._currentMissionId$.next(id)
  }
}
