import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export class SettingTabService {
  private static currentTab$ = new BehaviorSubject<number>(0)

  static get currentTab(): WithOutNextComplete<typeof this.currentTab$> {
    return this.currentTab$
  }

  static setTab(tab: number) {
    this.currentTab$.next(tab)
  }
}
