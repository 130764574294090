import { IRouterOption } from 'src/router'
import { Home } from './components/landing'
import { QuickPassword } from './components/quick-password'
import { EHomeRoutes } from './routes.enum'

export { EHomeRoutes }

export const HomeRoutes: IRouterOption[] = [
  {
    path: '/home/:sharingToken',
    component: Home,
    name: EHomeRoutes.HOME,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/quick-password/:sharingToken',
    component: QuickPassword,
    name: EHomeRoutes.QUICK_PASSWORD,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
