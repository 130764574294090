import { BehaviorSubject, Subject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export const WindowService = new (
  class {
    readonly message$ = new Subject<MessageEvent<any>>()

    private readonly _hidden$ = new BehaviorSubject(false)
    get hidden$(): WithOutNextComplete<typeof this._hidden$> {
      return this._hidden$
    }

    private readonly _isFocus$ = new BehaviorSubject(true)
    get isFocus$(): WithOutNextComplete<typeof this._isFocus$> {
      return this._isFocus$
    }

    constructor() {
      window.addEventListener('message', (event) => this.message$.next(event))

      document.addEventListener('visibilitychange', (event) => this._hidden$.next(document.hidden))

      window.onblur = () => this._isFocus$.next(false)
      window.onfocus = () => this._isFocus$.next(true)
    }
  }
)()
