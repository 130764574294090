import { Badge as MuiBadge, useTheme } from '@mui/material'
import { ComponentProps, FC, useMemo } from 'react'
import Style from './style.module.scss'

type TBadgePropsDefault = ComponentProps<typeof MuiBadge>

interface ISize {
  medium?: {
    minWidth?: number
    height?: number
    fontSize?: number
    top?: number
    right?: number
  }
  small?: {
    minWidth?: number
    height?: number
    fontSize?: number
    top?: number
    right?: number
  }
}

const size: ISize = {
  medium: {
    minWidth: 32,
    height: 32,
    fontSize: 14
  },
  small: {
    minWidth: 20,
    height: 20,
    fontSize: 12
  }
}

interface IProps extends Omit<TBadgePropsDefault, 'content'> {
  type?: keyof ISize
  content?: number
  background?: string
  colorText?: string
}

export const Badge: FC<IProps> = ({ type, content, background, colorText, ...props }) => {
  const theme = useTheme()
  const badgeContent = useMemo(() => content && content > 99 ? '99+' : content, [content])

  return (
    <MuiBadge
      id={props.id}
      max={999}
      badgeContent={badgeContent}
      className={Style.badge}
      sx={{
        '& .MuiBadge-badge': {
          background: background || theme.colors['--color-negative-500'],
          color: colorText || theme.colors['--color-neutral-theme-50'],
          padding: Number(content) < 10 ? 0 : '0 4px',
          position: 'unset',
          transform: 'unset',
          ...size[type || 'medium']
        }
      }}
    >
      {props.children}
    </MuiBadge>
  )
}

Badge.defaultProps = {
  type: 'medium',
  content: 0
}
