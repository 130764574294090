import { cloneDeep, isEqual, pick } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { ProfileApi, UploadApi } from 'src/api'
import { EFileUploadKind, IPlaceholder, ISettingsProfile, IUserModel } from 'src/interfaces'
import { WithOutNextComplete } from 'types/rxjs'

type TSettingProfileWithCompany = ISettingsProfile & { company?: IUserModel['company']; pfv?: IUserModel['pfv'] }

export class ProfileService {
  private static _pfv$ = new BehaviorSubject<IUserModel['pfv']>(undefined)
  private static _settingsProfile$ = new BehaviorSubject<ISettingsProfile>({})
  private static _initialSettingProfile: ISettingsProfile

  public static get pfv$(): WithOutNextComplete<typeof this._pfv$> {
    return this._pfv$
  }

  public static get pfv() {
    return this._pfv$.getValue()
  }

  public static get settingProfile$(): WithOutNextComplete<typeof this._settingsProfile$> {
    return this._settingsProfile$
  }

  public static patch(payload: Partial<ISettingsProfile>, commit?: boolean) {
    const current = this._settingsProfile$.getValue()
    this._settingsProfile$.next({
      ...current,
      ...payload
    })

    if (commit) {
      this._initialSettingProfile = cloneDeep({
        ...this._initialSettingProfile,
        ...payload
      })
    }

    return this
  }

  public static setSettingProfile({ company, ...settingData }: TSettingProfileWithCompany) {
    this.cleanUpAvatarFile()

    const transformedData: ISettingsProfile = {
      ...pick(settingData, [
        'bio',
        'scheduleUrl'
      ]),
      companyName: company?.name,
      companyUrls: company?.urls,
      avatar: {
        src: company?.logo?.url
      },
      video: settingData.pfv
    }

    this._initialSettingProfile = cloneDeep(transformedData)
    this._settingsProfile$.next(transformedData)
  }

  public static resetSettingProfile() {
    this.cleanUpAvatarFile()
    this._settingsProfile$.next(cloneDeep(this._initialSettingProfile))
  }

  public static isSettingProfileChanged(profile: TSettingProfileWithCompany) {
    return !profile || !this._initialSettingProfile
      ? false
      : !isEqual(profile, this._initialSettingProfile)
  }

  public static async uploadPfv(source: File | Blob, placeholder?: IPlaceholder, transcription?: string, thumbnailOffset?: number) {
    const payload: Parameters<typeof ProfileApi.uploadVideo>[0] = {
      key: '',
      transcription,
      thumbnailOffset
    }

    payload.key = await UploadApi.upload({
      kind: source.type.startsWith('audio')
        ? EFileUploadKind.AUDIO_VIBE_CHECK
        : EFileUploadKind.VIDEO_VIBE_CHECK,
      file: source
    })

    if (source.type.startsWith('audio')) {
      if (placeholder?.file) {
        // case 1: placeholder is image uploaded by user
        payload.photoKey = await UploadApi.upload({
          kind: EFileUploadKind.AUDIO_VIBE_PHOTO,
          file: placeholder.file
        })
      } else {
        // case 2: placeholder is image from library
        payload.photoKey = placeholder?.key
      }
    }

    const { data: { pfv } } = await ProfileApi.uploadVideo(payload)

    this.patch({ video: pfv }, true)
    return pfv
  }

  public static cleanUpAvatarFile() {
    const currentValue = this.settingProfile$.getValue()
    if (currentValue.avatar?.file && currentValue.avatar?.src) {
      URL.revokeObjectURL(currentValue.avatar?.src)
    }
  }
}
