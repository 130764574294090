import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  position: relative;
  height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(7)}
`)

export const STForm = styled('form', {
  label: 'Container'
})(({ theme }) => `
  width: 520px;
  box-shadow: 0px 24px 56px -12px rgb(37  38 46 / 10%);
  border-radius: 32px;
  background: ${theme.colors['--color-neutral-theme-50']};
  padding: ${theme.spacing(7)}
`)
