import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { Button, Share } from 'src/components'
import { CommentCardEmptyImg } from 'src/images'
import { OverlayService } from 'src/services'
import Style from './style.module.scss'

interface IProps {
  videoId: number
  candidateId: number
  submissionId?: number
  loadData?: () => void
  hideInvite?: boolean
}

export const Empty: FC<IProps> = ({ videoId, candidateId, submissionId, loadData, hideInvite }) => {
  const theme = useTheme()

  const handleOpenShare = () => {
    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={videoId} submissionId={submissionId} candidateId={candidateId} loadData={loadData}/>
    })
  }

  return (
    <div className={Style.container}>
      <img src={CommentCardEmptyImg} alt="no comment"/>
      <Typography
        variant="h5"
        color={theme.colors['--color-neutral-theme-250']}
      >
        No feedback yet
      </Typography>

      {hideInvite
        ? null
        : (
          <Button
            order="secondary"
            width={335}
            sx={{ marginTop: 4 }}
            onClick={handleOpenShare}
          >
            Invite Colleagues for Feedback
          </Button>
        )}
    </div>
  )
}
