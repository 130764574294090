import MenuItemUnstyled from '@mui/base/MenuItemUnstyled'
import MenuUnstyled from '@mui/base/MenuUnstyled'
import { ClickAwayListener } from '@mui/material'
import clsx from 'clsx'
import * as React from 'react'
import { useEffect } from 'react'
import { IconArrowDown } from '../../icons'
import { ButtonTertiary } from '../button/styled'
import Style from './style.module.scss'

export interface ISortMenuOption<T> {
  label: string
  value: T
}

interface IProps<T> {
  placeholder?: string
  value?: T
  options: ISortMenuOption<T>[]
  onChange?: (value: T, option: ISortMenuOption<T>) => void
  className?: string
  menuClassName?: string
}

export const SortMenu = <T = any>({ onChange, placeholder, value, options, className, menuClassName }: IProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuStyle = React.useMemo(() => {
    if (anchorEl?.offsetWidth) {
      return { width: `${anchorEl.offsetWidth}px` || 'auto' }
    }

    return { width: 'auto' }
  }, [anchorEl])

  const [selected, setSelected] = React.useState<ISortMenuOption<T>>()

  useEffect(() => {
    if (value) {
      const defaultOption = options.find(option => option.value === value)
      setSelected(defaultOption)
    }
  }, [value, options])

  const handleSelect = (option: ISortMenuOption<T>) => {
    setSelected(option)
    onChange?.(option.value, option)
    handleClose()
  }

  return (

    <ClickAwayListener onClickAway={handleClose}>
      <>
        <ButtonTertiary
          id="basic-button"
          aria-controls={open ? 'basic-video-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={clsx(Style.STButton, className)}
          endIcon={<IconArrowDown/>}
        >
          {selected ? selected.label : placeholder}
        </ButtonTertiary>
        <MenuUnstyled
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={clsx(Style.STMenu, menuClassName)}
          style={menuStyle}
        >
          {options.map((option, key) => (
            <MenuItemUnstyled
              key={key}
              onClick={() => handleSelect(option)}
            >
              {/* <span style={option === selected ? { color: theme.colors['--color-neutral-theme-700'], fontWeight: 700 } : {}}>{option.label}</span> */}
              <span>{option.label}</span>
            </MenuItemUnstyled>
          ))}
        </MenuUnstyled>
      </>
    </ClickAwayListener>
  )
}
