import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TypographyProps } from '@mui/system'
import { toCssVariables } from 'src/constants'

const colorMapping: Record<string, keyof ReturnType<typeof toCssVariables>> = {
  default: '--color-neutral-theme-300',
  success: '--color-positive-500',
  error: '--color-negative-500'
}

export const STMessage = styled(Typography, {
  shouldForwardProp: prop => prop !== 'showMessage',
  label: 'TextMessage'
})<TypographyProps & {status?: string; showMessage?: boolean}>`
  color: ${({ status, theme }) => status ? theme.colors[colorMapping[status]] : 'initial'};
  visibility: ${({ showMessage }) => showMessage ? 'visible' : 'hidden'};
  display: block;
  min-height: 24px;
`

STMessage.defaultProps = {
  status: 'default',
  showMessage: false
}
