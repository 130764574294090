import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { RecordingService } from 'src/services'
import { TeleprompterUtils } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  height?: number | string
  play: boolean
  recordingService: RecordingService
  delay?: number
  width?: number
  color?: string
}

export const Teleprompter: FC<IProps> = (props) => {
  const teleprompter = useBehaviorMapper(props.recordingService.teleprompter$)
  const teleRef = useRef<HTMLParagraphElement>(null)
  const intervalIdRef = useRef<NodeJS.Timer>()
  const [previewing, setPreviewing] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [shouldScrollTopWhenStop, setShouldScrollTopWhenStop] = useState(true)

  const showed = teleRef.current?.getBoundingClientRect().width !== 0
  useEffect(() => {
    if (showed && !isPlaying && !previewing && shouldScrollTopWhenStop) {
      teleRef.current?.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [isPlaying, previewing, showed, shouldScrollTopWhenStop])

  useEffect(() => {
    const timeout = setTimeout(
      () => setIsPlaying(props.play),
      props.play
        ? props.delay || 0
        : 0
    )
    return () => {
      clearTimeout(timeout)
    }
  }, [props.delay, props.play])

  useEffect(() => {
    if (!teleRef.current) {
      return
    }

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (window === undefined) {
      return
    }

    const scrollHeight = teleRef.current.scrollHeight

    if (!isPlaying && !previewing) {
      return
    }

    intervalIdRef.current = setInterval(() => {
      if ((teleRef.current?.scrollTop || 0) <= scrollHeight) {
        teleRef.current?.scrollTo(0, teleRef.current.scrollTop + 1)
      }
    }, teleprompter.speed)

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [teleRef, props, isPlaying, teleprompter.speed, previewing])

  const handleTogglePlaying = useCallback(() => {
    setShouldScrollTopWhenStop(!props.play)
    const playing = isPlaying || previewing

    setPreviewing(!playing)
    setIsPlaying(!playing)
  }, [isPlaying, previewing, props.play])

  if (!teleprompter.isOpening || teleprompter.text === '') {
    return null
  }

  return (
    <>
      <p
        ref={teleRef}
        className={Style.teleprompterContainer}
        style={{ width: props.width, maxWidth: 400, color: props.color }}
      >
        {teleprompter.text}
      </p>
      <div className={Style.buttonGroup}>

        <div
          role="button"
          className={Style.buttonIcon}
          onClick={handleTogglePlaying}
        >
          {(previewing || isPlaying)
            ? (
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.80951 0.25C1.46141 0.25 1.12757 0.388281 0.881432 0.634422C0.63529 0.880564 0.497009 1.2144 0.497009 1.5625V12.4375C0.497009 13.162 1.08501 13.75 1.80951 13.75H4.43451C4.78261 13.75 5.11645 13.6117 5.36259 13.3656C5.60873 13.1194 5.74701 12.7856 5.74701 12.4375V1.5625C5.74701 1.2144 5.60873 0.880564 5.36259 0.634422C5.11645 0.388281 4.78261 0.25 4.43451 0.25H1.80951ZM8.55951 0.25C8.21141 0.25 7.87757 0.388281 7.63143 0.634422C7.38529 0.880564 7.24701 1.2144 7.24701 1.5625V12.4375C7.24701 13.162 7.83501 13.75 8.55951 13.75H11.1845C11.5326 13.75 11.8664 13.6117 12.1126 13.3656C12.3587 13.1194 12.497 12.7856 12.497 12.4375V1.5625C12.497 1.39014 12.4631 1.21947 12.3971 1.06023C12.3311 0.900988 12.2345 0.756299 12.1126 0.634422C11.9907 0.512546 11.846 0.415867 11.6868 0.349908C11.5275 0.283949 11.3569 0.25 11.1845 0.25H8.55951Z" fill="white"/>
              </svg>
            )
            : (
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.37633 0.510179C2.06389 -0.232981 0.4375 0.71511 0.4375 2.22334V11.7809C0.4375 13.2894 2.06444 14.2375 3.37688 13.4938L11.8131 8.71318C13.1442 7.95889 13.1439 6.04102 11.8125 5.28717L3.37633 0.510179Z" fill="white"/>
              </svg>
            )}

        </div>

        <button
          className={Style.buttonIcon}
          onClick={() => props.recordingService.setTeleprompter({
            ...teleprompter,
            speed: TeleprompterUtils.nextSpeed(teleprompter.speed)
          })}
        >
          {TeleprompterUtils.speedToTxt(teleprompter?.speed)}
        </button>
      </div>
    </>
  )
}
