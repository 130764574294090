import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export class CreditService {
  private static showBuyMoreCreditsMobileModal$ = new BehaviorSubject<boolean>(false)

  public static get showBuyMoreCreditsMobileModal(): WithOutNextComplete<typeof this.showBuyMoreCreditsMobileModal$> {
    return this.showBuyMoreCreditsMobileModal$
  }

  public static setShowBuyMoreCreditsMobileModal(show: boolean) {
    this.showBuyMoreCreditsMobileModal$.next(show)
  }

  public static handleCloseBuyMoreCreditsMobileModal = () => {
    this.setShowBuyMoreCreditsMobileModal(false)
  }
}
