import { Typography } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import { EEnv } from 'src/constants'
import Style from './style.module.scss'

export const CreditInstruction: FC = () => {
  return (
    <div>
      <Typography variant="h3" className={clsx('absolute text-center', Style.headerText)} color="#FFFFFF">What is a Credit?</Typography>
      <div className={Style.container}>
        <div className={Style.cover}>
          <div className={clsx('w-100 h-100', Style.coverImg)}>
            <video
              autoPlay
              loop
              muted
              style={{ width: '100%', height: 'auto' }}
              src={`${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/images/hiring-manager/video/explain-credit.mp4`}
            />
          </div>
        </div>
      </div>
      <p className="subtitle--medium text-center mt-7 neutral-250">
        With a Credit, You can Unlock Talent Contact Information by Clicking on "Introduce Me"
      </p>
    </div>
  )
}
