import { Typography, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { InvoiceApi } from 'src/api'
import { Button, Table, Upgrade, useAnalytic } from 'src/components'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useUnsubscribe } from 'src/hooks'
import { IInvoiceModel, IPaginationResponse } from 'src/interfaces'
import { OverlayService, SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutPageTitle } from 'src/store/actions'
import { getPaymentIntentId } from 'src/store/selectors'
import { ensureArray, getApiErrorMessage } from 'src/utils'
import { Empty } from '../empty'
import { RenderColumns } from './columns'
import { STContainer, STHeader } from './styled'

export const PaymentHistory: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const paymentIntentId = useAppSelector(getPaymentIntentId)
  const { eventHandler } = useAnalytic('payment_history')
  const unsubscribe$ = useUnsubscribe()

  const [data, setData] = useState<IPaginationResponse<IInvoiceModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const handleBuyMoreUnit = () => {
    OverlayService.setOverlay({
      open: true,
      content: <Upgrade/>
    })
  }

  const handlePDF = async (id: number) => {
    try {
      dispatch(setLayoutLoading(true))

      const { data } = await InvoiceApi.detail(id)
      window.open(data.pdfUrl)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally { dispatch(setLayoutLoading(false)) }
  }

  const loadPayment = useCallback((page = 1, limit = 10) => {
    dispatch(setLayoutLoading(true))

    from(InvoiceApi.pagination({ page, limit }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })

          return EMPTY
        }),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe(({ data }) => {
        setData({
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: ensureArray(data.rows)
        })
      })
  }, [dispatch, unsubscribe$])

  useEffect(() => {
    dispatch(setLayoutPageTitle('Payment History'))
    loadPayment()
  }, [dispatch, loadPayment, paymentIntentId])

  return (
    <STContainer>
      <STHeader>
        <Typography variant="h5">Invoice</Typography>
        {!!data.rows?.length && (
          <Button width={188} height={40} onClick={eventHandler(ETrackingEvent.BTN_STORE, handleBuyMoreUnit)}>
            Buy Credits
          </Button>
        )}

      </STHeader>
      {data.rows?.length
        ? (
          <Table
            data={data.rows}
            columns={RenderColumns({ theme, handlePDF, eventHandler })}
            page={data.page}
            pages={data.pages}
            loadPage={loadPayment}
          />
        )
        : <Empty/>}
    </STContainer>
  )
}
