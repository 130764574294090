import { EUserGuide } from 'src/enums'
import { IUserModel, IUserStatModel } from 'src/interfaces'
import { TAuthSagaAction } from '../saga/auth'

/* authentication actions */
export const AUTH_LOGIN_SUCCESS = Symbol('AUTH_LOGIN_SUCCESS')
export const AUTH_GET_PROFILE = Symbol('AUTH_GET_PROFILE')
export const AUTH_SET_STATS = Symbol('AUTH_SET_STATS')
export const AUTH_SET_CREDENTIALS = Symbol('AUTH_SET_CREDENTIALS')
export const AUTH_LOGOUT = Symbol('AUTH_LOGOUT')
export const AUTH_LOGOUT_SUCCESS = Symbol('AUTH_LOGOUT_SUCCESS')
export const AUTH_SET_GUIDE = Symbol('AUTH_SET_GUIDE')

/**
 * state
 */
export interface IAuthState {
  isAuthenticated: boolean | null
  credentials: IUserModel
  stats: IUserStatModel
  forceOpenTutorial: boolean
}

/**
 * actions
 */
export type TAuthAction =
| {
  type: typeof AUTH_SET_CREDENTIALS | typeof AUTH_LOGOUT_SUCCESS | typeof AUTH_LOGIN_SUCCESS
  value?: IUserModel
}
| TAuthSagaAction
| {
  type: typeof AUTH_SET_STATS
  value: IUserStatModel
}
| {
  type: typeof AUTH_SET_GUIDE
  value: {
    [key in EUserGuide]?: boolean | number
  }
}
