import { array, number, object, string } from 'yup'

export const createCampaignSchema = object().shape({
  jobTitle: string().required('This field is required'),
  location: string().required('This field is required'),
  salaryValue: object()
    .shape({
      min: number()
        .min(1, 'This field is required')
        .required('This field is required'),
      max: number()
        .min(1, 'This field is required')
        .required('This field is required')
    })
    .nullable()
    .required('This field is required'),
  companyName: string().required('This field is required'),
  companyWebsiteUrls: array().length(1, 'This field is required')
    .of(string().required('This field is required'))
    .required('This field is required')
})
