/**
 * [].clear polyfill
 */
if (!Array.prototype.clear) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'clear', {
    value() {
      this.splice(0, this.length)
    },
    enumerable: false
  })
}

/**
 * [].random polyfill
 */
if (!Array.prototype.random) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'random', {
    value() {
      return this[Math.floor(Math.random() * this.length)]
    },
    enumerable: false
  })
}

/**
 * Array.ensure polyfill
 */
export const ensure = <T = any>(array: T | T[], defaults?: Array<T>): T[] => {
  if (Array.isArray(array)) {
    return array
  }

  if (Array.isArray(defaults)) {
    return defaults
  }

  return [array]
}

if (!Array.ensure) {
  Array.ensure = ensure
}
