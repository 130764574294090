import clsx from 'clsx'
import { isEmpty, isNumber } from 'lodash'
import { CSSProperties, FC, ForwardedRef, MutableRefObject, SyntheticEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ReactPlayer from 'react-player'
import { ReactPlayerProps } from 'react-player/types/lib'
import { takeUntil } from 'rxjs'
import { RECORD_FILE_NAME } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { useAsRef, useBehaviorMapper, useDidMountEffect, useTouchDevice, useUnsubscribe } from 'src/hooks'
import { IconPlay, IconSpeakerOff, IconSpeakerOn } from 'src/icons'
import { IUserModel } from 'src/interfaces'
import { OverlayService, SettingsService } from 'src/services'
import { backgroundImage } from 'src/utils'
import { useAnalytic } from '../analytic-provider'
import { Spinner } from '../spinner'
import { VideoProcessingSpinner } from '../video-processing-spinner'
import { AuthorAndControl } from './author-and-control'
import { EHlsEvents } from './interfaces'
import { MouseContext } from './mouse.context'
import { PlayerService } from './player.service'
import Style from './style.module.scss'
import { STPlay, STSpeaker, STSpinner, STVideoPlayer } from './styled'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

interface IProps {
  id?: string
  ref?: MutableRefObject<ReactPlayerProps['ref']> | ForwardedRef<ReactPlayerProps['ref']>
  url?: ReactPlayerProps['url'] | File | Blob
  className?: string
  wrapperClassName?: string
  style?: CSSProperties
  playAt?: number
  onProgress?: (progress: IProgress) => void
  image?: string | File | Blob
  animatedImage?: string
  tracks?: {
    label: string
    kind: TextTrackKind
    src: string
    srcLang: string
    default: boolean
  }[]
  /**
   * Not really will show processing, it will also depend on other stuffs
   */
  showProcessing?: boolean
  hideCC?: boolean
  isPlay?: boolean
  duration?: number
  isStop?: boolean
  mini?: boolean
  isMuted?: boolean
  videoId?: string | number
  mimeType?: string
  isLinkedin?: boolean
  isShowThumbnail?: boolean
  trackingEvent?: boolean
  onPlayingChange?: (playing: boolean) => void
  forceRender?: boolean
  hideSpeaker?: boolean
  hideStartEnd?: boolean
  author?: IUserModel
  onBeforeGoToDetail?: () => void
  detailUrl?: string
  onBeforePlay?: () => boolean
  /**
   * When passed, overwrite the `detailUrl` and `onBeforeGoToDetail` props
   */
  onDetailClicked?: () => void
  action?: React.ReactNode
  optionalTrackEventData?: object
  showSpeed?: boolean

  shouldFlipBg?: boolean
}

export interface IProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

const speeds = [
  { label: '1x', value: 1 },
  { label: '1.5x', value: 1.5 },
  { label: '2x', value: 2 }
]

export const VideoPlayer: FC<IProps> = forwardRef<typeof ReactPlayer, IProps>(
  ({ tracks, hideSpeaker = false, hideCC = false, optionalTrackEventData = {}, ...props }: IProps, ref: ForwardedRef<typeof ReactPlayer>) => {
    const { analytic } = useAnalytic()
    const unsubscribe$ = useUnsubscribe()
    const settings = useBehaviorMapper(SettingsService.settings$)
    const isTouchDevice = useTouchDevice()

    const playerRef = useAsRef(PlayerService.genRef())
    const [mouseIn, setMouseIn] = useState(false)
    const trackRef = useRef<HTMLParagraphElement>(null)

    const [isFlip, setIsFlip] = useState(false)
    const [, setEnableSubtitles] = useState(false)
    const [isShowVideo, setIsShowVideo] = useState(false)
    const [isMuted, setIsMuted] = useState(PlayerService.muted)
    const [isSpinner, setIsSpinner] = useState(false)
    const [player, setPlayer] = useState<_ReactPlayer>()
    const [playing, setPlaying] = useState(false)
    const [playEnd, setPlayEnd] = useState(false)
    const [duration, setDuration] = useState(0)
    const [progress, setProgress] = useState<IProgress>({
      played: 0,
      playedSeconds: 0,
      loaded: 0,
      loadedSeconds: 0
    })

    const [url, setUrl] = useState<ReactPlayerProps['url']>()
    useEffect(() => {
      if ((props.url instanceof Blob) || (props.url instanceof File)) {
        const blobUrl = URL.createObjectURL(props.url)
        setUrl(blobUrl)
        setIsFlip(props.url instanceof File && props.url.name === RECORD_FILE_NAME)
        return () => {
          URL.revokeObjectURL(blobUrl)
          setIsFlip(false)
        }
      }

      setUrl(props.url)

      if (props.url && typeof props.url === 'string') {
        const url = new URL(props.url as string)
        if (url.searchParams.get('hflip')) {
          setIsFlip(true)
        }

        return () => {
          setIsFlip(false)
        }
      }
    }, [props.url])

    useEffect(() => {
      if (trackRef.current) {
        trackRef.current.style.display = 'none'
      }
    }, [props.url, trackRef])

    const [imgSrc, setImgSrc] = useState<string>()
    useEffect(() => {
      if ((!(props.image instanceof Blob) || (props.image instanceof File))) {
        return setImgSrc(props.image as string)
      }

      const blobUrl = URL.createObjectURL(props.image)
      setImgSrc(blobUrl)
      return () => URL.revokeObjectURL(blobUrl)
    }, [props.image])

    useEffect(() => {
      setEnableSubtitles(settings.appEnableSubtitle)
    }, [settings.appEnableSubtitle])

    useEffect(() => {
      if (props.isStop) {
        setPlaying(false)
      }
    }, [props.isStop])

    /**
     * Select best quality for HLS based on bandwidth estimate (latest bandwidth)
     */
    useEffect(() => {
      if (player) {
        const hslPlayer = player.getInternalPlayer('hls')
        if (hslPlayer && hslPlayer.levels?.length) {
          if (PlayerService.bandwidthEstimate) {
            hslPlayer.currentLevel = Math.max(
              (hslPlayer.levels as { bitrate: number }[]).findIndex(
                ({ bitrate }: { bitrate: number }) => bitrate >= PlayerService.bandwidthEstimate
              ),
              hslPlayer.levels.length - 1
            )
          } else {
            PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
          }

          hslPlayer.on(EHlsEvents.LEVEL_SWITCHED, () => {
            PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
          })
        }
      }
    }, [player, props.url])

    useEffect(() => {
      const videoDuration = player?.getDuration()
      if (videoDuration === Infinity) {
        setDuration(props.duration || 0)
      }
    }, [player, props.duration])

    useEffect(() => {
      if (props.isPlay) {
        setIsShowVideo(true)
        setPlaying(true)
        setPlayEnd(false)
        setIsMuted((prev) => props.isMuted ?? prev)
      } else {
        // setIsShowVideo(false)
        setPlaying(false)
        setProgress({
          played: 0,
          playedSeconds: 0,
          loaded: 0,
          loadedSeconds: 0
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isPlay, url])

    useEffect(() => {
      if (playing) {
        PlayerService.playing = playerRef.current
      }
    }, [playerRef, playing])

    useEffect(() => {
      PlayerService.muted = isMuted
    }, [isMuted])

    const onPlayingChangeRef = useAsRef(props.onPlayingChange)
    useEffect(() => {
      onPlayingChangeRef.current?.(playing)
    }, [onPlayingChangeRef, playing])

    useEffect(() => {
      if (!player || !playing) {
        return
      }

      props.onProgress?.(progress)
    }, [progress, props.onProgress, player, playing, props])

    const analyticContextRef = useRef({
      videoId: props.videoId || 0,
      second: progress.playedSeconds
    })

    useEffect(() => {
      analyticContextRef.current = {
        videoId: props.videoId || 0,
        second: progress.playedSeconds,
        ...optionalTrackEventData
      }
    }, [props.videoId, progress.playedSeconds, optionalTrackEventData])

    useDidMountEffect(() => {
      analytic(
        playing
          ? ETrackingEvent.VIDEO_PLAY
          : ETrackingEvent.VIDEO_PAUSE,
        analyticContextRef.current
      )
    }, [playing])

    useEffect(() => {
      PlayerService.playing$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((no) => setPlaying(no === playerRef.current))

      PlayerService.muted$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((val) => setIsMuted(val))
    }, [playerRef, unsubscribe$])

    useEffect(() => {
      OverlayService.overlay$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((val) => {
          if (val.open) {
            setPlaying(false)
          }
        })
    }, [unsubscribe$])

    const handlePlay = () => {
      if (!url) return

      if (props.onBeforePlay && !props.onBeforePlay()) {
        return
      }

      if (!isShowVideo) {
        setIsShowVideo(true)
      }

      if (playEnd) {
        setPlayEnd(false)
      }

      setPlaying(!playing)
    }

    const handleMuted = (e: Event|SyntheticEvent) => {
      e.stopPropagation()
      setIsMuted((prev) => !prev)
    }

    const handlePlayEnd = () => {
      setPlayEnd(true)
      setPlaying(false)
    }

    const handleStart = useCallback(() => {
      if (player && props.playAt !== undefined) {
        player.seekTo(props.playAt)
      }
    }, [player, props.playAt])

    const shouldRenderVideo = useMemo(() => {
      return isShowVideo || props.forceRender || props.isShowThumbnail
    }, [isShowVideo, props.forceRender, props.isShowThumbnail])

    const handleProgressChange = useCallback((percent: number) => {
      setProgress(prev => ({
        ...prev,
        played: percent
      }))

      analytic(ETrackingEvent.VIDEO_SEEK_TO, {
        videoId: props.videoId || 0,
        toSecond: duration * percent
      })

      player?.seekTo(duration * percent)
    }, [analytic, duration, player, props.videoId])

    useEffect(() => {
      if (shouldRenderVideo) {
        setIsSpinner(!player)
      }
    }, [player, shouldRenderVideo])

    const [mimeType, setMimeType] = useState<'audio' | 'video'>()
    useEffect(() => {
      const video = player?.getInternalPlayer?.() as HTMLVideoElement
      if (progress.playedSeconds && video) {
        setMimeType(!video.videoWidth || !video.videoHeight ? 'audio' : 'video')
      }
    }, [player, progress.playedSeconds])

    const handleMouseIn = useCallback(() => {
      setMouseIn(true)
    }, [])

    const handleMouseOut = useCallback(() => {
      setMouseIn(false)
    }, [])

    const actionStyle = useMemo(() => {
      if (!props.action) {
        return undefined
      }

      return {
        transition: 'opacity 0.3s ease-in-out',
        opacity: !mouseIn && playing ? 0 : 1
      }
    }, [mouseIn, playing, props.action])

    useEffect(() => {
      const videoTag = player?.getInternalPlayer?.() as HTMLVideoElement

      const tracksEl: HTMLTrackElement[] = []
      for (const track of (tracks || [])) {
        const trackEl = document.createElement('track')
        trackEl.src = track.src
        trackEl.kind = track.kind
        trackEl.label = track.label
        trackEl.srclang = track.srcLang
        trackEl.default = track.default
        trackEl.addEventListener('load', function () {
          trackEl.track.mode = 'hidden'
        })
        videoTag?.appendChild(trackEl)
        tracksEl.push(trackEl)
      }

      if (!trackRef.current) return

      const replaceText = function (text: string) {
        if (trackRef.current) {
          trackRef.current.innerHTML = text
        }
      }

      const showText = function () {
        if (trackRef.current) {
          trackRef.current.style.display = 'block'
        }
      }

      const hideText = function () {
        if (trackRef.current) {
          trackRef.current.style.display = 'none'
        }
      }

      const cueEnter = function (this: any) {
        replaceText(this.text)
        showText()
      }

      const cueExit = function () {
        hideText()
      }

      const videoLoaded = function (e: any) {
        setTimeout(() => {
          try {
            const textTrack = videoTag.textTracks[0]
            if (!textTrack) return
            textTrack.mode = 'hidden'
            const cues = textTrack.cues

            if (!cues) return

            for (const i in cues) {
              const cue = cues[i]
              if (isNumber(cue) || !('onenter' in cue) || !('onexit' in cue)) {
                continue
              }

              cue.onenter = cueEnter
              cue.onexit = cueExit
            }
          } catch (err) {
            console.error('videoLoaded', err)
          }
        }, 500)
      }

      if (videoTag?.readyState > 0) {
        videoLoaded(null)
      } else {
        videoTag?.addEventListener('loadedmetadata', videoLoaded)
      }

      return () => {
        for (const el of tracksEl) {
          el.track.mode = 'disabled'
          videoTag?.removeChild(el)
        }
      }
    }, [player, tracks, url, trackRef])

    const handleOnReady = useCallback((_player: _ReactPlayer) => {
      setPlayer(_player)
    }, [])

    const trackStyle = useMemo(() => {
      if (isEmpty(tracks)) {
        return {
          opacity: 0
        }
      }

      if (isTouchDevice) {
        return {
          opacity: 1,
          bottom: '72px'
        }
      }

      if (playing && isSpinner) {
        return {
          opacity: 0
        }
      }

      if (!props.mini) {
        return {
          bottom: '100px'
        }
      }

      if (mouseIn || !playing) {
        return {
          opacity: 0
        }
      }

      return {
        bottom: !mouseIn && playing ? '12px' : '72px'
      }
    }, [tracks, isSpinner, mouseIn, playing, props.mini, isTouchDevice])

    const isProcessing = props.showProcessing && !props.image && (!progress.loaded || isSpinner)

    const [playbackRate, setPlaybackRate] = useState(1)

    const handleChangeSpeed = useCallback(() => {
      const currentIndex = speeds.findIndex(speed => speed.value === playbackRate)
      const nextIndex = (currentIndex + 1) % speeds.length

      setPlaybackRate(speeds[nextIndex].value)
    }, [playbackRate])

    return (
      <MouseContext.Provider value={mouseIn}>
        <STVideoPlayer
          id={props.id}
          style={{
            ...props.style,
            transform: ((progress.playedSeconds && mimeType === 'video') || shouldRenderVideo)
              ? undefined
              : props.shouldFlipBg ? 'scaleX(-1)' : undefined,
            backgroundImage: progress.playedSeconds && mimeType === 'video'
              ? undefined
              : backgroundImage(imgSrc)
          }}
          className={clsx({
            [Style.blackWallpaper]: progress.playedSeconds && mimeType === 'video',
            [Style.flip]: isFlip
          }, props.className)}
          isShowVideo={shouldRenderVideo}
          animatedImage={props.animatedImage}
          onClick={handlePlay}
          onMouseEnter={handleMouseIn}
          onMouseLeave={handleMouseOut}
        >
          {shouldRenderVideo && (
            <>
              <ReactPlayer
                ref={ref}
                width="100%"
                height="100%"
                url={url}
                muted={isMuted}
                playing={playing}
                onBuffer={() => setIsSpinner(true)}
                onBufferEnd={() => setIsSpinner(false)}
                onReady={handleOnReady}
                onDuration={(_duration) => setDuration(_duration)}
                onProgress={progress => setProgress(progress)}
                onEnded={handlePlayEnd}
                onStart={handleStart}
                playsinline
                config={{
                  file: {
                    tracks: tracks || [],
                    forceAudio: props.mimeType ? props.mimeType.startsWith('audio') : false,
                    attributes: {
                      preload: 'auto',
                      crossOrigin: 'anonymous'
                      // playsInline: true
                    }
                  }
                }}
                playbackRate={playbackRate}
              />

              {playing && !hideSpeaker && (
                <STSpeaker onClick={handleMuted} isLinkedin={props.isLinkedin}>
                  {isMuted ? <IconSpeakerOff/> : <IconSpeakerOn/>}
                </STSpeaker>
              )}

              {isProcessing && (
                <div className={Style.videoProcessingContainer}>
                  <VideoProcessingSpinner/>
                </div>
              )}

              {playing && isSpinner && !isProcessing && (
                <STSpinner>
                  <Spinner/>
                </STSpinner>
              )}
            </>
          )}

          <p style={trackStyle} className={Style.track} ref={trackRef}/>

          {props.action && (
            <div style={actionStyle}>
              {props.action}
            </div>
          )}

          <AuthorAndControl
            playing={playing}
            duration={duration}
            progress={progress}
            mini={props.mini}
            detailUrl={props.detailUrl}
            hideStartEnd={props.hideStartEnd}
            shouldRenderControl={shouldRenderVideo}
            onProgressChange={handleProgressChange}
            author={props.author}
            onBeforeGoToDetail={props.onBeforeGoToDetail}
            onDetailClicked={props.onDetailClicked}
            speed={playbackRate}
            handleChangeSpeed={handleChangeSpeed}
            showSpeed={props?.showSpeed}
          />

          <STPlay isShow={(!playing || playEnd) && !isProcessing}>
            {/* If shouldFlipBg is true, the icon will be flipped, which is not what we want, so we transform it back */}
            <IconPlay style={(props.shouldFlipBg && !shouldRenderVideo) ? { transform: 'scaleX(-1)' } : undefined}/>
          </STPlay>
        </STVideoPlayer>
      </MouseContext.Provider>
    )
  }
)

VideoPlayer.displayName = 'VideoPlayer'
