import { FC, useMemo, useState } from 'react'
import { EUpgradeView } from 'src/enums'
import { Payment } from './payment'
import { PaymentSuccess } from './payment-success'
import { IUpgradePlan, Store } from './store'

export * from './store'

export const Upgrade: FC = () => {
  const [view, setView] = useState(0)
  const [plan, setPlan] = useState<IUpgradePlan | null>(null)

  const renderView = useMemo(() => {
    switch (view) {
      case EUpgradeView.PAYMENT:
        return <Payment setView={setView} plan={plan}/>

      case EUpgradeView.PAYMENT_SUCCESS:
        return <PaymentSuccess/>

      default:
        return <Store setView={setView} setPlan={setPlan}/>
    }
  }, [view, plan, setView, setPlan])

  return <>{renderView}</>
}
