import { uniqBy } from 'lodash'
import { BehaviorSubject } from 'rxjs'

export interface ITyping {
  id: number
  name: string
}

export class TypingService {
  private readonly timmerRefs: { [key: string]: ReturnType<typeof setTimeout> } = {}
  private readonly peoples$ = new BehaviorSubject<ITyping[]>([])

  readonly typingTxt$ = new BehaviorSubject('')

  constructor() {
    this.peoples$.subscribe((peoples) => {
      if (!peoples.length) {
        return this.typingTxt$.next('')
      }

      if (peoples.length === 1) {
        return this.typingTxt$.next(`<strong>${peoples[0].name}</strong> is typing`)
      }

      if (peoples.length >= 3) {
        return this.typingTxt$.next('Several people are typing')
      }

      return this.typingTxt$.next(
        `${peoples.map((typing) => `<strong>${typing.name}</strong>`).join(' and ')} are typing`
      )
    })
  }

  onNewTyping(typing: ITyping) {
    const { id } = typing

    this.peoples$.next(uniqBy([
      typing,
      ...this.peoples$.value
    ], 'id'))

    clearTimeout(this.timmerRefs[id])

    this.timmerRefs[id] = setTimeout(() => {
      this.peoples$.next(
        this.peoples$.value.filter((typing) => typing.id !== id)
      )
    }, 5000)
  }
}
