import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { FC, FormEventHandler, MouseEventHandler } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconSendMail } from 'src/icons'
import { Footer } from 'src/layout/layout-container/footer'
import { Header } from '../header'
import { LeftSection } from '../left-section'
import Style from './password-reset-success.module.scss'

export const PasswordResetSuccess: FC<{ handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler
  email: string }> = ({ handleSubmit, email }) => {
    const { eventHandler } = useAnalytic('pswd_reset2')
    const theme = useTheme()

    return (
      <>
        <div className={Style.Header}>
          <Header/>
        </div>
        <div className={Style.Container}>
          <LeftSection illustration={2}/>
          <div className={Style.RightSide}>
            <Box className={Style.STContainer}>
              <div className={Style.SectionSendMail}>
                <IconSendMail className={Style.iconEmailLarge}/>
                <Typography className={Style.mainText}>Email has been sent</Typography>
                <Typography
                  className={Style.subText}
                  variant="subtitle-regular"
                  align="center"
                >We sent a magical link to <span className={Style.Email}>{email}</span>.
                  Check your inbox, follow instructions and get back on track!
                </Typography>
                <Typography
                  variant="body1-regular"
                  className={Style.resendEmail}
                  color={theme.colors['--color-neutral-theme-300']}
                >
                  Didn’t receive the email?
                  <br/>
                  Check your spam folder or
                  <Typography
                    component="a"
                    variant="body1-regular"
                    sx={{ cursor: 'pointer' }}
                    onClick={eventHandler(ETrackingEvent.BTN_RESEND_RESET_PSWD_LINK, handleSubmit)}
                  >
                    {' '} click to resend.
                  </Typography>
                </Typography>
              </div>
            </Box>
            <Footer/>
          </div>
        </div>
      </>
    )
  }
