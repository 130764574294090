import { Snackbar, Typography } from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { EMessage } from 'src/enums'
import { useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IconMessageError, IconMessageInfo, IconMessageSuccess, IconMessageWarning } from 'src/icons'
import { IMessage } from 'src/interfaces'
import { ToastService } from 'src/services'
import Style from './style.module.scss'

export const ToastContainer: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [openMessage, setOpenMessage] = useState<IMessage>()
  const [messages, setMessages] = useState<IMessage[]>([])

  const icons: { [key in EMessage]: ReactNode } = {
    [EMessage.NONE]: null,
    [EMessage.SUCCESS]: <IconMessageSuccess className="fx-shrink-0 w-4 h-4"/>,
    [EMessage.ERROR]: <IconMessageError className="fx-shrink-0 w-4 h-4"/>,
    [EMessage.WARNING]: <IconMessageWarning className="fx-shrink-0 w-4 h-4"/>,
    [EMessage.INFO]: <IconMessageInfo className="fx-shrink-0 w-4 h-4"/>
  }

  useDidMountEffect(() => {
    const hasClosedMessages = messages.some(message => !message.open)
    if (hasClosedMessages) {
      setMessages(prev => prev.filter(message => message.open))
    }

    const _openMessage = messages.find(message => message.open)
    if (_openMessage) {
      setOpenMessage(() => _openMessage)
    }
  }, [messages])

  useEffect(() => {
    ToastService.message$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(message => setMessages(prev => [...prev, message]))
  }, [unsubscribe$])

  const handleClose = (
    reason?: 'timeout' | 'clickaway' | 'escapeKeyDown',
    id?: IMessage['id']
  ) => {
    if (reason && ['clickaway', 'escapeKeyDown'].includes(reason)) {
      return
    }

    const message = id && messages.find(item => item.id === id)
    if (message) {
      message.open = false
      setMessages(prev => [...prev])
    }
  }

  return (
    <>
      {openMessage && (
        <Snackbar
          key={openMessage.id}
          open={openMessage.open}
          anchorOrigin={openMessage.position}
          autoHideDuration={openMessage.autoHideDuration}
          onClose={(e, reason) => handleClose(reason, openMessage.id)}
          sx={{
            '& .MuiSnackbarContent-root, .MuiSnackbarContent-message, .MuiSnackbarContent-action': {
              width: '100%',
              padding: 0,
              borderRadius: '8px'
            }
          }}
          message={(
            <div className={Style.toast}>
              <div className="flex gap-2 fx-ai-center">
                {icons[openMessage.severity]}
                <Typography
                  component="div"
                  variant="body2-medium"
                  sx={{
                    wordBreak: 'break-word'
                  }}
                >
                  {openMessage.content}
                </Typography>
              </div>
            </div>
          )}
        />
      )}
    </>
  )
}
