import clsx from 'clsx'
import { FC } from 'react'

import Style from './style.module.scss'

interface IProps {
  /**
   * 0-based index
   */
  currentStep: number
  maxStep: number
}

export const StepIndicator: FC<IProps> = (props) => {
  return (
    <div className={Style.stepIndicator}>
      {Array.from({ length: props.maxStep }).map((_, index) => (
        <div key={index} className={clsx(Style.step, index <= props.currentStep && Style.stepFilled)}/>
      ))}
    </div>
  )
}
