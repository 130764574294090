
/* layout actions */
export const LAYOUT_RESET_CONFIG = Symbol('LAYOUT_RESET_CONFIG')
export const LAYOUT_SET_ASIDE = Symbol('LAYOUT_SET_ASIDE')
export const LAYOUT_SET_HEADER = Symbol('LAYOUT_SET_HEADER')
export const LAYOUT_SET_PAGE_TITLE = Symbol('LAYOUT_SET_PAGE_TITLE')
export const LAYOUT_SET_SCROLL_TO_BOTTOM = Symbol('LAYOUT_SET_SCROLL_TO_BOTTOM')
export const LAYOUT_SET_LOADING = Symbol('LAYOUT_SET_LOADING')
export const LAYOUT_SET_SHOULD_SHOW_LOADING = Symbol('LAYOUT_SET_SHOULD_SHOW_LOADING')
export const LAYOUT_SET_SHARING_TOKEN = Symbol('LAYOUT_SET_SHARING_TOKEN')
export const LAYOUT_SET_MOBILE_ASIDE_OPEN = Symbol('LAYOUT_SET_MOBILE_ASIDE_OPEN')

export const LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER = Symbol('LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER')
export const LAYOUT_SET_PAGE_COMPONENT = Symbol('LAYOUT_SET_PAGE_COMPONENT')
export const LAYOUT_SET_AD = Symbol('LAYOUT_SET_AD')

/**
 * state
 */
export interface ILayoutState {
  isHeader: boolean
  isAside: boolean
  pageTitle: string | null
  isScrollToBottom: boolean
  isLoading: boolean
  shouldShowLoading: boolean
  sharingToken: string | null
  isMobileAsideOpen?: boolean
  shouldShowShareButtonHeaderMobile: boolean
  pageComponent?: any
  isAd?: boolean
}

/**
 * actions
 */
export interface ILayoutAction {
  type: typeof LAYOUT_RESET_CONFIG | typeof LAYOUT_SET_ASIDE | typeof LAYOUT_SET_HEADER | typeof LAYOUT_SET_PAGE_TITLE | typeof LAYOUT_SET_SCROLL_TO_BOTTOM | typeof LAYOUT_SET_LOADING | typeof LAYOUT_SET_SHARING_TOKEN | typeof LAYOUT_SET_MOBILE_ASIDE_OPEN | typeof LAYOUT_SET_SHOULD_SHOW_LOADING | typeof LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER | typeof LAYOUT_SET_PAGE_COMPONENT | typeof LAYOUT_SET_AD
  value?: boolean | string | null | void
}
