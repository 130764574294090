import {
  LAYOUT_RESET_CONFIG,
  LAYOUT_SET_ASIDE,
  LAYOUT_SET_HEADER,
  LAYOUT_SET_LOADING,
  LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER,
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM,
  LAYOUT_SET_SHARING_TOKEN
} from '../types'
import { ILayoutAction, LAYOUT_SET_AD, LAYOUT_SET_MOBILE_ASIDE_OPEN, LAYOUT_SET_PAGE_COMPONENT, LAYOUT_SET_SHOULD_SHOW_LOADING } from '../types/layout'

export const resetLayoutConfig = (): ILayoutAction => ({
  type: LAYOUT_RESET_CONFIG
})

export const setLayoutAside = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_ASIDE,
  value
})

export const setLayoutHeader = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_HEADER,
  value
})

export const setLayoutPageTitle = (value: string): ILayoutAction => ({
  type: LAYOUT_SET_PAGE_TITLE,
  value
})

export const setLayoutScrollToBottom = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_SCROLL_TO_BOTTOM,
  value
})

export const setLayoutLoading = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_LOADING,
  value
})

export const setLayoutShouldShowLoading = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_SHOULD_SHOW_LOADING,
  value
})

export const setLayoutSharingToken = (value: string | null): ILayoutAction => ({
  type: LAYOUT_SET_SHARING_TOKEN,
  value
})

export const setLayoutMobileAsideOpen = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_MOBILE_ASIDE_OPEN,
  value
})

export const setLayoutShouldShowShareButtonHeaderMobile = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER,
  value
})

export const setLayoutPageComponent = (value: any): ILayoutAction => ({
  type: LAYOUT_SET_PAGE_COMPONENT,
  value
})

export const setLayoutAd = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_AD,
  value
})
