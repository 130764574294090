enum EGACommand {
  CONFIG = 'config',
  EVENT = 'event',
  SET = 'set',
  JS = 'js'
}

// (command: 'config', targetId: string, config?: ControlParams | EventParams | CustomParams): void;
// (command: 'set', config: CustomParams): void;
// (command: 'js', config: Date): void;
// (command: 'event', eventName: EventNames | string, eventParams?: ControlParams |  EventParams | CustomParams): void;

export const GAService = new (class {
  gtag(...args: any[]) {
    return window.gtag?.(...args)
  }

  event(name?: string, data?: Record<string, any>) {
    return this.gtag(EGACommand.EVENT, name, data)
  }
})()
