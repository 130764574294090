import { Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi } from 'src/api'
import { Button, Input } from 'src/components'
import { PRIVACY_POLICY_URL, SST_GUEST_CAMPAIGN_ID, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useOldValidation, useUnsubscribe } from 'src/hooks'
import { IconInfo } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
import { homeSchema } from 'src/validation'
import { HintContent } from '../register-email'
import Style from './style.module.scss'

interface IProps {
  showCandidateText?: boolean
  showVVCText?: boolean
  campaignOnboardingSlug?: string
  onboardingPfvId?: number
}

export const SignUpModal: FC<IProps> = (props) => {
  const theme = useTheme()
  const { errors, validateAt, validate } = useOldValidation<{ email?: string }>()
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()

  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const sstCampaignHashId = useMemo(() => sessionStorage.getItem(SST_GUEST_CAMPAIGN_ID) || undefined, [])

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: homeSchema,
      path: name,
      data: { [name]: value }
    })

    setError('')
    setEmail(value)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    const isValid = await validate({ schema: homeSchema, data: { email } })
    if (!isValid) {
      return
    }

    setLoading(true)
    from(AuthApi.signUpRequest({
      email,
      campaignHashId: sstCampaignHashId,
      sessionId: AnalyticApi._session,
      campaignOnboardingSlug: props.campaignOnboardingSlug,
      onboardingPfvId: props.onboardingPfvId
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          setError(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
        })
      )
      .subscribe(() => {
        OverlayService.reset()
        history.push(generate(ERoutes.SIGN_UP, { emailSent: 'true' }), email)
      })
  }

  return (
    <div className={Style.container}>
      <div className="fx flex-column fx-ai-center gap-14px">
        <Typography
          variant="h4"
          align="center"
          color={theme.colors['--color-neutral-theme-700']}
        >
          {props.showCandidateText && !props.showVVCText && 'Start With An Email To Create Your First Job!'}
          {props.showVVCText && !props.showCandidateText && 'Start With An Email To Sumbit Your First Video Vibe Check!'}
          {!props.showVVCText && !props.showCandidateText && 'Sign Up'}
        </Typography>

        {props.showCandidateText && (
          <div className="fx flex-column">
            <Typography
              variant="body1-regular"
              align="center"
              color={theme.colors['--color-neutral-theme-500']}
            >
              Discovering more is a step away - Sign up<br/> to unlock the full experience now!
            </Typography>
          </div>
        )}
      </div>

      <form className="fx flex-column fx-ai-center gap-4" onSubmit={handleSubmit}>
        <div className="w-100 fx flex-column gap-2">

          <span className="body2-bold color-neutral-theme-500">Email</span>
          <Input
            name="email"
            placeholder="name@company.com"
            type="email"
            fullWidth
            mb={0}
            required
            onChange={handleChangeInput}
          />
          {errors.email && (
            <Typography className={Style.STSignUpEmailHint}>
              {HintContent}
            </Typography>
          )}
          {error && (
            <Typography className={Style.STSignUpEmailHint}>
              <IconInfo/> {error}
            </Typography>
          )}
        </div>
        <Button
          fullWidth
          disabled={loading || !!errors.email || !email}
          type="submit"
        >
          <span className="body1-bold">Sign Up</span>
        </Button>
        <Typography
          align="center"
          variant="body2-regular"
          color={theme.colors['--color-neutral-theme-300']}
        >
          By signing up, you agree to KnowMe’s <a href={PRIVACY_POLICY_URL} target="_blank" className="text-decorator-underline color-neutral-theme-300 font-medium" rel="noreferrer">Privacy Policy</a> & <a href={TERMS_AND_SERVICE_URL} className="color-neutral-theme-300 text-decorator-underline font-medium">Terms of Service</a>
        </Typography>
      </form>

      <Typography
        align="center"
        variant="body2-medium"
        color={theme.colors['--color-neutral-theme-300']}
      >
        You already have an account? <a href="/sign-in">Sign in</a>
      </Typography>
    </div>
  )
}
