import { Drawer, IconButton } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { useAppSelector, useShowRedDot } from 'src/hooks'
import { IconHorizontalLinesBold } from 'src/icons'
import { Aside } from 'src/layout/layout-container/aside'
import { getIsAuthenticated } from 'src/store/selectors'
import Style from './style.module.scss'

export const Hamburger: FC = () => {
  const [open, setOpen] = useState(false)
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  const toggleDrawer = useCallback(() => {
    setOpen(prev => !prev)
  }, [setOpen])

  const closeDrawer = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const showRedDot = useShowRedDot()

  useEffect(() => {
    if (!isAuthenticated) {
      setOpen(false)
    }
  }, [isAuthenticated])

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        className={Style.STContainer}
      >
        <IconHorizontalLinesBold color="#181920"/>
        {showRedDot && (
          <span className={Style.redDotCollapse}/>
        )}
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <Aside onDrawerClose={closeDrawer}/>
      </Drawer>
    </>
  )
}
