import { put, select, takeLatest } from 'redux-saga/effects'
import { AnalyticApi, AuthApi, ProfileApi } from 'src/api'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { EMessage } from 'src/enums'
import { IUserModel } from 'src/interfaces'
import { ERoutes, browserHistory, generate } from 'src/router'
import { CampaignMutationService, ProfileService, SnackbarService } from 'src/services'
import { CreateJobTourService } from 'src/services/tour/create-job-tour.service'
import { GuestViewJobTourService } from 'src/services/tour/guest-view-job-tour.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { AuthenticationUtil, StorageUtils, getApiErrorMessage } from 'src/utils'
import { setLayoutLoading } from '../actions'
import {
  AUTH_GET_PROFILE,
  AUTH_LOGIN_SUCCESS, AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS, AUTH_SET_CREDENTIALS, CAMPAIGN_RESET
} from '../types'

/**
 * Get user credentials with jwt
 */
function * getProfile(action: { type: typeof AUTH_GET_PROFILE; payload?: { afterSignUp?: boolean; fromGuestView?: boolean } }) {
  try {
    const layout: { sharingToken: string | null } = yield select(state => state.layout)

    // workaround for promise with generator function
    const { data: profile }: Awaited<ReturnType<typeof ProfileApi.detail>> = yield ProfileApi.detail()

    yield put({
      type: AUTH_SET_CREDENTIALS,
      value: profile
    })

    ProfileService.setSettingProfile(profile)

    if (action.payload?.afterSignUp) {
      if (profile.guide?.campaignId) {
        return browserHistory.push(generate([ERoutes.CAMPAIGNS, { id: profile.guide.campaignId }]))
      }

      if (action.payload?.fromGuestView) {
        return browserHistory.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
      }

      return browserHistory.push('/vibes')
    }

    if (layout.sharingToken) {
      return browserHistory.push(`/share/${layout.sharingToken}`)
    }
  } catch (error) {
    yield put({ type: AUTH_LOGOUT_SUCCESS })
  }
}

/**
 * Call login with user credentials
 * @param {object} action
 * @return {Redirect} Go home
 */
function * loginSuccess(action: { type: typeof AUTH_LOGIN_SUCCESS; payload: IUserModel & { campaignId: number; fromGuestView?: boolean } }) {
  const layout: { sharingToken: string | null } = yield select(state => state.layout)
  const { campaignId, ...payload } = action.payload

  // workaround for promise with generator function
  // const { data }: Awaited<ReturnType<typeof AuthApi.login>> = yield AuthApi.login(action.payload)

  yield put({
    type: AUTH_SET_CREDENTIALS,
    value: payload
  })

  ProfileService.setSettingProfile(payload)
  PopupTourService.hide()

  if (action.payload.fromGuestView) {
    AnalyticApi.resetSession()
  }

  if (payload.guide?.campaignId) {
    return browserHistory.push(generate([ERoutes.CAMPAIGNS, { id: payload.guide.campaignId }]))
  }

  if (layout.sharingToken) {
    return browserHistory.push(`/share/${layout.sharingToken}`)
  }

  if (campaignId) {
    return browserHistory.push(generate([ERoutes.CAMPAIGNS, { id: campaignId }]))
  }

  const toURL = StorageUtils.getItem(AUTH_FALLBACK_KEY)
  StorageUtils.removeItem(AUTH_FALLBACK_KEY)
  if (toURL) {
    yield put(setLayoutLoading(false))
    return browserHistory.push(toURL)
  }

  return browserHistory.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
}

/**
 * Logout, redirect to home, clear credentials
 * @return {Redirect} Go home
 */
function * logout(action: { type: typeof AUTH_LOGOUT }) {
  try {
    yield AuthApi.logout()
    yield put({ type: AUTH_LOGOUT_SUCCESS })
    yield put({ type: CAMPAIGN_RESET })
    yield AuthenticationUtil.clear()

    yield PopupTourService.hide()
    yield CreateJobTourService.stopTour()
    yield ShareJobTourService.stopTour()
    yield GuestViewJobTourService.stopTour()
    yield CampaignMutationService.reset()
    sessionStorage.clear()

    browserHistory.push(generate(ERoutes.SIGN_IN))
    // window redirect to sign in
    // window.location.href = generate(ERoutes.SIGN_IN)
  } catch (error) {
    SnackbarService.push({
      severity: EMessage.ERROR,
      content: getApiErrorMessage(error)
    })
  }
}

export type TAuthSagaAction = Parameters<typeof getProfile>[0]
| Parameters<typeof loginSuccess>[0]
| Parameters<typeof logout>[0]

export const handler = function * () {
  yield takeLatest(AUTH_LOGIN_SUCCESS, loginSuccess)
  yield takeLatest(AUTH_LOGOUT, logout)
  yield takeLatest(AUTH_GET_PROFILE, getProfile)
}
