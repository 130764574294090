import clsx from 'clsx'
import { CSSProperties, FC } from 'react'
import { useAppSelector } from 'src/hooks'
import { getProfileTraits } from 'src/store/selectors'
import Style from './style.module.scss'

interface IProps {
  items?: string[]
  style?: CSSProperties
  className?: string
}

/**
 * @deprecated no longer used, remove next time if you see this
 */
export const Traits: FC<IProps> = ({ items = [], style, className }) => {
  const filter = useAppSelector(getProfileTraits)
  const selectedTraits = [...(filter?.characteristics || []), ...(filter?.motivations || [])]

  return (
    <div
      style={style}
      className={clsx('fx gap-1', className)}
    >
      {items?.map((trait, index) => (
        <span
          key={`${index}-${trait}`}
          title={trait}
          className={clsx(Style.trait, {
            // [Style.matchTrait]: selectedTraits.includes(trait),
            [Style.noTraitSelected]: !selectedTraits.length
          })}
        >
          {trait}
        </span>
      ))}
    </div>
  )
}
