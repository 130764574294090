export class TeleprompterUtils {
  static readonly SPEEDS_DEF = [70, 50, 30, 20, 10]

  static nextSpeed(currentSpeed = 30) {
    const speeds = TeleprompterUtils.SPEEDS_DEF
    const nextIndex = speeds.indexOf(currentSpeed) + 1
    return speeds[nextIndex] || speeds[0]
  }

  static speedToTxt(speed = 30) {
    switch (speed) {
      case 70:
        return '0.25'
      case 50:
        return '0.5'
      case 30:
        return '1x'
      case 20:
        return '1.25x'
      case 10:
        return '1.5x'
      default:
        return '1x'
    }
  }
}
