import { Portal } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { useIfMobileL, useIfTablet } from 'src/hooks'

export const NotPortalIfDesktop: FC<PropsWithChildren<any>> = (props) => {
  const isTablet = useIfTablet()
  const isMobileL = useIfMobileL()

  if (!isTablet && !isMobileL) return props.children

  return (
    <Portal>
      {props.children}
    </Portal>
  )
}
