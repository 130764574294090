import { FC } from 'react'

import clsx from 'clsx'
import { CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { IllustrationPurpleParty } from '../illustration-purple-party'
import Style from './style.module.scss'

interface IProps {
  className?: string
}

export const PurplePromotionBanner: FC<IProps> = (props) => {
  return (
    <div className={clsx(Style.container, props.className)}>
      <IllustrationPurpleParty className="flex-shrink-0"/>
      <span className="meta-bold color-neutral-theme-600">Make a video vibe check now to earn <span className="color-purple-400">{CREDIT_FOR_FIRST_TIME_VVC} free credits</span> </span>
    </div>
  )
}
