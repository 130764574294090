import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'STContainer'
})(({ theme }) => `
  position: relative;
  display: grid;
  gap: ${theme.spacing(2)};
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1410px) {
    gap: 8px;
  }

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`)

export const STIntros = styled('div', {
  label: 'Intros'
})<{ showingDetail: boolean }>(({ showingDetail, theme }) => `
  position: relative;
  height: 100%;
  margin: ${theme.spacing(4)};
  display: grid;
  gap: ${theme.spacing(2)};
  grid-auto-rows: min-content;

   ${showingDetail
    ? `
    grid-template-columns: none;

    @media (max-width: ${theme.breakpoints.values.md}px) {
      margin: 0;
    }
  `
    : `
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (min-width: 1380px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 1580px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 1875px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media only screen and (min-width: 2242px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: ${theme.breakpoints.values.md}px) {
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`)

export const STIntrosItem = styled('div', {
  shouldForwardProp: prop => prop !== 'isSuperNova',
  label: 'IntrosItem'
})<{isSuperNova?: boolean}>(({ theme, isSuperNova }) => `
  position: relative;
  border-radius: 16px;
  background: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  isolation: isolate;
`)

export const STFlipFront = styled('div', {
  label: 'FlipFront',
  shouldForwardProp: prop => prop !== 'isSuperNova'
})<{isSuperNova?: boolean}>(({ theme, isSuperNova }) => `
  background: ${isSuperNova ? 'conic-gradient(from 180deg at 50% 50%, #000000 -24.66deg, #FFFFFF 0.25deg, #000000 50.63deg, #000000 51.97deg, #FFFFFF 88.12deg, #000000 142.5deg, #FFFFFF 196.87deg, #000000 256.87deg, #FFFFFF 300deg, #000000 335.2deg, #000000 335.34deg, #FFFFFF 360.25deg), conic-gradient(from 176.21deg at 50% 50%, #000000 -24.66deg, #FFFFFF 0.25deg, #000000 50.63deg, #000000 51.97deg, #FFFFFF 88.12deg, #000000 142.5deg, #FFFFFF 196.87deg, #000000 256.87deg, #FFFFFF 300deg, #000000 335.2deg, #000000 335.34deg, #FFFFFF 360.25deg), radial-gradient(95.11% 95.11% at 36.64% 4.89%, #2AD0CA 0%, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%)' : theme.colors['--color-neutral-theme-50']};
  background-blend-mode: screen, difference, normal;
  border-radius: 16px;
`)

// export const STFlipButton = styled(IconButton, { label: 'FlipButton' })(({ theme }) => `
//   position: absolute;
//   top: 20px;
//   right: 16px;
//   cursor: pointer;
//   z-index: 100;
// `)

export const STInfo = styled('div', {
  label: 'Info'
})(() => `
  position: absolute;
  left: 20px;
  bottom: 50px;
  z-index: 2
`)

export const STBottom = styled('div', { label: 'Bottom' })(({ theme }) => `
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${theme.spacing(1.5, 1.5)};
  border-top: 1px solid ${theme.colors['--color-neutral-theme-200']};
`)

export const STIcons = styled('div', {
  label: 'Icons'
})(({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`)

export const STIconWrap = styled('div', {
  label: 'IconWrap'
})<{background?: string}>(({ theme, background }) => `
    width: 40px;
    height: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: pointer;
    background:  ${background || theme.colors['--color-neutral-theme-50']};
    border-radius: 50%;
`)
