import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useIfMobileL } from 'src/hooks'
import { OverlayService } from 'src/services'
import { Button } from '../button'
import { STAction, STContainer, STContent } from './styled'

interface IProps {
  action: () => void
  leavePage: () => void
}

export const UnSaveChange: FC<IProps> = ({ action, leavePage }) => {
  const theme = useTheme()
  const isMobile = useIfMobileL()

  const handleLeave = () => {
    OverlayService.reset()
    leavePage()
  }

  const handleSaveChange = () => {
    action()
    handleLeave()
  }

  return (
    <STContainer isMobile={isMobile}>
      <STContent>
        <Typography variant="h3">Unsaved Changes</Typography>
        <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-300']}>
          All changes will be lost. Do you really want to leave without saving?
        </Typography>
      </STContent>
      <STAction>
        <Button fullWidth onClick={handleSaveChange}>
          Save Changes
        </Button>
        <Button order="secondary" fullWidth onClick={handleLeave}>
          Leave Without Saving
        </Button>
      </STAction>
    </STContainer>
  )
}
