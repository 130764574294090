import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { NotificationApi } from 'src/api'
import { Button, NoMobile, OnlyMobile, Table } from 'src/components'
import { EMessage } from 'src/enums'
import { useIfMobileL, useIfTablet, useUnsubscribe } from 'src/hooks'
import { INotificationSettingRecord } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { Setting } from '../setting'
import { RenderColumns } from './columns'
import { MobileView } from './index.mobile'
import Style from './style.module.scss'

export const Notification: FC = () => {
  const unsubscribe$ = useUnsubscribe()

  const [records, setRecords] = useState<INotificationSettingRecord[]>([])
  const [loading, setLoading] = useState(false)
  const originalRecordRef = useRef<INotificationSettingRecord[]>([])
  const [columns, setColumns] = useState<object[]>([])

  const isTablet = useIfTablet()
  const isMobileL = useIfMobileL()

  const loadSettings = useCallback(() => {
    setLoading(true)

    from(NotificationApi.getSettings())
      .pipe(
        takeUntil(unsubscribe$),
        catchError(() => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: 'Failed to load notification settings'
          })
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setRecords(data)
        originalRecordRef.current = data
      })
  }, [unsubscribe$])

  useEffect(() => {
    loadSettings()
  }, [loadSettings])

  const handleRecordChange = useCallback((type: INotificationSettingRecord['type'], place: 'email' | 'notification', value: boolean) => {
    setRecords(records => records.map(record => {
      if (record.type === type) {
        return {
          ...record,
          [place]: value
        }
      }
      return record
    }))
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    from(NotificationApi.updateSettings(records))
      .pipe(
        takeUntil(unsubscribe$),
        catchError(() => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: 'Failed to update notification settings'
          })
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        SnackbarService.push('Notification settings updated')
        loadSettings()
      })
  }

  const handleReset = () => {
    setRecords(originalRecordRef.current)
  }

  useEffect(() => {
    if (isTablet || isMobileL) {
      setColumns(RenderColumns({
        onChange: handleRecordChange,
        width: '50px'
      }))

      return
    }

    setColumns(RenderColumns({
      onChange: handleRecordChange,
      width: '240px'
    }))
  }, [isTablet, isMobileL, handleRecordChange, setColumns])

  return (
    <Setting>
      <div className={Style.container}>
        <div className={Style.content}>
          <NoMobile>
            <Table
              isLoading={loading}
              className={Style.table}
              columns={columns}
              data={records}
              loadPage={() => {}}
            />
          </NoMobile>
          <OnlyMobile>
            <MobileView data={records} onChange={handleRecordChange}/>
          </OnlyMobile>
        </div>
        <div className={Style.footer}>
          <Button disabled={loading} onClick={handleReset} order="secondary">Cancel</Button>
          <Button disabled={loading} order="primary" onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </Setting>
  )
}
