import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export enum EUploadStatus {
  NOT_STARTED = 'NOT_STARTED',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

interface ITrackUploadItem {
  id: string
  percentage: BehaviorSubject<number>
  status: BehaviorSubject<EUploadStatus>
}

export class UploadService {
  private static _uploadingItems$ = new BehaviorSubject<ITrackUploadItem[]>([])

  public static get uploadingItems$(): WithOutNextComplete<typeof UploadService._uploadingItems$> {
    return UploadService._uploadingItems$
  }

  public static pushTrackUploadItem(id: string) {
    const percentage = new BehaviorSubject(0)
    const status = new BehaviorSubject<EUploadStatus>(EUploadStatus.NOT_STARTED)

    UploadService._uploadingItems$.next([
      ...UploadService._uploadingItems$.getValue(),
      {
        id,
        percentage,
        status
      }
    ])

    return percentage
  }

  public static removeTrackUploadItem(id: string) {
    UploadService._uploadingItems$.next(UploadService._uploadingItems$.getValue().filter(item => item.id !== id))
  }

  public static updateTrackUploadItem(id: string, percentage: number, status: EUploadStatus) {
    const item = UploadService._uploadingItems$.getValue().find(item => item.id === id)

    if (item) {
      item.percentage.next(percentage)
      item.status?.getValue() !== status && item.status?.next(status)
    }
  }

  public static getUploadItem(id: string) {
    return UploadService._uploadingItems$.getValue().find(item => item.id === id)
  }

  public static genUploadItemId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }
}
