import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  position: relative;
  width: 415px;
  padding: ${theme.spacing(8, 0)};
  background: ${theme.colors['--color-neutral-theme-50']};
  border-radius: 32px;
  
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(5)};
  align-items: center;
  justify-content: center;
`)

export const STContent = styled('div', {
  label: 'Content'
})(({ theme }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing(2)}
`)
