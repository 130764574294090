import { TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class PromoCodeApi {
  static readonly _prefix = '/promo-code'

  static apply(code: string): TAxiosResponseData<{ introCredit: number; credit: number }> {
    return axiosHiringApi.post(this._prefix, { code })
  }
}
