import { State } from '@popperjs/core'
import clsx from 'clsx'
import { FC, useCallback, useMemo, useState } from 'react'
import { usePopper } from 'src/hooks'
import Style from './style.module.scss'

interface IProps {
  color: 'purple' | 'cyan' | 'yellow' | 'white'
  title: string
  description: string
  target: string
  placement: 'top' | 'bottom' | 'left' | 'right'
  width?: number
}

const COLORS: Record<IProps['color'], string> = {
  purple: '#C299EB',
  cyan: '#97EBF2',
  yellow: '#F7FB6F',
  white: '#FFFFFF'
}

const POSITIONS: Record<IProps['placement'], 'top' | 'left' | 'bottom' | 'right'> = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
}

export const TutorialPopper: FC<IProps> = (props) => {
  const [placementPosition, setPlacementPosition] = useState<IProps['placement'] | null>(null)
  const handleUpdate = useCallback((state: State) => {
    setPlacementPosition(state.placement as IProps['placement'])
  }, [])

  const { setPopperElement, currentPopperInstance } = usePopper({
    selector: props.target,
    position: POSITIONS[props.placement],
    shouldScroll: false,
    onUpdate: handleUpdate
  })

  const containerStyle = useMemo(() => {
    return { backgroundColor: COLORS[props.color], width: `${props.width}px` }
  }, [props.color, props.width])

  const arrowPosition = useMemo(() => {
    if (!placementPosition) return props.placement
    return POSITIONS[placementPosition as IProps['placement']]
  }, [placementPosition, props.placement])

  return (
    <div
      className={Style.container}
      style={containerStyle}
      ref={setPopperElement}
      {...currentPopperInstance?.state.attributes.popper}
    >
      <span className="body1-bold color-neutral-900">{props.title}</span>
      <span className="meta-medium color-neutral-900">{props.description}</span>
      <Arrow
        color={COLORS[props.color]}
        placement={arrowPosition}
        className={clsx(Style.position, Style[`position-${arrowPosition}`])}
      />
    </div>
  )
}

const Arrow: FC<{ className?: string; color: string; placement: IProps['placement'] }> = (props) => {
  return (
    <svg className={clsx(props.className, Style[`arrow-${props.placement}`])} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5C4 9 0 10 0 10L-4.37114e-07 0C-4.37114e-07 0 3.48242 5 8 5Z" fill={props.color}/>
    </svg>

  )
}
