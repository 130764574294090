import clsx from 'clsx'
import { FC } from 'react'
import Style from './style.module.scss'
import { Applicant } from './variants/applicant'
import { LikeIntro } from './variants/like-intro'
import { Skip } from './variants/skip'
import { Vibe } from './variants/vibe'

export const EEmptyVideoVariant = Object.freeze({
  LikeIntro: LikeIntro,
  Skip: Skip,
  Vibe: Vibe
})

interface IProps {
  variant: 'like' | 'intro' | 'skip' | 'vibe' | 'applicant'
  quantity: number
  className?: string
}

export const EmptyVideos: FC<IProps> = (props) => {
  return (
    <div className={clsx(Style.container, props.className)}>
      {Array.from({ length: props.quantity }).map((_, i) => {
        let Component = ['like', 'intro'].includes(props.variant) ? <LikeIntro key={i}/> : null
        Component = props.variant === 'skip' ? <Skip key={i}/> : Component
        Component = props.variant === 'vibe' ? <Vibe key={i}/> : Component
        Component = props.variant === 'applicant' ? <Applicant key={i}/> : Component

        return Component
      })}
    </div>
  )
}
