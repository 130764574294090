import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { PaymentSuccessImg } from 'src/images'
import { STContainer } from './styled'

interface PaymentSuccessProps {
  onClose: () => void
}
export const PaymentSuccess: FC<PaymentSuccessProps> = ({ onClose }) => {
  const { eventHandler } = useAnalytic('store_sale')

  return (
    <STContainer>
      <img src={PaymentSuccessImg} alt="payment success"/>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography variant="h3">Thank you!</Typography>
        <Typography variant="body1-regular">Your order has been successfully placed</Typography>
      </Box>
      <Button fullWidth order="secondary" onClick={eventHandler(ETrackingEvent.BTN_FINISH, onClose)}>Close</Button>
    </STContainer>
  )
}
