import { Avatar, Box, ClickAwayListener, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Invite, useAnalytic } from 'src/components'
import { EEnv } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { IconChevronRight, IconExternalLink, IconInviteFriend, IconPayment, IconSetting, IconSignOut } from 'src/icons'
import { NavigationService, OverlayService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { AUTH_LOGOUT } from 'src/store/types'
import { getFullName } from 'src/utils'
import { ReactComponent as IconKnowme } from './knowme.svg'
import Style from './style.module.scss'

interface IProps {
  onItemClick?: () => void
  isMinimized?: boolean
}

export const UserMenu: FC<IProps> = ({ onItemClick, isMinimized }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(getCredentials)
  const history = useHistory()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { eventHandler } = useAnalytic('')

  const fullName = useMemo(() => getFullName(profile) || profile.email, [profile])
  const avatarChars = useMemo(() => {
    if (!fullName) {
      return ''
    }

    if (fullName === profile.email) {
      return profile.email.split('@')[0].substring(0, 2)
    }

    const words = fullName.split(' ')
    if (words.length === 1) {
      return words[0].charAt(0)
    }

    return words[0].charAt(0) + words[words.length - 1].charAt(0)
  }, [fullName, profile])

  const handleOpenDropdown = () => setIsOpen(!isOpen)

  const handleRedirect = (pathname: string) => {
    history.push(pathname)
    onItemClick?.()
  }

  const handleInvite = () => {
    OverlayService.setOverlay({
      open: true,
      content: <Invite/>
    })
    onItemClick?.()
  }

  const logout = useCallback(() => {
    dispatch({ type: AUTH_LOGOUT })
    NavigationService.reset()
  }, [dispatch])

  const handleCW = useCallback(() => {
    window.open(EEnv.REACT_APP_CANDIDATE_WEB_DNS, '_blank')
  }, [])

  return (
    <div className={clsx(Style.STUM, isMinimized ? 'fx-jc-center' : 'fx-jc-space-between')} onClick={handleOpenDropdown}>
      <div className={Style.STInfo}>
        <Avatar sx={{ width: 48, height: 48, cursor: 'pointer' }} src={profile.pfp?.url}>
          {avatarChars}
        </Avatar>

        {!isMinimized && (
          <Box display="flex" flexDirection="column" style={{ overflow: 'hidden' }}>
            <Typography variant="body1-bold" color={theme.colors['--color-neutral-theme-50']} className="oneline-text">
              {fullName}
            </Typography>
          </Box>
        )}
      </div>

      {!isMinimized && (
        <IconChevronRight className={Style.STIconArrowRight}/>
      )}

      {isOpen && (
        <ClickAwayListener onClickAway={handleOpenDropdown}>
          <div className={clsx(Style.STUMDropdown, isMinimized && Style.STUMDropdownMinimized)}>
            <div
              className={Style.STUMDropdownItem}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_PROFILE, () => handleRedirect('/setting/profile'))}
            >
              <IconSetting className={Style.icon} width={16}/>
              <span>Settings</span>
            </div>
            <div
              className={Style.STUMDropdownItem}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_PAYMENTS, () => handleRedirect('/payment-history'))}
            >
              <IconPayment className={Style.icon} width={16}/>
              <span>Payments</span>
            </div>
            <div
              className={Style.STUMDropdownItem}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_INVITE, handleInvite)}
            >
              <IconInviteFriend className={Style.icon} width={16}/>
              <span>Invite Friends</span>
            </div>

            <div
              className={Style.STUMDropdownItem}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_INVITE, handleCW)}
            >
              <IconKnowme/>
              <span>KnowMe Jobs Marketplace</span>
              <IconExternalLink className="w-4 h-4 svg-color-primary-400"/>
            </div>

            <div className={Style.STUMDropdownItem} onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_SIGNOUT, logout)}>
              <IconSignOut className={Style.icon} width={16}/>
              <span>Sign out</span>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
