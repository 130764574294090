import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router'
import { IconArrowRight } from 'src/icons'
import { IUserModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { getFirstName, getFullName } from 'src/utils'
import { useMouse } from '../video-player/mouse.context'
import Style from './style.module.scss'

interface IProps {
  author: IUserModel
  playing: boolean
  className?: string
  detailUrl?: string
  onBeforeGoToDetail?: () => void
  onDetailClicked?: () => void
}

export const VideoAuthor: FC<IProps> = (props) => {
  const history = useHistory()
  const mouseIn = useMouse()

  const showFullName = useMemo(() => {
    const currentPath = history.location.pathname
    const likePath = generate(ERoutes.LIKES)

    return currentPath === likePath
  }, [history.location.pathname])

  const handleOnDetailClicked = (e: any) => {
    if (props.onDetailClicked) {
      props.onDetailClicked()
      return
    }

    props.onBeforeGoToDetail?.()

    if (props.detailUrl) {
      return history.push({ search: props.detailUrl })
    }

    history.push({ search: `?detailCandidateId=${props.author.id}&detailTab=${0}` })
  }

  const containerStyle = useMemo(() => {
    if (!mouseIn && props.playing) {
      return {
        opacity: 0
      }
    }

    return {
      opacity: 1
    }
  }, [props.playing, mouseIn])

  return (
    <div style={containerStyle} className={clsx('fx flex-row fx-jc-space-between fx-ai-center p-3', props.className, Style.container)}>
      <div className="fx flex-col">
        <span className="meta-bold neutral-white">
          Meet {!showFullName ? getFullName(props.author) : getFirstName(props.author)}
        </span>
      </div>

      <IconButton className={Style.iconButton} onClick={handleOnDetailClicked}>
        <IconArrowRight/>
      </IconButton>
    </div>
  )
}
