import { FC, SVGProps } from 'react'

export const LikesIllustration: FC<SVGProps<any>> = (props) => {
  return (
    <svg width="86" height="143" viewBox="0 0 86 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_12205_1381)">
        <path d="M19.4197 64.6017C17.9159 64.8762 15.98 65.1674 15.5558 66.7621C15.3411 67.54 15.4421 68.3164 15.5481 69.1315C16.2109 74.5323 16.8348 79.9381 17.5026 85.3777C17.7398 87.2022 18.0361 89.1769 19.3823 90.4229C21.1723 92.0454 23.9284 91.6871 26.2761 91.2239C26.5478 91.1886 26.8145 91.1144 27.0272 90.9289C27.2737 90.6995 27.3009 90.3012 27.3381 89.9805C27.713 85.2738 26.9808 80.5531 26.2536 75.8713C25.8816 73.3143 25.4759 70.8012 25.104 68.2443C24.998 67.4291 24.6443 65.6198 24.2328 64.8838C23.4149 63.4507 20.9235 64.3272 19.4197 64.6017Z" fill="#24252D"/>
        <path d="M34.6211 50.6646C35.1768 57.9741 34.584 58.8801 31.6087 63.0171C30.4757 64.6249 29.0068 66.0791 28.3799 67.937C27.9541 69.216 27.8979 70.605 27.9193 71.9838C27.9881 77.067 28.6493 82.1522 29.8777 87.0452C30.1052 87.8841 30.3377 88.7618 30.9957 89.2683C31.6149 89.7799 32.4502 89.8292 33.2417 89.8447C39.7054 90.0701 46.2334 89.5765 52.598 88.4332C53.0588 88.3338 53.5635 88.2682 53.9602 87.9797C55.3343 87.0116 54.6872 84.7667 53.8979 83.2509C55.4844 83.0051 56.7728 81.3771 56.6722 79.6927C56.6035 78.5569 56.1551 77.2336 56.9182 76.4238C58.5171 74.7554 58.6649 72.2493 58.0124 70.8736C57.4037 69.5316 56.9801 69.3104 56.8997 67.7813C56.8477 67.0776 57.0674 66.3385 57.0103 65.5959C56.9183 63.6736 55.2211 62.1574 53.4273 61.7196C51.6335 61.2817 49.7465 61.6455 47.9861 62.0718C46.1869 62.5031 44.3489 62.9395 42.5314 62.6231C42.2496 62.5808 41.9239 62.5048 41.7383 62.2921C41.46 61.973 41.4383 61.5021 41.4604 61.065C41.6167 58.3209 42.0937 55.6141 41.8569 52.8817C41.62 50.1493 40.5427 47.3288 38.2633 45.8883C37.4888 45.3969 36.5405 45.086 35.7067 45.3523C34.8729 45.6186 34.2177 46.6512 34.5667 47.5137L34.6211 50.6646Z" fill="#24252D"/>
      </g>
      <g clipPath="url(#clip1_12205_1381)">
        <path d="M59.8607 14.7165C59.1106 14.5714 58.1563 14.3556 57.6693 15.0212C57.4282 15.3445 57.3343 15.7244 57.2357 16.1232C56.5641 18.7584 55.8735 21.389 55.1972 24.0432C54.9765 24.9359 54.7561 25.9092 55.1568 26.7338C55.6958 27.8142 57.0443 28.1476 58.2216 28.358C58.3546 28.3908 58.4922 28.4047 58.6249 28.3569C58.7814 28.2948 58.8663 28.1143 58.9418 27.9717C59.9707 25.848 60.4865 23.5169 60.9976 21.2047C61.2885 19.9465 61.5557 18.7026 61.8466 17.4444C61.9451 17.0456 62.1088 16.1388 62.0508 15.7214C61.93 14.9056 60.6108 14.8616 59.8607 14.7165Z" fill="#24252D"/>
        <path d="M69.4691 10.9861C68.4012 14.4907 67.9607 14.805 65.8244 16.1915C65.0049 16.7346 64.057 17.1451 63.4279 17.8965C62.9974 18.4148 62.7192 19.0514 62.4789 19.6973C61.5883 22.0769 60.9733 24.5649 60.6572 27.0664C60.6109 27.4983 60.5599 27.9492 60.7743 28.3045C60.9698 28.6552 61.3499 28.8297 61.7156 28.9806C64.6846 30.2587 67.8141 31.2137 70.9854 31.8364C71.218 31.8738 71.4649 31.9348 71.702 31.8725C72.5176 31.6711 72.6237 30.5082 72.5313 29.6591C73.3147 29.8326 74.2101 29.3083 74.469 28.5057C74.6431 27.9643 74.6745 27.2667 75.1768 27.0282C76.2242 26.5414 76.7479 25.4012 76.6937 24.6422C76.6538 23.9068 76.4968 23.7269 76.7368 23.0003C76.8403 22.6631 77.0768 22.3588 77.185 22.0027C77.491 21.0908 76.9759 20.0767 76.22 19.5473C75.4642 19.0178 74.5195 18.8448 73.6223 18.7238C72.7062 18.5981 71.7711 18.4677 70.9822 17.9905C70.8586 17.9196 70.7208 17.8251 70.673 17.6924C70.6013 17.4933 70.6766 17.27 70.7662 17.0705C71.3371 15.8211 72.0505 14.6472 72.4361 13.3318C72.8218 12.0164 72.832 10.5075 72.032 9.42298C71.7606 9.05358 71.3754 8.73668 70.9388 8.70936C70.5022 8.68204 70.0096 9.04396 70.0156 9.50895L69.4691 10.9861Z" fill="#24252D"/>
      </g>
      <g clipPath="url(#clip2_12205_1381)">
        <path d="M51.337 131.461C50.8548 131.636 50.2306 131.842 50.1788 132.389C50.151 132.657 50.2276 132.906 50.3079 133.168C50.8272 134.905 51.334 136.645 51.8571 138.395C52.037 138.981 52.2447 139.612 52.7593 139.946C53.4416 140.377 54.3334 140.104 55.0843 139.818C55.1722 139.791 55.2563 139.752 55.3164 139.679C55.3852 139.59 55.372 139.457 55.3665 139.35C55.229 137.782 54.7245 136.273 54.2238 134.776C53.9587 133.957 53.6849 133.155 53.4198 132.336C53.3394 132.074 53.1219 131.5 52.9449 131.281C52.5947 130.857 51.8192 131.286 51.337 131.461Z" fill="#24252D"/>
        <path d="M55.5913 126.021C56.1812 128.391 56.0355 128.722 55.2811 130.25C54.9956 130.842 54.5905 131.403 54.4863 132.049C54.4165 132.493 54.4751 132.953 54.5588 133.404C54.8639 135.07 55.3652 136.703 56.0434 138.241C56.1653 138.504 56.291 138.779 56.5368 138.908C56.77 139.041 57.0491 139.01 57.3118 138.97C59.4625 138.679 61.5946 138.147 63.6366 137.411C63.7835 137.352 63.9468 137.302 64.062 137.185C64.4628 136.789 64.124 136.088 63.7787 135.635C64.2898 135.465 64.6256 134.857 64.4987 134.309C64.4129 133.94 64.191 133.531 64.3985 133.221C64.8347 132.583 64.7444 131.751 64.4521 131.336C64.1761 130.93 64.0237 130.881 63.9122 130.383C63.8559 130.155 63.8875 129.9 63.8273 129.659C63.6901 129.033 63.0444 128.631 62.4267 128.588C61.809 128.546 61.205 128.773 60.6463 129.012C60.0751 129.256 59.4913 129.503 58.8725 129.502C58.7769 129.504 58.6649 129.498 58.5917 129.438C58.4819 129.349 58.4486 129.196 58.4316 129.051C58.3309 128.141 58.3383 127.224 58.1081 126.34C57.8779 125.456 57.3648 124.59 56.5308 124.246C56.2473 124.129 55.9163 124.08 55.6552 124.215C55.3942 124.35 55.2348 124.726 55.3982 124.989L55.5913 126.021Z" fill="#24252D"/>
      </g>
      <defs>
        <clipPath id="clip0_12205_1381">
          <rect width="42.1591" height="44.9775" fill="white" transform="translate(12.6975 48.4229) rotate(-7.4084)"/>
        </clipPath>
        <clipPath id="clip1_12205_1381">
          <rect width="21.0705" height="22.4792" fill="white" transform="translate(59.667 5.96289) rotate(13.8854)"/>
        </clipPath>
        <clipPath id="clip2_12205_1381">
          <rect width="14.151" height="14.9834" fill="white" transform="translate(48.2144 126.526) rotate(-17.0635)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const StarIllustrations: FC<SVGProps<any>> = (props) => {
  return (
    <svg width="111" height="166" viewBox="0 0 111 166" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_12205_1397)">
        <path d="M64.6918 102.547C66.5527 99.7294 68.1166 99.0662 68.5161 99.1828C69.5717 99.5037 69.6828 103.873 69.8918 104.324C70.0237 104.609 72.9182 105.977 73.2138 106.398C73.7832 107.191 72.8864 108.082 72.0323 108.569C71.1783 109.056 70.4635 109.191 69.8502 109.979C69.001 111.066 69.3626 114.424 68.3375 115.131C67.3124 115.839 66.2397 113.836 65.7534 113.19C65.3563 112.675 64.8432 111.941 64.2445 111.736C63.0472 111.327 60.6561 113.404 59.3463 112.318C58.5387 111.663 61.2145 108.026 61.2023 106.944C61.1754 105.457 59.5101 104.07 59.8767 102.997C60.2872 101.74 64.6918 102.547 64.6918 102.547Z" fill="black"/>
        <path d="M65.0579 92.9937C59.3354 93.3186 54.0249 97.2667 52.4113 103.092C50.8112 108.946 53.3415 115.132 57.7989 118.716C59.9612 120.46 62.7137 121.72 65.5398 121.729C68.5995 121.745 70.9601 119.805 73.3254 117.99C77.7835 114.535 82.9377 107.664 79.8698 101.809C78.4145 99.0303 75.6592 96.8133 73.1276 95.1142C71.7502 94.1775 70.1861 93.3731 68.5488 93.0587C67.3773 92.83 66.2641 93.2588 65.1424 93.0226C64.7262 92.9294 64.5011 93.5882 64.9172 93.6814C65.7781 93.853 66.2421 93.6682 67.1494 93.635C68.4681 93.5707 69.7831 94.0692 70.955 94.6414C73.3622 95.8075 75.6559 97.7196 77.502 99.6995C79.3481 101.679 80.4849 104.079 79.9915 106.902C79.4638 110.033 77.6277 113.059 75.4318 115.276C74.3917 116.319 73.2339 117.223 72.055 118.119C70.855 119.009 69.6747 119.979 68.3039 120.541C65.6693 121.626 62.6733 120.872 60.2901 119.567C55.608 116.986 52.2544 111.622 52.6037 105.978C53.0163 99.2518 58.5918 94.0454 64.988 93.6811C65.4206 93.6572 65.4905 92.9699 65.0579 92.9937Z" fill="black"/>
        <path d="M72.5015 103.361C73.0086 102.774 73.5791 102.209 74.1345 101.687C74.4521 101.379 74.0369 100.869 73.6982 101.17C73.1141 101.706 72.5723 102.257 72.0652 102.844C71.7898 103.167 72.2186 103.706 72.5015 103.361Z" fill="black"/>
        <path d="M63.7382 99.7525C63.3005 99.3086 62.8839 98.872 62.4462 98.4281C62.1473 98.1297 61.6144 98.5852 61.9344 98.8908C62.3721 99.3347 62.7887 99.7713 63.2264 100.215C63.5464 100.521 64.0582 100.058 63.7382 99.7525Z" fill="black"/>
        <path d="M59.1588 106.943C58.4438 106.551 57.7087 106.839 56.9684 106.66C56.5522 106.567 56.3271 107.225 56.7432 107.319C57.3779 107.462 58.1764 107.195 58.7722 107.522C59.1508 107.725 59.5374 107.146 59.1588 106.943Z" fill="black"/>
        <path d="M63.4263 113.772C63.2508 114.423 63.1387 115.096 63.0689 115.784C63.0318 116.237 63.7041 116.27 63.7411 115.817C63.7885 115.196 63.8992 114.596 64.0596 113.989C64.1811 113.564 63.5478 113.348 63.4263 113.772Z" fill="black"/>
        <path d="M71.5394 111.419C72.0995 112.125 72.7545 112.693 73.4892 113.164C73.8603 113.389 74.2468 112.81 73.8757 112.585C73.1757 112.15 72.563 111.597 72.0512 110.956C71.7885 110.621 71.2555 111.077 71.5394 111.419Z" fill="black"/>
      </g>
      <g clipPath="url(#clip1_12205_1397)">
        <path d="M26.777 107.328C28.213 105.162 29.423 104.655 29.7326 104.745C30.5506 104.994 30.6444 108.358 30.807 108.705C30.9096 108.925 33.1535 109.983 33.3832 110.308C33.8255 110.919 33.1326 111.604 32.4721 111.977C31.8116 112.35 31.2583 112.453 30.7847 113.059C30.129 113.894 30.415 116.48 29.6224 117.022C28.8298 117.565 27.9956 116.021 27.6179 115.524C27.3095 115.126 26.9108 114.56 26.4468 114.402C25.5189 114.084 23.6709 115.679 22.6547 114.841C22.0281 114.335 24.0938 111.54 24.0825 110.707C24.059 109.562 22.767 108.491 23.049 107.666C23.3647 106.699 26.777 107.328 26.777 107.328Z" fill="black"/>
        <path d="M27.0434 99.9748C22.6125 100.215 18.507 103.245 17.2677 107.726C16.039 112.23 18.0094 116.997 21.4675 119.764C23.1451 121.111 25.2788 122.085 27.4673 122.097C29.8368 122.115 31.6614 120.626 33.4899 119.232C36.9361 116.58 40.9154 111.3 38.5293 106.787C37.3974 104.646 35.2597 102.934 33.2963 101.621C32.228 100.898 31.0153 100.276 29.7469 100.031C28.8393 99.8529 27.9779 100.181 27.1089 99.9972C26.7865 99.9247 26.6133 100.431 26.9357 100.504C27.6026 100.638 27.9616 100.496 28.6642 100.472C29.6853 100.425 30.7045 100.811 31.613 101.254C33.4792 102.156 35.2588 103.632 36.6919 105.159C38.1251 106.686 39.0096 108.536 38.6325 110.708C38.2294 113.117 36.8129 115.444 35.1163 117.147C34.3127 117.947 33.4177 118.641 32.5063 119.33C31.5786 120.013 30.6663 120.757 29.6057 121.187C27.5674 122.018 25.246 121.432 23.3981 120.424C19.7678 118.428 17.1613 114.292 17.4219 109.948C17.7295 104.771 22.038 100.773 26.9905 100.504C27.3255 100.486 27.3784 99.9572 27.0434 99.9748Z" fill="black"/>
        <path d="M32.8262 107.969C33.2179 107.518 33.6587 107.084 34.0879 106.684C34.3333 106.447 34.0109 106.054 33.7491 106.285C33.2978 106.697 32.8791 107.12 32.4875 107.57C32.2748 107.818 32.6078 108.234 32.8262 107.969Z" fill="black"/>
        <path d="M26.0333 105.176C25.6935 104.833 25.3701 104.496 25.0304 104.154C24.7984 103.923 24.3865 104.273 24.6348 104.509C24.9746 104.851 25.298 105.188 25.6377 105.531C25.886 105.767 26.2816 105.411 26.0333 105.176Z" fill="black"/>
        <path d="M22.4998 110.703C21.9454 110.4 21.3767 110.621 20.803 110.482C20.4806 110.409 20.3074 110.916 20.6298 110.988C21.1216 111.1 21.7395 110.896 22.2015 111.148C22.495 111.305 22.7933 110.86 22.4998 110.703Z" fill="black"/>
        <path d="M25.8166 115.967C25.6819 116.468 25.5962 116.986 25.5433 117.515C25.5154 117.864 26.0361 117.891 26.064 117.542C26.0996 117.064 26.1842 116.602 26.3074 116.135C26.4007 115.809 25.9099 115.641 25.8166 115.967Z" fill="black"/>
        <path d="M32.0952 114.171C32.5303 114.716 33.0384 115.154 33.6082 115.518C33.896 115.692 34.1943 115.247 33.9065 115.073C33.3637 114.737 32.8882 114.31 32.4907 113.816C32.2867 113.558 31.8748 113.907 32.0952 114.171Z" fill="black"/>
      </g>
      <g clipPath="url(#clip2_12205_1397)">
        <path d="M29.6946 145.764C30.6114 144.395 31.3887 144.077 31.5884 144.135C32.116 144.296 32.1886 146.431 32.2945 146.652C32.3615 146.792 33.8102 147.472 33.9592 147.678C34.2463 148.068 33.8026 148.5 33.3786 148.735C32.9546 148.969 32.5987 149.032 32.296 149.415C31.8768 149.943 32.0703 151.584 31.5619 151.926C31.0535 152.268 30.5107 151.285 30.2657 150.967C30.0657 150.714 29.8069 150.354 29.5076 150.251C28.9089 150.047 27.7247 151.052 27.0673 150.516C26.662 150.193 27.9821 148.427 27.9717 147.898C27.9525 147.172 27.1166 146.487 27.2952 145.965C27.495 145.352 29.6946 145.764 29.6946 145.764Z" fill="black"/>
        <path d="M29.8394 141.099C26.9871 141.235 24.3545 143.143 23.5727 145.982C22.7978 148.835 24.0838 151.868 26.3206 153.636C27.4057 154.497 28.7831 155.123 30.1924 155.138C31.7183 155.158 32.8878 154.22 34.0602 153.342C36.2697 151.672 38.8129 148.335 37.2601 145.463C36.5235 144.1 35.1408 143.006 33.8718 142.166C33.1812 141.703 32.3981 141.304 31.5804 141.144C30.9954 141.028 30.4419 141.233 29.8816 141.113C29.6738 141.066 29.5641 141.387 29.7719 141.434C30.2019 141.521 30.4325 141.433 30.8848 141.42C31.5422 141.394 32.1999 141.643 32.7865 141.927C33.9915 142.506 35.1427 143.449 36.0711 144.423C36.9994 145.398 37.5757 146.574 37.3408 147.952C37.0899 149.479 36.1862 150.95 35.0999 152.025C34.5853 152.53 34.0115 152.967 33.4272 153.4C32.8323 153.83 32.2475 154.3 31.5661 154.568C30.2566 155.089 28.7597 154.708 27.5661 154.062C25.2212 152.782 23.5279 150.148 23.68 147.393C23.8593 144.109 26.6192 141.587 29.8073 141.434C30.0229 141.424 30.055 141.089 29.8394 141.099Z" fill="black"/>
        <path d="M33.5922 146.193C33.8428 145.908 34.1251 145.634 34.4 145.382C34.5572 145.232 34.3481 144.982 34.1804 145.127C33.8912 145.387 33.6232 145.654 33.3726 145.939C33.2366 146.095 33.4525 146.36 33.5922 146.193Z" fill="black"/>
        <path d="M29.2082 144.395C28.9882 144.177 28.7787 143.962 28.5587 143.743C28.4085 143.596 28.1445 143.817 28.3053 143.967C28.5253 144.186 28.7348 144.401 28.9548 144.62C29.1155 144.77 29.3689 144.546 29.2082 144.395Z" fill="black"/>
        <path d="M26.9523 147.89C26.5942 147.696 26.2288 147.834 25.8589 147.743C25.651 147.696 25.5413 148.017 25.7492 148.064C26.0663 148.137 26.4634 148.01 26.7618 148.171C26.9514 148.272 27.1419 147.991 26.9523 147.89Z" fill="black"/>
        <path d="M29.1073 151.243C29.0224 151.561 28.9691 151.889 28.937 152.225C28.9203 152.446 29.2557 152.465 29.2724 152.244C29.2935 151.94 29.3464 151.648 29.424 151.352C29.4829 151.145 29.1663 151.036 29.1073 151.243Z" fill="black"/>
        <path d="M33.144 150.126C33.4261 150.474 33.7549 150.753 34.1231 150.987C34.309 151.098 34.4995 150.817 34.3136 150.705C33.9628 150.49 33.6551 150.217 33.3974 149.902C33.2651 149.738 33.0011 149.958 33.144 150.126Z" fill="black"/>
      </g>
      <g clipPath="url(#clip3_12205_1397)">
        <path d="M53.3952 6.61042C54.3121 5.24134 55.0894 4.92348 55.2891 4.98201C55.8167 5.14291 55.8893 7.27808 55.9952 7.49907C56.0621 7.63864 57.5108 8.31858 57.6599 8.52514C57.9469 8.91499 57.5032 9.34685 57.0793 9.58122C56.6553 9.8156 56.2994 9.87874 55.9967 10.2615C55.5775 10.7893 55.771 12.4309 55.2626 12.7726C54.7542 13.1142 54.2114 12.1313 53.9664 11.8141C53.7664 11.561 53.5076 11.2002 53.2082 11.0979C52.6096 10.8933 51.4254 11.8987 50.768 11.3628C50.3627 11.0395 51.6827 9.27332 51.6724 8.74463C51.6532 8.01839 50.8173 7.33397 50.9959 6.81149C51.1957 6.19901 53.3952 6.61042 53.3952 6.61042Z" fill="black"/>
        <path d="M53.5401 1.94525C50.6878 2.0815 48.0551 3.98942 47.2733 6.82864C46.4984 9.68216 47.7845 12.7144 50.0212 14.4825C51.1063 15.3433 52.4838 15.9694 53.8931 15.9851C55.4189 16.0048 56.5885 15.0664 57.7608 14.1887C59.9704 12.5185 62.5136 9.1821 60.9608 6.30959C60.2241 4.94667 58.8415 3.85281 57.5724 3.01284C56.8819 2.54981 56.0988 2.15072 55.2811 1.99072C54.6961 1.87439 54.1426 2.0795 53.5823 1.95969C53.3745 1.91253 53.2648 2.23347 53.4726 2.28063C53.9026 2.36784 54.1332 2.2794 54.5855 2.26673C55.2429 2.2405 55.9006 2.48919 56.4872 2.77334C57.6922 3.35244 58.8434 4.29554 59.7718 5.26997C60.7001 6.2444 61.2764 7.42109 61.0414 8.79841C60.7906 10.3256 59.8869 11.797 58.8005 12.8714C58.286 13.3765 57.7122 13.8136 57.1278 14.2471C56.5329 14.677 55.9482 15.1462 55.2668 15.4152C53.9573 15.9354 52.4604 15.5551 51.2668 14.9082C48.9219 13.6289 47.2286 10.9951 47.3806 8.23931C47.56 4.95523 50.3199 2.43366 53.508 2.28075C53.7235 2.27081 53.7557 1.93531 53.5401 1.94525Z" fill="black"/>
        <path d="M57.2929 7.03961C57.5435 6.75488 57.8257 6.48096 58.1007 6.22844C58.2578 6.07905 58.0488 5.82838 57.8811 5.97417C57.5919 6.23378 57.3239 6.5006 57.0733 6.78534C56.9373 6.94195 57.1532 7.20692 57.2929 7.03961Z" fill="black"/>
        <path d="M52.9089 5.24218C52.6888 5.0236 52.4794 4.80864 52.2594 4.59007C52.1092 4.44315 51.8452 4.66357 52.006 4.8141C52.226 5.03267 52.4354 5.24764 52.6555 5.46621C52.8162 5.61673 53.0696 5.3927 52.9089 5.24218Z" fill="black"/>
        <path d="M50.653 8.73663C50.2949 8.54256 49.9295 8.6805 49.5596 8.58993C49.3517 8.54278 49.242 8.86372 49.4499 8.91087C49.767 8.98341 50.1641 8.85628 50.4625 9.01801C50.6521 9.11865 50.8426 8.83727 50.653 8.73663Z" fill="black"/>
        <path d="M52.808 12.09C52.7231 12.4075 52.6698 12.7358 52.6377 13.0713C52.621 13.2926 52.9564 13.3116 52.973 13.0903C52.9942 12.7869 53.0471 12.4943 53.1247 12.1983C53.1836 11.9914 52.8669 11.8832 52.808 12.09Z" fill="black"/>
        <path d="M56.8442 10.972C57.1263 11.3194 57.4551 11.599 57.8233 11.8324C58.0092 11.9437 58.1997 11.6624 58.0138 11.551C57.663 11.3356 57.3553 11.0631 57.0976 10.748C56.9653 10.5833 56.7013 10.8037 56.8442 10.972Z" fill="black"/>
      </g>
      <g clipPath="url(#clip4_12205_1397)">
        <path d="M49.6136 45.1511C51.9936 41.5371 53.9902 40.6839 54.4996 40.8325C55.8456 41.2413 55.978 46.8402 56.2435 47.4172C56.4113 47.7816 60.1008 49.5289 60.4771 50.067C61.2018 51.0825 60.0557 52.2264 58.9651 52.8517C57.8746 53.4771 56.9624 53.6516 56.1782 54.6632C55.0926 56.058 55.5467 60.3592 54.2374 61.2681C52.9281 62.177 51.5639 59.6122 50.945 58.7864C50.4395 58.1274 49.7864 57.1876 49.0231 56.9267C47.4965 56.4049 44.4416 59.0717 42.773 57.6824C41.7442 56.8445 45.1656 52.1788 45.1524 50.7923C45.1213 48.8878 42.9998 47.1134 43.4698 45.7385C43.9962 44.1269 49.6136 45.1511 49.6136 45.1511Z" fill="black"/>
        <path d="M50.1018 32.91C42.8006 33.3386 36.0173 38.4089 33.9462 45.8758C31.8923 53.3801 35.1069 61.3016 40.7857 65.8836C43.5404 68.1143 47.0492 69.7223 50.6545 69.7284C54.5578 69.7419 57.5736 67.2512 60.595 64.9197C66.2898 60.4835 72.8801 51.6685 68.9789 44.1726C67.1282 40.616 63.6179 37.7812 60.392 35.6096C58.6368 34.4123 56.6431 33.385 54.5551 32.9858C53.0611 32.6952 51.64 33.2471 50.2095 32.9468C49.6788 32.8283 49.3901 33.6729 49.9208 33.7914C51.0187 34.0094 51.611 33.7717 52.7685 33.7271C54.451 33.6419 56.1276 34.2778 57.6214 35.0085C60.6898 36.4974 63.6118 38.9425 65.9627 41.4753C68.3136 44.0081 69.7587 47.0802 69.1231 50.6988C68.4432 54.7111 66.0943 58.593 63.288 61.4383C61.9589 62.7762 60.4799 63.9371 58.974 65.0888C57.4412 66.2313 55.9333 67.4767 54.1833 68.1991C50.8199 69.5962 46.9995 68.6363 43.9619 66.9693C37.9943 63.6719 33.7276 56.8057 34.1855 49.5734C34.7264 40.9546 41.8505 34.2715 50.0112 33.7908C50.563 33.7593 50.6537 32.8785 50.1018 32.91Z" fill="black"/>
        <path d="M59.5754 46.1782C60.2236 45.4251 60.9525 44.6996 61.6623 44.0303C62.0681 43.6345 61.5395 42.9822 61.1068 43.3688C60.3605 44.057 59.6681 44.7636 59.0199 45.5167C58.6679 45.9309 59.2137 46.6206 59.5754 46.1782Z" fill="black"/>
        <path d="M48.4032 41.5737C47.8457 41.0058 47.3152 40.4472 46.7577 39.8794C46.3771 39.4978 45.6962 40.0825 46.1038 40.4733C46.6612 41.0412 47.1918 41.5998 47.7492 42.1676C48.1568 42.5585 48.8107 41.9645 48.4032 41.5737Z" fill="black"/>
        <path d="M42.5456 50.7957C41.6344 50.2956 40.6959 50.6666 39.7519 50.4382C39.2212 50.3197 38.9325 51.1643 39.4632 51.2828C40.2726 51.4651 41.2918 51.1218 42.0512 51.5385C42.5338 51.7978 43.0282 51.055 42.5456 50.7957Z" fill="black"/>
        <path d="M47.9749 59.5371C47.7497 60.372 47.6052 61.2344 47.5146 62.1152C47.4663 62.6961 48.3239 62.7377 48.3721 62.1568C48.4339 61.3604 48.5764 60.5917 48.7824 59.8131C48.9383 59.269 48.1308 58.993 47.9749 59.5371Z" fill="black"/>
        <path d="M58.3304 56.5049C59.0435 57.4089 59.8778 58.1342 60.8141 58.7372C61.287 59.0246 61.7814 58.2818 61.3085 57.9944C60.4164 57.4379 59.636 56.731 58.9844 55.9109C58.6499 55.4822 57.9691 56.0669 58.3304 56.5049Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_12205_1397">
          <rect width="28.2228" height="28.7527" fill="white" transform="translate(57.1787 89.0742) rotate(18.87)"/>
        </clipPath>
        <clipPath id="clip1_12205_1397">
          <rect width="21.8727" height="22.1175" fill="white" transform="translate(20.9351 96.9434) rotate(18.87)"/>
        </clipPath>
        <clipPath id="clip2_12205_1397">
          <rect width="14.1114" height="14.0077" fill="white" transform="translate(25.895 139.153) rotate(18.87)"/>
        </clipPath>
        <clipPath id="clip3_12205_1397">
          <rect width="14.1114" height="14.0077" fill="white" transform="translate(49.5957) rotate(18.87)"/>
        </clipPath>
        <clipPath id="clip4_12205_1397">
          <rect width="35.9841" height="36.8624" fill="white" transform="translate(40.0581 27.9053) rotate(18.87)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
