import clsx from 'clsx'
import { forwardRef } from 'react'
import sanitizeHtml from 'sanitize-html'
import { ILinkedinWorkingExperience } from 'src/interfaces'
import Style from './style.module.scss'

interface IExperienceItemProps {
  isLast: boolean
  isOnly: boolean

  lightMode: boolean

  item: ILinkedinWorkingExperience
}

export const ExperienceItem = forwardRef<HTMLDivElement, IExperienceItemProps>((props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(Style.STItem, {
        [Style.STItem_notLastAndNotOnly]: !props.isLast && !props.isOnly,
        [Style.STItem_notLastAndNotOnly_hideWorkDuration]: props.lightMode
      })}
    >
      {!props.lightMode && (
        <div className={Style.STItemTime}>
          {props.item.workDuration}
        </div>
      )}

      <svg style={{ marginTop: '7px', width: '10px', flexBasis: '10px', flexShrink: 0, height: '10px' }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4.5" stroke={props.lightMode ? '#4FDEE8' : '#80818E'}/>
      </svg>

      <div className={Style.STItemDescription}>
        <div className={clsx([Style.STItemDescriptionTitle, props.lightMode && Style.STItemDescriptionLightmode])}>{props.item.title}</div>

        <div className={clsx([Style.STItemDescriptionCompanyAndLocation, props.lightMode && Style.STItemDescriptionLightmode])}>
          {props.item.company} · {props.item.location || 'Unknown'}
        </div>

        {!props.lightMode && (
          <pre className={Style.STItemDescriptionDescription}>
            {/<\/[a-zA-Z]{1,}>/.test(props.item.description || '')
              ? <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.item.description || '') }}/>
              : props.item.description}
          </pre>
        )}
      </div>
    </div>
  )
})
