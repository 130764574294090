export interface IPlaceholder {
  file?: File | Blob
  src?: string
  key?: string
}

export enum EFileUploadKind {
  PFP = 'pfp',
  PFV = 'pfv',
  RESUME = 'resume',
  TAPE = 'tape',
  DEFAULT = 'default',
  VIDEO_VIBE_CHECK = 'videoVibeCheck',
  AUDIO_VIBE_CHECK = 'audioVibeCheck',
  AUDIO_VIBE_PHOTO = 'audioVibePhoto',
  COMPANY_PHOTO = 'companyPhoto',

  COMPANY_CAMPAIGN_PHOTO = 'companyCampaignPhoto',
  COMPANY_CAMPAIGN_VIDEO = 'companyCampaignVideo',
  COMPANY_CAMPAIGN_AUDIO = 'companyCampaignAudio',
  COMPANY_CAMPAIGN_AUDIO_BG = 'companyCampaignAudioBg',
  JD_CAMPAIGN_FILE = 'jdCampaignFile'
}

export enum ESourceFrom {
  RECORD = 'record',
  UPLOAD = 'upload'
}
