import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useAsRef } from 'src/hooks'
import { IconArrowDown } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  open?: boolean
  onChange?: (open?: boolean) => void
  className?: string
  title?: ReactNode
  titleClassName?: string
  children?: ReactNode
  borderTop?: boolean
}

export const CollapseContent: FC<IProps> = (props) => {
  const [_open, _setOpen] = useState(props.open)
  useEffect(() => {
    _setOpen(props.open)
  }, [props.open])

  const onChangeRef = useAsRef(props.onChange)
  useEffect(() => {
    onChangeRef.current?.(_open)
  }, [_open, onChangeRef])

  return (
    <div
      className={clsx(Style.ccContainer, props.className, {
        [Style.borderTop]: props.borderTop
      })}
    >
      <button className={Style.ccHeader} onClick={() => _setOpen((prev) => !prev)}>
        <span className={clsx(Style.ccTitle, props.titleClassName)}>{props.title}</span>
        <div
          className={Style.ccToggleIcon}
          style={{ transform: `rotate(${_open ? 180 : 0}deg)` }}
        >
          <IconArrowDown/>
        </div>
      </button>

      <Collapse in={_open}>
        {props.children}
      </Collapse>
    </div>
  )
}
