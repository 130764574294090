import { Box, Skeleton, useTheme } from '@mui/material'
import { FC } from 'react'
import Style from './style.module.scss'

export const LikeIntro: FC = () => {
  const theme = useTheme()

  return (
    <div className={Style.container}>
      <Skeleton
        animation="wave"
        sx={{
          bgcolor: theme.colors['--color-neutral-theme-200'],
          width: '100%',
          height: '100%',
          // fix border radius in safari
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
        }}
        variant="rectangular"
      />
      <div className={Style.content}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          {[1, 2, 3].map((_, i) => (
            <Skeleton
              key={i}
              width={40}
              height={40}
              animation="wave"
              sx={{
                bgcolor: theme.colors['--color-neutral-theme-200']
              }}
              variant="circular"
            />
          ))}
        </Box>
        <Skeleton
          width={80}
          height={40}
          animation="wave"
          sx={{
            bgcolor: theme.colors['--color-neutral-theme-200'],
            borderRadius: '12px'
          }}
          variant="rectangular"
        />
      </div>
    </div>
  )
}
