import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

export const useClickOutside = <T extends HTMLElement>(
  callback: () => void
): MutableRefObject<T | null> => {
  const ref = useRef<T | null>(null)

  const handleClick = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }, [callback])

  useEffect(() => {
    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [handleClick])

  return ref
}
