import { EffectCallback, useEffect, useRef } from 'react'

export const useDidMountEffect = (effect: EffectCallback, deps?: Parameters<typeof useEffect>[1]) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      return effect()
    } else {
      didMount.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
