import { StylesObj } from 'src/components/mask/styles'

export const maskStyle: StylesObj = {
  maskWrapper: (defaultStyle) => ({
    ...defaultStyle,
    opacity: '1',
    zIndex: 999
  }),
  svgWrapper: (defaultStyle) => ({
    ...defaultStyle,
    color: 'rgba(0, 0, 0, 0.15)'
  }),
  maskArea: (defaultStyle) => ({
    ...defaultStyle,
    rx: 16
  }),
  clickArea: (defaultStyle) => ({
    ...defaultStyle,
    fill: 'transparent'
  })
}
