import { TAxiosResponseData } from 'src/interfaces'
import { IMission } from 'src/services/tour/popup.service'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export type TourRepository = {
  get: () => Promise<IMission[]>
  finish: (id: IMission['id']) => Promise<IMission | undefined>
}

export class TourApi {
  static readonly _prefix = '/tour'

  static get(): TAxiosResponseData<IMission[]> {
    return axiosHiringApi.get(`${this._prefix}`)
  }

  static finish(params: { id?: IMission['id']; firstTimeClaimedJob?: boolean }): TAxiosResponseData<IMission | undefined> {
    return axiosHiringApi.post(`${this._prefix}`, params)
  }
}

export class RestTourRepository {
  static get(): Promise<IMission[]> {
    return TourApi.get().then(({ data }) => data)
  }

  static finish(id: IMission['id']): Promise<IMission | undefined> {
    return TourApi.finish({ id }).then(({ data }) => data)
  }
}

export class GuestViewTourApi {
  static readonly tourTakenIds: Array<IMission['id']> = []

  static async get(): Promise<IMission[]> {
    const missions: IMission[] = [
      {
        id: 'guest-view-job',
        title: 'View Job & Candidates',
        description: 'Eager to explore your job listing and discover potential talent? Let\'s dive in and uncover the possibilities together!',
        disabled: false,
        creditAmount: 0,
        checked: false
      },
      {
        id: 'guest-manage-job',
        title: 'Manage Job & Candidates',
        description: 'Curious about managing your job and applicants? Discover smart tools for searching, filtering, editing, and sharing your job seamlessly. Explore now!',
        disabled: false,
        creditAmount: 0,
        checked: false
      }
    ]

    return missions.map(mission => {
      if (this.tourTakenIds.includes(mission.id)) {
        return {
          ...mission,
          checked: true
        }
      }

      return {
        ...mission
      }
    })
  }

  static async finish(id: IMission['id']): Promise<IMission | undefined> {
    if (this.tourTakenIds.includes(id)) {
      return
    }

    this.tourTakenIds.push(id)
    return this.get().then(missions => missions.find(mission => mission.id === id))
  }
}

export class GuestOnboardingTourApi {
  static readonly tourTakenIds: Array<IMission['id']> = []

  static async get(): Promise<IMission[]> {
    const missions: IMission[] = [
      {
        id: 'guest-onboarding-vibe-applicants',
        title: 'Vibe Applicants',
        description: 'Curious to see how your job listing looks once your job is created? Let\'s dive in and explore the possibilities together!',
        disabled: false,
        creditAmount: 0,
        checked: false
      },
      {
        id: 'guest-onboarding-create-job',
        title: 'Create a Job',
        description: 'Learn how to create your first job and share it with everyone.',
        disabled: false,
        creditAmount: 0,
        checked: false
      }
    ]

    return missions.map(mission => {
      if (this.tourTakenIds.includes(mission.id)) {
        return {
          ...mission,
          checked: true
        }
      }

      return {
        ...mission
      }
    })
  }

  static async finish(id: IMission['id']): Promise<IMission | undefined> {
    if (this.tourTakenIds.includes(id)) {
      return
    }

    this.tourTakenIds.push(id)
    return this.get().then(missions => missions.find(mission => mission.id === id))
  }
}
