import { FC, useEffect, useMemo, useState } from 'react'
import { IconStopAudio } from 'src/icons'
import Style from './style.module.scss'

interface IStartRecordBtnProps {
  onClick?: () => void
  startTime?: number
}

export const StopRecordBtn: FC<IStartRecordBtnProps> = ({ onClick, startTime = 0 }) => {
  const [countUp, setCountUp] = useState(startTime)

  useEffect(() => {
    setCountUp(startTime)
  }, [startTime])

  useEffect(() => {
    const startTimeout = setTimeout(() => {
      setCountUp((prev) => prev + 1)
    }, 1000)

    return () => clearTimeout(startTimeout)
  }, [countUp])

  const label = useMemo(() => {
    const min = Math.floor(countUp / 60)
    const sec = countUp % 60
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
  }, [countUp])

  return (
    <button
      className={Style.StopBtn}
      onClick={onClick}
    >
      <IconStopAudio/>
      <span>{label}</span>
    </button>
  )
}
