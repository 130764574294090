import { FC, SVGProps } from 'react'
import { ESubmissionActions } from 'src/enums'
import { IconDocument, IconLikeWhite } from 'src/icons'

interface IIcon {
  title: string
  action: ESubmissionActions
  icon: FC<SVGProps<SVGSVGElement>>
  color?: string
  active?: {
    color?: string
    background?: string
  }
}

export const defaultFilterOptions = [
  {
    label: 'Newest First',
    value: 'NEWEST_FIRST',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Oldest First',
    value: 'LATEST_FIRST',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Liked First',
    value: 'LIKED_FIRST',
    isChecked: false,
    isDefault: false

  }
]

export const SEXE = 'SEXE'
export const defaultViewOptions = Object.freeze([
  {
    label: 'View by VVC',
    value: 'VVC',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Candidate Insight',
    value: SEXE,
    isChecked: false,
    isDefault: false
  }
])

export const icons: IIcon[] = [
  {
    title: 'Like',
    action: ESubmissionActions.LIKE,
    icon: IconLikeWhite
  },
  {
    title: 'Info',
    action: ESubmissionActions.INFO,
    icon: IconDocument
  }
]
