import { css, styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'AlertMessage',
  shouldForwardProp: (props) => props !== 'show'
})<{
  mt?: number
  mb?: number
  width?: number
  show?: boolean
}>(({ theme, mt, mb, width, show }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  
  color: ${theme.colors['--color-negative-500']};
  background: ${theme.colors['--color-negative-50']};
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 16px;
  padding: ${theme.spacing(2)};

  transition: opacity 0.3s linear;
  opacity: 0;
  width: 0;
  height: 0;

  svg {
    width: 20px;
    height: 20px;
    display: none;
  }

  ${show && `
    opacity: 1;
    width: auto;
    height: auto;
    border: 1px solid ${theme.colors['--color-negative-500']};

    svg {
      display: flex;
      overflow: unset
    }
  `}


  ${mt && `margin-top: ${theme.spacing(mt)}`};
  ${mb && `margin-bottom: ${theme.spacing(mb)}`};
  ${width && `width: ${width}px`};
`)
