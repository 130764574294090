import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { Icon404 } from 'src/icons'

export const Notfound: FC = () => {
  const theme = useTheme()

  return (
    <div className="fx-column fx-1 fx-ai-center fx-jc-center gap-10">
      <Icon404/>
      <div className="fx-column gap-2">
        <Typography
          variant="h5"
          color={theme.colors['--color-neutral-theme-300']}
          textAlign="center"
        >
          Doh! Try Later
        </Typography>
        <Typography
          variant="body1-regular"
          color={theme.colors['--color-neutral-theme-300']}
          textAlign="center"
        >
          Oops! It seems you've taken a detour to the unknown{' '}
        </Typography>
      </div>
    </div>
  )
}
