import { useTheme } from '@mui/material'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent, EUpgradeView } from 'src/enums'
import { IconCheckedCircleFilled } from 'src/icons'
import { CardRegular, CardSaving, CardSingle } from '../cards'
import Style from './style.module.scss'

export interface IUpgradePlan {
  title?: string
  event: ETrackingEvent
  intros: number
  colorTitle?: string
  color?: string
  price?: number
  colorPrice?: string
  label?: string
  colorLabel?: string
  backgroundLabel?: string
  background?: string
  pricePerCredit?: string
  isBasic?: boolean
}

interface IProps {
  setView: Dispatch<SetStateAction<number>>
  setPlan: Dispatch<SetStateAction<IUpgradePlan | null>>
}

export const usePlan = () => {
  const theme = useTheme()

  return useMemo(() => [
    {
      title: '1 Intro Credit',
      event: ETrackingEvent.BTN_BUY_1,
      intros: 1,
      colorTitle: theme.colors['--color-neutral-theme-700'],
      price: 8,
      colorPrice: theme.colors['--color-neutral-theme-700'],
      isBasic: true
    },
    {
      title: '4 Intro Credits',
      event: ETrackingEvent.BTN_BUY_4,
      intros: 4,
      colorTitle: theme.colors['--color-cyan-300'],
      price: 28,
      colorPrice: theme.colors['--color-neutral-theme-50'],
      label: 'Most Popular',
      colorLabel: theme.colors['--color-cyan-300'],
      backgroundLabel: theme.colors['--color-cyan-800'],
      background: theme.colors['--color-neutral-theme-700'],
      pricePerCredit: '($7 per Credit)',
      isBasic: false
    },
    {
      title: '20 Intro Credits',
      event: ETrackingEvent.BTN_BUY_20,
      intros: 20,
      colorTitle: theme.colors['--color-primary-400'],
      price: 128,
      colorPrice: theme.colors['--color-neutral-theme-50'],
      label: 'Best value',
      backgroundLabel: theme.colors['--color-primary-300'],
      background: theme.colors['--color-neutral-theme-700'],
      pricePerCredit: '($6.4 per Credit)',
      isBasic: false
    }
  ], [theme])
}

export const INCLUDE_TEXTS = [
  'Unlock Talent’s Contact Information',
  'Access to Talent’s Dashboard',
  'No Ghosting Guarantee'
]

export const Store: FC<IProps> = ({ setView, setPlan }) => {
  const { eventHandler } = useAnalytic('store')

  const plans = usePlan()

  const handleSelectPlan = async (plan: IUpgradePlan) => {
    setPlan(plan)
    setView(EUpgradeView.PAYMENT)
  }

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <span className={Style.header_title}>Buy More Introduction Credit</span>
        <div className={Style.header_info}>
          <span className={Style.header_info_title}>With 1 Intro Credit, You Can:</span>
          <div className={Style.header_info_packages}>
            {INCLUDE_TEXTS.map((text, index) => (
              <div key={index} className={Style.header_info_package}>
                <IconCheckedCircleFilled className={Style.header_info_package_icon}/> {text}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={Style.plans}>
        <CardSingle onClick={eventHandler(plans[0].event, () => handleSelectPlan(plans[0]))}/>
        <CardRegular onClick={eventHandler(plans[1].event, () => handleSelectPlan(plans[1]))}/>
        <CardSaving onClick={eventHandler(plans[2].event, () => handleSelectPlan(plans[2]))}/>
      </div>
    </div>
  )
}
