import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = styled.button`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: rgba(227, 73, 28, 1);
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 5;
  :hover {
    background: #fb6d42;
  }
`

const RecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonBorder = styled.div`
  border: 4px solid rgba(255, 255, 255, 1);
  background: transparent;
  height: 78px;
  width: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

Button.defaultProps = {
  color: 'black',
  backgroundColor: 'white'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecordButton = ({ _, ...props }) => (
  <RecWrapper>
    <ButtonBorder>
      <Button {...props}/>
    </ButtonBorder>
  </RecWrapper>
)

RecordButton.propTypes = {
  t: PropTypes.func
}

export default RecordButton
