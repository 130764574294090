import axios from 'axios'
import { EEnv } from 'src/constants'

export const axiosHiringApi = axios.create()

/**
 * axios config
 */
if (EEnv.REACT_APP_HIRING_API_DNS) {
  axiosHiringApi.defaults.baseURL = EEnv.REACT_APP_HIRING_API_DNS
}
