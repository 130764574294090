import { Snackbar, Typography, useTheme } from '@mui/material'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { EMessage } from 'src/enums'
import { useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IconMessageError, IconMessageInfo, IconMessageSuccess, IconMessageWarning } from 'src/icons'
import { IBannerMessage } from 'src/interfaces'
import { BannerService } from 'src/services/banner.service'
import Style from './style.module.scss'

export const BannerContainer: FC = () => {
  const theme = useTheme()
  const unsubscribe$ = useUnsubscribe()
  const [openMessage, setOpenMessage] = useState<IBannerMessage>()
  const [messages, setMessages] = useState<IBannerMessage[]>([])

  const icons: { [key in EMessage]: ReactNode } = {
    [EMessage.NONE]: null,
    [EMessage.SUCCESS]: <IconMessageSuccess className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.ERROR]: <IconMessageError className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.WARNING]: <IconMessageWarning className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.INFO]: <IconMessageInfo className="fx-shrink-0 w-6 h-6"/>
  }

  const colors: { [key in EMessage]: string } = useMemo(() => ({
    [EMessage.NONE]: theme.colors['--color-primary-400'],
    [EMessage.SUCCESS]: theme.colors['--color-positive-500'],
    [EMessage.ERROR]: theme.colors['--color-negative-500'],
    [EMessage.WARNING]: theme.colors['--color-orange-400'],
    [EMessage.INFO]: theme.colors['--color-purple-300']
  }), [theme])

  useDidMountEffect(() => {
    const hasReset = messages.find(message => !message.title)
    if (hasReset) {
      setMessages([])
      setOpenMessage({} as IBannerMessage)
      return
    }

    const hasClosedMessages = messages.some(message => !message.open)
    if (hasClosedMessages) {
      setMessages(prev => prev.filter(message => message.open))
    }

    const _openMessage = messages.find(message => message.open)
    if (_openMessage) {
      setOpenMessage(() => _openMessage)
    }
  }, [messages])

  useEffect(() => {
    BannerService.message$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(message => setMessages(prev => [...prev, message]))
  }, [unsubscribe$])

  const handleClose = (
    reason?: 'primaryClicked' | 'secondaryClicked' | 'timeout' | 'clickaway' | 'escapeKeyDown',
    id?: IBannerMessage['id']
  ) => {
    if (reason && ['clickaway', 'escapeKeyDown'].includes(reason)) {
      return
    }

    const message = id && messages.find(item => item.id === id)
    if (message) {
      message.open = false
      setMessages(prev => [...prev])

      if (reason === 'primaryClicked') {
        message.onClick?.(message)
      }

      if (reason === 'secondaryClicked') {
        message.onClickSecondary?.(message)
      }
    }
  }

  return (
    <>
      {openMessage && (
        <Snackbar
          key={openMessage.id}
          open={openMessage.open}
          anchorOrigin={openMessage.position}
          autoHideDuration={openMessage.autoHideDuration}
          onClose={(e, reason) => handleClose(reason, openMessage.id)}
          className={openMessage.bannerClassName}
          sx={{
            '& .MuiSnackbarContent-root, .MuiSnackbarContent-message, .MuiSnackbarContent-action': {
              width: '100%',
              padding: 0,
              borderRadius: '8px'
            }
          }}
          message={(
            <div className={Style.banner}>
              <div className="flex gap-2 fx-ai-center">
                {icons[openMessage.severity]}
                <Typography component="div" variant="body1-bold">
                  {openMessage.title}
                </Typography>
              </div>
              <Typography
                variant="body2-regular"
                color={theme.colors['--color-neutral-theme-50']}
              >
                {openMessage.content}
              </Typography>

              <div className="flex fx-ai-center fx-jc-flex-end gap-2 py-2">
                {openMessage.secondaryText?.trim() && (
                  <Typography
                    variant="body2-bold"
                    color={theme.colors['--color-neutral-theme-300']}
                    px={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleClose('secondaryClicked', openMessage.id)}
                  >
                    {openMessage.secondaryText}
                  </Typography>
                )}

                {openMessage.actionText?.trim() && (
                  <Typography
                    variant="body2-bold"
                    color={colors[openMessage.severity]}
                    sx={{ cursor: 'pointer' }}
                    px={1}
                    onClick={() => handleClose('primaryClicked', openMessage.id)}
                  >
                    {openMessage.actionText}
                  </Typography>
                )}
              </div>
            </div>
          )}
        />
      )}
    </>
  )
}
