import { ICandidateModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class ViewApi {
  static readonly _prefix = '/view'

  static share(
    sharingToken: string
  ): TAxiosResponseData<ICandidateModel> {
    return axiosHiringApi.get(`${this._prefix}/${sharingToken}`)
  }
}
