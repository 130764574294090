import { FC } from 'react'
import { Private } from './components/private'
import { Public } from './components/public'

export enum EReport {
  PUBLIC = 'Public',
  PRIVATE = 'Private'
}

interface IProps {
  type?: EReport
  name?: string
  view?: 'intro' | 'like'
}

export const Report: FC<IProps> = ({ type = EReport.PRIVATE, name, view }) => {
  return (
    <>
      {type === EReport.PUBLIC ? <Public/> : <Private name={name} view={view}/>}
    </>
  )
}
