import { styled } from '@mui/material'
import { Button, Input } from 'src/components'

export const STContainer = styled('div', { label: 'Container' })(({ theme }) => `
  position: relative;
  height: 44px;
  padding: ${theme.spacing(0, 2)};
  border-radius: 49px;
  background-color: ${theme.colors['--color-neutral-theme-100']};
  cursor: pointer;
`)

export const STCredit = styled('div', { label: 'Credit' })(({ theme }) => `
  height: 44px;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)}
`)

export const STDropdown = styled('div', {
  label: 'Dropdown'
})(({ theme }) => `
  position: absolute;
  z-index: 999;
  top: 50px;
  right: 0;
  width: 194px;
  border: 1px solid ${theme.colors['--color-neutral-theme-200']};
  padding: ${theme.spacing(1.5, 1)};
  box-shadow: 0px 40px 80px -12px rgb(37 38 46 / 10%);
  border-radius: 12px;
  background-color: ${theme.colors['--color-neutral-theme-50']} !important;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)}
`)

export const STDropdownItem = styled('div', {
  label: 'DropdownItem'
})(({ theme }) => `
  padding: ${theme.spacing(1)};

  span {
    color: ${theme.colors['--color-neutral-theme-700']}
  }
`)

export const STPromoLabel = styled('span', {
  label: 'PromoLabel'
})(({ theme }) => `
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors['--color-neutral-theme-700']};
`)

export const STPromoInput = styled(Input, {
  label: 'PromoInput'
})(({ theme }) => `
  height: 40px;
  margin-bottom: -32px;
  margin-top: 4px;

  input {
    height: 40px;
    padding: 0 0 0 16px !important;
  }

  >div {
    padding-right: 4px;
  }
`)

export const STPromoInputError = styled('span', {
  label: 'PromoInputError'
})(({ theme }) => `
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors['--color-negative-500']} !important;
`)

export const STPromoButton = styled(Button, {
  label: 'PromoButton'
})(({ theme }) => `
  height: 32px;
  width: 57px;
`)
