import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FC, ReactNode } from 'react'
import { EEnv } from 'src/constants'

const stripe = loadStripe(EEnv.REACT_APP_STRIPE_KEY, { locale: 'en' })

export const StripeProvider: FC<{ children?: ReactNode }> = (props) => {
  return (
    <Elements stripe={stripe}>
      {props.children}
    </Elements>
  )
}
