import { Box, SxProps, TextareaAutosizeProps } from '@mui/material'
import clsx from 'clsx'
import { forwardRef, useEffect, useState } from 'react'
import { IconError } from 'src/icons'
import { Label } from '../label'
import { Message } from '../message'
import { TextareaCounter, TextareaCustom, TextareaWrapper } from './styled'

interface IProps extends TextareaAutosizeProps {
  label?: string
  error?: string
  disableResize?: boolean
  countLength?: boolean
  sx?: SxProps
  wrapperClassName?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement | null, IProps>(({ label, error, disableResize, countLength, onChange, value, wrapperClassName, ...props }, forwardRef) => {
  const [_value, _setValue] = useState(value?.toString())

  useEffect(() => {
    _setValue(value?.toString())
  }, [value])

  const handleValueChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    _setValue(e.target.value)
    onChange?.(e)
  }

  return (
    <TextareaWrapper className={wrapperClassName}>
      {label && <Label>{label}</Label>}
      <Box position="relative">
        <TextareaCustom
          ref={forwardRef}
          disableResize={disableResize}
          minRows={5}
          value={_value}
          onChange={handleValueChange}
          {...props}
          className={clsx(props.className, error && 'Mui-error')}
        />
        {countLength && !!props.maxLength && (
          <TextareaCounter>
            {`${_value?.length || 0}/${props.maxLength}`}
          </TextareaCounter>
        )}
      </Box>
      {error && (
        <div className="fx gap-2 mt-2">
          <IconError className="w-6 h-6"/> <Message showMessage={!!error} status="error">{error}</Message>
        </div>
      )}
    </TextareaWrapper>
  )
})
