import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import { IconWrapper } from 'src/components'
import { IconDocument, IconLikeWhite, IconPlay } from 'src/icons'
import { backgroundImage } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  url: string
  name?: string
}

export const VideoCard: FC<IProps> = (props) => {
  const theme = useTheme()

  return (
    <div className={Style.container} style={{ background: backgroundImage(props.url) }}>
      <IconPlay className={Style.iconPlay}/>
      <IconWrapper
        width={36}
        height={36}
        color={theme.colors['--color-neutral-theme-50']}
        className={clsx(Style.icon, Style.iconLike)}
      >
        <IconLikeWhite/>
      </IconWrapper>

      <IconWrapper
        width={36}
        height={36}
        color={theme.colors['--color-neutral-theme-50']}
        className={clsx(Style.icon, Style.iconDocument)}
      >
        <IconDocument/>
      </IconWrapper>

      <div className={Style.name}>
        <span className="meta-bold color-neutral-theme-50">{props.name}</span>
      </div>
    </div>
  )
}
