import clsx from 'clsx'
import { FC, HTMLProps, useMemo } from 'react'
import { EPasswordRule, PasswordUtil } from 'src/utils'
import Style from './style.module.scss'

export const PasswordChecker: FC<{ password: string } & HTMLProps<HTMLUListElement>> = props => {
  const { password } = props
  const passwordUtil = useMemo(
    () => new PasswordUtil(password),
    [password]
  )

  return (
    <ul className={Style.pwdCheckList}>
      <li
        className={clsx(Style.pwdCheckItem, {
          [Style.passed]: passwordUtil.isPassedRule(EPasswordRule.NUMBER),
          [Style.failed]: !!password?.length && !passwordUtil.isPassedRule(EPasswordRule.NUMBER)
        })}
      >
        One number
      </li>
      <li
        className={clsx(Style.pwdCheckItem, {
          [Style.passed]: passwordUtil.isPassedRule(EPasswordRule.UPPERCASE),
          [Style.failed]: !!password?.length && !passwordUtil.isPassedRule(EPasswordRule.UPPERCASE)
        })}
      >
        One uppercase letter
      </li>
      <li
        className={clsx(Style.pwdCheckItem, {
          [Style.passed]: passwordUtil.isPassedRule(EPasswordRule.LENGTH),
          [Style.failed]: !!password?.length && !passwordUtil.isPassedRule(EPasswordRule.LENGTH)
        })}
      >
        8 characters minimum
      </li>
    </ul>
  )
}
