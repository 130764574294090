import { isFunction } from 'lodash'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { filter, takeUntil } from 'rxjs'
import { NavigationService } from 'src/services'
import { useUnsubscribeEffect } from './useUnsubscribeEffect'

export const useSubscribeToGoBack = ({ showArrow, title, titleActions, handler }:
{ showArrow: boolean; title?: string; titleActions?: any; handler?: () => any }) => {
  const router = useHistory()

  useEffect(() => {
    if (titleActions?.length) {
      NavigationService.showTitle(showArrow, title, titleActions)
    } else {
      NavigationService.showTitle(showArrow, title)
    }

    return () => {
      NavigationService.hideTitle()
    }
  }, [title, showArrow, titleActions])

  useUnsubscribeEffect((unsubscribe$) => {
    NavigationService.navigation$
      .pipe(
        takeUntil(unsubscribe$),
        filter((currentTitle) => currentTitle === title)
      )
      .subscribe(() => isFunction(handler) ? handler() : router.goBack())
  }, [router, handler, title])
}
