import { Box, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router'
import { AnalyticApi, AuthApi } from 'src/api'
import { useAnalytic } from 'src/components'
import { SST_GUEST_CAMPAIGN_ID } from 'src/constants'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useQueryParams } from 'src/hooks'
import { IconSendMail } from 'src/icons'
import { Footer } from 'src/layout/layout-container/footer'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getApiErrorMessage } from 'src/utils'
import { Header } from '../../../header'
import { LeftSection } from '../../../left-section'
import Style from './style.module.scss'

export const VerifyEmail: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { inviteToken } = useQueryParams()
  const dispatch = useAppDispatch()
  const { eventHandler } = useAnalytic('signup_verify_email')
  const sstCampaignHashId = useMemo(() => sessionStorage.getItem(SST_GUEST_CAMPAIGN_ID) || undefined, [])

  const resendEmail = async () => {
    try {
      dispatch(setLayoutLoading(true))

      await AuthApi.signUpRequest({
        email: history.location.state as string,
        inviteToken: inviteToken as string,
        campaignHashId: sstCampaignHashId,
        sessionId: AnalyticApi._session
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>
        <LeftSection illustration={2}/>
        <div className={Style.RightSide}>
          <Box className={Style.STContainer}>
            <div className={Style.SectionSendMail}>
              <IconSendMail className={Style.iconEmailLarge}/>
              <Typography className={Style.mainText}>Verify Your Email</Typography>
              <Typography
                className={Style.subText}
                variant="body1-medium"
                align="center"
              >We sent you a verification link to <span className={clsx('body1-bold', Style.Email)}>{history.location.state as string}</span>.
                {/* Click the link to verify your email. */}
              </Typography>
              <Typography
                variant="body2-regular"
                className={Style.resendEmail}
                color={theme.colors['--color-neutral-theme-300']}
              >
                Didn’t receive the email?
                <br/>
                Check your spam folder or
                <Typography
                  component="a"
                  variant="body2-regular"
                  sx={{ cursor: 'pointer' }}
                  onClick={eventHandler(ETrackingEvent.BTN_RESEND_VERIFY_EMAIL, resendEmail)}
                >
                  {' '} click to resend.
                </Typography>
              </Typography>
            </div>
          </Box>
          <Footer/>
        </div>
      </div>
    </>
  )
}
