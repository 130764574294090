export enum EUserGuide {
  IS_DEMO = 'isDemo',
  FIRST_INTRO = 'firstIntro',
  ONBOARDING = 'onboarding',
  LIKE = 'like',
  SUPER_LIKE = 'superLike',
  SUPER_NOVA = 'superNova',
  SKIP = 'skip',
  FIRST_TIME_UPLOAD_PFV = 'firstTimeUploadPfv',
  FIRST_TIME_CLICK_CREDIT = 'firstTimeClickCredit',
  ONBOARDING_CAMPAIGN= 'onboardingCampaign',
  ONBOARDING_TALENT= 'onboardingTalent',
  CAMPAIGN_ID = 'campaignId'
}

export enum EUserType {
  UNSURE = 'unsure',
  NOPE = 'nope',
  LIKE = 'like',
  SUPER_LIKE = 'superlike',
  SKIP = 'skip'
}

export enum EWorkInterests {
  REMOTE = 'Remote',
  HYBRID = 'Hybrid',
  ONSITE = 'Onsite'
}
