import { FC, PropsWithChildren, createContext, useContext } from 'react'
import { IMission } from 'src/services/tour/popup.service'

interface IExpandParams {
  expanding?: IMission['id']
  setExpanding: (id?: IMission['id']) => void
}

export const ExpandContext = createContext<IExpandParams>({
  expanding: undefined,
  setExpanding: () => null
})

export const useMissionExpand = () => {
  const values = useContext(ExpandContext)
  return values
}

export const ExpandProvider: FC<PropsWithChildren<IExpandParams>> = (props) => {
  return (
    <ExpandContext.Provider value={{ expanding: props.expanding, setExpanding: props.setExpanding }}>
      {props.children}
    </ExpandContext.Provider>
  )
}
