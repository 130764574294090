import { Typography, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { CandidateApi } from 'src/api'
import { EMessage, EShareTable, ETrackingEvent } from 'src/enums'
import { useAppSelector, useIfMobileL, useOldValidation } from 'src/hooks'
import { OverlayService, SnackbarService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { getApiErrorCode } from 'src/utils'
import { shareSchema } from 'src/validation'
import { useAnalytic } from '../analytic-provider'
import { Button } from '../button'
import { EmailInput } from '../email-input'
import { ErrorMessage } from '../message'
import { Textarea } from '../textarea'
import { STShare } from './styled'

interface IShareProps {
  videoId: number
  candidateId: number
  submissionId?: number
  setForceRender?: (val: number | ((prevState: number) => number)) => void
  loadData?: () => void
  title?: string
}

export const Share: FC<IShareProps> = ({ videoId, candidateId, submissionId, loadData, setForceRender, title = 'Share Video Vibe Check' }) => {
  const theme = useTheme()
  const isMobile = useIfMobileL()
  const { errors, setErrors, validate } = useOldValidation<{ emails?: string[] }>()

  const [message, setMessage] = useState<string>('')
  const [emails, setEmails] = useState<string[]>([])
  const { eventHandler } = useAnalytic('share_candidate')
  const credentials = useAppSelector(getCredentials)

  const handleEmailsChange = useCallback((emails: string[]) => {
    setEmails(emails)
  }, [setEmails])

  const handleShare = async () => {
    try {
      const isValid = await validate({ schema: shareSchema, data: { emails } })
      if (!isValid) return

      const sharablePayload = submissionId !== undefined
        ? {
          shareableType: EShareTable.CAMPAIGN_SUBMISSION,
          shareableId: submissionId
        }
        : {
          shareableType: EShareTable.VIDEO,
          shareableId: videoId
        }

      await CandidateApi.share(candidateId, {
        emails,
        ...sharablePayload,
        message
      })

      loadData && loadData()
      setForceRender && setForceRender((prevState: number) => prevState + 1)

      OverlayService.reset()
      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Email Sent'
      })
    } catch (error) {
      if (getApiErrorCode(error) === 'error_email_domain') {
        eventHandler(ETrackingEvent.ERROR_EMAIL_DOMAIN)()
      }

      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'Network Error'
      })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setErrors({}), [emails])

  return (
    <STShare isMobile={isMobile}>

      <Typography
        variant="h4"
        sx={{ marginBottom: 1 }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2-regular"
        color={theme.colors['--color-neutral-theme-300']}
      >
        Share with others in your company
      </Typography>

      {/* <EmailSuggestion setEmails={setEmails} error={errors.emails} onInputClick={eventHandler(ETrackingEvent.INPUT_SHARE_EMAIL)}/> */}
      <EmailInput
        domain={credentials?.email?.split('@')[1]}
        onChange={handleEmailsChange}
        error={errors.emails?.[0]}
      />

      <ErrorMessage className="mt-2">{errors.emails}</ErrorMessage>

      <Textarea
        placeholder="Your message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        onClick={eventHandler(ETrackingEvent.INPUT_SHARE_COMMENT)}
        autoComplete="off"
        disableResize
        sx={{ height: '128px', marginTop: 1.5 }}
      />

      <Button
        fullWidth
        sx={{ marginTop: 2 }}
        onClick={eventHandler({
          key: ETrackingEvent.BTN_VIDEO_SHARE,
          contextData: { videoId }
        }, handleShare)}
      >
        Share
      </Button>
    </STShare>
  )
}
