import { createSelector } from 'reselect'
import { TAppState, TSelectorResult } from 'src/store'
import { IAuthState } from '../types'

export const getCredentials: TSelectorResult<IAuthState['credentials']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => auth.credentials
)

export const getGuide: TSelectorResult<IAuthState['credentials']['guide']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => auth.credentials.guide
)

export const getIsAuthenticated: TSelectorResult<IAuthState['isAuthenticated']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => auth.isAuthenticated
)

export const getCompleteProfileFields: TSelectorResult<{
  firstName: string
  lastName: string
  companyName: string
  companyUrls: string[]
}> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => ({
    firstName: auth.credentials.firstName || '',
    lastName: auth.credentials.lastName || '',
    companyName: auth.credentials.company?.name || '',
    companyUrls: auth.credentials.company?.urls || []
  })
)

export const getAuthStat: TSelectorResult<IAuthState['stats']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => auth.stats
)

export const getForceOpenTutorial: TSelectorResult<IAuthState['forceOpenTutorial']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => auth.forceOpenTutorial
)
