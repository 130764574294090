import { FC, useCallback, useEffect, useState } from 'react'
import { CandidateApi } from 'src/api'
import { Button } from 'src/components'
import { EMessage } from 'src/enums'
import { SnackbarService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
import Style from './style.module.scss'

export interface INoDataProps {
  subscribed?: boolean
  candidateId: number
  onSubscribeChanged?: (subscribed: boolean) => void
}

export const NoData: FC<INoDataProps> = ({ candidateId, subscribed, onSubscribeChanged }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(subscribed)

  useEffect(() => {
    setIsSubscribed(subscribed)
  }, [subscribed])

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true)
      if (isSubscribed) {
        await CandidateApi.unSubscribeLinkedin(candidateId)
        onSubscribeChanged?.(false)
      } else {
        await CandidateApi.subscribeLinkedin(candidateId)
        onSubscribeChanged?.(true)
      }
      setIsSubscribed(prev => !prev)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      setIsLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribed, setIsSubscribed, isLoading, setIsLoading, candidateId])

  return (
    <div className={Style.STNoDataContainer}>
      <span>
        {isSubscribed ? 'You will receive a notification when the candidate updates their information' : 'Notify me when this candidate updates their experience'}
      </span>
      <Button className={Style.buttonUnsubscribe} order={isSubscribed ? 'secondary' : 'primary'} onClick={handleClick} disabled={isLoading}>
        {isSubscribed ? 'Subscribed' : 'Subscribe'}
      </Button>
    </div>
  )
}
