import { EffectCallback, useEffect } from 'react'

export const useDebounce = (effect: EffectCallback, wait = 500, deps?: Parameters<typeof useEffect>[1]) => {
  useEffect(() => {
    const timer = setTimeout(effect, wait)
    return () => {
      clearTimeout(timer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
