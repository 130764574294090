import { UnregisterCallback } from 'history'
import React from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, Subscription, finalize, take, tap } from 'rxjs'
import { ITourPopperProps } from 'src/components'
import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import { SignUpModal } from 'src/partials'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { OverlayService } from '../overlay.service'
import { PopupTourService } from './popup.service'

const MAX_STEP_TOTAL = 4

export const TARGET_PUBLIC_GUEST_EDIT_JOB = new Target(
  'target-public-guest-edit-job'
)

export const TARGET_PUBLIC_GUEST_COPY_JOB = new Target(
  'target-public-guest-copy-job'
)

export const TARGET_PUBLIC_GUEST_SEARCH_AND_SORT = new Target(
  'target-public-guest-search-sort'
)

export const TARGET_PUBLIC_GUEST_CHANGE_VIEW = new Target(
  'target-public-guest-change-view'
)

const STEP_CONFIGS: ITourPopperProps[] = [
  {
    title: 'Edit Your Job',
    content: 'Tweak your job details whenever you need to for a successful hiring campaign. Your control, your success!',
    currentStep: 0,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_EDIT_JOB.getSelector(),
    arrowPosition: 'top',
    ctaText: 'Next',
    onCtaClick: () => {
      (
        document.querySelector(
          TARGET_PUBLIC_GUEST_EDIT_JOB.getSelector()
        ) as HTMLButtonElement
      )?.click()
    },
    onExitClick: () => {
      GuestManageJobTourService.stopTour(true)
    }
  },
  {
    title: 'Share Your Job',
    content: 'Copy link and share your job on popular platforms or even your own channel to attract a flood of applications.',
    currentStep: 1,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_COPY_JOB.getSelector(),
    arrowPosition: 'top',
    ctaText: 'Next',
    onCtaClick: () => {
      GuestManageJobTourService.nextStep()
    },
    onBack: () => {
      GuestManageJobTourService.previousStep()
    },
    onExitClick: () => {
      GuestManageJobTourService.stopTour(true)
    }
  },
  {
    title: 'Search & Sort!',
    content: 'When applicants start flooding in, take control by searching or sorting them by newest or oldest. Manage your applicants effortlessly!',
    arrowPosition: 'top',
    currentStep: 2,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_SEARCH_AND_SORT.getSelector(),
    onBack: () => {
      GuestManageJobTourService.previousStep()
    },
    ctaText: 'Next',
    onCtaClick: () => {
      GuestManageJobTourService.nextStep()
    },
    onExitClick: () => {
      GuestManageJobTourService.stopTour(true)
    }
  },
  {
    title: 'Switch Applicant Video Mode',
    content: 'Switch applicant video mode to Video Vibe Check (VVC) or access applicant\'s insights for a quick overview of their information.',
    arrowPosition: 'right',
    currentStep: 3,
    width: '379px',
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_CHANGE_VIEW.getSelector(),
    onBack: () => {
      GuestManageJobTourService.previousStep()
    },
    ctaText: 'End Tour',
    onCtaClick: () => {
      GuestManageJobTourService.nextStep()
    },
    onExitClick: () => {
      GuestManageJobTourService.stopTour(true)
    }
  }
]

export class GuestManageJobTourService {
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined
  private static _overlaySubscription?: Subscription

  public static get currentStep$(): WithOutNextComplete<
    typeof GuestManageJobTourService._currentStep$
  > {
    return GuestManageJobTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<
    typeof GuestManageJobTourService._enableTour$
  > {
    return GuestManageJobTourService._enableTour$
  }

  public static nextStep(onAfterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep + 1)

    if (currentStep + 1 === MAX_STEP_TOTAL) {
      PopupTourService.completeMission('guest-manage-job')
        .pipe(
          take(1),
          tap(() => {
            this.stopTour()
          }),
          finalize(() => {
            onAfterNext?.()
          })
        )
        .subscribe(() => {
          OverlayService.setOverlay({
            open: true,
            content: React.createElement(SignUpModal, { showCandidateText: true })
          })
        })
    }
  }

  public static goToStep(step: number) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this._currentStep$.next(step)
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)
    OverlayService.reset()

    this.stopOverlaySubscription()

    this._overlaySubscription = OverlayService.overlay$.subscribe((overlay) => {
      if (overlay.open) {
        this.stopTour()
      }
    })

    this.stopRouteListener()

    this._routeListener = history?.listen((location) => {
      if (!location.pathname.startsWith(`/${PUBLIC_CAMPAIGN_BASE_PATH}`)) {
        this.stopTour(true)
      }
    })
  }

  private static stopOverlaySubscription() {
    if (this._overlaySubscription) {
      this._overlaySubscription.unsubscribe()
      this._overlaySubscription = undefined
    }
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopOverlaySubscription()
    this.stopRouteListener()

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }
}
