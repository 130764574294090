import { FC } from 'react'
import { EMessage } from 'src/enums'
import { SnackbarService, ToastService } from 'src/services'
import { BannerService } from 'src/services/banner.service'

export const PlayGround: FC = () => {
  return (
    <div className="p-16">
      <p className="body1-medium color-neutral-theme-900">Snackbar</p>
      <div className="flex gap-4 ">
        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.NONE,
            content: 'Longer snackbar',
            position: { vertical: 'top', horizontal: 'center' },
            actionText: undefined
          })}
        >Longer snackbar
        </button>

        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.NONE,
            content: 'Longer snackbar that contains action',
            actionText: 'Button'
          })}
        >Longer snackbar that contains action
        </button>

        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.NONE,
            content: 'Longer snackbar that contains 2 actions',
            actionText: 'Longer Button',
            secondaryText: 'Button'
          })}
        >Longer snackbar that contains 2 actions
        </button>

        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.SUCCESS,
            content: 'Longer snackbar with heading icon',
            actionText: undefined
          })}
        >Longer snackbar with heading icon
        </button>

        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.SUCCESS,
            content: 'Longer snackbar with heading icon & action',
            actionText: 'Button'
          })}
        >Longer snackbar with heading icon & action
        </button>

        <button
          onClick={() => SnackbarService.push({
            severity: EMessage.SUCCESS,
            content: 'Longer snackbar with heading icon & actions',
            secondaryText: 'Button',
            actionText: 'Longer Button'
          })}
        >Longer snackbar with heading icon & actions
        </button>
      </div>

      <p className="body1-medium color-neutral-theme-900">Banner</p>
      <div className="flex gap-4 ">
        <button
          onClick={() => BannerService.push({
            severity: EMessage.NONE,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: undefined,
            autoHideDuration: 2000
          })}
        >Banner 1
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.NONE,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 2
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.NONE,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Larget Button',
            secondaryText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 3
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.SUCCESS,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Larget Button',
            secondaryText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 4
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.ERROR,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Larget Button',
            secondaryText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 5
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.WARNING,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Larget Button',
            secondaryText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 6
        </button>

        <button
          onClick={() => BannerService.push({
            severity: EMessage.INFO,
            title: 'Banner Title',
            content: 'Some important information will appear here—Lorem ipsum dolor sit amet conseqtetur adipiscing elit',
            position: { vertical: 'bottom', horizontal: 'right' },
            actionText: 'Larget Button',
            secondaryText: 'Button',
            autoHideDuration: 2000
          })}
        >Banner 7
        </button>
      </div>

      <p className="body1-medium color-neutral-theme-900">Toast</p>
      <div className="flex gap-4 ">
        <button
          onClick={() => ToastService.push({
            severity: EMessage.INFO,
            content: 'Toast Message',
            position: { vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: 2000
          })}
        >Toast 1
        </button>

        <button
          onClick={() => ToastService.push({
            severity: EMessage.SUCCESS,
            content: 'Toast Message',
            position: { vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: 2000
          })}
        >Toast 2
        </button>

        <button
          onClick={() => ToastService.push({
            severity: EMessage.WARNING,
            content: 'Toast Message',
            position: { vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: 2000
          })}
        >Toast 3
        </button>

        <button
          onClick={() => ToastService.push({
            severity: EMessage.ERROR,
            content: 'Toast Message',
            position: { vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: 2000
          })}
        >Toast 4
        </button>
      </div>
    </div>
  )
}
