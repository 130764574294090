import { FormControlLabel, SxProps, Theme } from '@mui/material'
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import { FC, ReactNode, SVGProps, useMemo } from 'react'
import { IconChecked, IconCheckedGreen, IconUnChecked } from 'src/icons'

interface IProps extends CheckboxProps {
  label?: string | ReactNode
  sx?: SxProps
  type?: 'primary' | 'positive'
  iconProps?: SVGProps<any>
}

export const Checkbox: FC<IProps> = ({ type = 'primary', iconProps = {}, ...props }) => {
  const sx = useMemo(() => ({
    '&.MuiFormControlLabel-root': {
      color: (theme: Theme) => theme.colors['--color-neutral-theme-900'],
      marginLeft: 0
    },
    ...(props.sx || {})
  }), [props.sx])

  return (
    <FormControlLabel
      className={props.className}
      sx={sx}
      control={(
        <MuiCheckbox
          checkedIcon={type === 'primary' ? <IconChecked {...(iconProps)}/> : <IconCheckedGreen {...(iconProps)}/>}
          icon={<IconUnChecked {...(iconProps)}/>}
          sx={{
            padding: 0,
            marginRight: 1.5
          }}
          {...props}
          checked={props.checked || false}
        />
      )}
      label={props.label}
    />
  )
}
