import clsx from 'clsx'
import { motion } from 'framer-motion'
import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { filter, takeUntil } from 'rxjs'
import { RestTourRepository, TourRepository } from 'src/api/tour'
import { FlyingCoins } from 'src/components/flying-coin'
import { Spinner } from 'src/components/spinner'
import { useBehaviorMapper, useIfMobileL, useUnsubscribeEffect } from 'src/hooks'
import { OverlayService } from 'src/services'
import { CreateJobTourService } from 'src/services/tour/create-job-tour.service'
import { GuestManageJobTourService } from 'src/services/tour/guest-manage-job-tour.service'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { GuestOnboardingVibeApplicantTourService } from 'src/services/tour/guest-onboarding-vibe-applicant.service'
import { GuestViewJobTourService } from 'src/services/tour/guest-view-job-tour.service'
import { IMission, PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { TARGET_WALLET } from 'src/utils'
import { Mission } from '../mission'
import { MissionVVC } from '../mission-vvc'
import { ExpandProvider } from './expand.context'
import Style from './style.module.scss'

interface IProps {
  className?: string
  isMinimized?: boolean
  provider?: TourRepository
}

export const TourPopup: FC<IProps> = ({ provider = RestTourRepository, ...props }) => {
  const history = useHistory()
  const isMobile = useIfMobileL()
  const [isMinimized, setIsMinized] = useState(props.isMinimized)
  const isShowingPopup = useBehaviorMapper(PopupTourService.isShowingPopup$)
  const isCoinFlying = useBehaviorMapper(PopupTourService.coinFlying$)
  const missions = useBehaviorMapper(PopupTourService.missions$)
  const loadingMission = useBehaviorMapper(PopupTourService.loadingMission$)
  const [expading, setExpading] = useState<IMission['id']>()
  const overlayState = useBehaviorMapper(OverlayService.overlay$)

  useEffect(() => {
    PopupTourService.adapter = provider
  }, [provider])

  useEffect(() => {
    setExpading(missions.find(mission => !mission.checked)?.id)
  }, [missions])

  useEffect(() => {
    setIsMinized(props.isMinimized)
  }, [props.isMinimized])

  useUnsubscribeEffect((unsubscribe$) => {
    PopupTourService.currentMissionId$.pipe(
      takeUntil(unsubscribe$),
      filter(id => id !== undefined && !isMobile)
    )
      .subscribe((id) => {
        if (id === 'create-job') {
          CreateJobTourService.startTour(history)
        }

        if (id === 'share-job') {
          ShareJobTourService.startTour(history)
        }

        if (id === 'guest-view-job') {
          GuestViewJobTourService.startTour(history)
        }

        if (id === 'guest-manage-job') {
          GuestManageJobTourService.startTour(history)
        }

        if (id === 'guest-onboarding-vibe-applicants') {
          GuestOnboardingVibeApplicantTourService.startTour(history)
        }

        if (id === 'guest-onboarding-create-job') {
          GuestOnboardingCreateJobTourService.startTour(history)
        }
      })
  }, [isMobile])

  // If you are in the middle of a tour, and then you click to open this pop-up, we stop all the tours
  useEffect(() => {
    if (isShowingPopup) {
      CreateJobTourService.stopTour()
      ShareJobTourService.stopTour()
      GuestViewJobTourService.stopTour()
    }
  }, [isShowingPopup])

  const containerStyle = useMemo(() => {
    if (overlayState?.open) {
      return { zIndex: 0 }
    }
  }, [overlayState])

  if (!isShowingPopup) {
    return null
  }

  return (
    <>
      <motion.div layout="position" className={clsx(Style.container, props.className, isMinimized && Style.containerWidthAuto)} style={containerStyle}>
        <FlyingCoins
          qty={10}
          fromSelector={`.${Style.container}`}
          toSelector={TARGET_WALLET.getSelector()}
          play={isCoinFlying && missions.length > 0}
          onEnd={PopupTourService.stopFlyingCoin.bind(PopupTourService)}
        />
        <>
          <div className={isMinimized ? Style.minimizedContainer : Style.header} onClick={() => isMinimized ? setIsMinized(false) : null} key={0}>
            {isMinimized && (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.5C7.60761 1.5 6.27226 2.05312 5.28769 3.03769C4.30312 4.02226 3.75 5.35761 3.75 6.75C3.75 8.535 4.6425 10.1025 6 11.055V12.75C6 12.9489 6.07902 13.1397 6.21967 13.2803C6.36032 13.421 6.55109 13.5 6.75 13.5H11.25C11.4489 13.5 11.6397 13.421 11.7803 13.2803C11.921 13.1397 12 12.9489 12 12.75V11.055C13.3575 10.1025 14.25 8.535 14.25 6.75C14.25 5.35761 13.6969 4.02226 12.7123 3.03769C11.7277 2.05312 10.3924 1.5 9 1.5ZM6.75 15.75C6.75 15.9489 6.82902 16.1397 6.96967 16.2803C7.11032 16.421 7.30109 16.5 7.5 16.5H10.5C10.6989 16.5 10.8897 16.421 11.0303 16.2803C11.171 16.1397 11.25 15.9489 11.25 15.75V15H6.75V15.75Z" fill="#181920"/>
              </svg>
            )}

            <span className={isMinimized ? Style.minimizedTitle : Style.header_title}>
              Getting Started
            </span>
            {!isMinimized && (
              <div className={Style.header_action}>
                <div className={Style.header_action_button} onClick={() => PopupTourService.hide()}>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.85828 0.826019C10.0472 0.637056 10.0472 0.330686 9.85828 0.141723C9.66931 -0.0472408 9.36294 -0.0472408 9.17398 0.141723L5 4.3157L0.826019 0.141723C0.637056 -0.0472408 0.330686 -0.0472408 0.141723 0.141723C-0.0472406 0.330686 -0.0472406 0.637056 0.141723 0.826019L4.3157 5L0.141723 9.17398C-0.0472408 9.36294 -0.0472408 9.66931 0.141723 9.85828C0.330686 10.0472 0.637056 10.0472 0.826019 9.85828L5 5.6843L9.17398 9.85828C9.36294 10.0472 9.66931 10.0472 9.85828 9.85828C10.0472 9.66931 10.0472 9.36294 9.85828 9.17398L5.6843 5L9.85828 0.826019Z" fill="#181920"/>
                  </svg>
                </div>

                <div className={Style.header_action_button} onClick={() => setIsMinized(true)}>
                  <svg width="10" height="1" viewBox="0 0 10 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 0H0.5C0.223858 0 0 0.223858 0 0.5C0 0.776142 0.223858 1 0.5 1H9.5C9.77614 1 10 0.776142 10 0.5C10 0.223858 9.77614 0 9.5 0Z" fill="#181920"/>
                  </svg>
                </div>
              </div>
            )}
          </div>

          {!isMinimized && (
            <motion.div className={Style.body} key={1}>
              {loadingMission
                ? (
                  <div className="flex fx-ai-center fx-jc-center relative w-full">
                    <Spinner size={24}/>
                  </div>
                )
                : (
                  <>
                    <MissionVVC/>

                    <ExpandProvider expanding={expading} setExpanding={setExpading}>
                      {missions.map((mission) => (
                        <Mission {...mission} key={mission.id}/>
                      ))}
                    </ExpandProvider>
                  </>
                )}
            </motion.div>
          )}
        </>
      </motion.div>
    </>
  )
}
