import { FC, useMemo } from 'react'
import Lottie from 'react-lottie'
import { useBehaviorMapper } from 'src/hooks'
import { LottiesModule } from 'src/store'

interface IGiftOpenProps {
  onComplete?: () => void
}

export const GiftOpen: FC<IGiftOpenProps> = (props) => {
  const gifOpenAnimationData = useBehaviorMapper(LottiesModule.giftOpen$)
  const defaultOptions = useMemo(() => ({
    loop: false,
    autoplay: true,
    animationData: gifOpenAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }), [gifOpenAnimationData])
  return (
    <Lottie
      options={defaultOptions}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onComplete?.()
        }
      ]}
    />
  )
}
