import moment from 'moment'
import { ILinkedinWorkingExperience } from 'src/interfaces'

export class LinkedinUtil {
  static descByDate<T extends {
    startDate?: Date
    endDate?: Date
  }>(docs: T[] = []) {
    return docs.sort((a, b) => {
      if (!a.endDate && !b.endDate) {
        return new Date(a.startDate || Date.now()).getTime() - new Date(b.startDate || Date.now()).getTime()
      }

      if (!a.endDate) {
        return 1
      }

      if (!b.endDate) {
        return -1
      }

      const diff = new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
      if (diff) {
        return diff
      }

      return new Date(a.startDate || Date.now()).getTime() - new Date(b.startDate || Date.now()).getTime()
    }).reverse()
  }

  static removeInvalidStartDate = (linkedinWorkingExperiences: ILinkedinWorkingExperience[]) => {
    return linkedinWorkingExperiences.filter((linkedinWorkingExperience) => {
      // check for valid date
      const startDate = moment(linkedinWorkingExperience.startDate)
      if (startDate.isValid()) {
        return true
      }
      return false
    })
  }

  static computeWorkDuration = (item: ILinkedinWorkingExperience) => {
    const startDateString = moment(item.startDate).format('MMM YYYY') // Feb 2023
    const endDateString = item.endDate ? moment(item.endDate).format('MMM YYYY') : 'Present' // Feb 2023

    return `${startDateString} - ${endDateString}`
  }

  static checkValidData = (linkedinWorkingExperiences?: ILinkedinWorkingExperience[]) => {
    if (!linkedinWorkingExperiences) {
      return []
    }

    return this.removeInvalidStartDate(linkedinWorkingExperiences).map((item) => {
      item.workDuration = this.computeWorkDuration(item)
      return item
    })
  }
}
