import { Typography } from '@mui/material'
import { ChangeEvent, FC, FormEvent } from 'react'
import { AlertMessage, Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconInfo } from 'src/icons'
import { Footer } from 'src/layout/layout-container/footer'
import { Header } from '../header'
import { LeftSection } from '../left-section'
import Style from './password-reset.module.scss'

interface PasswordResetProps {
  email: string
  error: string
  handleSubmit: (e: FormEvent<HTMLFormElement> & MouseEvent) => void
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
}

export const PasswordReset: FC<PasswordResetProps> = ({
  handleChangeInput,
  handleSubmit,
  error,
  email
}) => {
  const { eventHandler } = useAnalytic('pswd_reset1')

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>
        <LeftSection/>
        <div className={Style.RightSide}>
          <div className={Style.STContainer}>
            <form className={Style.STForm} onSubmit={handleSubmit} autoComplete="off">
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Forgot Password</Typography>
              </div>

              {error && (
                <AlertMessage
                  className={Style.ErrorMsg}
                  message={error}
                  mb={4}
                  icon={<IconInfo/>}
                />
              )}

              <div className={Style.FormInput}>
                <Input
                  label="Email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="name@company.com"
                  value={email || ''}
                  onChange={handleChangeInput}
                  onClick={eventHandler(ETrackingEvent.INPUT_EMAIL)}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                />

                <Button fullWidth type="submit" onClick={eventHandler(ETrackingEvent.BTN_SEND_RESET_PSWD_LINK)}>
                  Send
                </Button>
              </div>
            </form>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  )
}
