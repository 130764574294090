import {
  CAMPAIGN_CREATED,
  CAMPAIGN_FETCHED,
  CAMPAIGN_PAGE_ENTERED,
  CAMPAIGN_RESET,
  ICampaignAction,
  ICampaignState
} from '../types/campaign'

export const initState: ICampaignState = {
  campaigns: []
}

export const reducer = (state: ICampaignState = initState, action: ICampaignAction) => {
  switch (action.type) {
    case CAMPAIGN_PAGE_ENTERED:
      return {
        ...state,
        campaigns: []
      }
    case CAMPAIGN_RESET:
      return {
        ...initState
      }
    case CAMPAIGN_CREATED:
      return state
    case CAMPAIGN_FETCHED:
      return {
        ...state,
        campaigns: action.value
      }
    default:
      return state
  }
}
