import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})<{isMobile: boolean}>(({ isMobile, theme }) => `
    width: ${isMobile ? '100%' : '418px'};
    max-width: 418px;
    padding: ${isMobile ? theme.spacing(3) : theme.spacing(5)};
    border-radius: 32px;
    background-color: ${theme.colors['--color-neutral-theme-50']};

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(5)}
`)

export const STContent = styled('div', {
  label: 'Content'
})(({ theme }) => `
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(2)}
`)

export const STAction = styled('div', {
  label: 'Action'
})(({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)}
`)
