import { StripeError } from '@stripe/stripe-js'

export const mapStripeError = (error: StripeError) => {
  // switch (error.code) {
  //   case 'incorrect_cvc':
  //     return 'Whoops, Security Code Incomplete'
  //   case 'invalid_expiry_month':
  //   case 'invalid_expiry_year':
  //     return 'Yikes! Expiration Date Invalid'
  //   default:
  //     return error.message
  // }
  switch (error.message) {
    case 'Your card\'s security code is incomplete.':
      return 'Whoops, Security Code Incomplete'
    case 'Your card\'s expiration date is incomplete.':
      return 'Yikes! Expiration Date Invalid'
    default:
      return error.message
  }
}
