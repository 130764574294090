import clsx from 'clsx'
import { ComponentProps, FC, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ErrorMessage } from '../message'
import Style from './style.module.scss'

const quillModules = {
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ align: null }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    // [{ header: [1, 2, 3, false] }],
    [{ list: 'bullet' }, { list: 'ordered' }]
  ]
}

interface IProps extends ComponentProps<typeof ReactQuill> {
  error?: string
  focused?: boolean
}

export const Editor: FC<IProps> = ({ focused, error, ...props }) => {
  const quillRef = useRef<any>(null)

  useEffect(() => {
    if (focused) {
      quillRef.current?.editor?.focus()
    }
  }, [focused])

  return (
    <div className="fx fx-column gap-2">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        {...props}
        className={clsx(Style.editor, props.className)}
        modules={quillModules}
      />

      <ErrorMessage>{error}</ErrorMessage>
    </div>
  )
}
