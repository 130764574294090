import { FC } from 'react'
import Image403 from 'src/images/403.svg'
import Style from './403.module.scss'

export const Forbidden: FC = props => (
  <div className={Style.container}>
    <img src={Image403} alt="403"/>
    <p>Permission Denied</p>
  </div>
)
