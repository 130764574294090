import { useEffect, useState } from 'react'

export const useImageFromFile = (file?: string | File | Blob | null): string | null => {
  const [fileURL, setFileURL] = useState<string | null>(null)

  useEffect(() => {
    if (file) {
      if (typeof file === 'string') {
        setFileURL(file)
        return
      }

      const objectURL = URL.createObjectURL(file)
      setFileURL(objectURL)

      return () => {
        // Clean up the URL when the component unmounts or the file changes
        URL.revokeObjectURL(objectURL)
      }
    } else {
      // If there's no file provided, reset the URL
      setFileURL(null)
    }
  }, [file])

  return fileURL
}
