import { styled } from '@mui/material'

export const STTitleSubText = styled('div', { label: 'TitleSubText' })(({ theme }) => `
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  color: ${theme.colors['--color-neutral-theme-300']};
`)

export const STItem = styled('div', { label: 'Item' })<{ isOnly?: boolean; isLast?: boolean; lightMode?: boolean }>(({ isOnly, isLast, lightMode, theme }) => `
  flex: 1;
  display: flex;
  position: relative;
  gap: 11px;
  padding-bottom: 16px;
  left: ${lightMode ? '6px' : 'unset'};

  ${!isLast && !isOnly
    ? `
    ::after {
      content: '';
      position: absolute;
      top: 20px;
      left: 135px;
      width: 1px;
      height: calc(100% - 16px);

      background: linear-gradient(
        to bottom,
        ${theme.colors['--color-neutral-theme-300']},
        ${theme.colors['--color-neutral-theme-300']} 50%,
        ${theme.colors['--color-neutral-theme-50']} 50%,
        ${theme.colors['--color-neutral-theme-50']}
      );
      background-size: 100% 2px;
    }
  `
    : ''}

    ${lightMode
    ? `
      ::after {
        content: '';
        position: absolute;
        top: 20px;
        left: 5px;
        width: 1px;
        height: calc(100% - 16px);

        background: linear-gradient(
          to bottom,
          #4FDEE8,
          #4FDEE8 50%,
          ${theme.colors['--color-neutral-theme-50']} 50%,
          ${theme.colors['--color-neutral-theme-50']}
        );
        background-size: 100% 2px;
      }
      &:last-child {
        ::after {
          display: none;
        }
      }
    `
    : ''}
`)

export const STItemTime = styled('div', { label: 'ItemTime' })(({ theme }) => `
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: ${theme.colors['--color-neutral-theme-900']};
  width: 120px;
  flex-shrink: 0;
 `)

export const STItemDescription = styled('div', { label: 'ItemDescription' })(({ theme }) => `
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
`)

export const STItemDescriptionTitle = styled('div', { label: 'ItemDescriptionTitle' })<{ lightMode?: boolean }>(({ theme, lightMode }) => `
  font-weight: 700;
  font-size: ${lightMode ? '12px' : '14px'};
  line-height: ${lightMode ? '20px' : '24px'};
  color: ${theme.colors['--color-neutral-theme-900']};
`)

export const STItemDescriptionDegree = styled('div', { label: 'ItemDescriptionCompanyAndLocation' })<{ lightMode?: boolean }>(({ theme, lightMode }) => `
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 400;
  font-size: ${lightMode ? '12px' : '14px'};
  line-height: ${lightMode ? '20px' : '24px'};
  color: ${theme.colors['--color-neutral-theme-300']};
`)
