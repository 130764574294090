import { backgroundImage } from './style.utils'

export interface IVideoFly {
  sidebarId: string
  videoId: string
  url?: string
  width?: number
  height?: number
}

export const videoFly = ({ sidebarId, videoId, url, width, height }: IVideoFly) => {
  const speed = 1000
  const sibar = document.querySelector(`#${sidebarId}`) as HTMLDivElement
  const video = document.querySelector(`#${videoId}`) as HTMLDivElement

  if (!video) return

  const videoY = video.getBoundingClientRect().top
  const videoX = video.getBoundingClientRect().left
  const videoFly = video.cloneNode() as HTMLDivElement

  videoFly.style.position = 'fixed'
  videoFly.style.top = `${videoY}px`
  videoFly.style.left = `${videoX}px`
  videoFly.style.opacity = '1'
  videoFly.style.height = (height || 595) + 'px'
  videoFly.style.width = (width || 335) + 'px'
  videoFly.style.zIndex = '99999'
  videoFly.style.transition = `all ${speed / 1000}s ease, top ${
    (speed + 300) / 1000
  }s ease, left ${speed / 1000}s ease, transform ${speed / 1000}s ease ${
    (speed - 10) / 1000
  }s`

  document.body.appendChild(videoFly)
  videoFly.style.top = `${sibar.offsetTop + sibar.offsetHeight - 200}px`
  videoFly.style.left = `${sibar.offsetLeft + sibar.offsetWidth - 130}px`
  if (url) {
    videoFly.style.backgroundImage = backgroundImage(url)
  } else {
    videoFly.style.backgroundColor = '#F1F2F3'
  }
  videoFly.style.objectFit = 'cover'
  videoFly.style.backgroundRepeat = 'no-repeat'
  videoFly.style.backgroundPosition = 'center center'
  videoFly.style.height = '360px'
  videoFly.style.width = '240px'
  videoFly.style.transform = 'scale(0)'

  setTimeout(() => {
    videoFly.remove()
  }, speed * 1.5)
}
