import { Tooltip as MuiTooltip, TooltipProps, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface IProps extends Omit<TooltipProps, 'title' | 'color' | 'background' | 'placement'> {
  title: NonNullable<ReactNode>
  color?: string
  background?: string
  placement?: TooltipProps['placement']
}

export const Tooltip: FC<IProps> = ({ title, background, color = '#fff', children, placement = 'top', ...props }) => {
  const theme = useTheme()

  return (
    <MuiTooltip
      arrow
      disableInteractive
      title={title}
      placement={placement}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            background: background || theme.colors['--color-black'],
            padding: '8px',
            color,
            maxWidth: 320,
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
            borderRadius: '8px'
          },
          '& .MuiTooltip-arrow': {
            color: background || theme.colors['--color-black'],
            width: '9px',
            height: '6px',

            '&::before': {
              borderRadius: '1px !important'
            }
          },

          '&[data-popper-placement*="top"]': {
            '.MuiTooltip-arrow': {
              marginBottom: '-5px'
            }
          },

          '&[data-popper-placement*="top-start"]': {
            '.MuiTooltip-arrow': {
              transform: 'translate(20px, 0px) !important'
            }
          },

          '&[data-popper-placement*="bottom"]': {
            '.MuiTooltip-arrow': {
              marginTop: '-5px'
            }
          },

          '&[data-popper-placement*="bottom-start"]': {
            '.MuiTooltip-arrow': {
              transform: 'translate(20px, 0px) !important'
            }
          },

          '&[data-popper-placement*="left"]': {
            '.MuiTooltip-arrow': {
              width: '5px',
              height: '8px',
              marginRight: '-5px'
            }
          },

          '&[data-popper-placement*="right"]': {
            '.MuiTooltip-arrow': {
              width: '5px',
              height: '8px',
              marginLeft: '-5px'
            }
          }
        }
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}
