import { styled } from '@mui/material'

export const STContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'Container'
})<{isShow?: boolean}>(({ theme, isShow }) => `
  margin-top: ${theme.spacing(1)};
  background-color: ${theme.colors['--color-neutral-theme-100']};
  display: flex;
  flex-direction: column;
  padding-bottom: 112px;
`)

export const STHeader = styled('div', {
  label: 'Header'
})(({ theme }) => `
  margin-bottom: ${theme.spacing(3.5)};

  display: flex;
  align-items: center;
  justify-content: space-between
`)

export const STTransactionList = styled('div', {
  label: 'TransactionList'
})(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`)

export const STTransactionItem = styled('div', {
  label: 'TransactionItem'
})(({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(3, 2)};
  gap: ${theme.spacing(1)};
  background-color: ${theme.colors['--color-neutral-theme-50']};

  div:nth-of-type(odd) {
    background-color: ${theme.colors['--color-neutral-theme-50']};
  }

  div:nth-of-type(even) {
    background-color: ${theme.colors['--color-neutral-theme-100']};
  }
`)

export const STRow = styled('div', {
  label: 'Row'
})(({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 8px 16px;
  border-radius: 8px;
`)

export const STDateText = styled('span', {
  label: 'DateText'
})(({ theme }) => `
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors['--color-neutral-theme-900']};
`)

export const STViewDetailText = styled('a', {
  label: 'ViewDetailText'
})(({ theme }) => `
  font-weight: 400;
  font-size: 16px;
  color: #0094FF;
`)

export const STLabelText = styled('span', {
  label: 'LabelText'
})(({ theme }) => `
  font-weight: 500;
  font-size: 16px;
  flex: 1;
  line-height: 28px;
  color: ${theme.colors['--color-neutral-theme-300']};
`)

export const STValueText = styled('span', {
  label: 'LabelText'
})(({ theme }) => `
  font-weight: 400;
  font-size: 16px;
  display: block;
  text-align: right;
  line-height: 28px;
  flex: 2;
  color: ${theme.colors['--color-neutral-theme-900']};
`)

export const STFooter = styled('div', {
  label: 'Footer'
})(({ theme }) => `
  position: fixed;
  height: 104px;
  padding: 24px;
  width: 100%;
  bottom: 0;
  background-color: ${theme.colors['--color-neutral-theme-50']};
  display: flex;
  align-items: center;
`)
