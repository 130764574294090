import { EUserGuide } from 'src/enums'
import { TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class GuideApi {
  static readonly _prefix = '/guide'

  static edit(state: EUserGuide, value?: number): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/${state}`, value !== undefined ? { value } : undefined)
  }
}
