import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'

export const useIsAtGuestView = () => {
  const location = useLocation()

  const isAtGuestView = useMemo(() => {
    return location.pathname.startsWith(`/${PUBLIC_CAMPAIGN_BASE_PATH}`)
  }, [location])

  return isAtGuestView
}
