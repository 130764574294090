import { BehaviorSubject, Subject } from 'rxjs'

export const NavigationService = new (class {
  readonly navigation$ = new Subject<string>()
  readonly arrowAndTitle$ = new BehaviorSubject<{
    title?: string
    showArrow: boolean
    titleActions?: any
  }>({ showArrow: false })

  readonly isSizebarCollapsed$ = new Subject<boolean>()

  readonly isAtRecordingPage$ = new BehaviorSubject<boolean>(false)

  readonly showHeader$ = new BehaviorSubject<boolean>(true)
  readonly showRecordTabs$ = new BehaviorSubject<{
    status: boolean
    currentTab: 'video' | 'audio'
  }>({
    status: false,
    currentTab: 'video'
  })

  readonly showRedeemCode$ = new BehaviorSubject<{
    status: boolean
    introCredit: number
  }>({ status: false, introCredit: 0 })

  readonly creditPoint$ = new BehaviorSubject({
    show: true
  })

  readonly firstUploadedPfv$ = new BehaviorSubject<{
    uploaded: boolean
    watchedTalentOnboarding: boolean
    isAtKnowmeTalent: boolean
  }>({ uploaded: false, watchedTalentOnboarding: false, isAtKnowmeTalent: false })

  public setShowHeader(show: boolean) {
    this.showHeader$.next(show)
  }

  public setIsAtRecordingPage(isAtRecordingPage: boolean) {
    this.isAtRecordingPage$.next(isAtRecordingPage)
  }

  public setShowRecordTab(
    show: boolean,
    currentTab: 'video' | 'audio' = this.showRecordTabs$.value.currentTab
  ) {
    this.showRecordTabs$.next({ status: show, currentTab })
  }

  public setShowRedeemCode(show: boolean, introCredit = 0) {
    this.showRedeemCode$.next({ status: show, introCredit })
    return this
  }

  public setFirstUploadedPfv(uploaded: boolean) {
    this.firstUploadedPfv$.next({ ...this.firstUploadedPfv$.value, uploaded })
  }

  public setWatchedTalentOnboarding(watched: boolean) {
    this.firstUploadedPfv$.next({
      ...this.firstUploadedPfv$.value,
      watchedTalentOnboarding: watched
    })
  }

  public setIsAtKnowmeTalent(isAtKnowmeTalent: boolean) {
    this.firstUploadedPfv$.next({
      ...this.firstUploadedPfv$.value,
      isAtKnowmeTalent
    })
  }

  public setCreditPoint(show: boolean) {
    this.creditPoint$.next({ show })
  }

  back(currentTitle: string) {
    this.navigation$.next(currentTitle)
  }

  showTitle(showArrow: boolean, title?: string, titleActions?: any) {
    this.arrowAndTitle$.next({ showArrow, title, titleActions })
  }

  hideTitle() {
    this.arrowAndTitle$.next({ showArrow: false, title: undefined })
  }

  reset() {
    this.firstUploadedPfv$.next({
      uploaded: false,
      watchedTalentOnboarding: false,
      isAtKnowmeTalent: false
    })
  }
})()
