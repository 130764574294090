import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})<{ isMobile?: boolean }>(({ isMobile, theme }) => `
  width: ${isMobile ? '100%' : '335px'};
  height: 100%;
  border-radius: 32px;
  padding: ${isMobile ? theme.spacing(3) : theme.spacing(5)};
  background-color: ${theme.colors['--color-neutral-theme-50']};

  display: flex;
  flex-direction: column;
`)

export const STAction = styled('div', {
  label: 'Action'
})(({ theme }) => `
  margin-top: ${theme.spacing(4)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)}
`)
