import { Box, TextField as MuiTextField } from '@mui/material'
import { css, styled } from '@mui/material/styles'

export const TextField = styled(MuiTextField, {
  label: 'TextFieldCustom',
  shouldForwardProp: (prop) => prop !== 'labelClassName' && prop !== 'startIcon' && prop !== 'startIconTabIndex'
})(
  ({ theme }) => css`
    ${{ ...theme.typography['body1-regular'] }};
    .MuiFormHelperText-root {
      margin: ${theme.spacing(1.5)} 0 0;
    }
    div.MuiInputBase-root {
      border: none;
      border-radius: 8px;
      color: ${theme.colors['--color-neutral-theme-700']};
      background-color: ${theme.colors['--color-neutral-theme-100']};
      input {
        padding: ${theme.spacing(2)};

        &::placeholder {
          color: ${theme.colors['--color-neutral-theme-300']};
        }
      }
      &:hover:not(:focus-within):not(.Mui-disabled):not(.Mui-error) {
        border-color: transparent; /* remove the border's colour */
        box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-200']}; /* emulate the border */
      }
      :focus-within:not(.Mui-error) {
        border-color: transparent; /* remove the border's colour */
        box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-300']}; /* emulate the border */
      }
      &.Mui-disabled {
        border-color: transparent;
        background-color: ${theme.colors['--color-neutral-theme-50']};
        box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-200']}; /* emulate the border */
      }
    }
    .MuiInputAdornment-positionStart {
      margin-right: 0;
    }
    .MuiOutlinedInput-root {
      fieldset:not(.Mui-disabled) {
        border-color: transparent;
        border-width: 1px !important;
        border-radius: 8px !important;
      }
      &:hover,
      &.Mui-focused {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
      &.Mui-error {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: ${theme.colors['--color-negative-500']};
        }
      }
    }
  `
)

export const TextInput = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mb',
  label: 'TextInput'
})<{ mb?: number }>(
  ({ theme, ...props }) => `
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(props.mb || 0)};
`
)
