import { IInvoiceModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class InvoiceApi {
  static readonly _prefix = '/invoices'

  static pagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IInvoiceModel> {
    return axiosHiringApi.get(`${this._prefix}`, { params })
  }

  static detail(
    id: number
  ): TAxiosResponseData<IInvoiceModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}`)
  }
}
