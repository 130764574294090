import { ArchiveRoutes, EArchiveRoutes } from 'src/modules/archive/routes'
import { AuthRoutes, EAuthRoutes } from 'src/modules/auth/routes'
import { CampaignRoutes, ECampaignRoutes } from 'src/modules/campaign/routes'
import { Notfound } from 'src/modules/core/components/404'
import { CoreRoutes, ECoreRoutes } from 'src/modules/core/routes'
import { EHomeRoutes, HomeRoutes } from 'src/modules/home/routes'
import { EIntrosRoutes, IntrosRoutes } from 'src/modules/intros/routes'
import { ELikesRoutes, LikesRoutes } from 'src/modules/likes/routes'
import { EPaymentHistoryRoutes, PaymentHistoryRoutes } from 'src/modules/payment-history/routes'
import { EPlayGroundRoutes, PlaygroundRoutes } from 'src/modules/playground/routes'
import { RecordingModule } from 'src/modules/recording/routes'
import { ESettingRoutes, SettingRoutes } from 'src/modules/setting/routes'
import { EShareRoutes, ShareRoutes } from 'src/modules/share/routes'
import { EVibesRoutes, VibesRoutes } from 'src/modules/vibes/routes'
import { EWatchRoutes, WatchRoutes } from 'src/modules/watch/routes'
import { IRouterOption } from './interface'

export * from './generate'
export * from './history'
export * from './interface'

export const routes: IRouterOption[] = [
  ...ArchiveRoutes,
  ...AuthRoutes,
  ...CampaignRoutes,
  ...CoreRoutes,
  ...HomeRoutes,
  ...IntrosRoutes,
  ...LikesRoutes,
  ...PaymentHistoryRoutes,
  ...SettingRoutes,
  ...ShareRoutes,
  ...VibesRoutes,
  ...WatchRoutes,
  ...PlaygroundRoutes,
  ...RecordingModule.Routes,
  // last route handle 404 error
  {
    path: '*',
    component: Notfound
  }
]

export const ERoutes = {
  ...EArchiveRoutes,
  ...EAuthRoutes,
  ...ECampaignRoutes,
  ...RecordingModule.ERoutes,
  ...ECoreRoutes,
  ...EHomeRoutes,
  ...EIntrosRoutes,
  ...ELikesRoutes,
  ...EPaymentHistoryRoutes,
  ...EPlayGroundRoutes,
  ...ESettingRoutes,
  ...EShareRoutes,
  ...EVibesRoutes,
  ...EWatchRoutes
}
