import { FC } from 'react'
import { useAppSelector } from 'src/hooks'
import { getIsAuthenticated } from 'src/store/selectors'
import { Private } from './private'

export const Header: FC = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  return (
    <>
      {isAuthenticated
        ? <Private/>
        : <></>}
    </>
  )
}
