import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconCheckmarkRegular, IconClose } from 'src/icons'
import { RecordingService } from 'src/services'
import { ITeleprompterEditorProps, TeleprompterEditor } from '.'
import Style from './style.module.scss'

interface IProps extends ITeleprompterEditorProps {
  onClose?: () => void
  recordingService: RecordingService
}

export const MobileTeleprompterEditor: FC<IProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const [isFocusing, setIsFocusing] = useState(false)
  const teleData = useBehaviorMapper(props.recordingService.teleprompter$)

  const handleOpeningTeleprompter = useCallback(() => {
    props.recordingService.setTeleprompter({
      ...teleData,
      isOpening: !teleData.isOpening
    })

    props.onClose?.()
  }, [props, teleData])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const currentRef = ref.current

    const focusHandler = currentRef.onfocus = () => {
      setIsFocusing(true)
    }

    const touchCancel = currentRef.ontouchcancel = () => {
      setIsFocusing(false)
    }

    const blurHandler = currentRef.onblur = () => {
      setIsFocusing(false)
    }

    return () => {
      currentRef?.removeEventListener('focus', focusHandler)
      currentRef?.removeEventListener('blur', blurHandler)
      currentRef?.removeEventListener('touchcancel', touchCancel)
    }
  }, [ref, setIsFocusing])

  return (
    <div className={Style.mobile_container}>
      <div className={Style.mobile_header}>
        <div
          role="button"
          className={Style.mobile_close}
          onClick={props.onClose}
        >
          <IconClose/>
        </div>
        <span className={Style.mobile_header_title}>Teleprompter</span>
        {(!isFocusing && teleData.text)
          ? (
            <Button
              onClick={handleOpeningTeleprompter}
              size="small"
            >
              {teleData.isOpening ? 'Hide Script' : 'Use Script'}
            </Button>
          )
          : (
            <IconCheckmarkRegular/>
          )}
      </div>
      <TeleprompterEditor
        ref={ref}
        onChange={props.onChange}
        editorClassName={Style.mobile_editor}
        containerClassName={Style.mobile_editor_fullheight}
      />
    </div>
  )
}
