import { createSelector } from 'reselect'
import { TAppState, TSelectorResult } from 'src/store'
import { getFullName } from 'src/utils'
import { IAuthState, IProfileState } from '../types'

const undefinedToEmptyString = (value?: string | null) => value ?? ''

export const getProfile: TSelectorResult<IProfileState['info']> = createSelector(
  (state: TAppState) => state.auth,
  (auth: IAuthState) => ({
    email: undefinedToEmptyString(auth.credentials.email),
    firstName: undefinedToEmptyString(auth.credentials.firstName),
    lastName: undefinedToEmptyString(auth.credentials.lastName),
    companyName: undefinedToEmptyString(auth.credentials.company?.name),
    companyUrls: auth.credentials.company?.urls || [],
    bio: undefinedToEmptyString(auth.credentials.bio),
    avatar: undefinedToEmptyString(auth.credentials.pfp?.url),
    companyLogo: undefinedToEmptyString(auth.credentials.company?.logo?.url),
    video: undefinedToEmptyString(auth.credentials.pfv?.urlVideoSource),
    lookupId: undefinedToEmptyString(auth.credentials.lookupId),
    get fullName(): string {
      return auth.credentials.fullName
        ? auth.credentials.fullName
        : getFullName(auth.credentials)
    }
  })
)

export const getProfileTraits: TSelectorResult<IProfileState['traits']> = createSelector(
  (state: TAppState) => state.profile,
  (profile: IProfileState) => profile.traits
)

export const getProfileCountCredits: TSelectorResult<IProfileState['countCredits']> = createSelector(
  (state: TAppState) => state.profile,
  (profile: IProfileState) => profile.countCredits
)
