import axios from 'axios'
import { EErrorCode } from 'src/constants'
import { OverlayService } from 'src/services'
import { getApiErrorCode } from './axios.utils'

export const requiredProfileInterceptor: Parameters<typeof axios.interceptors.response.use>[1] = (error) => {
  const errorCode = getApiErrorCode(error)
  if (errorCode === EErrorCode.PROFILE_NOT_COMPLETED) {
    OverlayService.requiredProfile()
  }

  throw error
}
