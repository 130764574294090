import { Theme, Typography } from '@mui/material'
import { ETrackingEvent } from 'src/enums'
import { IInvoiceModel } from 'src/interfaces'
import { formatCurrency, formatDateTime } from 'src/utils'

interface RenderColumnsProps {
  theme: Theme
  handlePDF: (id: number) => void
  eventHandler: (event: string, handler?: (...args: any[]) => any) => any
}

export const RenderColumns = ({ theme, handlePDF, eventHandler }: RenderColumnsProps) => {
  const columns = [
    {
      field: 'create_at',
      title: 'Date',
      render: (data: IInvoiceModel) => data.createdAt && formatDateTime(data.createdAt)
    },
    {
      field: 'description',
      title: 'Description',
      width: '30%',
      render: (data: IInvoiceModel) => `Introduction Credits - ${data.metadata?.credits} Units`
    },
    {
      field: 'quantity',
      title: 'Qty',
      render: (data: IInvoiceModel) => data.metadata?.items?.[0]?.qty
    },

    {
      field: 'price',
      title: 'Price',
      render: (data: IInvoiceModel) => (
        <Typography variant="body1-bold" color={theme.colors['--color-neutral-theme-900']}>
          {data.metadata && `$${formatCurrency(data.metadata?.amount)}.00`}
        </Typography>
      )
    },
    {
      field: 'status',
      title: 'Status',
      render: () => 'Paid'
    },
    {
      field: 'action',
      title: '',
      width: '150px',
      render: (data: IInvoiceModel) => (
        <Typography
          variant="body1-regular"
          color="#0094FF"
          sx={{ cursor: 'pointer' }}
          onClick={eventHandler(ETrackingEvent.BTN_VIEW_INVOICE, () => handlePDF(data.id))}
        >
          View invoice
        </Typography>
      )
    }
  ]

  return columns
}
