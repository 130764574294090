import { Typography } from '@mui/material'
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PasswordApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useIfMobileL, useOldValidation, useUnsavedChanges } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getApiErrorMessage } from 'src/utils'
import { changePasswordSchema } from 'src/validation'
import { Setting } from '../setting'
import Style from '../setting/style.module.scss'
import { SettingTabService } from '../setting/tab.service'
import { STAction } from './styled'

interface ISecurity {
  currentPassword?: string
  newPassword?: string
  confirmPassword?: string
}

export const Security: FC = () => {
  const dispatch = useAppDispatch()
  const isMobile = useIfMobileL()
  const { errors, setErrors, validateAt, validate } = useOldValidation<ISecurity>()
  const [formData, setFormData] = useState<ISecurity>({})
  const { eventHandler } = useAnalytic('settings_security')

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: changePasswordSchema,
      path: name,
      data: { ...formData, [name]: value }
    })
    setFormData(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }

  const isChanged = useMemo(() => (
    !!formData.newPassword ||
    !!formData.currentPassword ||
    !!formData.confirmPassword
  ), [formData])

  const handleCancel = () => {
    setErrors({})
    setFormData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
  }

  const save = useCallback(async (payload = formData) => {
    try {
      dispatch(setLayoutLoading(true))
      const isValid = await validate({
        schema: changePasswordSchema,
        data: payload
      })
      if (!isValid || !payload?.currentPassword || !payload?.newPassword) return

      await PasswordApi.change({
        currentPassword: payload.currentPassword,
        newPassword: payload.newPassword
      })

      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Awesome! Password Changed'
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally { dispatch(setLayoutLoading(false)) }
  }, [dispatch, formData, validate])

  const refFormData = useRef<typeof formData>()
  useEffect(() => {
    refFormData.current = formData.currentPassword && formData.newPassword && formData.confirmPassword
      ? formData
      : undefined
  }, [formData])
  useUnsavedChanges({ isChanged, onSave: save, onClose: () => { SettingTabService.setTab(2) } })

  return (
    <Setting>
      <div className={Style.STFormContainer}>
        <div className={Style.STFormContentWrap}>
          <Typography variant="h5" sx={{ marginBottom: 4 }}>Change Password</Typography>
          <Input
            required
            type="password"
            label="Your Current Password"
            name="currentPassword"
            placeholder="Enter your current password"
            value={formData.currentPassword || ''}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CURRENT)}
            autoComplete="off"
            error={errors.currentPassword}
            labelClassName={Style.customLabel}
          />
          <Input
            required
            type="password"
            label="New Password"
            name="newPassword"
            placeholder="Enter your new password"
            value={formData.newPassword || ''}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD_NEW)}
            autoComplete="off"
            error={errors.newPassword}
            isCheckPassword={!!errors.newPassword}
            labelClassName={Style.customLabel}
          />
          <Input
            required
            type="password"
            label="Confirm New Password"
            name="confirmPassword"
            placeholder="Enter your confirm new password"
            value={formData.confirmPassword || ''}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
            autoComplete="off"
            error={errors.confirmPassword}
            labelClassName={Style.customLabel}
          />

          <STAction isMobile={isMobile}>
            <Button
              order="secondary"
              {...(isMobile ? { sx: { flex: 1 } } : { width: 201 })}
              onClick={eventHandler(ETrackingEvent.BTN_CANCEL, handleCancel)}
            >
              Cancel
            </Button>
            <Button
              {...(isMobile ? { sx: { flex: 1 } } : { width: 201 })}
              onClick={eventHandler(ETrackingEvent.BTN_UPDATE, () => save(formData))}
              className={Style.customeSubmitBtn}
            >
              Change password
            </Button>
          </STAction>
        </div>
      </div>
    </Setting>
  )
}
