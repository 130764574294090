import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { UnSaveChange } from 'src/components'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'

export function useUnsavedChanges({ isChanged, onSave, onClose, onLeave }: { isChanged: boolean | null; onSave: () => void; onClose?: () => void; onLeave?: () => void }) {
  const history = useHistory()
  const recordingRoute = generate(ERoutes.RECORDING)

  useEffect(() => {
    const unblock = history.block((location) => {
      if (location.pathname === recordingRoute) {
        unblock()
        history.push(location)
        return false
      }

      if (isChanged) {
        OverlayService.setOverlay({
          open: true,
          content: (
            <UnSaveChange
              action={onSave}
              leavePage={() => {
                onLeave?.()
                unblock()
                history.push(location)
              }}
            />
          ),
          onClose: onClose
        })
        return false
      }
    })

    return () => {
      unblock()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChanged, onSave, history])

  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    // if the form is NOT unchanged, then set the onbeforeunload
    if (isChanged) {
      window.addEventListener('beforeunload', handler)
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
    // since this is not dirty, don't do anything
    return () => {}
  }, [isChanged])
}

useUnsavedChanges.isChange = function (oldVal: any, newVal: any) {
  return (oldVal || newVal) && oldVal !== newVal
}
