import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useState } from 'react'
import { PasswordApi } from 'src/api'
import { NotAllowAuthenticated } from 'src/components'
import { EventUtils, getApiErrorMessage } from 'src/utils'
import { PasswordReset } from './PasswordReset'
import { PasswordResetSuccess } from './PasswordResetSuccess'
import Style from './style.module.scss'

interface IForgotPasswordForm {
  email: string
}

export const ForgotPassword: FC = () => {
  const [formValues, setFormValues] = useState<IForgotPasswordForm>({ email: '' })
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    setError('')
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [])

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement> & MouseEventHandler>(async (e) => {
    EventUtils.preventDefault(e)

    try {
      await PasswordApi.forgotPasswordRequest(formValues)
      setIsSuccess(true)
    } catch (error) {
      setError(getApiErrorMessage(error))
    }
  }, [formValues])

  return (
    <NotAllowAuthenticated>
      <div className={Style.STForgotPasswordWrapper}>
        {isSuccess
          ? <PasswordResetSuccess handleSubmit={handleSubmit} email={formValues.email}/>
          : (
            <PasswordReset
              email={formValues.email}
              error={error}
              handleChangeInput={handleChangeInput}
              handleSubmit={handleSubmit}
            />
          )}
      </div>
    </NotAllowAuthenticated>
  )
}
