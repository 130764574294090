import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { IconWarning } from 'src/icons'
import { STContainer } from './styled'

export const AlertMessage: FC<{
  message?: string
  mt?: number
  mb?: number
  width?: number
  children?: ReactNode
  className?: string
  icon?: any
}> = (props) => {
  return (
    <STContainer
      mt={props.mt}
      mb={props.mb}
      width={props.width}
      className={props.className}
      show={!!props.message || !!props.children}
    >
      {props.icon ? props.icon : <IconWarning/>}
      <Typography variant="body2-medium">{props.message || props.children}</Typography>
    </STContainer>
  )
}
