import { FC } from 'react'

export const Illustration: FC = () => {
  return (
    <svg width="175" height="147" viewBox="0 0 175 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12785_106414)">
        <path d="M172.385 59.6457L172.344 59.9551L172.513 60.218C174.629 63.5277 173.658 67.4351 170.745 70.1924L170.209 70.7005L170.633 71.3056C171.916 73.1361 172.379 74.9926 172.227 76.8921C172.073 78.8133 171.286 80.8329 169.973 82.9495L169.662 83.4519L170.021 83.9213C171.785 86.2234 172.155 88.439 171.609 90.3513C171.056 92.2909 169.516 94.0678 167.175 95.363C162.492 97.953 154.802 98.4789 146.718 94.5128L146.242 94.2791L145.821 94.6016C138.249 100.398 127.801 101.752 115.216 99.9625L114.994 99.9309L114.785 100.012L85.5965 111.383L85.5965 111.383L23.1551 135.714L1.91976 80.2262L83.1724 59.2919L83.1725 59.2919L99.0823 55.1944L99.2729 55.1453L99.423 55.0179C104.183 50.9756 112.168 46.547 121.163 41.9734L121.276 41.9158L121.368 41.8277C122.902 40.3545 124.468 38.0928 126.052 35.3136C127.643 32.5223 129.28 29.1559 130.946 25.4319C133.925 18.7692 137.015 10.9107 140.096 3.07289C140.336 2.462 140.577 1.85124 140.817 1.24118C143.505 2.89714 145.272 4.91905 146.351 7.21667C147.545 9.75819 147.928 12.7013 147.706 15.9742C147.259 22.554 144.389 30.2518 141.116 38.2389L140.569 39.5739L142.003 39.4144C153.167 38.1726 161.199 40.032 166.195 43.7452C171.149 47.4264 173.251 53.0275 172.385 59.6457Z" stroke="#181920" strokeWidth="1.71231"/>
        <path d="M151.445 65.4624C148.695 65.4624 145.944 64.9331 143.372 63.8744C139.117 62.1295 137.692 59.7769 137.297 58.8554C136.644 57.385 137.039 54.4639 138.702 52.4837C140.008 50.9153 141.828 50.288 143.847 50.7389L150.021 52.2681L159.123 46.7002L159.737 47.7001L150.199 53.5228L143.55 51.876C141.571 51.4447 140.305 52.3661 139.592 53.2091C138.286 54.7775 137.871 57.2282 138.365 58.3653C138.721 59.1691 139.968 61.1885 143.827 62.7765C147.844 64.4429 152.376 64.737 156.571 63.5999L173.054 59.1691L173.371 60.3062L156.887 64.737C155.106 65.2271 153.286 65.4624 151.445 65.4624Z" fill="#181920"/>
        <path d="M150.36 76.4215C148.738 76.4215 147.273 76.2255 146.106 75.7746C139.279 73.1083 135.796 67.9521 135.44 65.7759C135.123 63.7566 138.052 60.7962 138.388 60.4629L139.22 61.3059C138.111 62.3842 136.429 64.5212 136.607 65.5995C136.845 67.0306 139.714 72.03 146.541 74.6767C153.131 77.245 170.921 70.3243 171.099 70.2459L171.534 71.3438C170.94 71.5987 158.533 76.4215 150.36 76.4215Z" fill="#181920"/>
        <path d="M154.674 86.7137C151.944 86.7137 149.153 86.4196 146.502 85.6746C135.48 82.5182 132.848 80.5184 132.036 78.1266C131.562 76.7542 132.096 74.382 133.817 72.8136C134.906 71.8137 137.34 70.4021 141.495 72.1666L141.02 73.2449C137.656 71.8333 135.678 72.696 134.609 73.6762C133.204 74.9702 132.848 76.8719 133.145 77.7541C133.798 79.6558 136.37 81.5575 146.818 84.5375C157.049 87.4587 170.367 82.8907 170.485 82.8515L170.881 83.969C170.485 84.1062 162.906 86.7137 154.674 86.7137Z" fill="#181920"/>
        <path d="M146.046 95.8108C141.911 93.4386 136.449 88.1452 136.964 84.9103C137.102 84.0673 137.676 82.9694 139.694 82.5381L139.952 83.6948C138.309 84.0477 138.171 84.8515 138.131 85.1064C137.755 87.4982 142.504 92.4387 146.64 94.811L146.046 95.8108Z" fill="#181920"/>
        <path d="M127.561 78.6954L127.383 77.5387C132.963 76.6957 135.496 65.9912 137 59.5999L137.257 58.4824L138.405 58.7569L138.148 59.8744C136.466 66.9715 133.933 77.7152 127.561 78.6954Z" fill="#181920"/>
        <path d="M83.849 58.3848C80.2277 76.0099 81.1578 93.9683 85.907 112.182L0.5 147L0.777039 79.6368L82.9585 58.4632L83.849 58.3848Z" fill="#F7FB6F"/>
        <g clipPath="url(#clip1_12785_106414)">
          <path d="M25.5674 16.3142V15.2096C25.5674 14.9547 25.3951 14.7847 25.1367 14.7847H21.2604C21.002 14.7847 20.8297 14.6148 20.8297 14.4448V10.6211C20.8297 10.3662 20.6575 10.1963 20.4852 10.1963H19.3654C19.107 10.1963 18.9347 10.3662 18.9347 10.6211V14.4448C18.9347 14.6998 18.7624 14.8697 18.504 14.8697H14.7139C14.4555 14.7847 14.2832 14.9547 14.2832 15.2096V16.3142C14.2832 16.5691 14.4555 16.7391 14.7139 16.7391H18.5901C18.8485 16.7391 19.0208 16.909 19.0208 17.1639V20.9876C19.0208 21.2425 19.1931 21.4125 19.4515 21.4125H20.5713C20.8297 21.4125 21.002 21.2425 21.002 20.9876V17.0789C21.002 16.824 21.1743 16.6541 21.4327 16.6541H25.3089C25.3951 16.6541 25.5674 16.4841 25.5674 16.3142Z" fill="#00D1E0"/>
        </g>
        <g clipPath="url(#clip2_12785_106414)">
          <path d="M132.292 133.613V132.348C132.292 132.056 132.095 131.861 131.799 131.861H127.36C127.064 131.861 126.867 131.667 126.867 131.472V127.094C126.867 126.802 126.67 126.607 126.473 126.607H125.19C124.894 126.607 124.697 126.802 124.697 127.094V131.472C124.697 131.764 124.5 131.959 124.204 131.959H119.864C119.568 131.861 119.371 132.056 119.371 132.348V133.613C119.371 133.905 119.568 134.099 119.864 134.099H124.303C124.599 134.099 124.796 134.294 124.796 134.586V138.964C124.796 139.256 124.993 139.45 125.289 139.45H126.571C126.867 139.45 127.064 139.256 127.064 138.964V134.488C127.064 134.196 127.262 134.002 127.558 134.002H131.996C132.095 134.002 132.292 133.807 132.292 133.613Z" fill="#00D1E0"/>
        </g>
        <g clipPath="url(#clip3_12785_106414)">
          <path d="M20.3134 58.8373V57.5724C20.3134 57.2806 20.1161 57.086 19.8202 57.086H15.3818C15.0859 57.086 14.8886 56.8914 14.8886 56.6968V52.3185C14.8886 52.0266 14.6913 51.832 14.4941 51.832H13.2119C12.916 51.832 12.7187 52.0266 12.7187 52.3185V56.6968C12.7187 56.9887 12.5214 57.1833 12.2255 57.1833H7.88574C7.58984 57.086 7.39258 57.2806 7.39258 57.5724V58.8373C7.39258 59.1292 7.58984 59.3238 7.88574 59.3238H12.3242C12.6201 59.3238 12.8173 59.5183 12.8173 59.8102V64.1885C12.8173 64.4804 13.0146 64.675 13.3105 64.675H14.5927C14.8886 64.675 15.0859 64.4804 15.0859 64.1885V59.7129C15.0859 59.4211 15.2831 59.2265 15.579 59.2265H20.0175C20.1161 59.2265 20.3134 59.0319 20.3134 58.8373Z" fill="#32C37A"/>
        </g>
        <g clipPath="url(#clip4_12785_106414)">
          <path d="M122.817 17.4163V16.573C122.817 16.3784 122.685 16.2487 122.488 16.2487H119.529C119.332 16.2487 119.2 16.119 119.2 15.9893V13.0704C119.2 12.8758 119.069 12.7461 118.937 12.7461H118.083C117.885 12.7461 117.754 12.8758 117.754 13.0704V15.9893C117.754 16.1839 117.622 16.3136 117.425 16.3136H114.532C114.335 16.2487 114.203 16.3784 114.203 16.573V17.4163C114.203 17.6109 114.335 17.7406 114.532 17.7406H117.491C117.688 17.7406 117.82 17.8703 117.82 18.0649V20.9837C117.82 21.1783 117.951 21.3081 118.148 21.3081H119.003C119.2 21.3081 119.332 21.1783 119.332 20.9837V18C119.332 17.8054 119.463 17.6757 119.661 17.6757H122.62C122.685 17.6757 122.817 17.546 122.817 17.4163Z" fill="#00D1E0"/>
        </g>
        <path d="M51.328 46.7339C51.328 48.5965 49.7941 50.1263 47.8772 50.1263C45.9604 50.1263 44.4265 48.5965 44.4265 46.7339C44.4265 44.8713 45.9604 43.3415 47.8772 43.3415C49.7941 43.3415 51.328 44.8713 51.328 46.7339Z" stroke="#72E3AD" strokeWidth="1.71231"/>
        <path d="M170.197 25.4917C170.197 27.3543 168.663 28.8841 166.746 28.8841C164.83 28.8841 163.296 27.3543 163.296 25.4917C163.296 23.6291 164.83 22.0993 166.746 22.0993C168.663 22.0993 170.197 23.6291 170.197 25.4917Z" stroke="#72E3AD" strokeWidth="1.71231"/>
        <path d="M157.277 120.659C157.277 121.583 156.514 122.351 155.549 122.351C154.584 122.351 153.821 121.583 153.821 120.659C153.821 119.734 154.584 118.966 155.549 118.966C156.514 118.966 157.277 119.734 157.277 120.659Z" stroke="#72E3AD" strokeWidth="1.71231"/>
        <path d="M88.3669 13.1703C88.3669 14.3289 87.4115 15.2881 86.2082 15.2881C85.0049 15.2881 84.0495 14.3289 84.0495 13.1703C84.0495 12.0116 85.0049 11.0524 86.2082 11.0524C87.4115 11.0524 88.3669 12.0116 88.3669 13.1703Z" stroke="#32C37A" strokeWidth="1.71231"/>
        <path d="M86.6445 134.254C86.6445 136.585 84.725 138.496 82.3324 138.496C79.9398 138.496 78.0202 136.585 78.0202 134.254C78.0202 131.922 79.9398 130.011 82.3324 130.011C84.725 130.011 86.6445 131.922 86.6445 134.254Z" stroke="#32C37A" strokeWidth="1.71231"/>
      </g>
      <defs>
        <clipPath id="clip0_12785_106414">
          <rect width="174" height="147" fill="white" transform="translate(0.5)"/>
        </clipPath>
        <clipPath id="clip1_12785_106414">
          <rect width="11.2842" height="11.1312" fill="white" transform="translate(14.2832 10.1963)"/>
        </clipPath>
        <clipPath id="clip2_12785_106414">
          <rect width="12.9208" height="12.7457" fill="white" transform="translate(119.371 126.607)"/>
        </clipPath>
        <clipPath id="clip3_12785_106414">
          <rect width="12.9208" height="12.7457" fill="white" transform="translate(7.39258 51.832)"/>
        </clipPath>
        <clipPath id="clip4_12785_106414">
          <rect width="8.61386" height="8.49711" fill="white" transform="translate(114.203 12.7461)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
