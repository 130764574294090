import { Typography } from '@mui/material'
import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AuthApi, ViewApi } from 'src/api'
import {
  Button,
  Input,
  Label,
  NotAllowAuthenticated,
  useAnalytic
} from 'src/components'
import { MISMATCH_PASSWORD } from 'src/constants'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useOldValidation } from 'src/hooks'
import { IShareModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutSharingToken } from 'src/store/actions'
import { getIsAuthenticated } from 'src/store/selectors'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { setPasswordSchema } from 'src/validation'

import { ERoutes, generate } from 'src/router'
import styles from './styles.module.scss'

interface IQuickPassword {
  newPassword?: string
  confirmPassword?: string
}

export const QuickPassword: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { sharingToken } = useParams<{ sharingToken: string }>()
  const { eventHandler } = useAnalytic('share_password')
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const [shareData, setSharedData] = useState<IShareModel>()

  const loadSharedEmail = async (sharingToken: string) => {
    try {
      const { data } = await ViewApi.share(sharingToken)
      setSharedData(data)
    } catch (error) {
      console.error('not be able to get shared video data', error)
    }
  }

  useEffect(() => {
    dispatch(setLayoutSharingToken(sharingToken))
  }, [dispatch, sharingToken])

  useEffect(() => {
    if (!shareData) {
      loadSharedEmail(sharingToken)
    }
  }, [shareData, sharingToken])

  const { errors, validateAt, validate } = useOldValidation<IQuickPassword>()
  const [formData, setFormData] = useState<IQuickPassword>({
    newPassword: '',
    confirmPassword: ''
  })

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: setPasswordSchema,
      path: name,
      data: { ...formData, [name]: value }
    })
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleNext = async () => {
    try {
      dispatch(setLayoutLoading(true))
      const isValid = await validate({
        schema: setPasswordSchema,
        data: formData
      })
      if (!isValid || !sharingToken || !formData.newPassword) return

      const payload = {
        sharingToken,
        email: shareData?.email,
        password: formData.newPassword
      }

      await AuthApi.signUpQuick(payload)
      dispatch({ type: AUTH_GET_PROFILE })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }

  useEffect(() => {
    if (errors.confirmPassword === MISMATCH_PASSWORD) {
      eventHandler(ETrackingEvent.ERROR_PSWD_MISMATCH)()
    }
  }, [errors.confirmPassword, eventHandler])

  useEffect(() => {
    if (isAuthenticated) {
      history.push(generate(ERoutes.VIBES))
    }
  }, [history, isAuthenticated])

  return (
    <NotAllowAuthenticated>
      <div className={styles.STContainer}>
        <Typography variant="h3" className="my-32">Setup your password</Typography>
        <form className={styles.STForm} onSubmit={handleNext} autoComplete="off">
          <Label>Email</Label>
          <Typography
            variant="body1"
            sx={{ marginBottom: '32px' }}
          >
            {shareData?.email}
          </Typography>
          <Input
            required
            type="password"
            label="Type Password"
            name="newPassword"
            placeholder="Enter your password"
            value={formData.newPassword}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD)}
            autoComplete="off"
            error={errors.newPassword}
            isCheckPassword
          />
          <Input
            required
            type="password"
            label="Retype Password"
            name="confirmPassword"
            placeholder="Enter your password"
            value={formData.confirmPassword}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
            autoComplete="off"
            error={errors.confirmPassword}
          />
          <Button
            fullWidth
            onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT, handleNext)}
          >
            Next
          </Button>
        </form>
      </div>
    </NotAllowAuthenticated>
  )
}
