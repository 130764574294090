import { Button, TourPopper, useAnalytic } from 'src/components'
import { IconPlus } from 'src/icons'

import { Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, OverlayService } from 'src/services'
import { CreateJobTourService, TARGET_CREATE_JOB_BUTTON } from 'src/services/tour/create-job-tour.service'
import Style from './style.module.scss'

interface IProps {
  onlyIcon?: boolean
  /**
   * If you want to handle click event by yourself
   */
  onClick?: () => void
}

export const CreateJobButton: FC<IProps> = (props) => {
  const history = useHistory()
  const { eventHandler } = useAnalytic('campaigns')
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)

  const handleCreateCampaign = useCallback(() => {
    if (props.onClick) {
      return props.onClick()
    }

    CreateJobTourService.nextStep()
    OverlayService.reset()
    CampaignMutationService.reset()
    eventHandler(ETrackingEvent.CAMPAIGN_BTN_CREATE_JOBS)()
    history.push(generate(ERoutes.NEW_CAMPAIGN))
  }, [history, eventHandler, props])

  return (
    <>
      <Button
        onClick={handleCreateCampaign}
        sx={{ padding: '14px 32px' }}
        className={Style.button}
        id={TARGET_CREATE_JOB_BUTTON.getValue()}
      >
        <IconPlus color="#fff"/> {props.onlyIcon ? null : <Typography variant="body2-bold">Create Job</Typography>}
      </Button>
      {tourEnabled && currentTourStep === 0 && <TourPopper {...CreateJobTourService.getStepConfig()}/>}
    </>
  )
}
