import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { LinkedInEducation } from 'src/components'
import { IconArrowDown } from 'src/icons'
import { ILinkedinEducation } from 'src/interfaces'
import Style from './style.module.scss'

interface IProps {
  linkedinEducations?: ILinkedinEducation[]
  lightMode?: boolean
  className?: string
  onToggleCollapse?: () => void
}

export const CandidateInfoEducationSection: FC<IProps> = ({ onToggleCollapse, linkedinEducations = [], lightMode = false, className }) => {
  const [open, setOpen] = useState(true)
  const itemRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    if (!open) {
      itemRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    onToggleCollapse?.()
    setOpen(!open)
  }

  useEffect(() => {
    if (lightMode) {
      setOpen(false)
    }
  }, [lightMode])

  return (
    <div className={lightMode ? Style.containerLightMode : Style.STContainer}>
      {lightMode
        ? (
          <button className={Style.STHeaderLightMode} onClick={handleToggle}>
            <div
              className={Style.STArrowIcon}
              style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
            >
              <IconArrowDown/>
            </div>
            <p className={clsx(Style.STitle, className)}><span>E</span>ducation</p>
          </button>
        )
        : (
          <button className={Style.STHeader} onClick={handleToggle}>
            <p className={clsx(Style.STitle, className)}>Education</p>
            <div
              className={Style.STArrowIcon}
              style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
            >
              <IconArrowDown/>
            </div>
          </button>
        )}

      <Collapse in={open}>
        {linkedinEducations.map((item, index) => (
          <LinkedInEducation
            ref={itemRef}
            isLast={index === linkedinEducations.length - 1}
            isOnly={linkedinEducations.length === 1}
            key={index}
            item={item}
            lightMode={lightMode}
          />
        ))}
      </Collapse>
    </div>
  )
}
