import {
  IGuideAction, IGuideState, GUIDE_SET_FIRST_ACTION
} from '../types'

const initState: IGuideState = {
  firstAction: null
}

export const reducer = (state = initState, action: IGuideAction) => {
  switch (action.type) {
    case GUIDE_SET_FIRST_ACTION:
      return {
        ...state,
        firstAction: action.value
      }
    default:
      return state
  }
}
