import { FC, SVGProps } from 'react'
import { ETrackingEvent } from 'src/enums'
import {
  IconArchive,
  IconArchiveFilled,
  IconIntros, IconIntrosFilled, IconLikes, IconLikesFilled, IconVibes,
  IconVibesOutlined
} from 'src/icons'
import { IFirebaseNotification } from 'src/services'

interface MenuConfig {
  id?: string
  title: string
  event: ETrackingEvent
  route: string
  icon: FC<SVGProps<SVGSVGElement>>
  activeIcon: FC<SVGProps<SVGSVGElement>>
  watchNoticeKey?: keyof IFirebaseNotification
  watchGuideKey?: string[]
}

const bottomMenuConfig: MenuConfig[] = [
  {
    title: 'Vibes',
    event: ETrackingEvent.BTN_VIBES_SCREEN,
    route: '/vibes',
    icon: IconVibesOutlined,
    activeIcon: IconVibes
  },
  {
    id: 'likes',
    title: 'Likes',
    event: ETrackingEvent.BTN_LIKES_SCREEN,
    route: '/likes',
    icon: IconLikes,
    activeIcon: IconLikesFilled,
    watchNoticeKey: 'likeList',
    watchGuideKey: ['like', 'superLike']
  },
  {
    id: 'intros',
    title: 'Intros',
    event: ETrackingEvent.BTN_INTROS_SCREEN,
    route: '/intros',
    icon: IconIntros,
    activeIcon: IconIntrosFilled,
    watchNoticeKey: 'introList'
  },
  {
    title: 'Skips',
    event: ETrackingEvent.BTN_SKIPS_SCREEN,
    route: '/archive',
    icon: IconArchive,
    activeIcon: IconArchiveFilled
  }
]

const menuConfig: MenuConfig[] = [
  ...bottomMenuConfig
]

export { bottomMenuConfig, menuConfig }
