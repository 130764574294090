import axios from 'axios'
import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

const loadingStates = {
  coin: false,
  giftOpen: false,
  earnCredit: false,
  claimCredit: false
}

export const LottiesModule = new (
  class {
    constructor() {
      this.loadClaimCredit()
      this.loadCoin()
      this.loadEarnCredit()
      this.loadGiftOpen()
    }

    private readonly _coin$ = new BehaviorSubject<any>(undefined)
    get coin$(): WithOutNextComplete<typeof this._coin$> {
      return this._coin$
    }

    loadCoin() {
      if (loadingStates.coin) return
      loadingStates.coin = true
      axios.get('/lotties/coin.json').then(({ data }) => {
        this._coin$.next(data)
      }).catch(() => (loadingStates.coin = false))
    }

    private readonly _giftOpen$ = new BehaviorSubject<any>(undefined)
    get giftOpen$(): WithOutNextComplete<typeof this._giftOpen$> {
      return this._giftOpen$
    }

    loadGiftOpen() {
      if (loadingStates.giftOpen) return
      loadingStates.giftOpen = true
      axios.get('/lotties/giftopen.json').then(({ data }) => {
        this._giftOpen$.next(data)
      }).catch(() => (loadingStates.giftOpen = false))
    }

    private readonly _earnCredit$ = new BehaviorSubject<any>(undefined)
    get earnCredit$(): WithOutNextComplete<typeof this._earnCredit$> {
      return this._earnCredit$
    }

    loadEarnCredit() {
      if (loadingStates.earnCredit) return
      loadingStates.earnCredit = true
      axios.get('/lotties/earn-credit.json').then(({ data }) => {
        this._earnCredit$.next(data)
      }).catch(() => (loadingStates.earnCredit = false))
    }

    private readonly _claimCredit$ = new BehaviorSubject<any>(undefined)
    get claimCredit$(): WithOutNextComplete<typeof this._claimCredit$> {
      return this._claimCredit$
    }

    loadClaimCredit() {
      if (loadingStates.claimCredit) return
      loadingStates.claimCredit = true
      axios.get('/lotties/claim-credit.json').then(({ data }) => {
        this._claimCredit$.next(data)
      }).catch(() => (loadingStates.claimCredit = false))
    }
  }
)()
