import { FC } from 'react'

import clsx from 'clsx'
import Style from './style.module.scss'

export const WorkingLocation: FC = () => {
  return (
    <div className={Style.WorkingLocation}>
      <span className="body2-medium color-neutral-theme-400">Location</span>
      <div className={clsx('body2-bold color-neutral-theme-900', Style.remote)}>Remote</div>
    </div>
  )
}
