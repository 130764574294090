import { createTheme, ThemeProvider } from '@mui/material'
import { FC, useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { AnalyticProvider } from './components'
import { genThemeWithPaletteMode } from './constants'
import { useAppSelector } from './hooks'
import { AppLayout } from './layout'
import { browserHistory } from './router/history'
import { SettingsService } from './services'
import { store } from './store'
import { getThemeMode } from './store/selectors'

const AppTheme: FC = () => {
  const paletteMode = useAppSelector(getThemeMode)

  // Update the theme only if the mode changes
  const theme = useMemo(
    () => createTheme(genThemeWithPaletteMode(paletteMode)),
    [paletteMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <AppLayout/>
    </ThemeProvider>
  )
}

const App: FC = () => {
  useEffect(() => {
    SettingsService.load()
  }, [])

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <AnalyticProvider>
          <AppTheme/>
        </AnalyticProvider>
      </Router>
    </Provider>
  )
}

export default App
