import { Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components'
import { EEnv } from 'src/constants'
import { ERoutes, generate } from 'src/router'
import Style from './style.module.scss'

export const Empty: FC = () => {
  const theme = useTheme()

  const history = useHistory()

  const goToLikes = useCallback(() => {
    history.push(generate(ERoutes.LIKES))
  }, [history])

  return (
    <div className={Style.STContainer}>
      <div className={Style.imgContainer}>
        <div
          className={clsx(
            Style.cover
          )}
        >
          <div className={clsx(Style.coverImg)}>
            <video
              autoPlay
              loop
              muted
              style={{ width: '100%', height: 'auto' }}
              src={`${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/images/hiring-manager/video/onboarding-talent03.mp4`}
            />
          </div>
        </div>
      </div>
      <div className={Style.content}>
        <Typography variant="h5" color={theme.colors['--color-neutral-theme-250']}>
          Clicking "Introduce Me" will show up here
        </Typography>
        <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-250']}>
          Go to “Likes” and ask to meet talent by clicking “Introduce Me”
        </Typography>

        <Button className={Style.vibeBtn} onClick={goToLikes}>Go to Likes</Button>
      </div>
    </div>
  )
}
