import { IUserModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class PasswordApi {
  static readonly _prefix = '/password'

  /**
   * Forgot password
   */
  static forgotPasswordRequest(payload: { email: string }): TAxiosResponseData<{ token: string }> {
    return axiosHiringApi.post(`${this._prefix}/forgot`, payload)
  }

  /**
   * Reset password
   */
  static resetPasswordRequest(payload: { password: string; token: string }): TAxiosResponseData<{
    id: number
    token: string
    tokenType: 'Bearer'
    tokenExpireAt: string
    tokenExpireIn: number
    profile: IUserModel
  }> {
    return axiosHiringApi.post(`${this._prefix}/reset?token=${payload.token}`, { password: payload.password })
  }

  /**
   * Forgot password
   */
  static change(payload: { currentPassword: string; newPassword: string }): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}`, payload)
  }
}
