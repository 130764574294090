export const READ_COMMENT_ON_VIDEO = Symbol('READ_COMMENT_ON_VIDEO')

export interface IVibesState {
  readCommentOnVideoId?: number
}

export interface IVibesAction {
  type: typeof READ_COMMENT_ON_VIDEO
  value: IVibesState
}
