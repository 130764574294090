import { Box, TextField as MuiTextField } from '@mui/material'
import { Theme, css, styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const TextInput = styled(Box, { label: 'TextInput' })(() => `
  postion: relative;
  display: flex;
  flex-direction: column;

  .MuiAutocomplete-root {
    position: relative;
  }

  .MuiInputBase-adornedEnd {
    padding-right: 16px !important;
    padding-left: 56px !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none
  }

  .MuiAutocomplete-inputRoot {
    display: flex;
    flex-wrap: nowrap !important;
    overflow: auto;

    input {
      min-width: 200px !important;
    }
  }

  .MuiAutocomplete-listbox {
    background: red
  }
`)

export const TextField = styled(MuiTextField, { label: 'TextFieldCustom' })(({ theme }) => css`
  ${{ ...theme.typography['body1-regular'] }};

  .MuiFormHelperText-root {
    margin: ${theme.spacing(1.5)} 0 0;
  }


  div.MuiInputBase-root {
    border: none;
    border-radius: 16px;
    color: ${theme.colors['--color-neutral-theme-700']};
    background-color: ${theme.colors['--color-neutral-theme-100']};

    input {
      padding: ${theme.spacing(2)};

      &::placeholder {
        color: ${theme.colors['--color-neutral-theme-300']};
      }
    }

    &:hover:not(:focus-within):not(.Mui-disabled):not(.Mui-error) {
      border-color: transparent; /* remove the border's colour */
      box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-200']}; /* emulate the border */
    }

    :focus-within:not(.Mui-error) {
      border-color: transparent; /* remove the border's colour */
      box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-300']}; /* emulate the border */
    }

    &.Mui-disabled {
      border-color: transparent;
      background-color: ${theme.colors['--color-neutral-theme-50']};
      box-shadow: 0 0 0 1px ${theme.colors['--color-neutral-theme-200']}; /* emulate the border */
    }
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent;
      border-width: 1px !important;
      border-radius: 16px !important;
    }

    &:hover,
    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }

    &.Mui-error {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${theme.colors['--color-negative-500']};
      }
    }
  }
`)

// icon start input
export const STIconStart = styled(Box, { label: 'IconStart' })(({ theme }) => `
  position: absolute;
  top: 36px;
  left: 2px;
  z-index: 2;
  height: 48px;
  background: ${theme.colors['--color-neutral-theme-100']};
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`)

// tags
export const STTags = styled(Box, { label: 'Tags' })(({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)}
`)

export const STTagItems = styled(Box, { label: 'TagItems' })(({ theme }) => `
  height: 32px;
  padding: ${theme.spacing(0, 1)};
  border-radius: 25px;
  background-color: ${theme.colors['--color-neutral-theme-200']};

  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)}
`)

export const STClose = styled(Box, { label: 'Close' })(({ theme }) => `
  padding: 4px;
  background-color: ${theme.colors['--color-neutral-theme-250']};
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;

    path: {
      fill: ${theme.colors['--color-neutral-theme-700']};
    }
  }
`)

export const STOptionsItem = styled('li', {
  shouldForwardProp: prop => prop !== 'isSelected',
  label: 'OptionsItem'
})<{ isSelected?: boolean }>(({ theme, isSelected }) => `
    min-height: 48px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)};
    padding: ${theme.spacing(0, 2)};
    background-color: ${isSelected ? theme.colors['--color-neutral-theme-100'] : 'unset'} !important;

    :hover {
      background-color: ${theme.colors['--color-neutral-theme-100']} !important;
    }

    >div: nth-of-type(1) {
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
`)

export const STLoading = styled('div', { label: 'Loading' })(() => `
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 24px !important;
    height: 24px !important;
  }
`)

export const useStyles = makeStyles<Theme, { isMobile: boolean }>(
  theme => ({
    options: (props) => ({
      width: props.isMobile ? '100%' : '335px',
      maxHeight: '220px !important',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      overflow: 'auto',
      backgroundColor: theme.colors['--color-neutral-theme-50'],
      boxShadow: '0px 24px 56px -12px rgb(37 38 46 / 10%)',
      borderRadius: '16px',
      padding: `${theme.spacing(2, 0)} !important`
    })
  })
)
