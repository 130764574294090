import clsx from 'clsx'
import { FC } from 'react'
import { CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { IconClose } from 'src/icons'
import bgPromotionBanner from 'src/images/bg-promotion-banner.svg'
import gif from 'src/images/gif.svg'
import partyPopper from 'src/images/party-popper.svg'
import Style from './style.module.scss'

interface IProps {
  onClose?: () => void
  onOk?: () => void
}

export const PromotionBanner: FC<IProps> = ({ onClose, onOk }) => {
  return (
    <div className={Style.banner}>
      <button className={Style.btnClose} onClick={onClose}>
        <IconClose/>
      </button>
      <div className={Style.container}>
        <img className={Style.bgPromotionBanner} src={bgPromotionBanner} alt="bg-promotion-banner" draggable={false}/>
        <img className={Style.gif} src={gif} alt="gif" draggable={false}/>

        <div className={Style.content}>
          <div className={Style.content_popper}>
            <img src={partyPopper} alt="party-popper" draggable={false}/>
          </div>
          <span className={clsx('body2-bold', Style.content_text)}>
            Be the first 100 to create a video vibe check and get <br/>
            <span className={Style.textFee}>{CREDIT_FOR_FIRST_TIME_VVC} free</span> credits!
          </span>
        </div>
        <div className={Style.ctaWrapper}>
          <button className={Style.cta} onClick={onOk}>
            Let’s Do It !!!
          </button>
        </div>
      </div>
    </div>
  )
}
