import { BehaviorSubject } from 'rxjs'
import { WindowService } from 'src/services'

export const PlayerService = new (
  class {
    private playerRef = 0
    genRef() {
      return ++this.playerRef
    }

    readonly playing$ = new BehaviorSubject<number | string | undefined>(undefined)
    get playing() {
      return this.playing$.getValue()
    }

    set playing(refNo) {
      this.playing$.next(refNo)
    }

    readonly muted$ = new BehaviorSubject<boolean>(false)
    get muted() {
      return this.muted$.getValue()
    }

    set muted(refNo) {
      this.muted$.next(refNo)
    }

    readonly bandwidthEstimate$ = new BehaviorSubject<number>(0)
    get bandwidthEstimate() {
      return this.bandwidthEstimate$.getValue()
    }

    set bandwidthEstimate(refNo) {
      this.bandwidthEstimate$.next(refNo)
    }

    constructor() {
      WindowService.hidden$.subscribe((hidden) => {
        if (hidden) {
          this.playing = undefined
        }
      })
    }
  }
)()
