export class LockMonitor {
  private locked = false
  private queue: any[] = []

  acquire(): Promise<any> {
    return new Promise<any>((resolve) => {
      if (!this.locked) {
        this.locked = true
        resolve(
          this.release.bind(this)
        )
      } else {
        this.queue.push(resolve)
      }
    })
  }

  release(): void {
    this.locked = false
    if (this.queue.length > 0) {
      const nextResolve = this.queue.shift()
      if (nextResolve) {
        this.acquire().then(nextResolve)
      }
    }
  }

  destroy(): void {
    this.locked = false
    this.queue = []
  }
}
