import { IPaginationQuery, IShareModel, IUserModel, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class ShareApi {
  static readonly _prefix = '/share'

  static search(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IUserModel> {
    return axiosHiringApi.get(`${this._prefix}/emails`, { params })
  }

  static recent(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IShareModel> {
    return axiosHiringApi.get(`${this._prefix}/recent`, { params })
  }

  static shared(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IShareModel> {
    return axiosHiringApi.get(`${this._prefix}/shared`, { params })
  }
}
