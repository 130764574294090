import { useEffect, useState } from 'react'

export const useIntersection = (element: HTMLElement | null, rootMargin?: string) => {
  const [isVisible, setState] = useState(true)

  useEffect(() => {
    if (!element) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      }, { rootMargin }
    )

    observer.observe(element)

    return () => observer.unobserve(element)
  }, [element, rootMargin])

  return isVisible
}
