import { BehaviorSubject } from 'rxjs'
import { UploadApi } from 'src/api'
import { ESourceFrom, IPlaceholder } from 'src/interfaces'
import { WithOutNextComplete } from 'types/rxjs'

export enum ERecordFor {
  PFV = 'pfv',
  /**
   * Will save the recording to the `PopupTourService`, not upload to the server as `ERecordFor.PFV` type
   */
  PFV_TOUR_SERVICE = 'pfv_tour_service',
  CAMPAIGN = 'campaign'
}

interface IMetadata extends Record<'platform' | 'userAgent' | 'screenWidth' | 'screenHeight', number | string | undefined> {
  sourceFrom?: ESourceFrom
}

class ThumbnailPairs {
  private map: Record<string, { url: string; flip: boolean }> = {}

  private constructor(map: Record<string, { url: string; flip: boolean }>) {
    this.map = map
  }

  static create(map: Record<string, { url: string; flip: boolean }>) {
    return new ThumbnailPairs({ ...map })
  }

  cloneWithNewPair(name: string, data: { url: string; flip: boolean }) {
    return ThumbnailPairs.create({ ...this.map, [name]: data })
  }

  public get(name?: string): { url: string; flip: boolean } | undefined {
    if (!name) return

    return this.map[name]
  }
}

export class RecordingService {
  static placeholders: IPlaceholder[] = []

  static async randomPlaceholder() {
    if (!RecordingService.placeholders.length) {
      await UploadApi.getPlaceholders().then(({ data }) => {
        RecordingService.placeholders = data.placeholders
      })
    }

    return RecordingService.placeholders.random()
  }

  private startRecordingAt: Date | undefined
  private stopRecordingAt: Date | undefined

  readonly thumbnailAt$ = new BehaviorSubject<number>(0)
  private static readonly _mapThumbnails$ = new BehaviorSubject<ThumbnailPairs>(ThumbnailPairs.create({}))

  static get mapThumbnails$(): WithOutNextComplete<typeof this._mapThumbnails$> {
    return this._mapThumbnails$
  }

  static pushToMapThumbnail(name: string, data: { url: string; flip: boolean }) {
    this._mapThumbnails$.next(this._mapThumbnails$.getValue().cloneWithNewPair(name, data))
  }

  get thumbnailAt() {
    return this.thumbnailAt$.getValue()
  }

  set thumbnailAt(value) {
    this.thumbnailAt$.next(value)
  }

  startRecording() {
    this.startRecordingAt = new Date()
    this.stopRecordingAt = undefined
  }

  stopRecording() {
    this.stopRecordingAt = new Date()
  }

  get recordingDuration() {
    return this.stopRecordingAt && this.startRecordingAt
      ? Math.floor((this.stopRecordingAt.getTime() - this.startRecordingAt.getTime()) / 1000)
      : 0
  }

  private readonly _metadata$ = new BehaviorSubject<IMetadata>({
    sourceFrom: undefined,
    platform: navigator?.platform,
    userAgent: navigator?.userAgent,
    screenWidth: window?.screen?.width,
    screenHeight: window?.screen?.height
  })

  get metadata$(): WithOutNextComplete<typeof this._metadata$> {
    return this._metadata$
  }

  private readonly _recordResult$ = new BehaviorSubject<{
    file: File
    src?: string
  } | undefined>(undefined)

  get recordResult$(): WithOutNextComplete<typeof this._recordResult$> {
    return this._recordResult$
  }

  get recordResult() {
    return this._recordResult$.getValue()
  }

  private set recordResult(value) {
    this._recordResult$.next(value)
  }

  setRecordResult(file?: File, sourceFrom?: ESourceFrom) {
    this._metadata$.next({
      ...this._metadata$.value,
      sourceFrom
    })

    this.thumbnailAt = 0

    this._recordResult$.next(file ? { file } : undefined)
  }

  private readonly _placeholder$ = new BehaviorSubject<IPlaceholder | undefined>(undefined)

  get placeholder$(): WithOutNextComplete<typeof this._placeholder$> {
    return this._placeholder$
  }

  get placeholder() {
    return this.placeholder$.getValue()
  }

  private set placeholder(value) {
    if (value?.file) {
      this._placeholder$.next({ file: value?.file })
    } else {
      this._placeholder$.next(value)
    }
  }

  setPlaceholder(placeholder: IPlaceholder) {
    this.placeholder = placeholder
  }

  removePlaceholder() {
    this.placeholder = undefined
  }

  async randomPlaceholder() {
    this.placeholder = await RecordingService.randomPlaceholder()
  }

  private readonly _teleprompter$ = new BehaviorSubject({
    isOpening: false,
    text: '',
    speed: 30
  })

  get teleprompter$(): WithOutNextComplete<typeof this._teleprompter$> {
    return this._teleprompter$
  }

  get teleprompter() {
    return this._teleprompter$.getValue()
  }

  private set teleprompter(value) {
    this._teleprompter$.next(value)
  }

  setTeleprompter(teleprompter: { isOpening: boolean; text: string; speed: number }) {
    this.teleprompter = teleprompter
  }
}
