import {
  put, takeLatest
} from 'redux-saga/effects'
import { CampaignApi } from 'src/api'
import { EMessage } from 'src/enums'
import { SnackbarService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
import { setLayoutLoading } from '../actions'
import {
  CAMPAIGN_CREATED, CAMPAIGN_FETCHED, CAMPAIGN_PAGE_ENTERED, CAMPAIGN_UPDATED, ICampaignAction
} from '../types'

function * loadCampaigns(action: ICampaignAction) {
  try {
    const { data: campaigns }: Awaited<ReturnType<typeof CampaignApi.getList>> = yield CampaignApi.getList()
    yield put(setLayoutLoading(true))
    yield put({
      type: CAMPAIGN_FETCHED,
      value: campaigns
    })
  } catch (error) {
    SnackbarService.push({
      severity: EMessage.ERROR,
      content: getApiErrorMessage(error)
    })
  } finally {
    yield put(setLayoutLoading(false))
  }
}

export const handler = function * () {
  yield takeLatest(CAMPAIGN_CREATED, loadCampaigns)
  yield takeLatest(CAMPAIGN_UPDATED, loadCampaigns)
  yield takeLatest(CAMPAIGN_PAGE_ENTERED, loadCampaigns)
}
