import { IconButton, useMediaQuery } from '@mui/material'
import * as Popover from '@radix-ui/react-popover'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Button, Spinner, Textarea } from 'src/components'
import { IconEmoji } from 'src/icons'
import { EventUtils, getApiErrorMessage } from 'src/utils'

import { useHistory } from 'react-router'
import { CommentApi } from 'src/api'
import { ECommentTable, EMessage } from 'src/enums'
import { IShareModel, IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import Style from './style.module.scss'

interface IComment {
  width?: number
  watchData: Partial<IShareModel & { Video: IVideoModel }>
  externalIsCommented: boolean
  onChangeExternalIsCommented: () => void
  onClose?: () => void
}

export const Comment: FC<IComment> = ({
  width,
  watchData,
  externalIsCommented,
  onClose,
  onChangeExternalIsCommented
}) => {
  const isDesktop = useMediaQuery('(min-width: 769px)')
  const history = useHistory()

  const [loadingSend, setLoadingSend] = useState(false)
  const [content, setConTent] = useState('')
  const [caretEnd, setCaretEnd] = useState(0)
  const [caretStart, setCaretStart] = useState(0)
  const [isCommented, setIsCommented] = useState(externalIsCommented)

  useEffect(() => {
    setIsCommented(externalIsCommented)
  }, [externalIsCommented])

  const disableSendBtn = useMemo(
    () => !content || loadingSend,
    [content, loadingSend]
  )

  const handleOnEmojiClick = (emoji: EmojiClickData) => {
    setConTent((value) => {
      return (
        value.substring(0, caretStart) + emoji.emoji + value.substring(caretEnd)
      )
    })
  }

  const handleChangeInput: ChangeEventHandler<HTMLTextAreaElement> = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.persist()
    setConTent(e.target.value)
  }

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setCaretStart(e.target.selectionStart || 0)
    setCaretEnd(e.target.selectionEnd || 0)
  }

  const handleSend = async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)
    setLoadingSend(true)
    try {
      await CommentApi.createWithEmail(
        { email: watchData.email as string },
        {
          candidateId: watchData?.candidateId as number,
          commentableType: ECommentTable.VIDEO,
          commentableId: watchData?.Video?.id,
          content
        }
      )

      setIsCommented(true)
      onChangeExternalIsCommented()
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      setLoadingSend(false)
    }
  }

  const handleNavigateToLogin = () => {
    onClose?.()

    history.push(generate(ERoutes.SIGN_IN), {
      from: `/likes?detailCandidateId=${watchData?.candidateId}&detailTab=1`
    })
  }

  return (
    <div className={Style.Comment}>
      {isCommented
        ? (
          <div
            className={Style.CommentForm}
            style={{ width, alignItems: 'center' }}
          >
            <p className={Style.CommentedLabel}>
              Open app to discuss candidate further
            </p>
            <Button className={Style.LogInBtn} onClick={handleNavigateToLogin}>
              Log in
            </Button>
          </div>
        )
        : (
          <form
            className={Style.CommentForm}
            onSubmit={handleSend}
            style={{ width }}
          >
            <div className={Style.Input}>
              {isDesktop && (
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <IconButton className={Style.EmojiBtn}>
                      <IconEmoji/>
                    </IconButton>
                  </Popover.Trigger>
                  <Popover.Portal>
                    <Popover.Content style={{ zIndex: 3 }}>
                      <EmojiPicker onEmojiClick={handleOnEmojiClick}/>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              )}
              <Textarea
                disabled={loadingSend}
                wrapperClassName={Style.TextareaWrapper}
                className={Style.Textarea}
                disableResize
                minRows={1}
                maxRows={3}
                placeholder="Comment"
                value={content}
                onBlur={handleBlur}
                onChange={handleChangeInput}
              />
            </div>
            <Button
              type="submit"
              order="tertiary"
              className={Style.SubmitBtn}
              disabled={disableSendBtn}
            >
              {loadingSend ? <Spinner size={28}/> : 'Send'}
            </Button>
          </form>
        )}
    </div>
  )
}
