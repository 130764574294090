/**
 * use ifMobileL or ifTablet instead
 * @deprecated
 */
export const useIfMobileScreen = () => {
  // const theme = useTheme()
  // return useMediaQuery(theme.breakpoints.down('md'))

  return false
}
