import { Avatar, Box, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { orderBy, uniqBy } from 'lodash'
import { ChangeEvent, ChangeEventHandler, FC, FocusEventHandler, KeyboardEventHandler, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY, catchError, filter, finalize, from, takeUntil } from 'rxjs'
import sanitizeHtml from 'sanitize-html'
import { CommentApi } from 'src/api'
import { ECommentTable, EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useBehaviorMapper, useThrottle, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { ICommentModel, IPaginationResponse, IReactionModel } from 'src/interfaces'
import { EEventName, EventService, SnackbarService, TypingService } from 'src/services'
import { setReadCommentOnVideoId } from 'src/store/actions'
import { getCredentials } from 'src/store/selectors'
import { ensureArray, getApiErrorMessage, getFullName } from 'src/utils'
import { useAnalytic } from '../analytic-provider'
import { Button } from '../button'
import { Textarea } from '../textarea'
import { TimeAgo } from '../time-ago'
import { Empty } from './components/empty'
import { CommentLoadingWrapper } from './components/loading'
import { Summary } from './components/summary'
import Style from './style.module.scss'
import { STAction, STComment, STCommentItem, STCommentSection, STLeft, STSend } from './styled'

interface IProps {
  videoId: number
  candidateId: number
  reaction?: IReactionModel
  forceRender?: number
  showCommentInput?: boolean
  className?: string
  hideInvite?: boolean
  submissionId?: number
}

export const Comment: FC<IProps> = ({
  videoId,
  candidateId,
  reaction,
  forceRender,
  showCommentInput = false,
  hideInvite = false,
  submissionId,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const theme = useTheme()
  const profile = useAppSelector(getCredentials)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const { eventHandler } = useAnalytic('')
  const dispatch = useAppDispatch()
  const [firstLoading, setFirstLoading] = useState<boolean>(false)
  const [content, setConTent] = useState<string>('')
  const [comment, setComment] = useState<IPaginationResponse<ICommentModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  // const [caretStart, setCaretStart] = useState(0)
  // const [caretEnd, setCaretEnd] = useState(0)

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const element = (e.target as HTMLElement)
    const loadByScroll = element.scrollHeight - Math.abs(element.scrollTop) < element.clientHeight + (element.clientHeight / 2) // 50%

    if (timeout.current) clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      if (loadByScroll && comment.page && comment.page !== comment.pages) {
        loadComment({ page: comment.page + 1, showSkeleton: false })
      }
    }, 400)
  }

  /**
   * TODO: cover case reconnect after long time disconnect and receive a ton of new comments
   */
  const loadComment = async ({ page = 1, limit = 20, showSkeleton = true }: { page?: number; limit?: number; showSkeleton?: boolean}) => {
    if (page === 1 && showSkeleton) {
      setFirstLoading(true)
    }

    const promise = CommentApi.pagination({
      page,
      limit,
      candidateId
    })

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => setFirstLoading(false))
      )
      .subscribe(({ data }) => {
        dispatch(setReadCommentOnVideoId({ readCommentOnVideoId: videoId }))

        setComment((prev) => ({
          ...prev,
          page: data.page,
          pages: data.pages,
          total: data.total,
          rows: orderBy(
            uniqBy(
              [...ensureArray(prev.rows), ...ensureArray(data.rows)],
              'id'
            ),
            'id',
            'desc'
          )
        }))
      })
  }

  // const handleKeyUp: KeyboardEventHandler<HTMLTextAreaElement> = (e: any) => {
  //   setCaretStart(e.target?.selectionStart || 0)
  //   setCaretEnd(e.target?.selectionEnd || 0)
  // }

  const handleSent: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    try {
      if (e.keyCode === 13) {
        e.preventDefault()
      }

      if (!candidateId || !content) return
      if (![undefined, 13].includes(e.keyCode)) {
        return
      }

      CommentApi.create({
        candidateId,
        content,
        commentableType: ECommentTable.VIDEO,
        commentableId: videoId
      }).then(() => loadComment({ showSkeleton: false }))

      setConTent('')
      // setCaretStart(0)
      // setCaretEnd(0)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    }
  }

  const handleChangeInput: ChangeEventHandler<HTMLTextAreaElement> = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    setConTent(e.target.value)
  }

  // const handleOnEmojiClick = (emoji: EmojiClickData) => {
  //   setConTent(value => {
  //     return value.substring(0, caretStart) + emoji.emoji + value.substring(caretEnd)
  //   })
  // }

  const handleOnBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    // setCaretStart(e.target.selectionStart || 0)
    // setCaretEnd(e.target.selectionEnd || 0)
  }

  const typing = useThrottle(
    useMemo(
      () => content,
      [content]
    ),
    2000
  )
  useEffect(() => {
    if (typing) {
      CommentApi.typing({
        candidateId,
        commentableType: ECommentTable.VIDEO,
        commentableId: videoId
      })
    }
  }, [typing, candidateId, videoId])

  /**
   * Reset comment when candidateId change
   */
  useEffect(() => {
    setComment({
      page: 1,
      pages: 1,
      total: 0,
      rows: []
    })
  }, [candidateId])

  /**
   * Listen event new comment and load first page
   */
  useUnsubscribeEffect((unsubscribe$) => {
    from(loadComment({}))
      .pipe(takeUntil(unsubscribe$))
      .subscribe()
  }, [forceRender])

  useUnsubscribeEffect((unsubscribe$) => {
    EventService.event$
      .pipe(
        takeUntil(unsubscribe$),
        filter((event) => event.name === EEventName.NEW_COMMENT),
        filter((event) => event.data?.candidateId === candidateId)
      )
      .subscribe(() => loadComment({ showSkeleton: false }))
  }, [candidateId])

  const _typingService = useMemo(() => new TypingService(), [])
  const typingTxt = useBehaviorMapper(_typingService.typingTxt$)
  useUnsubscribeEffect((unsubscribe$) => {
    if (profile.id) {
      EventService.event$
        .pipe(
          takeUntil(unsubscribe$),
          filter((event) => event.name === EEventName.TYPING_COMMENT),
          filter((event) => event.data?.candidateId === candidateId),
          filter((event) => event.data?.userId !== profile.id)
        )
        .subscribe((event) => event.data?.userId && event.data?.name && _typingService.onNewTyping({
          id: event.data.userId as number,
          name: event.data.name as string
        }))
    }
  }, [profile, candidateId])

  return (
    <div className={clsx('fx fx-column overflow-hidden', props.className)}>
      {firstLoading
        ? (
          <CommentLoadingWrapper/>
        )
        : (
          <>
            <STComment>
              <Summary forceRender={forceRender} candidateId={candidateId}/>

              <STCommentSection onScroll={handleScroll}>
                {comment.rows?.map((item, index) => (
                  <STCommentItem key={index}>
                    <STLeft>
                      <Avatar sx={{ width: 48, height: 48 }} src={item.user?.pfp?.url}/>
                      <Box>
                        <Box display="flex" gap={2} flexWrap="wrap">
                          <Typography
                            variant="body1-bold"
                            color={theme.colors['--color-neutral-theme-700']}
                          >
                            {getFullName(item.user) || item.user?.email}
                          </Typography>
                          <Typography
                            variant="body1-regular"
                            color={theme.colors['--color-neutral-theme-300']}
                          >
                            <TimeAgo date={item.createdAt}/>
                          </Typography>
                        </Box>

                        <Typography
                          variant="body1-regular"
                          color={theme.colors['--color-neutral-theme-700']}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {item.content}
                        </Typography>
                      </Box>
                    </STLeft>
                  </STCommentItem>
                ))}

                {!comment.rows?.length && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <Empty videoId={videoId} submissionId={submissionId} candidateId={candidateId} loadData={() => loadComment({})} hideInvite={hideInvite}/>
                  </Box>
                )}
              </STCommentSection>

              {showCommentInput && (
                <STAction>
                  <Typography
                    variant="meta-regular"
                    color={theme.colors['--color-neutral-theme-250']}
                    className="pl-4"
                    style={{ height: '20px' }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(typingTxt || ' ') }}/>
                  </Typography>
                  <STSend>
                    <Box sx={{ position: 'relative', flexGrow: 1, width: '100%' }}>
                      <Textarea
                        disableResize
                        minRows={1}
                        placeholder="Share your thoughts with your team"
                        value={content}
                        onKeyDown={handleSent}
                        onBlur={handleOnBlur}
                        onChange={handleChangeInput}
                        className={Style.TextAreaComment}
                      />
                      {/* <Popover.Root>
                        <Popover.Trigger asChild>
                          <IconButton sx={{ position: 'absolute', bottom: '10px', right: 0 }}>
                            <IconEmoji/>
                          </IconButton>
                        </Popover.Trigger>
                        <Popover.Portal>
                          <Popover.Content>
                            <EmojiPicker onEmojiClick={handleOnEmojiClick}/>
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root> */}
                    </Box>

                    <Button
                      order="secondary"
                      sx={{ marginBottom: '6px' }}
                      width={80}
                      height={48}
                      onClick={eventHandler(ETrackingEvent.BTN_COMMENT_SEND, handleSent)}
                    >
                      Send
                    </Button>
                  </STSend>

                </STAction>
              )}
            </STComment>
          </>
        )}
    </div>
  )
}
