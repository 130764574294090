import { IUserModel } from './user.model'
import { IModel } from './model'

export enum EFeedbackRelateType {
  CANDIDATE = 'Candidate'
}

export interface IFeedbackModel extends IModel {
  id: number
  userId?: number
  user?: IUserModel
  relateType?: EFeedbackRelateType
  relateId?: number
  content?: string
}
