import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC, useState } from 'react'
import { FeedbackApi } from 'src/api'
import { EMessage } from 'src/enums'
import { useAppSelector } from 'src/hooks'
import { ImageTabletsImg } from 'src/images'
import { OverlayService, SnackbarService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { getApiErrorMessage } from 'src/utils'
import { Button } from '../button'
import { Divider } from '../divider'
import { Input } from '../input'
import { Textarea } from '../textarea'
import Style from './style.module.scss'

const inputSx = {
  marginBottom: 0
}

export const ContactUs: FC = () => {
  const [feedback, setFeedback] = useState('')
  const [guestEmail, setGuestEmail] = useState('')
  const theme = useTheme()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isAuthenticated = useAppSelector(getCredentials)

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      if (!feedback) {
        SnackbarService.push({
          severity: EMessage.ERROR,
          content: 'Please Provide Your Feedback'
        })
        return
      }

      if (!guestEmail && !isAuthenticated?.id) {
        SnackbarService.push({
          severity: EMessage.ERROR,
          content: 'Please Provide Your Email'
        })
        return
      }

      await FeedbackApi.create({ email: guestEmail, content: feedback })

      OverlayService.reset()
      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Sent'
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className={Style.STContainer}>
      <div className={Style.STHeader}>
        <span className={Style.STHeader__Title}>Contact Us</span>
        <span className={Style.STHeader__Description}>Ask us anything at anytime</span>
      </div>
      <Divider/>
      <div className={Style.STBody}>
        <div className={Style.STForm}>
          {!isAuthenticated?.id && (
            <>
              <Typography variant="meta-bold" color={theme.colors['--color-neutral-theme-300']}>
                Email
              </Typography>
              <Input
                sx={inputSx}
                placeholder="Your email"
                autoComplete="off"
                onChange={(e) => { setGuestEmail(e.target.value) }}
              />
            </>

          )}
          <Typography variant="meta-bold" color={theme.colors['--color-neutral-theme-300']}>Private
            Question / Feedback
          </Typography>
          <Textarea
            placeholder="Leave your opinion"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            autoComplete="off"
            disableResize
            minRows={10}
          />
          <Button disabled={isSubmitting} onClick={handleSubmit}>Send</Button>
        </div>
        <div className={Style.STImage}>
          <img src={ImageTabletsImg} alt="contact us"/>
        </div>
      </div>
    </div>
  )
}
