import { combineReducers } from 'redux'
import { reducer as auth } from './auth'
import { reducer as campaign } from './campaign'
import { reducer as candidate } from './candidate'
import { reducer as guide } from './guide'
import { reducer as layout } from './layout'
import { reducer as notification } from './notification'
import { reducer as payment } from './payment'
import { reducer as profile } from './profile'
import { reducer as theme } from './theme'
import { reducer as vibes } from './vibes'

export const reducers = combineReducers({
  theme,
  auth,
  layout,
  profile,
  guide,
  candidate,
  payment,
  notification,
  campaign,
  vibes
})
