import { parsePhoneNumber } from 'awesome-phonenumber'
import { IUserModel } from 'src/interfaces'
import { getBackgroundColor } from './style.utils'

export const getFullName = (user?: { fullName?: string; firstName?: string; lastName?: string }) => {
  if (!user) {
    return ''
  }

  return user.fullName || [user.firstName, user.lastName].filter(Boolean).join(' ')
}

export const getFirstName = (user?: { fullName?: string; firstName?: string; lastName?: string }) => {
  return user?.firstName || user?.fullName?.split(' ')?.[0] || user?.fullName
}

export const isNotCompleteProfile = (user?: IUserModel) => {
  return !getFullName(user) || !user?.company?.id
}

export const parsePhoneNo = (phoneNo: string, regionCode = 'US') => {
  const pn = parsePhoneNumber(
    phoneNo,
    {
      regionCode
    }
  )

  if (!pn.valid) {
    return phoneNo
  }

  return `+${pn.countryCode} ${pn.number?.national}`
}

export const normalizeFullName = (user?: IUserModel) => {
  const fullName = getFullName(user)

  // make fullname url safety
  return encodeURIComponent(fullName)
}

export const getFirst2Letters = (name?: string) => {
  if (!name) {
    return name
  }

  return name.split(' ').slice(0, 2).map(n => n[0]).join('').toUpperCase()
}

export const getInitials = (name: string, max?: number) => {
  const names = name.split(' ')
  const initials = names
    .map((n) => n[0])
    .join('')
    .toUpperCase()
  return max ? initials.slice(0, max) : initials
}

export const stringAvatar = (name?: string, sx: object = {}) => {
  return {
    sx: {
      ...sx,
      bgcolor: getBackgroundColor(name)
    },
    children: getFirst2Letters(name)
  }
}

export const stringGrayAvatar = (name?: string, sx: object = {}) => {
  return {
    sx: {
      ...sx,
      background: '#DADBE7'
    },
    children: getInitials(name || '', 2)
  }
}
