import { FC, forwardRef } from 'react'
import { useSwiper } from 'swiper/react'

enum ENavAction {
  PREV = 'prev',
  NEXT = 'next'
}

export const SwiperNavButton: FC<any> = forwardRef(function SwiperButton(
  { type = ENavAction.PREV, ...props },
  ref: any
) {
  const swiper = useSwiper()

  return (
    <div
      ref={ref}
      onClick={() =>
        type === ENavAction.PREV ? swiper.slidePrev() : swiper.slideNext()}
      {...props}
    >
      {type}
    </div>
  )
})
