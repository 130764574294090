import { FC } from 'react'

export const Illustration: FC = () => {
  return (
    <svg width="95" height="80" viewBox="0 0 95 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12957_28453)">
        <path d="M93.3085 32.4096L93.2681 32.719L93.4362 32.9819C94.4612 34.5849 94.022 36.5086 92.535 37.9166L91.9984 38.4247L92.4226 39.0298C93.0701 39.9533 93.2959 40.8738 93.2206 41.8149C93.1435 42.7778 92.746 43.8167 92.0514 44.9369L91.7398 45.4394L92.0993 45.9087C92.9968 47.0805 93.1557 48.1607 92.898 49.0637C92.6325 49.9939 91.8808 50.8877 90.6712 51.5567C88.2522 52.8946 84.2036 53.1971 79.8992 51.0852L79.4229 50.8515L79.0016 51.174C75.0014 54.2365 69.4415 54.9824 62.638 54.015L62.4158 53.9834L62.2067 54.0649L46.322 60.2532L46.322 60.2532L12.7061 73.3521L1.44601 43.9293L45.2417 32.6456L45.2418 32.6456L53.9002 30.4157L54.0908 30.3666L54.2409 30.2391C56.7912 28.0734 61.0959 25.6821 65.9963 23.1905L66.1095 23.1329L66.2012 23.0449C67.0771 22.2038 67.9516 20.934 68.8192 19.4115C69.6937 17.8769 70.591 16.0321 71.4998 13.9998C73.1252 10.3651 74.81 6.07959 76.4862 1.81593C76.5587 1.6316 76.6312 1.44731 76.7036 1.26311C77.8873 2.07581 78.6756 3.03157 79.1744 4.09336C79.7879 5.39923 79.9933 6.92989 79.8754 8.66699C79.6372 12.1751 78.1016 16.3076 76.3175 20.6623L75.7706 21.9973L77.2044 21.8378C83.2269 21.1679 87.4846 22.1806 90.0943 24.1201C92.661 26.0276 93.7641 28.9279 93.3085 32.4096Z" stroke="#181920" strokeWidth="1.71231"/>
        <path d="M82.3002 35.6258C80.8032 35.6258 79.3063 35.3377 77.9063 34.7615C75.5909 33.8119 74.8156 32.5316 74.6002 32.0301C74.2448 31.2299 74.4602 29.6402 75.3648 28.5625C76.0756 27.709 77.0663 27.3676 78.1648 27.613L81.5248 28.4452L86.4786 25.415L86.8125 25.9592L81.6217 29.128L78.0032 28.2318C76.9263 27.9971 76.2371 28.4985 75.8494 28.9573C75.1386 29.8109 74.9125 31.1446 75.1817 31.7634C75.3756 32.2009 76.054 33.2998 78.154 34.164C80.3402 35.0709 82.8063 35.231 85.0894 34.6122L94.0602 32.2008L94.2325 32.8197L85.2617 35.231C84.2925 35.4977 83.3017 35.6258 82.3002 35.6258Z" fill="#181920"/>
        <path d="M81.7092 41.5893C80.8262 41.5893 80.0292 41.4826 79.3939 41.2372C75.6785 39.7861 73.7831 36.9801 73.5892 35.7957C73.4169 34.6968 75.0108 33.0857 75.1938 32.9043L75.6462 33.3631C75.0431 33.9499 74.1277 35.1129 74.2246 35.6997C74.3538 36.4786 75.9154 39.1993 79.6308 40.6397C83.2169 42.0374 92.8985 38.2711 92.9954 38.2284L93.2323 38.8259C92.9092 38.9646 86.1569 41.5893 81.7092 41.5893Z" fill="#181920"/>
        <path d="M84.0571 47.191C82.5709 47.191 81.0525 47.031 79.6094 46.6255C73.6109 44.9077 72.1786 43.8194 71.7371 42.5178C71.4786 41.7709 71.7694 40.4799 72.7063 39.6263C73.2986 39.0822 74.6232 38.314 76.8848 39.2742L76.6263 39.8611C74.7956 39.0929 73.7186 39.5623 73.1371 40.0958C72.3725 40.8 72.1786 41.8349 72.3402 42.315C72.6956 43.35 74.0956 44.3849 79.7817 46.0067C85.3494 47.5965 92.5971 45.1105 92.6617 45.0891L92.8771 45.6973C92.6617 45.772 88.5371 47.191 84.0571 47.191Z" fill="#181920"/>
        <path d="M79.3615 52.1422C77.1107 50.8512 74.1384 47.9704 74.4184 46.21C74.4938 45.7512 74.8061 45.1537 75.9046 44.9189L76.0446 45.5484C75.1507 45.7405 75.0754 46.1779 75.0538 46.3167C74.8492 47.6183 77.4338 50.307 79.6846 51.5981L79.3615 52.1422Z" fill="#181920"/>
        <path d="M69.3019 42.8274L69.205 42.1979C72.2419 41.7391 73.6203 35.9136 74.4388 32.4353L74.5788 31.8271L75.2034 31.9765L75.0634 32.5847C74.148 36.447 72.7696 42.2939 69.3019 42.8274Z" fill="#181920"/>
        <path d="M45.5131 31.7734C43.5423 41.3653 44.0485 51.1386 46.6331 61.0506L0.153076 79.9996L0.303845 43.3392L45.0285 31.8161L45.5131 31.7734Z" fill="#F7FB6F"/>
        <g clipPath="url(#clip1_12957_28453)">
          <path d="M13.795 8.87831V8.27715C13.795 8.13842 13.7012 8.04594 13.5606 8.04594H11.4511C11.3104 8.04594 11.2167 7.95345 11.2167 7.86097V5.78004C11.2167 5.64131 11.1229 5.54883 11.0292 5.54883H10.4197C10.2791 5.54883 10.1854 5.64131 10.1854 5.78004V7.86097C10.1854 7.99969 10.0916 8.09218 9.95096 8.09218H7.88832C7.74769 8.04594 7.65393 8.13842 7.65393 8.27715V8.87831C7.65393 9.01704 7.74769 9.10952 7.88832 9.10952H9.99784C10.1385 9.10952 10.2322 9.20201 10.2322 9.34073V11.4217C10.2322 11.5604 10.326 11.6529 10.4666 11.6529H11.076C11.2167 11.6529 11.3104 11.5604 11.3104 11.4217V9.29449C11.3104 9.15576 11.4042 9.06328 11.5448 9.06328H13.6543C13.7012 9.06328 13.795 8.97079 13.795 8.87831Z" fill="#00D1E0"/>
        </g>
        <g clipPath="url(#clip2_12957_28453)">
          <path d="M71.8768 72.7147V72.0264C71.8768 71.8675 71.7695 71.7616 71.6084 71.7616H69.193C69.0319 71.7616 68.9246 71.6557 68.9246 71.5498V69.1671C68.9246 69.0082 68.8172 68.9023 68.7099 68.9023H68.0121C67.851 68.9023 67.7437 69.0082 67.7437 69.1671V71.5498C67.7437 71.7087 67.6363 71.8146 67.4753 71.8146H65.1135C64.9524 71.7616 64.8451 71.8675 64.8451 72.0264V72.7147C64.8451 72.8736 64.9524 72.9795 65.1135 72.9795H67.529C67.69 72.9795 67.7973 73.0854 67.7973 73.2442V75.627C67.7973 75.7858 67.9047 75.8917 68.0657 75.8917H68.7635C68.9246 75.8917 69.0319 75.7858 69.0319 75.627V73.1913C69.0319 73.0324 69.1393 72.9265 69.3003 72.9265H71.7158C71.7695 72.9265 71.8768 72.8206 71.8768 72.7147Z" fill="#00D1E0"/>
        </g>
        <g clipPath="url(#clip3_12957_28453)">
          <path d="M10.9359 32.0204V31.332C10.9359 31.1732 10.8285 31.0673 10.6675 31.0673H8.25203C8.091 31.0673 7.98365 30.9614 7.98365 30.8555V28.4728C7.98365 28.3139 7.87629 28.208 7.76894 28.208H7.07113C6.9101 28.208 6.80275 28.3139 6.80275 28.4728V30.8555C6.80275 31.0143 6.69539 31.1202 6.53436 31.1202H4.17256C4.01153 31.0673 3.90417 31.1732 3.90417 31.332V32.0204C3.90417 32.1792 4.01153 32.2851 4.17256 32.2851H6.58804C6.74907 32.2851 6.85643 32.391 6.85643 32.5499V34.9326C6.85643 35.0915 6.96378 35.1974 7.12481 35.1974H7.82262C7.98365 35.1974 8.091 35.0915 8.091 34.9326V32.4969C8.091 32.3381 8.19836 32.2322 8.35939 32.2322H10.7749C10.8285 32.2322 10.9359 32.1263 10.9359 32.0204Z" fill="#32C37A"/>
        </g>
        <g clipPath="url(#clip4_12957_28453)">
          <path d="M66.7204 9.47811V9.01921C66.7204 8.91331 66.6488 8.84271 66.5415 8.84271H64.9312C64.8238 8.84271 64.7522 8.77211 64.7522 8.70151V7.11302C64.7522 7.00712 64.6807 6.93652 64.6091 6.93652H64.1439C64.0365 6.93652 63.965 7.00712 63.965 7.11302V8.70151C63.965 8.80741 63.8934 8.87801 63.7861 8.87801H62.2115C62.1042 8.84271 62.0326 8.91331 62.0326 9.01921V9.47811C62.0326 9.58401 62.1042 9.65461 62.2115 9.65461H63.8218C63.9292 9.65461 64.0008 9.72521 64.0008 9.83111V11.4196C64.0008 11.5255 64.0723 11.5961 64.1797 11.5961H64.6449C64.7522 11.5961 64.8238 11.5255 64.8238 11.4196V9.79581C64.8238 9.68991 64.8954 9.61931 65.0027 9.61931H66.6131C66.6488 9.61931 66.7204 9.54871 66.7204 9.47811Z" fill="#00D1E0"/>
        </g>
        <path d="M27.4239 25.4332C27.4239 26.2264 26.7688 26.8892 25.9361 26.8892C25.1034 26.8892 24.4484 26.2264 24.4484 25.4332C24.4484 24.6401 25.1034 23.9773 25.9361 23.9773C26.7688 23.9773 27.4239 24.6401 27.4239 25.4332Z" stroke="#72E3AD" strokeWidth="1.71231"/>
        <path d="M92.1153 13.8727C92.1153 14.6658 91.4603 15.3287 90.6275 15.3287C89.7948 15.3287 89.1398 14.6658 89.1398 13.8727C89.1398 13.0795 89.7948 12.4167 90.6275 12.4167C91.4603 12.4167 92.1153 13.0795 92.1153 13.8727Z" stroke="#72E3AD" strokeWidth="1.71231"/>
        <mask id="path-14-inside-1_12957_28453" fill="white">
          <ellipse cx="84.5334" cy="65.6646" rx="1.40634" ry="1.38728"/>
        </mask>
        <path d="M84.2274 65.6646C84.2274 65.4632 84.3865 65.3396 84.5334 65.3396V68.7642C86.2336 68.7642 87.652 67.3984 87.652 65.6646H84.2274ZM84.5334 65.3396C84.6802 65.3396 84.8393 65.4632 84.8393 65.6646H81.4147C81.4147 67.3984 82.8331 68.7642 84.5334 68.7642V65.3396ZM84.8393 65.6646C84.8393 65.8661 84.6802 65.9897 84.5334 65.9897V62.565C82.8331 62.565 81.4147 63.9308 81.4147 65.6646H84.8393ZM84.5334 65.9897C84.3865 65.9897 84.2274 65.8661 84.2274 65.6646H87.652C87.652 63.9308 86.2336 62.565 84.5334 62.565V65.9897Z" fill="#72E3AD" mask="url(#path-14-inside-1_12957_28453)"/>
        <mask id="path-16-inside-2_12957_28453" fill="white">
          <ellipse cx="46.7967" cy="7.16733" rx="1.64074" ry="1.6185"/>
        </mask>
        <path d="M46.7252 7.16733C46.7252 7.15414 46.7282 7.13779 46.7348 7.12253C46.7408 7.10848 46.7482 7.09893 46.7544 7.09275C46.7673 7.08001 46.7851 7.07351 46.7967 7.07351V10.4981C48.6265 10.4981 50.1498 9.02884 50.1498 7.16733H46.7252ZM46.7967 7.07351C46.8084 7.07351 46.8261 7.08001 46.8391 7.09275C46.8453 7.09893 46.8527 7.10848 46.8587 7.12253C46.8652 7.13779 46.8683 7.15414 46.8683 7.16733H43.4437C43.4437 9.02884 44.967 10.4981 46.7967 10.4981V7.07351ZM46.8683 7.16733C46.8683 7.18051 46.8652 7.19686 46.8587 7.21212C46.8527 7.22617 46.8453 7.23572 46.8391 7.2419C46.8261 7.25464 46.8084 7.26114 46.7967 7.26114V3.83651C44.967 3.83651 43.4437 5.30581 43.4437 7.16733H46.8683ZM46.7967 7.26114C46.7851 7.26114 46.7673 7.25464 46.7544 7.2419C46.7482 7.23572 46.7408 7.22617 46.7348 7.21212C46.7282 7.19686 46.7252 7.18051 46.7252 7.16733H50.1498C50.1498 5.30581 48.6265 3.83651 46.7967 3.83651V7.26114Z" fill="#32C37A" mask="url(#path-16-inside-2_12957_28453)"/>
        <path d="M46.644 73.0636C46.644 74.1122 45.7791 74.982 44.6874 74.982C43.5958 74.982 42.7309 74.1122 42.7309 73.0636C42.7309 72.0151 43.5958 71.1452 44.6874 71.1452C45.7791 71.1452 46.644 72.0151 46.644 73.0636Z" stroke="#32C37A" strokeWidth="1.71231"/>
      </g>
      <defs>
        <clipPath id="clip0_12957_28453">
          <rect width="94.6939" height="80" fill="white" transform="translate(0.153076)"/>
        </clipPath>
        <clipPath id="clip1_12957_28453">
          <rect width="6.14104" height="6.0578" fill="white" transform="translate(7.65369 5.54883)"/>
        </clipPath>
        <clipPath id="clip2_12957_28453">
          <rect width="7.03172" height="6.93642" fill="white" transform="translate(64.8452 68.9023)"/>
        </clipPath>
        <clipPath id="clip3_12957_28453">
          <rect width="7.03172" height="6.93642" fill="white" transform="translate(3.90381 28.208)"/>
        </clipPath>
        <clipPath id="clip4_12957_28453">
          <rect width="4.68782" height="4.62428" fill="white" transform="translate(62.0326 6.93652)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
