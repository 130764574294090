import { MenuItem } from '@mui/material'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { StyledMenu } from 'src/components'
import { ELocationType } from 'src/enums'
import { IconArrowDown } from 'src/icons'
import Style from './style.module.scss'

interface IOption {
  label: string
  value: ELocationType
  description: string
}

const OPTIONS: IOption[] = [
  {
    label: 'On-site',
    description: 'Employees come to work in-person.',
    value: ELocationType.ON_SITE
  },
  {
    label: 'Hybrid',
    description: 'Employees work on-site and off-site.',
    value: ELocationType.HYBRID
  },
  {
    label: 'Remote',
    description: 'Employees work off-site.',
    value: ELocationType.REMOTE
  }
]

interface IProps {
  value?: ELocationType
  onChange: (value: ELocationType) => void
}

export const WorkplaceType: FC<IProps> = (props) => {
  const text = OPTIONS.find((option) => option.value === props.value)?.label

  const recentElWidthRef = useRef<number | undefined>(undefined)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const buttonWidth = useMemo(() => {
    if (anchorEl?.offsetWidth) {
      recentElWidthRef.current = anchorEl.offsetWidth
    }

    return recentElWidthRef.current
  }, [anchorEl])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnClickItem = useCallback((option: IOption) => {
    props.onChange(option.value)
    handleClose()
  }, [handleClose, props])

  return (
    <div className={Style.container}>
      <span className={Style.title}>Workplace Type</span>
      <div
        className={Style.buttonSelect}
        onClick={handleButtonClick}
      >
        <span className="body1-regular color-neutral-theme-900">{text}</span>
        <IconArrowDown/>
      </div>

      <StyledMenu
        width={buttonWidth}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={Style.menu}
      >
        {OPTIONS.map((option, index) => (
          <MenuItem onClick={() => handleOnClickItem(option)} disableRipple className={Style.menuItem} key={index}>
            <span className="body2-regular color-neutral-theme-700">{option.label}</span>
            <span className="meta-regular color-neutral-theme-300 whitespace-normal">{option.description}</span>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
