import { Subject } from 'rxjs'
import { IUserModel } from 'src/interfaces'
import { eventsCollection } from './firebase'

export enum EEventName {
  NEW_COMMENT = 'NEW_COMMENT',
  TYPING_COMMENT = 'TYPING_COMMENT'
}

export interface IEven {
  name: string
  data: Record<string, string | number | boolean>
}

export const EventService = new (
  class {
    private email: IUserModel['email']
    private unsubscribe() {}

    readonly event$ = new Subject<IEven>()

    bind(email: IUserModel['email']) {
      if (this.email === email) {
        return this.unbind
      }

      this.email = email
      const mailDomain = this.email?.split('@').pop()
      if (!mailDomain) {
        return
      }

      this.unsubscribe = eventsCollection
        .where('mailDomain', '==', mailDomain)
        .where('createdAt', '>=', Date.now())
        .orderBy('createdAt', 'asc')
        .limitToLast(1)
        .onSnapshot((snapshot) => {
          for (const changed of snapshot.docChanges()) {
            if (changed.type === 'added') {
              const event = changed.doc.data() as IEven
              this.event$.next(event)
            }
          }
        })
    }

    unbind() {
      this.unsubscribe()
      this.email = undefined
    }
  }
)()
