import { ButtonProps, StyledEngineProvider } from '@mui/material'
import { omit } from 'lodash'
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useMemo, useRef } from 'react'
import { Spinner } from '../spinner'
import { ButtonBlack, ButtonDanger, ButtonDangerOutlined, ButtonOutlineBlack, ButtonPrimary, ButtonSecondary, ButtonTertiary } from './styled'

export interface IButtonProps extends ButtonProps {
  order?: 'primary' | 'secondary' | 'tertiary' | 'danger-outlined' | 'danger' | 'black' | 'outline-black'
  field?: 'button' | 'input'
  width?: number
  height?: number
  background?: string
  accept?: 'video/*;image/*' | 'video/*' | 'image/*' | 'image/jpeg,image/png' | 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  colorText?: string
  loading?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
  event?: () => void
  kmSize?: 'medium' | 'large'
}

export const Button: FC<IButtonProps & ButtonProps<'span', { component?: 'span' }>> = ({ width, height, kmSize = 'large', accept, order, event, loading, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.handleChange && props.handleChange(e)

    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
  }

  const handleClick = useCallback(() => {
    if (props.field === 'input') {
      event && event()
      return inputRef.current?.click()
    }
  }, [props.field, event])

  const ButtonOrder = useMemo(() => {
    switch (order) {
      case 'secondary':
        return ButtonSecondary
      case 'tertiary':
        return ButtonTertiary
      case 'danger-outlined':
        return ButtonDangerOutlined
      case 'danger':
        return ButtonDanger
      case 'black':
        return ButtonBlack
      case 'outline-black':
        return ButtonOutlineBlack
      default:
        return ButtonPrimary
    }
  }, [order])

  return (
    <StyledEngineProvider injectFirst>
      <ButtonOrder
        width={width}
        height={height}
        onClick={handleClick}
        kmSize={kmSize}
        {...omit(props, 'handleChange')}
      >
        {props.children}
        {loading && <Spinner size={20} className="ml-2"/>}
      </ButtonOrder>

      {props.field === 'input' && (
        <input
          type="file"
          ref={inputRef}
          accept={accept || 'video/*;image/*'}
          style={{ display: 'none' }}
          onChange={onChange}
        />
      )}
    </StyledEngineProvider>
  )
}

Button.defaultProps = {
  order: 'primary',
  field: 'button',
  height: 56
}
