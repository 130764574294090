import { Typography, useTheme } from '@mui/material'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { IconEmptyVibes } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import { STButton, STContainer, STContent, STTextNoVibe } from './styled'

export const EmptyVibes: FC = () => {
  const theme = useTheme()
  const history = useHistory()

  const goToLikes = useCallback(() => {
    history.push(generate(ERoutes.LIKES))
  }, [history])

  return (
    <STContainer>
      <STContent>
        <IconEmptyVibes/>
        <STTextNoVibe>
          <Typography variant="h5" color={theme.colors['--color-neutral-theme-250']}>
            You've seen all potential candidates
          </Typography>
          <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-250']}>
            Go to “Likes” and get introduced to candidates you liked
          </Typography>

          <STButton onClick={goToLikes}>Go to Likes</STButton>
        </STTextNoVibe>
      </STContent>
    </STContainer>
  )
}
