import { useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { NotificationService } from 'src/services'
import { useUnsubscribe } from './useUnsubscribe'

export const useNotificationNewCandidateIds = (key: 'likeList' | 'introList') => {
  const unsubscribe$ = useUnsubscribe()
  const [newCandidateIds, setNewCandidateIds] = useState<number[]>([])

  useEffect(() => {
    NotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        if (Array.isArray(data[key])) {
          const arrayNotification = data[key] as number[] || []
          setNewCandidateIds(arrayNotification)
        }
      })
  }, [key, unsubscribe$])

  return newCandidateIds
}
