import { Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import Style from './style.module.scss'

interface IEmptyCampaignProps {
  placeholder: string
  cover: string
  className?: string
}

export const Empty: FC<IEmptyCampaignProps> = ({ placeholder, cover, className }) => {
  const theme = useTheme()

  return (
    <div className={clsx([Style.STContainer, className])}>
      <img src={cover} alt="card"/>
      <Typography
        variant="h5"
        color={theme.colors['--color-neutral-theme-250']}
        textAlign="center"
      >
        {placeholder}
      </Typography>
    </div>
  )
}
