import { IVideoModel } from './models'

export enum EAuthView {
  EMAIL_REGISTER = 'EMAIL_REGISTER',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  EMAIL_VERIFIED_SUCCESS = 'EMAIL_VERIFIED_SUCCESS',
  PASSWORD = 'PASSWORD',
  ROLE = 'ROLE',
  INFORMATION = 'INFORMATION',
  SUCCESS = 'SUCCESS',
  NAME = 'NAME'
}

export const ProgressStep: Record<string, string> = {
  [EAuthView.EMAIL_VERIFIED_SUCCESS]: 'Verify your email address',
  [EAuthView.PASSWORD]: 'Setup password'
}

export const ProgressStepMobile: Record<string, string> = {
  ...ProgressStep,
  [EAuthView.EMAIL_VERIFIED_SUCCESS]: 'Email verified'
}

export interface ISettingsProfile {
  companyName?: string
  companyUrls?: string[]
  scheduleUrl?: string
  bio?: string
  avatar?: {
    src?: string
    file?: File | null
  }
  video?: IVideoModel
}
