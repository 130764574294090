import { Chip } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { CardReactionAndResume, EmptyVideos, FlipCard, FlipCardBack, Share, VideoPlayer, useAnalytic } from 'src/components'
import { CacheProvider } from 'src/components/video-player/cache.context'
import {
  ECandidateInterviewState,
  ECandidateReaction,
  EMessage,
  ETrackingEvent
} from 'src/enums'
import { useAppDispatch, useAppSelector, useNotificationNewCandidateIds, useNumberOfSkeletons, useQueryParams, useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IPaginationResponse } from 'src/interfaces'
import { OverlayService, SnackbarService } from 'src/services'
import { setLayoutAside, setLayoutPageComponent, setLayoutPageTitle } from 'src/store/actions'
import { getLayoutIsScrollToBottom } from 'src/store/selectors'
import { ensureArray, getApiErrorMessage, getVideoSource } from 'src/utils'
import { IntrosDetail } from '../detail'
import { Empty } from '../empty'
import Style from './style.module.scss'
import { STContainer, STFlipFront, STIntrosItem } from './styled'

export const Intros: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const numberOfLoadingVideos = useNumberOfSkeletons()
  const { eventHandler } = useAnalytic()
  const { detailCandidateId: paramDetailCandidateId, detailTab: paramDetailTab } = useQueryParams()
  const newCandidateIds = useNotificationNewCandidateIds('introList')
  const watchedTimeMapRef = useRef<Map<number, number>>(new Map())
  const flippedMapRef = useRef<Map<number, boolean>>(new Map())
  const introItemRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IPaginationResponse<ICandidateModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })
  const [currOpenedTraitId, setCurrOpenedTraitId] = useState(0)
  const [detailCandidateId, setDetailCandidateId] = useState<number>()
  const [detailTab, setDetailTab] = useState<number>()
  const [lastLikeOffsetY, setLastLikeOffsetY] = useState<number>()
  const containerRef = useRef<any>()

  useEffect(() => {
    setDetailCandidateId(
      paramDetailCandidateId
        ? +paramDetailCandidateId
        : undefined
    )
  }, [paramDetailCandidateId])

  useEffect(() => {
    setDetailTab(+(paramDetailTab || 0))
  }, [paramDetailTab])

  const goToDetail = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number, tabId = 0) => {
    setLastLikeOffsetY(containerRef.current.parentElement.scrollTop)
    history.push({ search: `?detailCandidateId=${id}&detailTab=${tabId}` })
  }

  const handleBeforeGoToDetail = useCallback(() => {
    setLastLikeOffsetY(containerRef.current?.parentElement?.scrollTop)
  }, [containerRef])

  useEffect(() => {
    if (!detailCandidateId && containerRef.current) {
      containerRef.current.parentElement?.scrollTo(0, lastLikeOffsetY || 0)
    }
  }, [detailCandidateId, lastLikeOffsetY])

  const handleOpenShare = (videoId: number, candidateId: number) => {
    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={videoId} candidateId={candidateId}/>
    })
  }

  const loadIntros = useCallback((page = 1, limit = 10) => {
    if (page === 1) {
      setData({
        page: 1,
        pages: 1,
        total: 0,
        rows: []
      })
    }

    const promise = CandidateApi.interview({
      page,
      limit,
      state: ECandidateInterviewState.INTRO
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        const rows = data.rows
        // const rows = data.rows?.map(item => ({
        //   ...item,
        //   linkedinWorkingExperiences: LinkedinUtil.checkValidData(item.linkedinWorkingExperiences)
        // }))

        setData(prev => ({
          ...prev,
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: page === 1 ? rows : [...ensureArray(prev.rows), ...ensureArray(rows)]
        }))
      })
  }, [unsubscribe$])

  const setPageMetaData = useCallback(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutPageTitle('Intros'))
    dispatch(setLayoutPageComponent(null))
  }, [dispatch])

  const handleBackToList = useCallback(() => {
    setDetailCandidateId(undefined)
    history.push({ search: '' })
    setPageMetaData()
  }, [history, setPageMetaData])

  useEffect(() => {
    return setPageMetaData()
  }, [setPageMetaData])

  useEffect(() => {
    loadIntros()
  }, [loadIntros])

  const loadMorePage = useMemo(
    () => !loading && data.page && data.pages && data.page < data.pages ? data.page + 1 : false,
    [loading, data]
  )
  useEffect(() => {
    if (!detailCandidateId && loadMorePage && isScrollToBottom) {
      loadIntros(loadMorePage)
    }
  }, [detailCandidateId, isScrollToBottom, loadIntros, loadMorePage])

  const playedMapRef = useRef<Map<number, boolean>>(new Map([]))
  const handleWatchedVideo = useCallback(async (videoId: number, playing: boolean) => {
    try {
      if (!playing) {
        return
      }

      if (playedMapRef.current.get(videoId)) {
        return
      }

      playedMapRef.current.set(videoId, true)
      await CandidateApi.play(videoId)
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'Network Interruption'
      })
    }
  }, [])

  const handleTouch = async (candidateId: number) => {
    try {
      await CandidateApi.touch(candidateId, 'interview')
    } catch (error) {
      console.log('error to touch candidate', error)
    }
  }

  const handleFlipButtonClick = (flipFunc: () => void, candidateId: number, videoId?: number) => {
    flipFunc()

    if (videoId) {
      const isFlipped = flippedMapRef.current.get(videoId)
      flippedMapRef.current.set(videoId, !isFlipped)
    }

    eventHandler({
      key: ETrackingEvent.BTN_VIDEO_INFO,
      contextData: { videoId }
    }, () => {
      if (newCandidateIds.includes(candidateId)) {
        handleTouch(candidateId)
      }
    })()
  }

  const handelProgressVideo = (videoId: number, playedSecond: number) => {
    watchedTimeMapRef.current.set(videoId, playedSecond)
  }

  // const handleToggleTraitCollapse = (traitId: number) => {
  //   if (traitId === currOpenedTraitId) {
  //     setCurrOpenedTraitId(0)
  //     return
  //   }
  //   setCurrOpenedTraitId(traitId)
  // }

  return (
    <>
      {!data.rows?.length
        ? loading ? <EmptyVideos quantity={numberOfLoadingVideos} variant="intro"/> : <Empty/>
        : (
          <div
            ref={containerRef}
            className={clsx(Style.STContainerWrapper, { [Style.showDetail]: detailCandidateId })}
          >
            {!detailCandidateId && (
              <STContainer>
                {data.rows?.map(item => {
                  const isSuperNova = item.video?.createdReaction?.reaction === ECandidateReaction.SUPER_NOVA

                  return (
                    <STIntrosItem
                      key={item.id}
                      isSuperNova={isSuperNova}
                      {...(item?.video?.videoTranscription ? { ref: introItemRef } : {})}
                    >
                      <FlipCard flipped={flippedMapRef.current.get(item.video.id)}>
                        {(flipFunc) => [
                          <STFlipFront key={'front_' + item.id}>
                            <div className={Style.STVideoWrapper}>
                              <div
                                className={clsx(
                                  Style.videoContainer
                                )}
                              >
                                <VideoPlayer
                                  // always tell the VideoPlayer give a space for linkedin icon, which we now use it to show the flip button
                                  // isLinkedin={!!item.linkedinWorkingExperiences?.length && !!item.linkedinEducations?.length}
                                  videoId={item?.video?.id}
                                  tracks={item?.video?.tracks}
                                  trackingEvent
                                  image={item?.video?.urlVideoImageThumbnail}
                                  animatedImage={item?.video?.urlVideoAnimatedImage}
                                  url={getVideoSource(item?.video)}
                                  style={{ borderRadius: '16px 16px 0 0' }}
                                  isMuted={false}
                                  mini
                                  author={item?.video?.author}
                                  onBeforeGoToDetail={handleBeforeGoToDetail}
                                  hideStartEnd
                                  isStop={currOpenedTraitId === item.video.id}
                                  mimeType={item?.video?.internalSourceMetadata?.mimeType}
                                  onProgress={({ playedSeconds }) => handelProgressVideo(item.video.id, playedSeconds)}
                                  playAt={watchedTimeMapRef.current.get(item.video.id)}
                                  onPlayingChange={(playing) => {
                                    handleWatchedVideo(item.video.id, playing)
                                    if (newCandidateIds.includes(item.id) && playing) {
                                      handleTouch(item.id)
                                    }

                                    if (playing && currOpenedTraitId === item.video.id) {
                                      setCurrOpenedTraitId(0)
                                    }
                                  }}
                                  action={(
                                    <CardReactionAndResume
                                      item={item}
                                      newCandidateIds={newCandidateIds}
                                      onTouch={() => handleTouch(item.id)}
                                      onFlip={() => handleFlipButtonClick(flipFunc, item.id, item.video?.id)}
                                      onShare={handleOpenShare}
                                    />
                                  )}
                                  showSpeed
                                />
                                {newCandidateIds.includes(item.id) && (
                                  <Chip className={Style.STNewButton} label="New"/>
                                )}
                              </div>
                            </div>
                          </STFlipFront>,
                          <FlipCardBack
                            key={'back_' + item.id}
                            onDetailClick={(e) => goToDetail(e, item.id)}
                            onFlip={() => { flipFunc(); flippedMapRef.current.set(item.video.id, false) }}
                            educations={item.linkedinEducations}
                            workingExperiences={item.linkedinWorkingExperiences}
                            video={item.video}
                            author={item}
                          />
                        ]}
                      </FlipCard>
                    </STIntrosItem>
                  )
                })}
              </STContainer>
            )}
            <CacheProvider data={watchedTimeMapRef.current}>
              {!!detailCandidateId && <IntrosDetail tab={detailTab} id={detailCandidateId} onBack={handleBackToList}/>}
            </CacheProvider>
          </div>
        )}
    </>
  )
}
