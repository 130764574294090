import { styled } from '@mui/material'

export const STComment = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'Comment'
})(({ theme }) => `
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
`)

export const STCommentItem = styled('div', {
  label: 'CommentItem'
})(({ theme }) => `
    margin-top: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(0, 3, 0)};

    &:first-of-type {
      padding-bottom: 8px !important;
    }
`)

export const STLeft = styled('div', {
  label: 'Left'
})(({ theme }) => `
    display: flex;
    gap: ${theme.spacing(2)}
`)

export const STCommentSection = styled('div', {
  label: 'CommentSection'
})(({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  flex-grow: 1;
`)

export const STSend = styled('div', { label: 'Send' })(({ theme }) => `
  align-items: flex-end;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`)

export const STAction = styled('div', {
  label: 'Action'
})(({ theme }) => `
    border-top: 1px solid ${theme.colors['--color-neutral-theme-200']};
    padding: ${theme.spacing(1.5, 2, 1)};
    display: flex;
    flex-direction: column;
`)

export const STLikeBox = styled('div', {
  label: 'LikeBox'
})(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing(2.5)};

    svg {
      width: 25px;
      height: 30px;
    }
`)
