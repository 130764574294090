import { Box, ButtonBase, SxProps, Typography, useTheme } from '@mui/material'
import * as Popover from '@radix-ui/react-popover'
import { FC, useMemo } from 'react'

import clsx from 'clsx'
import { IconWrapper } from 'src/components'
import { ECandidateReaction } from 'src/enums'
import { IconArchive, IconLikesCyan, IconLikesFull, IconShareFilled, IconSuperLikeFull, IconSuperLikeOrange, IconSuperNova } from 'src/icons'
import Style from './style.module.scss'

const _boxSx = { display: 'flex', gap: '8px' }
const buttonBaseSx = { borderRadius: '40px' }

interface IProps {
  isSuperLike?: boolean
  isLike?: boolean
  isSuperNova?: boolean
  withText?: boolean
  onShareClick?: () => void
  onReactionChange?: (reaction: ECandidateReaction) => void
  sx?: SxProps
}

export const ReactionAndShare: FC<IProps> = ({ sx, isSuperLike, isLike, isSuperNova, withText, onShareClick, onReactionChange }) => {
  const theme = useTheme()

  const boxSx = useMemo(() => {
    return {
      ..._boxSx,
      ...(sx || {})
    }
  }, [sx])

  return (
    <Box sx={boxSx}>
      <Popover.Root>
        <Popover.Trigger asChild>
          <ButtonBase sx={buttonBaseSx}>
            {isSuperLike && (
              <div className={clsx(Style.STSuperLike, { [Style.STSuperLike_withText]: withText })}>
                <Box><IconSuperLikeOrange/></Box>
                {withText ? <Typography variant="body2-bold">Superlike</Typography> : null}
              </div>
            )}

            {isLike && (
              <div className={clsx(Style.STLike, { [Style.STLike_withText]: withText })}>
                <Box><IconLikesCyan/></Box>
                {withText ? <Typography variant="body2-bold">Liked</Typography> : null}
              </div>
            )}
          </ButtonBase>
        </Popover.Trigger>
        {onReactionChange
          ? (
            <Popover.Portal>
              <Popover.Content style={{ zIndex: 99999 }} side="top">
                <div className={Style.STPopper}>
                  <Popover.Close asChild>
                    <IconWrapper
                      width={48}
                      height={48}
                      color={theme.colors['--color-neutral-theme-50']}
                      background={theme.colors['--color-neutral-theme-300']}
                      onClick={() => onReactionChange?.(ECandidateReaction.SKIP)}
                    >
                      <IconArchive/>
                    </IconWrapper>
                  </Popover.Close>
                  <Popover.Close asChild>
                    <IconWrapper
                      width={48}
                      height={48}
                      color={theme.colors['--color-neutral-theme-50']}
                      background={theme.colors['--color-cyan-500']}
                      onClick={() => onReactionChange?.(ECandidateReaction.LIKE)}
                    >
                      <IconLikesFull/>
                    </IconWrapper>
                  </Popover.Close>
                  <Popover.Close asChild>
                    <IconWrapper
                      width={48}
                      height={48}
                      background={theme.colors['--color-orange-500']}
                      onClick={() => onReactionChange?.(ECandidateReaction.SUPER_LIKE)}
                    >
                      <IconSuperLikeFull/>
                    </IconWrapper>
                  </Popover.Close>
                </div>
              </Popover.Content>
            </Popover.Portal>
          )
          : null}
      </Popover.Root>

      {isSuperNova && (
        <Box position="relative">

          <div className={clsx(Style.STSuperNova, { [Style.STSuperNova_withText]: withText })}>
            <IconSuperNova/>
            {withText ? <Typography variant="body2-bold">Supernova</Typography> : null}
          </div>
        </Box>
      )}

      <div className={Style.STButtonShare} onClick={onShareClick}>
        <IconShareFilled/>
        <Typography variant="body2-bold">Share</Typography>
      </div>
    </Box>
  )
}
