import { Workbox } from 'workbox-window'
import { EEnv } from './constants'

export function register() {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if (EEnv.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js')

    wb.addEventListener('installed', event => {
      /**
       * We have the condition — event.isUpdate because we don’t want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        window.postMessage('hadNewUpdate', window.location.origin)
      }
    })
    wb.register()
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
