import { Box } from '@mui/material'
import { FC, FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { IconClose, IconEmail } from 'src/icons'
import Styles from './style.module.scss'

const CustomScrollContainer = ScrollContainer as any

const borderStyleObj = {
  borderColor: 'transparent',
  boxShadow: '0 0 0 1px #80818E'
}

const errorStyleObj = {
  borderColor: 'transparent',
  boxShadow: '0 0 0 1px #EA2E4E'
}

interface IProps {
  domain?: string
  onChange?: (emails: string[]) => void
  error?: string
}

export const EmailInput: FC<IProps> = ({ domain, onChange, error }) => {
  const [emails, setEmails] = useState<string[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const inputWrapperRef = useRef<HTMLInputElement>(null)
  const [emailValue, setEmailValue] = useState<string>('')
  const [containerStyle, setContainerStyle] = useState({})
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true)

  useEffect(() => {
    if (error) {
      setContainerStyle(errorStyleObj)
    }
  }, [error])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onfocus = () => {
        setContainerStyle(borderStyleObj)
        setShowPlaceholder(true)
      }

      inputRef.current.onblur = () => {
        setContainerStyle({})
      }

      return () => {
        if (inputRef.current) {
          inputRef.current.onfocus = null
          // eslint-disable-next-line react-hooks/exhaustive-deps
          inputRef.current.onblur = null
        }
      }
    }
  }, [inputRef, setContainerStyle])

  const handleChange = useCallback((event: FormEvent<HTMLSpanElement>) => {
    setEmailValue((event.target as HTMLSpanElement).innerText)
  }, [setEmailValue])

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLSpanElement>) => {
    const keyCode = event.which || event.keyCode
    if (keyCode === 32) {
      event.preventDefault()
      return
    }

    if (keyCode === 8) {
      if (!emailValue) {
        event.preventDefault()
        setEmails((prev) => prev.slice(0, prev.length - 1))
        return
      }
    }

    // enter or tab
    if ([13, 9].includes(keyCode)) {
      event.preventDefault()
      if (emailValue) {
        if (!emails.includes(`${emailValue}@${domain}`)) {
          setEmails((prev) => [...prev, `${emailValue}@${domain}`])
        }
        setEmailValue('')
        ;(event.target as HTMLSpanElement).innerText = ''

        setTimeout(() => {
          if (inputWrapperRef.current) {
            // inputWrapperRef.current.scrollTo({ left: inputWrapperRef.current.scrollWidth, behavior: 'smooth' })
          }
        }, 100)
      }
    }
  }, [setEmails, setEmailValue, emailValue, emails, domain])

  const handleBlur = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.position = 'absolute'
      inputRef.current.style.width = '100%'
      inputRef.current.style.width = '100%'
    }

    if (emails.length > 0) {
      setShowPlaceholder(false)
    }

    if (emailValue) {
      if (!emails.includes(`${emailValue}@${domain}`)) {
        setEmails((prev) => [...prev, `${emailValue}@${domain}`])
        setShowPlaceholder(false)
      }
      setEmailValue('')
      if (inputRef.current) {
        inputRef.current.innerText = ''
      }
    }
  }, [emailValue, emails, inputRef, domain])

  const handleFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.position = 'static'
      inputRef.current.style.width = 'auto'
      inputRef.current.style.width = 'auto'
    }
  }, [inputRef])

  useEffect(() => {
    if (!emails.length) {
      setShowPlaceholder(true)
    }
    onChange?.(emails)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails])

  return (
    <div className={Styles.STEmailInputContainer} style={containerStyle}>
      <div className={Styles.Icon}>
        <IconEmail/>
      </div>
      <CustomScrollContainer hideScrollbars={false} className={Styles.InputWrapper} innerRef={inputWrapperRef}>
        {emails.map((email, index) => (
          <div key={`${email}_${index}`} className={Styles.STTag}>
            {email}
            <Box
              className={Styles.STClose}
              onMouseDown={(e) => {
                e.stopPropagation()
                setEmails(prevEmails => prevEmails.filter((_, i) => i !== index))
              }}
            >
              <IconClose/>
            </Box>
          </div>
        ))}
        <div style={{ display: 'flex' }}>
          <span
            ref={inputRef}
            contentEditable
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            className={Styles.Input}
          />
          {showPlaceholder
            ? (
              <>
                {emailValue ? null : <span className={Styles.FakeInputPlaceholder}>example</span>}
                <span className={Styles.Domain}>@{domain}</span>
              </>
            )
            : null}
        </div>
      </CustomScrollContainer>
    </div>
  )
}
