import { FC } from 'react'
import { ISubItemProps } from '../../type'

import clsx from 'clsx'
import { NoticeBadge } from 'src/components'
import Style from './style.module.scss'

export const SubItem: FC<ISubItemProps> = ({
  id,
  isActive,
  activeIcon: ActiveIcon,
  icon: Icon,
  title,
  isMinimized,
  noticeBadgeKey
}) => {
  return (
    <div
      id={id}
      className={clsx(
        'group h-12 relative',
        Style.container,
        isActive && 'bg-color-neutral-theme-600',
        isMinimized && Style.containerMinimize
      )}
    >
      {isActive
        ? (
          <ActiveIcon
            width={24}
            className="svg-color-neutral-theme-50 fx-shrink-0"
          />
        )
        : (
          <Icon
            width={24}
            className="group-hover-svg-color-neutral-theme-50 svg-color-neutral-theme-300 fx-shrink-0"
          />
        )}

      <div className={clsx('fx flex-row fx-ai-center fx-jc-space-between fx-1', isMinimized && 'd-none')}>
        <span
          className={clsx(
            isActive ? 'color-neutral-theme-50' : 'color-neutral-theme-300 group-hover-color-neutral-theme-50',
            Style.title
          )}
        >{title}
          {!isMinimized && noticeBadgeKey && (
            <NoticeBadge
              type="small"
              watchKey={noticeBadgeKey}
              notRenderIfZero
            />
          )}
        </span>
      </div>

      {isMinimized && noticeBadgeKey && (
        <div className={Style.minimizedBadge}>
          <NoticeBadge
            type="small"
            watchKey={noticeBadgeKey}
            notRenderIfZero
          />
        </div>
      )}
    </div>
  )
}
