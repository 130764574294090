import { Drawer, IconButton, Typography } from '@mui/material'
import { FC, useCallback, useMemo, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import { Button } from 'src/components'
import { useIfMobileL } from 'src/hooks'
import { IconClose, IconFilePdf } from 'src/icons'
import { shortenFilename } from 'src/utils'
import { ChatGPTSummary } from '../chatgpt-summary'
import Style from './style.module.scss'

interface IProps {
  maxLineSummary: number
  summary?: string
  description?: string
  url?: string | null
  file?: File
  fileName?: string
}

export const JobSummary: FC<IProps> = (props) => {
  const [openingDrawer, setOpeningDrawer] = useState(false)

  const closeDrawer = useCallback(() => setOpeningDrawer(false), [])
  const isMobileL = useIfMobileL()

  const drawerProps = useMemo(() => {
    if (isMobileL) {
      return {
        anchor: 'bottom' as const,
        SlideProps: {
          easing: {
            enter: 'none'
          }
        }
      }
    }

    return {
      anchor: 'right' as const,
      SlideProps: {
        easing: {
          enter: 'none'
        }
      },
      className: Style.drawer,
      PaperProps: {
        sx: {
          width: '50%',
          background: 'transparent'
        }
      }
    }
  }, [isMobileL])

  // const handleExpand = () => {
  //   setReached(false)
  //   setStyle({})
  // }

  const handlePreviewFile = useCallback((props: IProps) => {
    if (props.file?.name) {
      const file = new Blob([props.file], { type: 'application/pdf' })
      const url = URL.createObjectURL(file)

      return window.open(url, '_blank', 'noreferrer')
    } else if (props?.url) {
      return window.open(props.url, '_blank', 'noreferrer')
    }
  }, [])

  const isPdfFile = useMemo(() => props.file?.name?.includes('.pdf') || props.url?.includes('.pdf'), [props.file?.name, props.url])

  const handleOpenJobDescription = useCallback(() => {
    if (isPdfFile && !props?.description) {
      return handlePreviewFile(props)
    }

    setOpeningDrawer(true)
  }, [handlePreviewFile, isPdfFile, props])

  return (
    <div className="fx flex-column gap-18px">
      <div className="fx flex-column gap-2">
        <ChatGPTSummary/>
      </div>

      <div>
        <Button
          fullWidth={false}
          onClick={handleOpenJobDescription}
          order="secondary"
          sx={{ paddingX: '32px' }}
        >Detailed Job Description
        </Button>
      </div>

      <Drawer
        open={openingDrawer}
        onClose={closeDrawer}
        elevation={0}
        {...drawerProps}
      >
        <div className={Style.drawerDescription}>
          <Typography variant="h5" className={Style.aboutTheJob}>About The Job</Typography>

          <IconButton
            className={Style.close}
            onClick={closeDrawer}
          >
            <IconClose/>
          </IconButton>

          {isPdfFile && (
            <div className="pl-15">
              <div className="fx gap-2 fx-ai-center pointer" onClick={() => handlePreviewFile(props)}>
                <IconFilePdf/>
                <span className="body-2 f-medium txt-neutral-700">
                  {props.fileName ? shortenFilename(props.fileName) : 'JobDescription.pdf'}
                </span>
              </div>
            </div>
          )}

          <div
            className={Style.description}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.description || '') }}
          />

          <svg
            onClick={closeDrawer}
            className={Style.buttonCloseDrawer}
            width="71"
            height="70"
            viewBox="0 0 71 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M70 29C70 38.3958 65.9388 48.4241 59.4832 56.1054C53.0257 63.7889 44.2832 69 35 69C25.5677 69 18.4668 66.0841 13.0348 60.4321C7.58467 54.7612 3.73038 46.2488 1.0002 34.8827C1.06346 16.159 16.2614 1 35 1C44.514 1 53.2887 3.38184 59.6569 8.05734C65.9918 12.7084 70 19.6629 70 29Z" fill="white" stroke="#24252D" strokeWidth="2"/>
            <path d="M28.1167 44.7837L39.3759 33.5L28.1167 22.2162L31.5829 18.75L46.3329 33.5L31.5829 48.25L28.1167 44.7837Z" fill="#181920"/>
          </svg>

        </div>
      </Drawer>

    </div>
  )
}
