import { AvatarGroup, styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors['--color-neutral-theme-200']};
    padding: ${theme.spacing(0, 3)};
`)

export const STLeft = styled('div', {
  label: 'Left'
})(({ theme }) => `
    display: flex;
    align-items: center;
`)

export const STLeftWrapper = styled('div', {
  label: 'LeftWrapper'
})(({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)}
`)

export const STRight = styled('div', {
  label: 'Right'
})(({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)}
`)

export const STDescription = styled('div', {
  label: 'Description'
})(() => `
    display: flex;
    align-items: center;
`)

export const STAvatarGroup = styled(AvatarGroup, {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'AvatarGroup'
})<{total: number}>(({ theme, ...props }) => `
    display: flex;
    cursor: pointer;

    svg {
      width: 28px;
      height: 28px;
    }

    div {
      width: 28px;
      height: 28px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }

    div:nth-of-type(1) {
      background-color: ${props.total > 3 ? theme.colors['--color-neutral-theme-50'] : 'inherit'};
      color: ${props.total > 3 ? theme.colors['--color-cyan-600'] : 'inherit'};
      position: relative;
      z-index: 2;
    }
`)
