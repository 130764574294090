import { Box, Skeleton, useTheme } from '@mui/material'
import { FC } from 'react'
import { STContainer, STContent } from './styled'

export const Skip: FC = () => {
  const theme = useTheme()

  return (
    <STContainer>
      <Skeleton
        animation="wave"
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: theme.colors['--color-neutral-theme-200'],
          borderRadius: '12px',
          boxShadow: '0px 16px 48px -12px rgba(37, 38, 46, 0.1)'
        }}
        variant="rectangular"
      />
      <STContent>
        <Box sx={{ display: 'flex', gap: '26.5px', width: '100%', justifyContent: 'center' }}>
          {[1, 2, 3].map((_, i) => (
            <Skeleton
              key={i}
              width={40}
              height={40}
              animation="wave"
              sx={{
                bgcolor: theme.colors['--color-neutral-theme-200'],
                boxShadow: '0px 16px 48px -12px rgba(37, 38, 46, 0.1)'
              }}
              variant="circular"
            />
          ))}
        </Box>
      </STContent>
    </STContainer>
  )
}
