import { Collapse, Drawer } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { NoTabletOrMobile, TabletAndMobile, useSpotlight } from 'src/components'
import { useBehaviorMapper, useIfMobileL, useIfTablet } from 'src/hooks'
import { IconTeleprompter } from 'src/icons'
import { RecordingService } from 'src/services'
import { TeleprompterEditor } from '../teleprompter-editor'
import { MobileTeleprompterEditor } from '../teleprompter-editor/mobile'
import Style from './style.module.scss'

interface IProps {
  recordingService: RecordingService
}

export const ActionTeleprompter: FC<IProps> = (props) => {
  const { spotRef } = useSpotlight({ stepIndex: 2 })
  const isTablet = useIfTablet()
  const isMobile = useIfMobileL()
  const teleprompter = useBehaviorMapper(props.recordingService.teleprompter$)

  const [isOpenMobileDrawer, setIsOpenMobileDrawer] = useState(false)

  const scriptRef = useRef<HTMLTextAreaElement>(null)

  const toggleScript = useCallback(() => {
    if (isTablet || isMobile) {
      setIsOpenMobileDrawer(true)
    } else {
      props.recordingService.setTeleprompter({
        ...teleprompter,
        isOpening: !teleprompter.isOpening
      })
    }
  }, [isTablet, isMobile, props.recordingService, teleprompter])

  useEffect(() => {
    scriptRef?.current?.focus?.()
  }, [teleprompter.isOpening])

  const handleChange = useCallback((value: string) => {
    props.recordingService.setTeleprompter({
      ...teleprompter,
      text: value
    })
  }, [props.recordingService, teleprompter])

  const closeMobileDrawer = useCallback(() => {
    setIsOpenMobileDrawer(false)
  }, [])

  return (
    <>
      <div className={Style.actionItem}>
        <button
          className={clsx({ [Style.buttonActionActive]: teleprompter.isOpening, [Style.buttonActionActiveWithText]: teleprompter.text })}
          onClick={toggleScript}
          ref={spotRef}
          id="target-teleprompter"
        >
          <IconTeleprompter/>
        </button>
        <p>Teleprompter</p>
      </div>

      <NoTabletOrMobile>
        <Collapse
          in={teleprompter.isOpening}
          className={clsx(!teleprompter.isOpening && 'd-none')}
        >
          <TeleprompterEditor
            onChange={handleChange}
            ref={scriptRef}
          />
        </Collapse>
      </NoTabletOrMobile>

      <TabletAndMobile>
        <Drawer
          anchor="bottom"
          open={isOpenMobileDrawer}
          keepMounted
          PaperProps={{
            sx: {
              height: '100%'
            }
          }}
        >
          <MobileTeleprompterEditor
            onChange={handleChange}
            onClose={closeMobileDrawer}
            recordingService={props.recordingService}
          />
        </Drawer>
      </TabletAndMobile>
    </>
  )
}
