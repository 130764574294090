import { EUserGuide } from 'src/enums'

/* layout actions */
export const GUIDE_SET_FIRST_ACTION = Symbol('GUIDE_SET_FIRST_ACTION')

export interface IFirstAction {
  name: EUserGuide
  content: string
}

/**
 * state
 */
export interface IGuideState {
  firstAction: IFirstAction | null
}

/**
 * actions
 */
export interface IGuideAction {
  type: typeof GUIDE_SET_FIRST_ACTION
  value?: string | null | IFirstAction
}
