import { Avatar, IconButton } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import _isNumber from 'lodash/isNumber'
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, take, takeUntil } from 'rxjs'
import { AnalyticApi, CampaignApi, UploadApi } from 'src/api'
import { Button, Editor, ErrorMessage, FakeHeader, GoogleLocationSearch, Input, LocationSearch, Switch, Tooltip, TourPopper } from 'src/components'
import { EEnv } from 'src/constants'
import { ELocationType, EMessage } from 'src/enums'
import { useAppDispatch, useBehaviorMapper, useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IconInfo, IconPlus, IconTrash } from 'src/icons'
import DefaultCampaignAvatar from 'src/images/default-campaign-avatar.svg'
import { ESalaryRange, ESalaryRate, ICampaignDetail } from 'src/interfaces'
import StyleActionButtons from 'src/modules/campaign/components/detail/components/action-buttons/style.module.scss'
import StyleDetail from 'src/modules/campaign/components/detail/components/detail/style.module.scss'
import StyleInformation from 'src/modules/campaign/components/detail/components/information/style.module.scss'
import { SignUpModal } from 'src/partials'
import { generate } from 'src/router'
import { ERecordFor, GuestOnboardingService, OverlayService, ToastService } from 'src/services'
import { TARGET_ENTER_JOB_DETAIL, TARGET_SHOW_ON_KNOWME } from 'src/services/tour/create-job-tour.service'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { setLayoutAside, setLayoutHeader, setLayoutLoading } from 'src/store/actions'
import { isEmptyBriefDescription, isValidWebsiteUrl } from 'src/utils'
import { ECampaignRoutes } from '../../routes.enum'
import { AttachedFile } from './components/attached-file'
import { Salary } from './components/detail/salary'
import { IntroduceYourselfVideo } from './components/introduce-yoursefl-video'
import { TooltipPostToKnowme } from './components/tooltip-post-to-know-me'
import { WorkplaceType } from './components/workplace-type'
import Style from './style.module.scss'

const avatarSx = { width: '80px', height: '80px', borderRadius: '8px' }
const inputSx = { marginBottom: 0 }
const inputWebsitesSx = { marginBottom: 0, width: '100%' }
const releaseObjectUrl = (url?: string) => url && URL.revokeObjectURL(url)
const isNumber = (value: any) => !isNaN(value) && _isNumber(value)

export const PublicGuestOnboardingNew: FC = () => {
  const router = useHistory()
  const tourEnabled = useBehaviorMapper(GuestOnboardingCreateJobTourService.enableTour$)
  const currentStep = useBehaviorMapper(GuestOnboardingCreateJobTourService.currentStep$)
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const campaignVideoData = useBehaviorMapper(GuestOnboardingService.campaignVideoData$)
  const [campaignVideoDataError, setCampaignVideoDataError] = useState('')

  useEffect(() => {
    if (campaignVideoData?.file) {
      setCampaignVideoDataError('')
    }
  }, [campaignVideoData])

  const handleBackClicked = useCallback(() => {
    router.push(generate(ECampaignRoutes.CAMPAIGNS_PUBLIC_ONBOARDING))
  }, [router])

  // form data
  const [jobTitle, setJobTitle] = useState('')
  const [jobTitleError, setJobTitleError] = useState('')
  const handleJobTitleChanged: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setJobTitle(event.target.value)
    setJobTitleError('')
  }, [])

  const [salary, setSalary] = useState<{
    range?: ESalaryRange
    value?: ICampaignDetail['salaryValue']
    rate?: ESalaryRate
  }>({})
  const [salaryError, setSalaryError] = useState('')
  const handleSalaryChanged = useCallback((value: typeof salary) => {
    setSalary(value)
    setSalaryError('')
  }, [])

  const [locationType, setLocationType] = useState<ELocationType>(ELocationType.ON_SITE)
  const [, setLocationTypeError] = useState('')
  const handleLocationTypeChanged = useCallback((value: ELocationType) => {
    setLocationType(value)
    setLocationTypeError('')
  }, [])

  const [location, setLocation] = useState<ICampaignDetail['location']>()
  const [locationError, setLocationError] = useState('')
  const handleLocationChanged = useCallback((value: typeof location) => {
    setLocation(value)
    setLocationError('')
  }, [])

  const [briefDescription, setBriefDescription] = useState('')
  const [briefDescriptionError, setBriefDescriptionError] = useState('')
  const handleBriefDescriptionChanged = useCallback((value: string) => {
    setBriefDescription(value)
    setBriefDescriptionError('')
  }, [])

  const [fileName, setFileName] = useState<string>()
  const [jdFile, setJdFile] = useState<File>()
  const [jdFileUrl, setJDFileUrl] = useState<string>()
  const handleJdFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    setJdFile(file)
    setJDFileUrl((prevFileUrl) => {
      releaseObjectUrl(prevFileUrl)
      return URL.createObjectURL(file)
    })
    setFileName(file.name)

    if (/\.pdf$/i.test(file.name)) {
      return
    }

    from(UploadApi.fetchContent(file, AnalyticApi._session))
      .pipe(
        take(1),
        catchError((error: AxiosError) => {
          console.log('error', error)
          // setErrors((prev) => prev.setError('briefDescription', getApiErrorMessage(error)))
          return EMPTY
        }),
        finalize(() => {})
      )
      .subscribe(({ data }) => {
        if (data.content) {
          const content = data.content
            .replace(/<h1/g, '<strong').replace(/<\/h1>/g, '</strong>')
            .replace(/<h2/g, '<strong').replace(/<\/h2>/g, '</strong>')
            .replace(/<h3/g, '<strong').replace(/<\/h3>/g, '</strong>')
            .replace(/<h4/g, '<strong').replace(/<\/h4>/g, '</strong>')
            .replace(/<h5/g, '<strong').replace(/<\/h5>/g, '</strong>')
            .replace(/<h6/g, '<strong').replace(/<\/h6>/g, '</strong>')

          const briefDescription = isEmptyBriefDescription(content) ? '' : content
          setBriefDescription(briefDescription)
          setBriefDescriptionError('')
        }

        // setErrors((prev) => prev.setError('briefDescription', 'Your attachment has no content.'))
      })
  }, [])

  const [companyName, setCompanyName] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const handleCompanyNameChanged: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setCompanyName(event.target.value)
    setCompanyNameError('')
  }, [])

  const [companyWebsiteUrls, setCompanyWebsiteUrls] = useState<string[]>([''])

  const [urlErrors, setUrlErrors] = useState<string[]>([])
  const handleWebsiteUrlChanged = useCallback((index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCompanyWebsiteUrls((urls) => urls.map((v, i) => (i === index ? value : v)))
  }, [])
  const handleRemoveWebsiteUrl = useCallback((index: number) => () => {
    setCompanyWebsiteUrls((urls) => urls.filter((_, i) => i !== index))
  }, [])
  const addCompanyWebsiteUrl = useCallback(() => {
    setCompanyWebsiteUrls((urls) => [...urls, ''])
  }, [])

  useDidMountEffect(() => {
    setUrlErrors(
      companyWebsiteUrls.map((url, index) => {
        return (!url || isValidWebsiteUrl(url))
          ? ''
          : 'Invalid website url'
      })
    )
  }, [companyWebsiteUrls])

  const [logoUrl, setLogoUrl] = useState<string>()
  const [logoFile, setLogoFile] = useState<File>()
  const handleLogoFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    setLogoFile(file)
    setLogoUrl((prevLogoUrl) => {
      releaseObjectUrl(prevLogoUrl)
      return URL.createObjectURL(file)
    })
  }, [])

  const [isSharingOnMarket, setIsSharingOnMarket] = useState(true)
  const handleSharingOnMarketChanged: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setIsSharingOnMarket(e.target.checked)
  }, [])
  // end form data

  useEffect(() => {
    GuestOnboardingService.tempJobCreationInputData$
      .pipe(
        takeUntil(unsubscribe$),
        take(1)
      )
      .subscribe(data => {
        if (data === null) return

        setJobTitle(data.jobTitle)
        setSalary({
          range: data.salaryRange,
          rate: data.salaryRate,
          value: data.salaryValue
        })

        if (data.locationType) {
          setLocationType(data.locationType)
        }

        setLocation(data.location)

        if (data.briefDescription) {
          setBriefDescription(data.briefDescription)
        }

        setJdFile(data.jdFile)

        if (data.jdFileUrl) {
          setJDFileUrl(data.jdFileUrl)
        }

        setFileName(data.jdFile?.name)
        setCompanyName(data.companyName)

        if (data.companyWebsiteUrls) {
          setCompanyWebsiteUrls(data.companyWebsiteUrls)
        }

        setLogoUrl(data.logoUrl)
        setIsSharingOnMarket(data.shareOnMarket)

        GuestOnboardingService.setTempJobCreationInputData(null)
      })
  }, [unsubscribe$])

  const cache = useCallback(() => {
    GuestOnboardingService.setTempJobCreationInputData({
      jobTitle,
      salaryRange: salary.range,
      salaryRate: salary.rate,
      salaryValue: salary.value,
      locationType,
      location,
      briefDescription,
      jdFile,
      jdFileUrl,
      companyName,
      companyWebsiteUrls,
      logoUrl,
      logoFile,
      shareOnMarket: isSharingOnMarket
    })
  }, [briefDescription, companyName, companyWebsiteUrls, isSharingOnMarket, jdFile, jdFileUrl, jobTitle, location, locationType, logoFile, logoUrl, salary.range, salary.rate, salary.value])

  const handleGoToPreview = useCallback(() => {
    cache()
    router.push(generate(ECampaignRoutes.CAMPAIGNS_PUBLIC_ONBOARDING_PREVIEW))
  }, [cache, router])

  const handleGoToRecording = useCallback(() => {
    cache()
    router.push(generate(ECampaignRoutes.CAMPAIGNS_PUBLIC_ONBOARDING_RECORDER, { type: ERecordFor.CAMPAIGN }))
  }, [cache, router])

  useEffect(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutHeader(false))

    return () => {
      dispatch(setLayoutHeader(true))
    }
  }, [dispatch])

  const titleActions = useMemo(() => {
    return [
      {
        title: 'My Jobs',
        handler: () => router.push(generate(ECampaignRoutes.CAMPAIGNS_PUBLIC_ONBOARDING))
      },
      {
        title: 'Create Job',
        handler: () => null
      }
    ]
  }, [router])

  const handleChangeUploadCampaignVideo = useCallback((file: File) => {
    GuestOnboardingService.setCampaignVideoData({ file })
  }, [])

  const validateData = useCallback((withSideEffect?: boolean) => {
    if (!jobTitle?.trim()) {
      if (withSideEffect) {
        setJobTitleError('Job Title is required')
      }

      return false
    }

    if (!salary.rate) {
      if (withSideEffect) {
        setSalaryError('Salary is required')
      }

      return false
    }

    if (!salary.value) {
      if (withSideEffect) {
        setSalaryError('Salary is required')
      }

      return false
    }

    if (!isNumber(get(salary.value, 'min')) && !isNumber(get(salary.value, 'max'))) {
      if (withSideEffect) {
        setSalaryError('Salary is required')
      }

      return false
    }

    if (isNumber(get(salary.value, 'min')) && isNumber(get(salary.value, 'max')) && get(salary.value, 'min') > get(salary.value, 'max')) {
      if (withSideEffect) {
        setSalaryError('Salary min must be less than max')
      }

      return false
    }

    if (!locationType) {
      if (withSideEffect) {
        setLocationTypeError('Location is required')
      }

      return false
    }

    if (locationType !== ELocationType.REMOTE && !location) {
      if (withSideEffect) {
        setLocationError('Location is required')
      }

      return false
    }

    if (!briefDescription?.trim()) {
      if (withSideEffect) {
        setBriefDescriptionError('Job Description is required')
      }

      return false
    }

    if (!companyName?.trim()) {
      if (withSideEffect) {
        setCompanyNameError('Company Name is required')
      }

      return false
    }

    if (urlErrors.some((error) => !!error)) {
      return false
    }

    if (!campaignVideoData?.file) {
      if (withSideEffect) {
        setCampaignVideoDataError('Company Name is required')
      }

      return false
    }

    return true
  }, [briefDescription, campaignVideoData?.file, companyName, jobTitle, location, locationType, salary.rate, salary.value, urlErrors])

  const isPreviewDisabled = useMemo(() => {
    return !validateData()
  }, [validateData])

  const handleCreateJobClicked = useCallback(async () => {
    const isValid = validateData(true)
    if (!isValid) return

    try {
      dispatch(setLayoutLoading(true))

      const seeds = await GuestOnboardingService.uploadFiles({
        jdFile,
        logoFile,
        uploadVideoFile: campaignVideoData?.file,
        thumbnailOffset: campaignVideoData?.offsetAt
      })

      const { data: { slug } } = await CampaignApi.createOnboarding({
        ...seeds,
        jobTitle,
        companyName,
        companyWebsiteUrls: companyWebsiteUrls.filter((url) => !!url),
        locationType,
        location,
        salaryValue: salary.value,
        salaryRate: salary.rate,
        salaryRange: salary.range,
        questionIds: [1],
        briefDescription,
        shareOnMarket: isSharingOnMarket,
        thumbnailOffset: campaignVideoData?.offsetAt
      })

      GuestOnboardingService.setCreatedCampaignSlug(slug)

      OverlayService.setOverlay({
        content: (
          <SignUpModal
            showCandidateText
            campaignOnboardingSlug={GuestOnboardingService.createdCampaignSlug$.getValue() ?? undefined}
            onboardingPfvId={GuestOnboardingService.vvcId$.getValue() ?? undefined}
          />
        ),
        open: true
      })
    } catch (error) {
      console.error(error)
      ToastService.push({
        severity: EMessage.ERROR,
        content: 'There is unexpected error. Please try again later.'
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }, [validateData, dispatch, jdFile, logoFile, campaignVideoData?.file, campaignVideoData?.offsetAt, jobTitle, companyName, companyWebsiteUrls, locationType, location, salary.value, salary.rate, salary.range, briefDescription, isSharingOnMarket])

  return (
    <div className="relative">
      <FakeHeader
        showLogo
        showBack
        onBackClicked={handleBackClicked}
        title={titleActions.map(({ title }) => title).join('/')}
        titleActions={titleActions}
      />
      <div className={Style.container}>
        <div className={Style.content}>
          <div className={Style.content_wrapper}>
            <div className={StyleDetail.container}>
              <span className={StyleDetail.header}>
                Job Detail
              </span>
              <div className={StyleDetail.content}>
                <div className={StyleDetail.campaignWrapper}>
                  <div id="uploadVideo">
                    {tourEnabled && currentStep === 0 && (<TourPopper {...GuestOnboardingCreateJobTourService.getStepConfig()}/>)}
                    <IntroduceYourselfVideo
                      uploadVideoFile={campaignVideoData?.file}
                      videoBackground={campaignVideoData?.thumbnailUrl}
                      onRecordVideo={handleGoToRecording}
                      onChangeUploadVideo={handleChangeUploadCampaignVideo}
                    />

                    {
                      campaignVideoDataError && (
                        <ErrorMessage className="mt-2">
                          This field is required
                        </ErrorMessage>
                      )
                    }
                  </div>
                  <div className="fx-1 gap-6 fx flex-column relative">
                    <div className={StyleDetail.jobDetailTarget} id={TARGET_ENTER_JOB_DETAIL.getValue()}/>
                    {tourEnabled && currentStep === 1 && (<TourPopper {...GuestOnboardingCreateJobTourService.getStepConfig()}/>)}

                    <div className={StyleDetail.campaignTitle} id="jobTitle">
                      <span className={clsx(StyleDetail.title, StyleDetail.title_required)}>Job Title</span>
                      <Input
                        sx={inputSx}
                        id="jobTitleInput"
                        placeholder="Job Title"
                        value={jobTitle}
                        onChange={handleJobTitleChanged}
                        error={jobTitleError}
                      />
                    </div>

                    <div className={StyleDetail.salary} id="salaryValue">
                      <span className={clsx(StyleDetail.title, StyleDetail.title_required)}>Salary</span>
                      <div className="fx-column gap-2">
                        <Salary
                          id="salaryValueInput"
                          rate={salary.rate}
                          range={salary.range}
                          value={salary.value}
                          onChange={handleSalaryChanged}
                          error={salaryError}
                        />
                      </div>
                    </div>

                    <div className="fx flex-row fx-ai-flex-start gap-4" id="location">
                      <WorkplaceType
                        value={locationType}
                        onChange={handleLocationTypeChanged}
                      />

                      {locationType !== ELocationType.REMOTE && (
                        <div className={StyleDetail.location}>
                          <div className="fx flex-row fx-ai-center gap-2">
                            <span className={clsx(StyleDetail.title, StyleDetail.title_required)}>Location</span>
                            <Tooltip
                              title="Picking a specific city or metro area can help make your job more discoverable by job seekers in those areas, while still remaining visible to everyone in your country or region."
                              classes={{ popper: StyleDetail.popper, tooltip: clsx('meta-medium', StyleDetail.tooltip), arrow: StyleDetail.tooltipArrow }}
                              placement="top"
                              arrow
                            >
                              <IconInfo
                                className={StyleDetail.iconInfo}
                              />
                            </Tooltip>
                          </div>

                          {EEnv.REACT_APP_LOCATION_PROVIDER === 'google'
                            ? (
                              <GoogleLocationSearch
                                id="locationInput"
                                location={location}
                                onChange={handleLocationChanged}
                                error={locationError}
                              />
                            )
                            : (
                              <LocationSearch
                                id="locationInput"
                                location={location}
                                onChange={handleLocationChanged}
                                error={locationError}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={clsx(StyleDetail.jd, 'fx fx-column gap-6 pt-4 relative')}>
                  <div className={StyleDetail.jobDescriptionTarget}/>

                  <div className="fx fx-column" id="briefDescription">
                    <div className="fx fx-column gap-1 px-4 mb-4">
                      <span className={clsx(StyleDetail.title_bold, StyleDetail.title_required)}>Job Description</span>
                    </div>

                    <Editor
                      value={briefDescription}
                      onChange={handleBriefDescriptionChanged}
                      className={clsx(StyleDetail.jdEditor, fileName !== undefined && StyleDetail.jdEditorWithAttachment)}
                    />

                    <div className={clsx(StyleDetail.jdAttachmentWrapper, fileName !== undefined && StyleDetail.jdWithAttachment)}>
                      {fileName !== undefined
                        ? (
                          <AttachedFile
                            fileName={fileName}
                            url={jdFileUrl}
                            onRemove={() => null}
                          />
                        )
                        : (
                          <Button
                            field="input"
                            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            order="tertiary"
                            className={StyleDetail.jd_attach_button}
                            handleChange={handleJdFileChange}
                          >
                            <span className="text-decorator-underline">Attach file</span>
                          </Button>
                        )}
                    </div>

                    {briefDescriptionError && (
                      <ErrorMessage className="px-4 my-2">
                        {briefDescriptionError}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={StyleInformation.container}>
              <span className={StyleInformation.header}>Company Detail</span>

              <div className={StyleInformation.content}>
                <div className={StyleInformation.subContent}>
                  <div className={StyleInformation.companyName} id="companyName">
                    <span className={clsx(StyleInformation.title, StyleInformation.title_required)}>
                      Company Name
                    </span>
                    <Input
                      sx={inputSx}
                      id="companyNameInput"
                      placeholder="Enter Company Name"
                      value={companyName}
                      onChange={handleCompanyNameChanged}
                      error={companyNameError}
                    />
                  </div>
                  <div className={StyleInformation.companyWebsites} id="companyWebsiteUrls">
                    <span className={clsx(StyleInformation.title, StyleInformation.title_required)}>
                      Company Website
                    </span>
                    {companyWebsiteUrls.map((value, index) => (
                      <div key={index} className={StyleInformation.companyWebsites_inputWrapper}>
                        <Input
                          id="companyWebsiteUrlsInput"
                          sx={inputWebsitesSx}
                          placeholder="Enter Company Website"
                          value={value}
                          onChange={handleWebsiteUrlChanged(index)}
                          error={urlErrors.at(index)}
                        />
                        {index > 0 && (
                          <IconButton
                            className={StyleInformation.companyWebsites_buttonRemoveWebsite}
                            onClick={handleRemoveWebsiteUrl(index)}
                          >
                            <IconTrash width={18.5} height={20.25}/>
                          </IconButton>
                        )}
                      </div>
                    ))}

                    {companyWebsiteUrls.length >= 3
                      ? null
                      : (
                        <span
                          className={clsx(
                            'body2-bold color-neutral-theme-700',
                            StyleInformation.companyWebsites_buttonMore
                          )}
                          onClick={addCompanyWebsiteUrl}
                        >
                          <IconPlus width={16} height={16}/>
                          Add Link
                        </span>
                      )}
                  </div>
                </div>
                <div className={StyleInformation.logo}>
                  <span className={StyleInformation.title}>Company Logo</span>
                  <div className={StyleInformation.logo_content}>
                    <Avatar
                      sx={avatarSx}
                      variant="square"
                      src={logoUrl || DefaultCampaignAvatar}
                      onError={(err) => console.log(err)}
                    />
                    <div className={StyleInformation.logo_upload}>
                      <Button
                        field="input"
                        accept="image/jpeg,image/png"
                        handleChange={handleLogoFileChange}
                        className={StyleInformation.logo_button}
                        order="secondary"
                      >
                        Click to Upload
                      </Button>
                      <span>PNG, JPG (max.800x800px)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.content_action}>
        <div className={StyleActionButtons.actionWrapper}>

          <div className={StyleActionButtons.switchContainer}>
            <Switch
              htmlId={TARGET_SHOW_ON_KNOWME.getValue()}
              label="Post To KnowMe Jobs Marketplace"
              labelClassName={clsx(StyleActionButtons.switchLabel, 'body2-medium color-neutral-theme-900')}
              switchClassName={StyleActionButtons.switch}
              checked={isSharingOnMarket}
              onChange={handleSharingOnMarketChanged}
            />
            {tourEnabled && currentStep === 2 && (<TourPopper {...GuestOnboardingCreateJobTourService.getStepConfig()}/>)}
            <TooltipPostToKnowme/>
          </div>

          <div className={StyleActionButtons.right}>
            <Button
              className={StyleActionButtons.button}
              order="secondary"
              disabled={isPreviewDisabled}
              onClick={handleGoToPreview}
            >Preview
            </Button>
            <Button
              className={StyleActionButtons.button}
              onClick={handleCreateJobClicked}
            >
              Create Job
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
