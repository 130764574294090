import { styled } from '@mui/material'

export const STContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'Container'
})<{isShow?: boolean}>(({ theme, isShow }) => `
  flex: 1;
  margin: ${theme.spacing(2, 4, 5)};
  padding: ${theme.spacing(4)};
  background-color: ${theme.colors['--color-neutral-theme-50']};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`)

export const STHeader = styled('div', {
  label: 'Header'
})(({ theme }) => `
  margin-bottom: ${theme.spacing(3.5)};

  display: flex;
  align-items: center;
  justify-content: space-between
`)
