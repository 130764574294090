import { FC } from 'react'
import { Button } from '../button'
import { ManFrontLaptop } from './illustration'

import clsx from 'clsx'
import Style from './style.module.scss'

interface IProps {
  onBannerClick?: () => void
}

export const BannerStartVibeTalent: FC<IProps> = ({ onBannerClick }) => {
  return (
    <div className={clsx('fx flex-row gap-3 fx-ai-center', Style.responsiveWrapper)}>
      <ManFrontLaptop/>
      <div className={clsx(Style.responsiveContent, 'fx flex-row fx-ai-center px-5 py-3 bg-color-purple-200 round-4')}>
        <div className="fx flex-column fx-1">
          <span className="body1-bold color-neutral-theme-900">
            Not Ready to Create a Job Now?
          </span>
          <span className="body2-regular color-neutral-theme-900">
            Let’s dive into exploring talents!
          </span>
        </div>
        <Button height={40} onClick={onBannerClick} order="black">Vibe Talent</Button>
      </div>
    </div>
  )
}
