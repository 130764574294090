import clsx from 'clsx'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { VideoPlayer } from 'src/components'
import { useElementSize } from 'src/hooks'
import { IconChevronLeft, IconChevronRight } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { getVideoSource } from 'src/utils'
import { SAMPLE_VIDEO } from './constants'
import Style from './style.module.scss'

export const SamplePlayers: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const videoContainerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [firstLoadedVideoRatio, setFirstLoadedVideoRatio] = useState(false)

  useEffect(() => {
    if (!firstLoadedVideoRatio) {
      setFirstLoadedVideoRatio(true)
    }
  }, [firstLoadedVideoRatio])

  return (
    <>
      <div className={Style.SamplePlayers}>
        <div className={Style.Container}>
          <div className={Style.CarouselContainer} ref={containerRef}>
            <div
              ref={videoContainerRef}
              className={Style.VideoPlayerCtn}
              style={{
                aspectRatio: '418 / 744',
                [elWidth / elHeight > 418 / 744 ? 'height' : 'width']: '100%'
              }}
            >
              <button
                className={clsx([
                  Style.PrevBtn,
                  { [Style.NoShow]: currentSlide === 0 }
                ])}
                onClick={() => setCurrentSlide(currentSlide - 1)}
              >
                <IconChevronLeft/>
              </button>
              <button
                className={clsx([
                  Style.NextBtn,
                  { [Style.NoShow]: currentSlide === SAMPLE_VIDEO.length - 1 }
                ])}
                onClick={() => setCurrentSlide(currentSlide + 1)}
              >
                <IconChevronRight/>
              </button>

              {SAMPLE_VIDEO.map((video, index) => (
                <SliderComponent
                  key={index}
                  index={index}
                  currentSlide={currentSlide}
                  video={video}
                  className={clsx({
                    [Style.VidePlayerSlidePrev]: index < currentSlide,
                    [Style.VidePlayerSlideNext]: index > currentSlide
                  })}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SliderComponent = (props: {
  video: Partial<IVideoModel>
  className?: string
  index: number
  currentSlide: number
}) => {
  const video = props.video

  const style = useMemo(() => {
    return {
      zIndex: props.index < props.currentSlide
        ? props.index
        : 1000 - props.index
    }
  }, [props.index, props.currentSlide])

  return (
    <div
      style={style}
      className={clsx([
        Style.VidePlayerSlide,
        props.className
      ])}
    >
      {props.index !== props.currentSlide && <div className={Style.Overlay}/>}
      <VideoPlayer
        className={Style.videoPlayerOverride}
        style={{ aspectRatio: '418 / 744' }}
        isPlay={props.index === props.currentSlide}
        url={getVideoSource(video)}
        tracks={video?.tracks}
        image={video?.urlVideoImageThumbnail}
        animatedImage={video?.urlVideoAnimatedImage}
        mimeType={video?.internalSourceMetadata?.mimeType}
      />
    </div>
  )
}
