import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ViewApi } from 'src/api'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { IShareModel } from 'src/interfaces'
import { setLayoutSharingToken } from 'src/store/actions'
import { getCredentials, getIsAuthenticated } from 'src/store/selectors'

export const Home: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { sharingToken } = useParams<{sharingToken: string}>()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const credentials = useAppSelector(getCredentials)
  const [shareData, setSharedData] = useState<IShareModel>()

  const loadSharedEmail = async (sharingToken: string) => {
    try {
      const { data } = await ViewApi.share(sharingToken)
      setSharedData(data)
    } catch (error) {
      console.error('not be able to get shared video data', error)
    }
  }

  useEffect(() => {
    dispatch(setLayoutSharingToken(sharingToken))
  }, [dispatch, sharingToken])

  useEffect(() => {
    if (!shareData) {
      loadSharedEmail(sharingToken)
    }
  }, [shareData, sharingToken])

  useEffect(() => {
    if (!shareData) {
      return
    }

    if (isAuthenticated && credentials?.email && credentials?.email === shareData.email) {
      // TODO: is introduction video still be viewed in the likes page?
      return history.push({
        pathname: '/likes',
        search: `?detailCandidateId=${shareData.candidateId}&detailTab=1`
      })
    }

    if (isAuthenticated) {
      // different shared user -> reset layout and redirect to home
      dispatch(setLayoutSharingToken(null))
      return history.push('/')
    }

    return history.push(`/home/${sharingToken}`)
  }, [credentials?.email, dispatch, history, isAuthenticated, shareData, sharingToken])

  return null
}
