import React, { FC, useMemo } from 'react'
import Lottie from 'react-lottie'
import { useBehaviorMapper } from 'src/hooks'
import { LottiesModule } from 'src/store'

interface IClaimCreditProps {
  play?: boolean
  onCompleted?: () => void
}

export const ClaimCredit: FC<IClaimCreditProps> = React.forwardRef(function ClaimCredit(
  props: IClaimCreditProps,
  ref: any
) {
  const claimCreditAnimation = useBehaviorMapper(LottiesModule.claimCredit$)
  const defaultOptions = useMemo(() => ({
    loop: false,
    autoplay: true,
    animationData: claimCreditAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }), [claimCreditAnimation])
  return (
    <Lottie
      ref={ref}
      width={120}
      options={defaultOptions}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onCompleted?.()
        }
      ]}
    />
  )
})
