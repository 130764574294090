import { IRouterOption } from 'src/router'
import { Home } from './components/home'
// import { ShareSignIn } from './components/share-sign-in'
import { EShareRoutes } from './routes.enum'

export { EShareRoutes }

export const ShareRoutes: IRouterOption[] = [
  {
    path: '/share/:sharingToken',
    component: Home,
    name: EShareRoutes.SHARE,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
  // {
  //   path: '/share/sign-in/:sharingToken',
  //   component: ShareSignIn,
  //   name: EShareRoutes.SHARE_SIGN_IN,
  //   exact: true,
  //   meta: {
  //     requireAuth: false
  //   }
  // }
  // FIXME: This could broke the sharing of CAMPAIGN video, will fix later
  // {
  //   path: '/share/view/:sharingToken',
  //   component: ShareDetail,
  //   name: EShareRoutes.SHARE_DETAIL,
  //   exact: true,
  //   meta: {
  //     requireAuth: true
  //   }
  // }
]
