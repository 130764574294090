import { IUserModel } from './user.model'
import { IModel } from './model'

export enum EPrivateNoteRelateType {
  CANDIDATE = 'Candidate'
}

export interface IPrivateNoteModel extends IModel {
  id: number
  userId?: number
  user?: IUserModel
  relateType?: EPrivateNoteRelateType
  relateId?: number
  content?: string
}
