import { FC, forwardRef } from 'react'
import { useSwiper } from 'swiper/react'

export const SwiperButtonLastSlide: FC<any> = forwardRef(function SwiperButton(
  { lastIndex = 0, ...props },
  ref: any
) {
  const swiper = useSwiper()

  return <div ref={ref} onClick={() => swiper.slideTo(lastIndex)} {...props}/>
})
