import { Menu, MenuProps, alpha, styled } from '@mui/material'

export const StyledMenu = styled((props: MenuProps & { width?: number }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))(({ theme, width }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    padding: '12px 12px 12px 8px',
    // minWidth: 180,
    width: width ? `${width}px` : 'auto',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      '0px 40px 80px -12px rgba(37, 38, 46, 0.1)',
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      },
      position: 'initial'
    }
  }
}))
