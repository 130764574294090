import { forwardRef } from 'react'
import {
  STItem,
  STItemDescription,
  STItemDescriptionDegree,
  STItemDescriptionTitle,
  STItemTime,
  STTitleSubText
} from './styled'

export const LinkedInEducationStyled = Object.freeze({
  STItem,
  STItemDescription,
  STItemDescriptionDegree,
  STItemDescriptionTitle,
  STItemTime,
  STTitleSubText
})

interface IProps {
  isLast: boolean
  isOnly: boolean
  item: {
    school?: string
    degree?: string
    duration?: string
  }
  lightMode: boolean
}

export const LinkedInEducation = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  return (
    <STItem isLast={props.isLast} isOnly={props.isOnly} lightMode={props.lightMode} ref={ref}>
      {!props.lightMode && <STItemTime>{props.item.duration}</STItemTime>}

      <svg style={{ marginTop: '7px', width: '10px', flexBasis: '10px', flexShrink: 0, height: '10px' }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4.5" stroke={props.lightMode ? '#4FDEE8' : '#80818E'}/>
      </svg>

      <STItemDescription>
        <STItemDescriptionTitle lightMode={props.lightMode}>{props.item.school}</STItemDescriptionTitle>
        <STItemDescriptionDegree lightMode={props.lightMode}>{props.item.degree}</STItemDescriptionDegree>
      </STItemDescription>
    </STItem>
  )
})
