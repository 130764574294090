import { useMemo } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useBehaviorMapper } from 'src/hooks'
import { CampaignMutationService } from 'src/services'
import { EUploadStatus, UploadService } from 'src/services/upload.service'

export const useUploadStatus = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const uploadingItems = useBehaviorMapper(UploadService.uploadingItems$)

  const status$ = useMemo(() => {
    if (!data.uploadVideoFile || !data.uploadVideoRefId) {
      return new BehaviorSubject(EUploadStatus.NOT_STARTED)
    }

    const observable = uploadingItems.find((item) => item.id === data.uploadVideoRefId)?.status
    if (!observable) {
      return new BehaviorSubject(EUploadStatus.NOT_STARTED)
    }

    return observable
  }, [data.uploadVideoFile, data.uploadVideoRefId, uploadingItems])

  return useBehaviorMapper(status$)
}
