import { faker } from '@faker-js/faker/locale/en'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  FilterDropdown,
  InputSearch,
  Tooltip,
  TourPopper
} from 'src/components'
import { useBehaviorMapper, useSubscribeToGoBack } from 'src/hooks'
import { IconEditRegular, IconGroup, IconTrash } from 'src/icons'
import { NavigationService } from 'src/services'
import { ShareJobTourService, TARGET_JOB_EDIT_BUTTON, TARGET_JOB_SEARCH_SORT, TARGET_JOB_SHARE_BUTTON, TARGET_JOB_TITLE, TARGET_JOB_VIEW_BY } from 'src/services/tour/share-job-tour.service'
import { SEXE, defaultFilterOptions, defaultViewOptions } from '../../list/constants'
import Style from '../../list/style.module.scss'
import { VideoCard } from '../video-card'

const TITLE = 'Customer Success'
const applicants = Array.from({ length: 12 }).map((_, index) => ({
  url: `https://knowme.github.io/images/hiring-manager/${index + 1}.png`,
  name: faker.person.fullName()
}))

export const ListSandBox: FC = () => {
  useSubscribeToGoBack({
    showArrow: true,
    title: `My Jobs/${TITLE}`,
    handler: () => ShareJobTourService.goToStep(0)
  })

  useEffect(() => {
    NavigationService.setCreditPoint(false)

    return () => {
      NavigationService.setCreditPoint(true)
    }
  }, [])

  const currentStep = useBehaviorMapper(ShareJobTourService.currentStep$)

  const [isShowSearchBar, setIsShowSearchBar] = useState(false)
  const [viewOptions, setViewOptions] = useState(cloneDeep([...defaultViewOptions]).map((option, index) => {
    if (index === 0) {
      option.isChecked = true
      option.isDefault = true
    }

    return option
  }))

  const filterOptions = useMemo(() => {
    const options = cloneDeep(defaultFilterOptions)
    options[0].isChecked = true
    options[0].isDefault = true

    return options
  }, [])

  const handleShowSearchBarChanged = useCallback((isShowing: boolean) => {
    setIsShowSearchBar(isShowing)
  }, [])

  const handleChooseViewOption = useCallback(async (option: {
    label: string
    value: string
    isChecked: boolean
    isDefault?: boolean
  }) => {
    const updatedViewOptions = await Promise.all(defaultViewOptions.map(async (obj) => {
      if (option.value === SEXE) {
        ShareJobTourService.nextStep()
      }

      if (obj.value === option.value) {
        return { ...obj, isChecked: true }
      }

      return obj
    }))

    setViewOptions(updatedViewOptions)
  }, [])

  return (
    <div className={Style.STContainer}>
      <div className={Style.listCampaignContent}>
        <div className={Style.STCampaignHeader}>
          <div className={Style.STCampaignHeaderLeft}>

            <Tooltip title={TITLE}>
              <span onClick={() => ShareJobTourService.nextStep()} id={TARGET_JOB_TITLE.getValue()} className={Style.STCampaignHeaderTitle}>
                {TITLE}
              </span>
            </Tooltip>
            {currentStep === 1 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}

            <Button
              id={TARGET_JOB_EDIT_BUTTON.getValue()}
              type="button"
              order="tertiary"
              className={Style.STCampaignHeaderAction}
              onClick={() => ShareJobTourService.nextStep()}
            >
              <IconEditRegular/>
            </Button>
            {currentStep === 2 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}

            <Button
              type="button"
              order="tertiary"
              className={Style.STCampaignHeaderAction}
            >
              <IconTrash className="svg-color-neutral-theme-900"/>
            </Button>

            <Button
              id={TARGET_JOB_SHARE_BUTTON.getValue()}
              type="button"
              order="tertiary"
              className={Style.STCampaignHeaderAction}
              onClick={() => ShareJobTourService.nextStep()}
            >
              <IconGroup/>
            </Button>
            {currentStep === 3 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
          </div>

          <div className={Style.STCampaignHeaderRight}>
            <InputSearch handleChange={() => {}} onShowSearchBarChanged={handleShowSearchBarChanged}/>

            <div id={TARGET_JOB_SEARCH_SORT.getValue()} style={{ width: 0, height: 0, position: 'absolute', left: '50px', bottom: 0 }}/>
            {currentStep === 4 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
            <FilterDropdown
              options={filterOptions}
              handleClickItem={() => {}}
              mobileLabel="Sort"
              wrapperClassName={clsx(isShowSearchBar && Style.FilterOption_isShowSearchBar)}
              className={Style.FilterOption}
            />
            <FilterDropdown
              id={TARGET_JOB_VIEW_BY.getValue()}
              options={viewOptions}
              handleClickItem={handleChooseViewOption}
              mobileLabel="View"
              wrapperClassName={clsx(isShowSearchBar && Style.ViewOption_isShowSearchBar)}
              className={Style.ViewOption}
            />
            {currentStep === 5 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
          </div>
        </div>

        <div className={Style.campaignContainerWrapper}>
          <div className={Style.campaignContainerData}>
            {applicants.map((applicant, index) => (
              <VideoCard key={index} name={applicant.name} url={applicant.url}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
