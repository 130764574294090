import { Box, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { CSSProperties, FC, memo, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'
import {
  NoMobile,
  NoTabletOrMobile,
  RedeemCode,
  TabletAndMobile
} from 'src/components'
import { BUTTON_BACK_ID, CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import { IconArrowLeft, IconClose, IconLogo } from 'src/icons'
import {
  HeaderNotificationBadge,
  HeaderQuestionAnswer
} from 'src/partials'
import { NavigationService } from 'src/services'
import {
  getLayoutIsHeader,
  getLayoutPageComponent,
  getLayoutPageTitle
} from 'src/store/selectors'
import { Credit } from '../credit'
import { Hamburger } from '../hamburger'
import { WorkingLocation } from '../working-location'
import Style from './style.module.scss'
import { STIconButtonBack, STTitleText } from './styled'

interface ITitleActions {
  title: string
  handler: () => void
}

export const Private: FC = memo(function Private() {
  const location = useLocation()

  const isHeader = useAppSelector(getLayoutIsHeader)
  const pageTitle = useAppSelector(getLayoutPageTitle)
  const pageComponent = useAppSelector(getLayoutPageComponent)
  const isMin600 = useMediaQuery('(min-width: 601px)')
  const isMax600 = useMediaQuery('(max-width: 600px)')
  const arrowAndTitle = useBehaviorMapper(NavigationService.arrowAndTitle$)
  const isAtRecordingPage = useBehaviorMapper(NavigationService.isAtRecordingPage$)

  const subTitles = useMemo(() => {
    if (!isEmpty(arrowAndTitle?.titleActions)) {
      return arrowAndTitle?.titleActions?.map(
        (item: { title: string }) => item?.title
      )
    }
    return (arrowAndTitle.title || pageTitle)?.split('/')
  }, [arrowAndTitle.title, arrowAndTitle?.titleActions, pageTitle])

  const showTraitFilter = useMemo(
    () => ['/', '/vibes'].includes(location.pathname),
    [location]
  )

  const handleClickBack = () => {
    NavigationService.back(
      arrowAndTitle.title ? arrowAndTitle.title : pageTitle || ''
    )
  }

  const showHeader = useBehaviorMapper(NavigationService.showHeader$)
  const showRedeemCode = useBehaviorMapper(NavigationService.showRedeemCode$)
  const showCreditPoint = useBehaviorMapper(
    NavigationService.creditPoint$
  ).show
  const firstUploadedPfv = useBehaviorMapper(
    NavigationService.firstUploadedPfv$
  )

  const renderAction = useCallback(
    (title: string) => {
      const findTitle = arrowAndTitle?.titleActions?.findIndex(
        (item: ITitleActions) => item.title === title
      )

      return arrowAndTitle?.titleActions?.[findTitle || 0]?.handler()
    },
    [arrowAndTitle?.titleActions]
  )

  const headerStyleAtRecordingPage: CSSProperties = useMemo(() => {
    if (!isAtRecordingPage) return {}

    return {
      position: 'absolute'
    }
  }, [isAtRecordingPage])

  return (
    <>
      {showHeader && (
        <div style={headerStyleAtRecordingPage} className={clsx(Style.privateHeader, { 'd-none': !isHeader })}>
          <div
            className={clsx([
              Style.redeemCodeContainer,
              {
                [Style.open]: showRedeemCode.status
              }
            ])}
          >
            <button
              className={Style.closeRedeemCodeBtn}
              onClick={() => NavigationService.setShowRedeemCode(false, 0)}
            >
              <IconClose/>
            </button>

            <RedeemCode
              introCredit={showRedeemCode.introCredit}
              play={showRedeemCode.status}
            />
          </div>

          <div
            className={clsx([
              Style.redeemCodeContainer,
              {
                [Style.open]:
                  firstUploadedPfv.uploaded &&
                  firstUploadedPfv.watchedTalentOnboarding &&
                  firstUploadedPfv.isAtKnowmeTalent
              }
            ])}
          >
            <button
              className={Style.closeRedeemCodeBtn}
              onClick={() => NavigationService.setFirstUploadedPfv(false)}
            >
              <IconClose/>
            </button>

            <RedeemCode
              introCredit={CREDIT_FOR_FIRST_TIME_VVC}
              play={
                firstUploadedPfv.uploaded &&
                firstUploadedPfv.watchedTalentOnboarding &&
                firstUploadedPfv.isAtKnowmeTalent
              }
            />
          </div>

          <NoTabletOrMobile>
            <div className={Style.STTitle} style={{ display: 'flex' }}>
              {arrowAndTitle.showArrow && (
                <STIconButtonBack id={BUTTON_BACK_ID} onClick={handleClickBack}>
                  <IconArrowLeft/>
                </STIconButtonBack>
              )}

              {pageComponent}

              <div className={Style.titleWrapper}>
                {subTitles?.map((subTitle: string, index: number) => (
                  <STTitleText
                    key={index}
                    className={Style.subTitle}
                    isLastTitle={subTitles?.length === index + 1}
                    onClick={() => renderAction(subTitle)}
                  >
                    {subTitle}
                    {subTitles?.length !== index + 1 && ' / '}
                  </STTitleText>
                ))}
              </div>
            </div>
          </NoTabletOrMobile>

          <TabletAndMobile>
            <div className={Style.STLeftLogo}>
              <IconLogo width={48}/>
              <Hamburger/>
            </div>
            {isMin600 && pageComponent}
          </TabletAndMobile>

          <NoMobile>
            {showTraitFilter && (
              <div
                className={clsx(
                  'fx gap-2 fx-ai-center',
                  Style.TraitFilterContainer
                )}
              >
                <WorkingLocation/>
              </div>
            )}
          </NoMobile>

          <Box className={Style.STRight}>
            {showCreditPoint && <Credit/>}
            <HeaderQuestionAnswer/>
            <NoTabletOrMobile>
              <HeaderNotificationBadge/>
            </NoTabletOrMobile>
          </Box>
        </div>
      )}

      {isMax600 && pageComponent && (
        <div className={Style.STSecondHeader}>{pageComponent}</div>
      )}
    </>
  )
})
