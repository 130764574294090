import { styled } from '@mui/material'
import { Button } from 'src/components'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    justify-content: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
`)

export const STContent = styled('div', {
  label: 'Content'
})(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    svg {
      width: 80%;
    }
  }
`)

export const STTextNoVibe = styled('div', {
  label: 'TextNoVibe'
})(({ theme }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    h5,
    span {
      max-width: 472px;
      text-align: center;
    }
`)

export const STButton = styled(Button, {
  label: 'Button'
})(({ theme }) => `
    margin-top: 12px;
    padding: 16px 32px;
`)
