import { FC, useRef, useState } from 'react'

import { Collapse } from '@mui/material'
import { LinkedInExperienceItem, LinkedInExperienceNoData } from 'src/components'
import { IconArrowDown } from 'src/icons'
import { ILinkedinWorkingExperience } from 'src/interfaces'
import { LinkedinUtil } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  linkedinWorkingExperiences?: ILinkedinWorkingExperience[]
  candidateId: number
  isSubscribed?: boolean
  onSubscribeChanged?: (subscribed: boolean) => void
}

export const NonIntroExperiencesSection: FC<IProps> = ({
  linkedinWorkingExperiences = [],
  candidateId,
  isSubscribed,
  onSubscribeChanged
}) => {
  const [open, setOpen] = useState(true)
  const itemRef = useRef<HTMLDivElement | null>(null)

  const handleToggle = () => {
    !open && itemRef.current?.scrollIntoView({ behavior: 'smooth' })
    setOpen(!open)
  }

  return (
    <div className={Style.STContainer}>
      <button className={Style.STHeader} onClick={handleToggle}>
        <p className={Style.STitle}>Experience</p>
        <div className="fx fx-ai-center">
          <div
            className={Style.STArrowIcon}
            style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
          >
            <IconArrowDown/>
          </div>
        </div>
      </button>
      <Collapse in={open}>
        {linkedinWorkingExperiences.length
          ? (
            LinkedinUtil.descByDate(linkedinWorkingExperiences).map((item, index) => (
              <LinkedInExperienceItem
                ref={itemRef}
                isLast={index === linkedinWorkingExperiences.length - 1}
                isOnly={linkedinWorkingExperiences.length === 1}
                key={index}
                lightMode={false}
                item={item}
              />
            ))
          )
          : (
            <LinkedInExperienceNoData
              candidateId={candidateId}
              subscribed={isSubscribed}
              onSubscribeChanged={onSubscribeChanged}
            />
          )}
      </Collapse>
    </div>
  )
}
