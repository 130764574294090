import { IHashTagCountVideoModel, IHashTagModel, IPaginationQuery, TAxiosResponseList, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class HashTagApi {
  static readonly _prefix = '/hashtags'

  static pagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IHashTagModel> {
    return axiosHiringApi.get(`${this._prefix}`, { params })
  }

  static publicPagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IHashTagModel> {
    return axiosHiringApi.get(`${this._prefix}/public`, { params })
  }

  static me(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IHashTagModel> {
    return axiosHiringApi.get(`${this._prefix}/me`, { params })
  }

  static countVideo(
    params: {
      hashtagIds?: number[]
    }
  ): TAxiosResponseList<IHashTagCountVideoModel> {
    return axiosHiringApi.get(`${this._prefix}/count-videos`, { params })
  }
}
