import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { STMessage } from './styled'

interface IProps {
  status?: 'default' | 'success' | 'error'
  showMessage?: boolean
  children?: ReactNode
}

export const Message: FC<IProps> = (props) => {
  return <STMessage variant="body2-regular" {...props}/>
}

export const ErrorMessage: FC<{
  className?: string
  children?: ReactNode
}> = (props) => {
  if (!props.children) {
    return null
  }

  return (
    <div className={clsx('fx gap-2', props.className)}>
      <Message showMessage status="error">
        {props.children}
      </Message>
    </div>
  )
}
