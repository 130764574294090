import { Box } from '@mui/material'
import { FC, ReactNode, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Tabs, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useAppDispatch, useBehaviorMapper, useIfMobileL } from 'src/hooks'
import { ITab } from 'src/interfaces'
import { setLayoutPageTitle, setLayoutShouldShowShareButtonHeaderMobile } from 'src/store/actions'
import Style from './style.module.scss'
import { SettingTabService } from './tab.service'

const tabs: (ITab & { path: string })[] = [
  { title: 'Public Profile', event: ETrackingEvent.BTN_PUBLIC_PROFILE, path: 'profile' },
  { title: 'Basic Information', event: ETrackingEvent.BTN_BASIC_INFO, path: 'information' },
  { title: 'Security', event: ETrackingEvent.BTN_SECURITY, path: 'security' },
  { title: 'Notification Preferences', event: ETrackingEvent.BTN_SECURITY, path: 'notification' }
]

export const Setting: FC<{ children?: ReactNode }> = (props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isMobile = useIfMobileL()
  const { eventHandler } = useAnalytic('')
  const currentTab = useBehaviorMapper(SettingTabService.currentTab)

  useEffect(() => {
    const currentTabIndexByPath = tabs.findIndex(tab => {
      return '/setting/' + tab.path === history.location.pathname
    })
    SettingTabService.setTab(currentTabIndexByPath > -1 ? currentTabIndexByPath : 0)
  }, [history])

  const handleSetTab = useCallback((value: number) => {
    const selectedTab = tabs[value]
    if (selectedTab?.path) {
      history.replace(selectedTab.path)
      SettingTabService.setTab(value)
    }
    if (selectedTab?.event) {
      eventHandler(tabs[value].event as string)()
    }
  }, [eventHandler, history])

  useEffect(() => {
    dispatch(setLayoutPageTitle('Settings'))
    dispatch(setLayoutShouldShowShareButtonHeaderMobile(false))

    return () => {
      dispatch(setLayoutShouldShowShareButtonHeaderMobile(true))
    }
  }, [dispatch])

  return (
    <div className={Style.STSettingContainer}>
      <Box
        paddingX={isMobile ? 0 : 4}
        marginY="10px"
      >
        <Tabs tab={currentTab} tabs={tabs} setTab={handleSetTab} variant="scrollable"/>
      </Box>

      {props.children}
    </div>
  )
}
