import { EWorkInterests } from 'src/enums'
import { IUserModel } from 'src/interfaces'

const mapEnumToText = (value: EWorkInterests) => {
  if (value === EWorkInterests.HYBRID) {
    return 'hybrid work'
  }

  return value.toLowerCase()
}

export const useUserOtherInfo = (user?: Pick<IUserModel, 'willingToRelocate' | 'workInterests'>) => {
  try {
    if (!user) {
      return {
        shouldShowOtherInfo: false,
        willingToRelocateText: '',
        workInterestsText: ''
      }
    }

    const { willingToRelocate } = user
    const workInterested = [...(user.workInterests || [])]
    const lastWorkInterested = workInterested.pop()
    const workInterestsText = workInterested.map(mapEnumToText).join(', ') + (lastWorkInterested ? workInterested.length ? ` & ${mapEnumToText(lastWorkInterested)}` : mapEnumToText(lastWorkInterested) : '')

    return {
      shouldShowOtherInfo: willingToRelocate || !!user.workInterests?.length,
      willingToRelocateText: willingToRelocate ? 'Willing to relocate' : '',
      workInterestsText: workInterestsText ? 'Interested in ' + workInterestsText : ''
    }
  } catch (error) {
    console.log(error)
    return {
      shouldShowOtherInfo: false,
      willingToRelocateText: '',
      workInterestsText: ''
    }
  }
}
