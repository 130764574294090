import { IUserModel } from 'src/interfaces'
import { EventService, NotificationService } from 'src/services'
import { AUTH_LOGOUT_SUCCESS, AUTH_SET_CREDENTIALS, AUTH_SET_GUIDE, AUTH_SET_STATS, IAuthState, TAuthAction } from '../types'

export const initState: IAuthState = {
  isAuthenticated: null,
  credentials: {} as IUserModel,
  stats: {
    countHMIntroduces: 0,
    countHMLikes: 0
  },
  forceOpenTutorial: false
}

export const reducer = (state = initState, action: TAuthAction) => {
  switch (action.type) {
    case AUTH_SET_CREDENTIALS:
      EventService.bind(action.value?.email)
      NotificationService.bind(action.value?.id)
      return {
        ...state,
        isAuthenticated: true,
        credentials: action.value,
        stats: {
          ...(action.value?.stats || {
            countHMIntroduces: 0,
            countHMLikes: 0
          })
        }
      }
    case AUTH_SET_STATS:
      return {
        ...state,
        stats: action.value
      }
    case AUTH_LOGOUT_SUCCESS:
      EventService.unbind()
      NotificationService.unbind()
      return {
        ...state,
        isAuthenticated: false,
        credentials: {}
      }
    case AUTH_SET_GUIDE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          guide: {
            ...state.credentials.guide,
            ...action.value
          }
        }
      }
    default:
      return state
  }
}
