import { BehaviorSubject } from 'rxjs'
import { AnalyticApi, CampaignApi, UploadApi } from 'src/api'
import { EFileUploadKind, ICampaignDetail, ICampaignModel, IFileUpload } from 'src/interfaces'
import { WithOutNextComplete } from 'types/rxjs'

type IGuestCampaignDetail = Omit<ICampaignDetail,
  | 'slug'
  | 'allowRemote'
  | 'requireZipCode'
  | 'requiredResume'
  | 'requireLinkedInUrl'
  | 'questionIds'
>

type VideoData = { file: File; offsetAt?: number; thumbnailUrl?: string }

export class GuestOnboardingService {
  private static _showedOnboarding$ = new BehaviorSubject(false)
  private static _tempJobCreationInputData = new BehaviorSubject<IGuestCampaignDetail | null>(null)
  private static _campaignVideoData$ = new BehaviorSubject<VideoData | null>(null)
  private static _vvcVideoData$ = new BehaviorSubject<VideoData | null>(null)
  private static _createdCampaignSlug$ = new BehaviorSubject<string | null>(null)
  private static _vvcId$ = new BehaviorSubject<number | null>(null)

  public static get showedOnboarding$(): WithOutNextComplete<
    typeof GuestOnboardingService._showedOnboarding$
  > {
    return GuestOnboardingService._showedOnboarding$
  }

  public static showOnboarding() {
    GuestOnboardingService._showedOnboarding$.next(true)
  }

  public static get tempJobCreationInputData$(): WithOutNextComplete<
    typeof GuestOnboardingService._tempJobCreationInputData
  > {
    return GuestOnboardingService._tempJobCreationInputData
  }

  public static setTempJobCreationInputData(data: IGuestCampaignDetail | null) {
    GuestOnboardingService._tempJobCreationInputData.next(data)
  }

  public static get campaignVideoData$(): WithOutNextComplete<
    typeof GuestOnboardingService._campaignVideoData$
  > {
    return GuestOnboardingService._campaignVideoData$
  }

  public static setCampaignVideoData(val: VideoData | null) {
    GuestOnboardingService._campaignVideoData$.next(val)
  }

  public static get vvcVideoData$(): WithOutNextComplete<
    typeof GuestOnboardingService._vvcVideoData$
  > {
    return GuestOnboardingService._vvcVideoData$
  }

  public static setVvcVideoData(val: VideoData | null) {
    GuestOnboardingService._vvcVideoData$.next(val)
  }

  public static get createdCampaignSlug$(): WithOutNextComplete<
    typeof GuestOnboardingService._createdCampaignSlug$
  > {
    return GuestOnboardingService._createdCampaignSlug$
  }

  public static setCreatedCampaignSlug(val: string | null) {
    GuestOnboardingService._createdCampaignSlug$.next(val)
  }

  public static get vvcId$(): WithOutNextComplete<
    typeof GuestOnboardingService._vvcId$
  > {
    return GuestOnboardingService._vvcId$
  }

  public static setVvcId(val: number | null) {
    GuestOnboardingService._vvcId$.next(val)
  }

  public static async uploadFiles(payload: IFileUpload, seed: Record<string, unknown> = {}, options?: { id: string }) {
    if (payload.jdFile) {
      seed.jdFileUrl = await UploadApi.upload({
        kind: EFileUploadKind.JD_CAMPAIGN_FILE,
        file: payload.jdFile,
        filename: payload.jdFile.name,
        sessionId: AnalyticApi._session
      })
    } else {
      seed.jdFileUrl = undefined
    }

    if (payload.logoFile) {
      seed.logoUrl = await UploadApi.upload({
        kind: EFileUploadKind.COMPANY_CAMPAIGN_PHOTO,
        file: payload.logoFile,
        sessionId: AnalyticApi._session
      })
    } else {
      seed.logoUrl = undefined
    }

    if (payload.uploadVideoFile) {
      seed.uploadVideoKey = await UploadApi.upload({
        kind: payload.uploadVideoFile.type.startsWith('audio')
          ? EFileUploadKind.COMPANY_CAMPAIGN_AUDIO
          : EFileUploadKind.COMPANY_CAMPAIGN_VIDEO,
        file: payload.uploadVideoFile,
        sessionId: AnalyticApi._session
      }, options)
    } else {
      seed.uploadVideoKey = undefined
    }

    const videoBg = payload.uploadAudioBackground
    if (videoBg?.file) {
      const file = videoBg.file
      seed.uploadAudioBgKey = await UploadApi.upload({
        kind: EFileUploadKind.COMPANY_CAMPAIGN_AUDIO_BG,
        file,
        sessionId: AnalyticApi._session
      }, options)
    } else if (videoBg?.key) {
      seed.uploadAudioBgKey = videoBg.key
    } else {
      seed.uploadAudioBgKey = undefined
    }

    return seed as unknown as { id?: ICampaignModel['id'] } & Parameters<typeof CampaignApi.create>[0]
  }
}
