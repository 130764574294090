import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(4)};
  padding-top: ${theme.spacing(20)}
`)
