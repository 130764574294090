import { isString } from 'lodash'
import { useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useUnsubscribeEffect } from 'src/hooks'
import { ICampaignDetail } from 'src/interfaces'

export const useCampaignById = (idOrHashId?: number | string) => {
  const [campaign, setCampaign] = useState<ICampaignDetail>()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!idOrHashId) {
      return
    }

    setLoading(true)
    from(
      isString(idOrHashId) ? CampaignApi.publicCampaign(idOrHashId) : CampaignApi.getOne(idOrHashId)
    )
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: (res) => {
          setCampaign(res.data)
        },
        error: (err) => {
          setError(err)
        }
      })
  }, [idOrHashId])

  return { campaign, error, loading }
}
