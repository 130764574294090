import {
  takeLatest
  // , put, select
} from 'redux-saga/effects'
import {
  ECandidateReaction, EMessage, EReactionTable
  // , EUserGuide
} from 'src/enums'
import { SnackbarService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
// import { IUserModel } from 'src/interfaces'
// import { GuideApi } from 'src/api'
import { CandidateApi } from 'src/api'
import {
  CANDIDATE_EDIT
} from '../types'

interface ICandidateAction {
  type: typeof CANDIDATE_EDIT
  payload: {
    id: number
    action: ECandidateReaction
    reactionableType?: EReactionTable
    reactionableId?: number
  }
}

function * candidateEdit(action: ICandidateAction) {
  try {
    // const auth: { isAuthenticated: boolean; credentials: IUserModel } = yield select(state => state.layout)
    // const guide = auth.credentials.guide

    // call candidate edit
    const payload = {
      reactionableType: action.payload.reactionableType,
      reactionableId: action.payload.reactionableId
    }

    yield CandidateApi.action(action.payload.id, action.payload.action, payload)

    // check guide and update for like
    // if (!guide?.like && action.payload.action === ECandidateReaction.LIKE) {
    //   yield GuideApi.edit(EUserGuide.LIKE)

    //   yield put({ type: AUTH_GET_PROFILE })
    //   yield put({
    //     type: GUIDE_SET_FIRST_ACTION,
    //     value: {
    //       name: EUserGuide.LIKE,
    //       content: 'Your introductions are waiting'
    //     }
    //   })
    // }

    // check guide and update for super like
    // if (!guide?.superLike && action.payload.action === ECandidateReaction.SUPER_LIKE) {
    //   yield GuideApi.edit(EUserGuide.SUPER_LIKE)

    //   yield put({ type: AUTH_GET_PROFILE })
    //   yield put({
    //     type: GUIDE_SET_FIRST_ACTION,
    //     value: {
    //       name: EUserGuide.SUPER_LIKE,
    //       content: "Come here! One guys've just sent back Superliked to you"
    //     }
    //   })
    // }
  } catch (error) {
    SnackbarService.push({
      severity: EMessage.ERROR,
      content: getApiErrorMessage(error)
    })
  }
}

export type TCandidateSagaAction = Parameters<typeof candidateEdit>[0]

export const handler = function * () {
  yield takeLatest(CANDIDATE_EDIT, candidateEdit)
}
