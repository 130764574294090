import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import Style from './style.module.scss'

interface IProps {
  onChange?: (value: number) => void
  mini?: boolean
  children?: ReactNode
  onMouseLeave?: () => void
  onMouseEnter?: () => void
}
export const Control: FC<IProps> = (props) => {
  // useEffect(() => {
  //   if (opacity) {
  //     const timer = setTimeout(() => setOpacity(0), 2000)
  //     return () => clearTimeout(timer)
  //   }
  // }, [opacity])

  // useEffect(() => {
  //   props.onChange?.(opacity)
  // }, [opacity])

  return (
    <div
      className={clsx([
        Style.controlContainer,
        { [Style.Mini]: props.mini }
      ])}
      onMouseMove={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className={clsx(Style.control, { [Style.controlMini]: props.mini })}>{props.children}</div>
    </div>
  )
}
