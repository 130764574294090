import { IRouterOption } from 'src/router'
import { Vibes } from './components/list'
import { GuestVibes } from './components/list/guest-index'
import { EVibesRoutes } from './routes.enum'

export { EVibesRoutes }

export const VibesRoutes: IRouterOption[] = [
  {
    path: '/:type(vibes)?',
    component: Vibes,
    name: EVibesRoutes.VIBES,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/vibes/public',
    component: GuestVibes,
    name: EVibesRoutes.PUBLIC_VIBES,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
