import { FC, SVGProps } from 'react'

export const IllustrationComment: FC<SVGProps<any>> = (props) => {
  return (
    <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.2669 17.4478L31.267 17.4476C32.2435 14.4251 31.4758 11.1271 29.3765 8.219C27.2772 5.31085 23.8602 2.81862 19.6107 1.45013L19.6101 1.44992C15.3856 0.081771 11.1643 0.111279 7.76232 1.24726C4.36019 2.38328 1.80476 4.61274 0.828236 7.63532C-0.148258 10.6578 0.619379 13.9558 2.71869 16.8639C4.81803 19.772 8.235 22.2643 12.4845 23.6328L12.4845 23.6327L12.4885 23.6341C12.9215 23.7784 13.3953 23.9202 13.8158 24.0136L14.887 24.2517L13.9208 24.7719C13.3191 25.0959 12.6068 25.4862 12.0243 25.807C12.182 25.8193 12.3528 25.8312 12.5358 25.8419C13.6555 25.9073 15.2279 25.93 17.0349 25.7583C20.65 25.4149 25.1683 24.2974 28.889 21.2277C29.9684 20.1448 30.8035 18.8844 31.2669 17.4478Z" stroke="black" strokeWidth="0.816829"/>
      <path d="M9.01641 12.1338C9.70025 12.335 10.4299 11.9321 10.6324 11.2435C10.8308 10.569 10.3915 9.83176 9.73988 9.62681C9.05668 9.42719 8.32838 9.82996 8.12607 10.5178C7.92494 11.2016 8.32783 11.9313 9.01641 12.1338Z" fill="#181920" stroke="black" strokeWidth="0.544553"/>
      <path d="M16.0021 14.0879C16.686 14.289 17.4156 13.8861 17.6182 13.1975C17.8193 12.5137 17.4164 11.784 16.7278 11.5815C16.044 11.3804 15.3143 11.7833 15.1118 12.4719C14.9107 13.1557 15.3136 13.8854 16.0021 14.0879Z" fill="#181920" stroke="black" strokeWidth="0.544553"/>
      <path d="M23.7281 13.5881L23.7208 13.5864L23.7137 13.5842C23.0298 13.3831 22.3002 13.786 22.0976 14.4746C21.8965 15.1584 22.2994 15.8881 22.988 16.0906C23.6712 16.2915 24.4001 15.8896 24.6034 15.2022C24.8093 14.4571 24.3923 13.7443 23.7281 13.5881Z" fill="#181920" stroke="black" strokeWidth="0.544553"/>
    </svg>
  )
}

export const IllustrationStar: FC<SVGProps<any>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.9951 5.14424L15.435 7.19339C16.0327 7.44954 16.0327 8.30335 15.435 8.5595L10.9951 10.6087C10.8244 10.694 10.6536 10.7794 10.6536 10.9502L8.60447 15.39C8.34832 15.9877 7.4945 15.9877 7.23836 15.39L5.27459 11.0356C5.18921 10.8648 5.01844 10.694 4.84768 10.6087L0.493226 8.90102C-0.104445 8.64488 -0.189831 7.79106 0.407839 7.53492L4.84768 5.22962C5.01844 5.14423 5.10383 5.05885 5.18921 4.88809L7.23836 0.448253C7.4945 -0.149418 8.34832 -0.149418 8.60447 0.448253L10.6536 4.88809C10.6536 4.97347 10.8244 5.05885 10.9951 5.14424Z" fill="#DAE029"/>
    </svg>
  )
}
