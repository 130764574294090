import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { EMessage } from 'src/enums'
import { useBehaviorMapper, useUnsubscribeEffect } from 'src/hooks'
import { CampaignMutationService, SnackbarService } from 'src/services'
import { EUploadStatus, UploadService } from 'src/services/upload.service'

/**
 * Pull data from CampaignMutationService.data$, check if data has been pushed to UploadService,
 * if not, push it to UploadService to keep track of upload progress
 */
export const useUploadRecord = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!data.uploadVideoFile) return
    if (!data.uploadVideoRefId) return

    const uploadItem = UploadService.getUploadItem(data.uploadVideoRefId)

    const uploadStatus = uploadItem?.status?.getValue()
    if (
      uploadStatus && [EUploadStatus.UPLOADING, EUploadStatus.COMPLETED, EUploadStatus.FAILED].includes(uploadStatus)
    ) {
      return
    }

    if (!uploadItem) {
      UploadService.pushTrackUploadItem(data.uploadVideoRefId)
    }

    UploadService.updateTrackUploadItem(data.uploadVideoRefId, 0, EUploadStatus.UPLOADING)
    from(CampaignMutationService.uploadFilesAndSaveDraft({
      uploadVideoFile: data.uploadVideoFile,
      uploadAudioBackground: data.uploadAudioBackground,
      thumbnailOffset: data.thumbnailOffset
    }, data.uploadVideoRefId ? { id: data.uploadVideoRefId } : undefined))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('failed to upload campaign video', error)
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: 'Failed to upload video'
          })

          return EMPTY
        })
      )
      .subscribe(() => {
      })
  }, [data.uploadVideoRefId, data.uploadVideoFile])
}
