import { INotificationModel, INotificationSettingRecord, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class NotificationApi {
  static readonly _prefix = '/notifications'

  static pagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<INotificationModel> {
    return axiosHiringApi.get(`${this._prefix}`, { params })
  }

  static detail(
    id: number
  ): TAxiosResponseData<INotificationModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}`)
  }

  static delete(
    id: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/${id}`)
  }

  static getSettings(): TAxiosResponseData<INotificationSettingRecord[]> {
    return axiosHiringApi.get(`${this._prefix}/settings`)
  }

  static updateSettings(
    data: INotificationSettingRecord[]
  ): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/settings`, {
      settings: data
    })
  }
}
