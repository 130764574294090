
import clsx from 'clsx'
import { FC, ReactNode, useEffect, useState } from 'react'
import Style from './style.module.scss'

interface IOnboardingSliderProps {
  videoSrcs?: string[]
  /**
   * @deprecated not used
   */
  videoSrc?: string
  title?: string
  titleElement?: ReactNode
  text?: string
  onClose: () => void
  show?: boolean
}
export const OnboardingSlider: FC<IOnboardingSliderProps> = ({ videoSrcs, videoSrc, title, text, titleElement, show }) => {
  const [rotate, setRotate] = useState<boolean>(false)

  useEffect(() => {
    if (show) {
      const timeoutRef = setTimeout(() => {
        setRotate(true)
      }, 0)

      return () => {
        clearTimeout(timeoutRef)
      }
    }

    setRotate(false)
  }, [show])

  return (
    <div
      className={clsx(
        Style.slide
      )}
      style={show ? { display: 'block' } : { display: 'none' }}
    >
      {videoSrc && (
        <div
          className={Style.coverSingleVideoSrc}
        >
          <div>
            <video autoPlay loop muted style={{ width: '100%', height: 'auto' }} src={videoSrc}/>
          </div>
        </div>
      )}

      {Array.isArray(videoSrcs) && (
        <div className="flex fx-row gap-4 fx-jc-center">
          {videoSrcs.map((videoSrc) => (
            <div className={Style.cover} key={videoSrc}>
              <div className={clsx(Style.cover_content, rotate && Style.rotate)}>
                <div className={Style.cover_front}>
                  <video autoPlay loop muted style={{ height: '100%' }} src={videoSrc}/>
                </div>
                <div className={Style.cover_back}/>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="fx-column gap-2 mt-7">
        {title && (
          <h5
            className={clsx('m-0 color-white text-center', Style.title)}
          >
            {title}
          </h5>
        )}

        {titleElement}

        {text && (
          <p
            className="m-0 color-neutral-theme-50 subtitle-regular text-center"
          >
            {text}
          </p>
        )}
      </div>
    </div>
  )
}
