import { BehaviorSubject } from 'rxjs'
import { IUserModel } from 'src/interfaces'
import { noticesCollection } from './firebase'

export interface IFirebaseNotification {
  newLikeCandidate?: boolean
  newIntroCandidate?: boolean
  countNotifications?: number
  countCredits?: number
  likeList?: number[]
  introList?: number[]
}

export const NotificationService = new (
  class {
    readonly firebaseNotification$ = new BehaviorSubject<IFirebaseNotification>({})

    private userId: IUserModel['id']
    private notifications: IFirebaseNotification = {}
    private unsubscribe() {}

    bind(userId: IUserModel['id']) {
      // if (this.userId === userId) {
      //   return this.unbind
      // }

      // console.log(this.userId)

      this.userId = userId

      this.unsubscribe = noticesCollection
        .doc(String(userId))
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          const data = (snapshot.data() as IFirebaseNotification) || {}
          this.notifications = {
            ...this.notifications,
            countNotifications: Math.max(0, data.countNotifications || 0),
            countCredits: Math.max(0, data.countCredits || 0),
            newLikeCandidate: data.newLikeCandidate,
            newIntroCandidate: data.newIntroCandidate,
            likeList: data.likeList,
            introList: data.introList
          }

          this.firebaseNotification$.next(
            this.notifications
          )

          // this.firebaseNotification$.next(
          //   Object.fromEntries(
          //     Object.entries(data).map(
          //       ([key, value]) =>
          //         ([
          //           key,
          //           Math.max(0, value || 0)
          //         ])
          //     )
          //   )
          // )
        })

      return this.unbind
    }

    unbind() {
      this.unsubscribe()
      this.userId = undefined

      setTimeout(() => {
        this.firebaseNotification$.next({})
      }, 100)
    }
  }
)()
