import { motion } from 'framer-motion'
import { FC, useEffect, useRef, useState } from 'react'
import { Subject } from 'rxjs'
import { scan, takeUntil } from 'rxjs/operators'
import { useUnsubscribeEffect } from 'src/hooks'
import { IconPenny } from 'src/icons'
import { calculateXY } from 'src/utils'

export const FlyingCoin: FC<{ x1: number; x2: number; y1: number; y2: number; _doneChannel: Subject<number> }> = (props) => {
  return (
    <motion.div
      initial={{ opacity: 1, x: props.x1, y: props.y1, scale: 1 }}
      animate={{
        x: props.x2,
        y: props.y2,
        scale: 0.5,
        opacity: [1, 0.8, 0.5, 0]
      }}
      transition={{ duration: 3, delay: Math.random() * 1.5 }}
      style={{ position: 'fixed' }}
      onAnimationComplete={() => props._doneChannel.next(1)}
    >
      <IconPenny/>
    </motion.div>
  )
}

interface IProps {
  play: boolean
  left?: number
  top?: number
  qty: number
  fromSelector: string
  toSelector: string
  onEnd?: () => void
}

export const FlyingCoins: FC<IProps> = (props) => {
  const [[x2, y2], setX2Y2] = useState<Array<number | undefined>>([undefined, undefined])
  const doneSubjectRef = useRef(new Subject<number>())

  useEffect(() => {
    setTimeout(() => {
      const distances = calculateXY(props.fromSelector, props.toSelector)
      setX2Y2(distances)
    }, 2000)
  }, [props.fromSelector, props.toSelector, props.play])

  useUnsubscribeEffect((unsubscribe) => {
    const subscription = doneSubjectRef.current.asObservable()
      .pipe(
        takeUntil(unsubscribe),
        scan((acc, value) => acc + value, 0)
      )
      .subscribe((acc) => {
        if (acc === props.qty) {
          props.onEnd?.()
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [doneSubjectRef, props.qty])

  return (
    <div style={{ position: 'absolute', left: props.left || 0, top: props.top || 0 }}>
      {x2 !== undefined && y2 !== undefined && props.play
        ? (
          Array.from({ length: props.qty }).map((_, index) => (
            <FlyingCoin _doneChannel={doneSubjectRef.current} key={index} x1={0} y1={0} x2={x2} y2={y2}/>
          ))
        )
        : null}
    </div>
  )
}
