import { useMediaQuery } from '@mui/material'
import { FC, ReactElement, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Easing } from 'react-native-web'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fly from 'react-flying-objects'

import { IconPenny } from 'src/icons'

const DELAY = 100
const DURATION = 500

const Coin: FC = () => {
  return <IconPenny/>
}

interface IFlyingCoinsProps {
  top: number
  right: number
}

export const FlyingCoins: FC<IFlyingCoinsProps> = ({ right, top }) => {
  const [flyingObjects, setFlyingObjects] = useState([]) // Used to manage all flying currently objects by the Fly component
  const [objectToFly, setObjectToFly] = useState<ReactElement>(<Coin/>)

  const isMax768 = useMediaQuery('(max-width:768px)')
  const isMax1024 = useMediaQuery('(max-width:1024px)')

  useEffect(() => {
    const numberOfCoins = 10
    for (let i = 0; i < numberOfCoins; i++) {
      setTimeout(() => {
        setObjectToFly(<Coin/>)
      }, i * Math.random() * 300 + 100)
    }
  }, [])

  const objectConfig = useRef({
    right: {
      fromValue: 0,
      toValue: -right + 275 + 88 + 32 + 22 + (isMax768 ? 120 : 0),

      duration: DURATION,
      delay: DELAY
    },
    top: {
      fromValue: 30,
      toValue: -30,

      duration: DURATION,
      delay: DELAY
    },
    height: {
      fromValue: 20,
      toValue: 10,

      duration: DURATION,
      delay: DELAY
    },
    width: {
      fromValue: 20,
      toValue: 10,

      duration: DURATION,
      delay: DELAY
    },
    opacity: {
      fromValue: 1,
      toValue: 0,

      duration: DURATION,
      easing: Easing.exp,
      delay: DELAY
    }
  })

  useEffect(() => {
    objectConfig.current.right.toValue = -right + (isMax768 ? 0 : isMax1024 ? 112 : 275) + 88 + 32 + 22 + (isMax768 ? 0 : 120)
  }, [isMax1024, isMax768, objectConfig, right])

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 101
      }}
    >
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Fly
          objectToFly={objectToFly}
          objectConfig={objectConfig.current}
          flyingObjects={flyingObjects}
          setFlyingObjects={setFlyingObjects}
        />
      </div>
    </div>
  )
}
