import clsx from 'clsx'
import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import Style from './style.module.scss'

export interface ITeleprompterEditorProps {
  editorClassName?: string
  containerClassName?: string
  onChange?: (script: string) => void
  teleprompterContent?: string
}

export const TeleprompterEditor = forwardRef<HTMLTextAreaElement, ITeleprompterEditorProps>((props, ref) => {
  const [value, setValue] = useState('')

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    props.onChange?.(newValue)
    setValue(newValue)
  }, [props])

  useEffect(() => {
    setValue(props.teleprompterContent || '')
  }, [props.teleprompterContent])

  return (
    <div className={clsx(Style.container, props.containerClassName)}>
      <textarea
        ref={ref}
        className={clsx('body1-regular', Style.editor, props.editorClassName)}
        placeholder="Add your script here"
        value={value}
        onChange={handleChange}
      />

      <div className={Style.counter}>
        <span className="meta-regular color-neutral-300">{value.length}</span>
      </div>
    </div>
  )
})

TeleprompterEditor.displayName = 'TeleprompterEditor'
