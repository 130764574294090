import { FC } from 'react'
import { IconFacebook, IconInstagram, IconLinkedin, IconTwitter } from 'src/icons'
import Style from './style.module.scss'

const icons = [
  {
    icon: IconFacebook,
    url: 'https://www.facebook.com/knowmebest'
  },
  {
    icon: IconTwitter,
    url: 'https://twitter.com/the_knowme'
  },
  {
    icon: IconLinkedin,
    url: 'https://www.linkedin.com/company/knowmebest/'
  },
  {
    icon: IconInstagram,
    url: 'https://www.instagram.com/the_knowme/'
  }
]

const date = new Date()

export const Footer: FC = () => {
  return (
    <div className={Style.Footer}>
      <div className={Style.Icons}>
        {icons.map(({ icon: Icon, url }, index) => (
          <div className={Style.Icon} key={index} onClick={() => window.open(url, '_blank')}>
            <Icon/>
          </div>
        ))}
      </div>
      <div className={Style.Text}>
        ©{date.getFullYear()} Know Me, Inc. All rights reserved. Various trademarks held by their respective owners.
      </div>
    </div>
  )
}
