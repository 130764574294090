import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation } from 'src/hooks'
import { EAuthView } from 'src/interfaces'
import { Footer } from 'src/layout/layout-container/footer'
import { setNameSchema } from 'src/validation'
import { Header } from '../../../header'
import { LeftSection } from '../../../left-section'
import Style from './style.module.scss'

interface ISetName {
  name: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  handleChangeFormData: (name: string, value: TSignUpPayload[keyof TSignUpPayload]) => void
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onSubmit: (data: any) => Promise<void>
}

export const SetName: FC<IProps> = ({ handleChangeFormData, onSubmit }) => {
  const [formValues, setFormValues] = useState<ISetName>({ name: '' })
  const { errors, validateAt } = useOldValidation<ISetName>()
  const { eventHandler } = useAnalytic('signup_name')
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: setNameSchema,
      path: name as keyof ISetName,
      data: { ...formValues, [name]: value }
    })
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, formValues])

  const onNext = (e: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    handleChangeFormData('name', formValues.name)
    onSubmit({ name: formValues.name }).catch(
      () => setLoading(false)
    )
  }

  const isInValid = useMemo(() =>
    !formValues.name || errors.name
  , [errors.name, formValues.name])

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>
        <LeftSection illustration={3}/>
        <div className={Style.RightSide}>
          <div className={Style.STSetPasswordWrapper}>
            <form className={Style.STForm} onSubmit={onNext}>
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>One Last Thing</Typography>
              </div>
              <div className={Style.FormInput}>
                <Input
                  label="Name"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formValues.name || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_NAME)}
                  error={errors.name}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                />

                <Button
                  fullWidth
                  disabled={!!isInValid || loading}
                  onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT)}
                  type="submit"
                >
                  Complete Signup
                </Button>
              </div>
            </form>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  )
}
