import { Box, Dialog, Paper, PaperProps, Typography } from '@mui/material'
import { FC } from 'react'
import { Button } from 'src/components'
import Style from './style.module.scss'

interface IProps {
  open: boolean
  onClose?: () => void
  onAgree?: () => void
  type: 'archive' | 'cancel'
}

const mapTypeToMessage = (type: IProps['type']) => {
  switch (type) {
    case 'archive':
      return 'You would like to archive the Job. Confirm to proceed.'
    case 'cancel':
      return 'If you go back now, you will lose your change in this page'
    default:
      return ''
  }
}

const mapTypeToTitle = (type: IProps['type']) => {
  switch (type) {
    case 'archive':
      return 'Confirmation'
    case 'cancel':
      return 'Exit Without Saving'
    default:
      return ''
  }
}

export const DialogArchiveCancel: FC<IProps> = ({ open, onClose, onAgree, type }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
    >
      <div className={Style.content}>
        <Typography variant="h4" textAlign="center">{mapTypeToTitle(type)}</Typography>
        <span className={Style.description}>{mapTypeToMessage(type)}</span>

        <Box display="flex" flexDirection="column" gap="8px" mt="40px" width="100%">
          <Button className={Style.myButton} order="secondary" onClick={onAgree}>
            Yes
          </Button>
          <Button className={Style.myButton} order="secondary" onClick={onClose}>
            No
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}

const PaperComponent: FC<PaperProps> = (props) => {
  return (
    <Paper
      {...props}
      sx={{ borderRadius: '32px' }}
    />
  )
}
