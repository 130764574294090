
/* layout actions */
export const PAYMENT_INTENT_ID = Symbol('PAYMENT_INTENT_ID')

/**
 * state
 */
export interface IPaymentState {
  paymentIntentId: string | null
}

/**
 * actions
 */
export interface IPaymentAction {
  type: typeof PAYMENT_INTENT_ID
  value?: string | null
}
