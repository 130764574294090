import { IPaymentAction, IPaymentState, PAYMENT_INTENT_ID } from '../types'

const initState: IPaymentState = {
  paymentIntentId: null
}

export const reducer = (state = initState, action: IPaymentAction) => {
  switch (action.type) {
    case PAYMENT_INTENT_ID:
      return {
        ...state,
        paymentIntentId: action.value
      }
    default:
      return state
  }
}
