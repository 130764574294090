import { ICampaignModel } from 'src/interfaces'

export const CAMPAIGN_CREATED = Symbol('CAMPAIGN_CREATED')
export const CAMPAIGN_UPDATED = Symbol('CAMPAIGN_UPDATED')
export const CAMPAIGN_PAGE_ENTERED = Symbol('CAMPAIGN_PAGE_ENTERED')
export const CAMPAIGN_FETCHED = Symbol('CAMPAIGN_FETCHED')
export const CAMPAIGN_RESET = Symbol('CAMPAIGN_RESET')

export interface ICampaignState {
  campaigns: ICampaignModel[]
}

export interface ICampaignAction {
  type: typeof CAMPAIGN_CREATED | typeof CAMPAIGN_UPDATED | typeof CAMPAIGN_FETCHED | typeof CAMPAIGN_PAGE_ENTERED | typeof CAMPAIGN_RESET
  value?: ICampaignModel[]
}
