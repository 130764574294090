import { useState } from 'react'
import { takeUntil } from 'rxjs'
import { IFirebaseNotification, NotificationService } from 'src/services'
import { useUnsubscribeEffect } from './useUnsubscribeEffect'

const watchFirebaseKeys: Array<keyof IFirebaseNotification> = ['newIntroCandidate', 'countNotifications', 'likeList', 'introList']

export const useShowRedDot = () => {
  const [showRedDot, setShowRedDot] = useState(false)

  useUnsubscribeEffect((unsubscribe$) => {
    NotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        let redDotSetToTrue = false
        watchFirebaseKeys.forEach(watchKey => {
          if (watchKey === 'countNotifications') {
            const numberNotification = data[watchKey] as number ?? 0
            if (numberNotification > 0) {
              setShowRedDot(true)
              redDotSetToTrue = true
            }
          }

          if (redDotSetToTrue) {
            return
          }

          if (Array.isArray(data[watchKey])) {
            const arrayNotification = data[watchKey] as number[]
            setShowRedDot(arrayNotification.length > 0)
          }
        })
      })
  }, [setShowRedDot])

  return showRedDot
}
