import { styled } from '@mui/material'

export const STContainer = styled('div', { label: 'Container' })<{ isOpen: boolean }>(({ theme, isOpen }) => `
  position: relative;
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${isOpen ? theme.colors['--color-neutral-theme-900'] : theme.colors['--color-neutral-theme-100']};
  cursor: pointer;

  > svg {
    cursor: pointer;
    ${isOpen ? 'color: white;' : ''}
  }
`)

export const STDropdown = styled('div', {
  label: 'Dropdown'
})(({ theme }) => `
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 0;
  width: 194px;
  border: 1px solid ${theme.colors['--color-neutral-theme-200']};
  padding: ${theme.spacing(1.5, 1)};
  box-shadow: 0px 40px 80px -12px rgb(37 38 46 / 10%);
  border-radius: 12px;
  background-color: ${theme.colors['--color-neutral-theme-50']} !important;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)}
`)

export const STDropdownItem = styled('div', {
  label: 'DropdownItem'
})(({ theme }) => `
  padding: ${theme.spacing(1)};

  color: ${theme.colors['--color-neutral-theme-900']};
  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: ${theme.colors['--color-neutral-theme-100']};
  }

  border-radius: 12px;

  span {
    color: ${theme.colors['--color-neutral-theme-700']}
  }
`)
