/**
 * Promise.sleep polyfill
 * @param {number} ms
 * @returns {Promise}
 */
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

if (!Promise.sleep) {
  Object.assign(Promise, { sleep })
}

/**
 * Promise.timeout polyfill
 * @param {Promise} promise
 * @param {number} time
 * @returns {Promise}
 */
export const timeout = <T = any>(promise: Promise<T>, time: number) => Promise.race([
  promise,
  new Promise((resolve, reject) => setTimeout(reject, time))
])

if (!Promise.timeout) {
  Object.assign(Promise, { timeout })
}

/**
 * Promise.settled polyfill
 * @param {(resolve: (value: any) => void, reject: (reason?: any) => void) => void} executor
 */
export const settled = <T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) => {
  return (new Promise(executor))
    .then((value) => ({
      status: 'fulfilled',
      value
    }))
    .catch((reason) => ({
      status: 'rejected',
      reason
    }))
}

if (!Promise.settled) {
  Object.assign(Promise, { settled })
}

/**
 * Promise.allSettled polyfill
 * @param {Promise[]} promises
 */
export const allSettled = <T = any>(promises: Promise<T>[]) => {
  const mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: 'fulfilled',
          value
        }
      })
      .catch((reason) => {
        return {
          status: 'rejected',
          reason
        }
      })
  })

  return Promise.all(mappedPromises)
}

if (!Promise.allSettled) {
  Object.assign(Promise, { allSettled })
}
