import moment from 'moment'
import { FC } from 'react'
import MTimeAgo from 'react-timeago'

export const TimeAgo: FC<{ date?: string }> = (props) => {
  const Ts1Minute = 60000
  const TsDateNow = moment(new Date()).toISOString()
  const TsDate = Date.parse(TsDateNow) - Date.parse(props.date || '')

  return (
    <MTimeAgo
      date={props.date}
      formatter={(value: number, unit: string, suffix: string) => {
        if (TsDate < Ts1Minute) {
          return 'just now'
        }

        if (value !== 1) {
          unit = `${unit}s`
        }

        return `${value} ${unit} ${suffix}`
      }}
    />
  )
}
