import { IFeedbackModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class FeedbackApi {
  static readonly _prefix = '/feedback'

  static create(
    payload: {
      email?: string
      content: string
    }
  ): TAxiosResponseData<IFeedbackModel> {
    return axiosHiringApi.post(`${this._prefix}`, payload)
  }
}
