import { Avatar, AvatarGroup, MenuItem, Typography } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { CampaignApi } from 'src/api'
import { Button, StyledMenu, Tooltip } from 'src/components'
import { useElementSize } from 'src/hooks'
import { IconDocument, IconEditRegular, IconGroup, IconMore, IconTrash } from 'src/icons'
import { IMinimumSubmissionData } from 'src/interfaces'
import { DeleteConfirmPopup } from 'src/modules/campaign/components/delete-confirm-popup'
import { JobDetailPopup } from 'src/modules/campaign/components/job-detail-popup'
import { getCampaignShareLink } from 'src/modules/campaign/utils'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, OverlayService } from 'src/services'
import { stringAvatar, stringGrayAvatar } from 'src/utils'
import { CampaignOwners } from '../campaign-owners'
import Style from './style.module.scss'

const avatarCompanyStyle = { width: '32px', height: '32px', borderRadius: '6px', fontSize: '10px' }

interface IProps {
  htmlId?: string
  id?: number
  jobTitle?: string
  logoUrl?: string
  submissions: IMinimumSubmissionData[]
  draftId?: number
  slug?: string
  companyDomain?: string
  companyName?: string

  onAfterDelete?: (deletedItem: { id?: number; draftId?: number}) => void
  onTourTrigger?: () => void
}

const avatarSize = { height: '32px', width: '32px' }
const logoCompany = { height: '32px', width: '32px', borderRadius: '6px' }

export const CardJob: FC<IProps> = ({ id, htmlId, companyName, jobTitle, companyDomain, submissions = [], logoUrl, draftId, onAfterDelete, slug, onTourTrigger }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const timerRef = useRef<any>()
  const history = useHistory()

  const goToJob = useCallback((e: any) => {
    e.stopPropagation()
    setAnchorEl(null)

    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      CampaignMutationService.reset()
      return history.push(generate(ERoutes.NEW_CAMPAIGN, { draftId }))
    }

    history.push(generate([ERoutes.CAMPAIGN_DETAIL, { campaignId: id }]))
  }, [onTourTrigger, draftId, history, id])

  const handleClickCard = useCallback(() => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      history.push(generate(ERoutes.NEW_CAMPAIGN, { draftId }))
      return
    }

    history.push(generate([ERoutes.CAMPAIGNS, { id }]))
  }, [draftId, history, id, onTourTrigger])

  const handleOnDelete = useCallback(async () => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      await CampaignApi.deleteDraft(draftId)
      onAfterDelete?.({ draftId })

      OverlayService.reset()
      return
    }

    if (id) {
      await CampaignApi.archive(id)
      onAfterDelete?.({ id })

      OverlayService.reset()
    }
  }, [id, draftId, onAfterDelete, onTourTrigger])

  const handleDelete = useCallback((e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    setAnchorEl(null)

    OverlayService.setOverlay({
      content: (
        <DeleteConfirmPopup
          onCancel={() => OverlayService.reset()}
          onDelete={handleOnDelete}
        />
      ),
      open: true
    })
  }, [handleOnDelete, onTourTrigger])

  const handleCopyLink = useCallback(async (e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    await navigator.clipboard.writeText(getCampaignShareLink(slug))

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    setAnchorEl(null)
  }, [slug, onTourTrigger])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const openDetailJobPopup = useCallback((e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    if (!id) return

    OverlayService.setOverlay({
      open: true,
      content: (
        <JobDetailPopup
          campaignId={id}
        />
      )
    })
  }, [id, onTourTrigger])

  const titleRef = useRef<HTMLSpanElement>(null)
  const [, titleHeight] = useElementSize(titleRef.current)
  const [reached, setReached] = useState(false)
  const [tooltipTitleEnable, setTooltipTitleEnable] = useState(true)
  const handleOwnerTooltipOpen = useCallback(() => {
    setTooltipTitleEnable(false)
  }, [])

  const handleOwnerTooltipClose = useCallback(() => {
    setTooltipTitleEnable(true)
  }, [])

  useEffect(() => {
    try {
      if (!titleRef.current) {
        return
      }

      const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight.slice(0, -2)) // to remove px

      const lineCount = Math.ceil(titleHeight / lineHeight)
      const reached = lineCount > 2

      setReached(reached)
    } catch (error) {
      console.log('[card job] error: ', error)
      setReached(true)
    }
  }, [titleRef, titleHeight])

  const tooltipTitleSx = useMemo(() => {
    if (tooltipTitleEnable) {
      return {}
    }

    return {
      tooltip: Style.popperHidden
    }
  }, [tooltipTitleEnable])

  return (
    <div id={htmlId} className={Style.container} onClick={handleClickCard}>
      <div className={Style.header}>
        {logoUrl
          ? (
            <Avatar
              variant="square"
              src={logoUrl}
              sx={logoCompany}
            />
          )
          : (
            <Avatar
              {...stringGrayAvatar(companyName, avatarCompanyStyle)}
            />
          )}

        <div className="fx flex-row fx-ai-center gap-3">
          {companyDomain && id && (
            <CampaignOwners
              onOpen={handleOwnerTooltipOpen}
              onClose={handleOwnerTooltipClose}
              companyDomain={companyDomain}
              id={id}
            />
          )}

          <Button
            order="tertiary"
            className={Style.buttonMore}
            onClick={handleOpenMenu}
          >
            <IconMore className={Style.iconMore}/>
          </Button>
          <StyledMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            className={Style.menu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {!draftId && (
              <MenuItem disableRipple className={Style.menuItem} onClick={handleCopyLink}>
                <IconGroup/> <Typography variant="body2-regular">Copy Link</Typography>
              </MenuItem>
            )}
            <MenuItem disableRipple className={Style.menuItem} onClick={goToJob}>
              <IconEditRegular/> <Typography variant="body2-regular">Edit</Typography>
            </MenuItem>
            <MenuItem disableRipple className={Style.menuItem} onClick={handleDelete}>
              <IconTrash/> <Typography variant="body2-regular">Delete</Typography>
            </MenuItem>
          </StyledMenu>
        </div>

      </div>
      <Tooltip
        title={jobTitle || 'Untitled'}
        placement="top"
        disableFocusListener={!reached}
        disableHoverListener={!reached}
        disableTouchListener={!reached}
        arrow
        classes={tooltipTitleSx}
      >
        <div className={Style.content}>
          <span className={clsx('body1-bold color-neutral-theme-900', reached && 'twoline-text')} ref={titleRef}>
            {jobTitle || 'Untitled'}
          </span>

          <div>
            <div className={Style.chip} onClick={openDetailJobPopup}>
              <IconDocument width={9}/> Job Details
            </div>
          </div>

          <div className={clsx(Style.footer, 'fx flex-row fx-ai-center fx-jc-space-between')}>
            <span className="body2-regular color-neutral-theme-300">{submissions.length} {(submissions.length === 0 || submissions.length > 1) ? 'candidates' : 'candidate'}</span>

            <AvatarGroup max={3} total={submissions.length} sx={avatarSize} className={Style.avatarGroup}>
              {submissions.slice(0, 3).map((submission, index) => (
                <Avatar
                  key={index}
                  alt={submission.author?.fullName}
                  src={submission.author?.pfp.url}
                  {...stringAvatar(submission.author?.fullName, avatarSize)}
                />
              ))}
            </AvatarGroup>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
