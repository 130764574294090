import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { MISMATCH_PASSWORD } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation } from 'src/hooks'
import { IconLock, IconVerifiedEmail } from 'src/icons'
import { EAuthView } from 'src/interfaces'
import { Footer } from 'src/layout/layout-container/footer'
import { registerPasswordSchema } from 'src/validation'
import { Header } from '../../../header'
import { LeftSection } from '../../../left-section'
import Style from './style.module.scss'

interface ISetPassword {
  password: string
  confirmPassword: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  handleChangeFormData: (name: string, value: TSignUpPayload[keyof TSignUpPayload]) => void
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onSubmit: (data: any) => Promise<void>
  onChangeView?: () => void
}

export const SetPassword: FC<IProps> = ({ handleChangeFormData, onChangeView }) => {
  const [formValues, setFormValues] = useState<ISetPassword>({ password: '', confirmPassword: '' })
  const { errors, validateAt } = useOldValidation<ISetPassword>()
  const { eventHandler } = useAnalytic('signup_password')
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: registerPasswordSchema,
      path: name as keyof ISetPassword,
      data: { ...formValues, [name]: value }
    })
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, formValues])

  const onNext = (e: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    handleChangeFormData('password', formValues.password)
    onChangeView?.()
  }

  useEffect(() => {
    if (errors.confirmPassword === MISMATCH_PASSWORD) {
      eventHandler(ETrackingEvent.ERROR_PSWD_MISMATCH)()
    }
  }, [errors.confirmPassword, eventHandler])

  const isInValid = useMemo(() =>
    Object.keys(errors)?.length <= 0 ||
      Object.values(errors).some((err) => err) ||
      Object.values(formValues).some((value) => !value)
  , [errors, formValues])

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>
        <LeftSection illustration={3}/>
        <div className={Style.RightSide}>
          <div className={Style.STSetPasswordWrapper}>
            <form className={Style.STForm} onSubmit={onNext}>
              <IconVerifiedEmail className={Style.Icon}/>

              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Let’s set up your password</Typography>
              </div>
              <div className={Style.FormInput}>
                <Input
                  label="Password"
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formValues.password || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD)}
                  error={errors.password}
                  required
                  isCheckPassword
                  mb={0}
                  labelClassName={Style.Label}
                  startIcon={<IconLock/>}
                  startIconTabIndex={-1}
                />
                <Input
                  label="Confirm your password"
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  placeholder="Retype your password"
                  value={formValues.confirmPassword || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
                  error={errors.confirmPassword}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                  startIcon={<IconLock/>}
                  startIconTabIndex={-1}
                />

                <Button
                  fullWidth
                  disabled={isInValid || loading}
                  onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT)}
                  type="submit"
                >
                  One Last Step
                </Button>
              </div>
            </form>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  )
}
