import { BehaviorSubject, EMPTY, Subject, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { ELocationType } from 'src/enums'
import { ICampaignDetail } from 'src/interfaces'
import { isValidWebsiteUrl as _isValidWebsiteUrl } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { CampaignMutationService } from './campaign-mutation.service'

export class CampaignService {
  public static readonly loading$ = new BehaviorSubject<boolean>(false)

  private static error$ = new BehaviorSubject<Error | null>(null)

  public static get error(): WithOutNextComplete<typeof this.error$> {
    return this.error$
  }

  public static resetError() {
    this.error$.next(null)
  }

  public static fetchCampaign(id: number, unsubscribe$: Subject<void>) {
    const { id: currentId } = CampaignMutationService.data
    if (currentId === id) {
      return
    }

    this.loading$.next(true)
    from(CampaignApi.getOne(id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('error', error)
          this.error$.next(error)
          return EMPTY
        }),
        finalize(() => this.loading$.next(false))
      ).subscribe(({ data }) => {
        // FIXME: typing in api CampaignApi.getOne
        CampaignMutationService.setData(data as unknown as ICampaignDetail)
      })
  }

  public static fetchCampaignDraft(draftId: number, unsubscribe$: Subject<void>) {
    const currentDraftId = CampaignMutationService.draftId
    if (currentDraftId === draftId) {
      return
    }

    this.loading$.next(true)
    from(CampaignApi.getDraftDetail(draftId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('error', error)
          this.error$.next(error)
          return EMPTY
        }),
        finalize(() => this.loading$.next(false))
      ).subscribe(({ data }) => {
        CampaignMutationService.setDraftData(data)
      })
  }

  static isDataValid(data: ICampaignDetail) {
    const {
      jobTitle,
      salaryValue,
      salaryRate,
      location,
      // questionIds,
      uploadVideoFile,
      uploadVideoUrl,
      companyName,
      companyWebsiteUrls,
      // logo become optional field 👇
      // logoFile,
      // logoUrl,
      briefDescription,
      jdFile,
      jdFileUrl,
      locationType
    } = data

    if (!uploadVideoUrl && !uploadVideoFile) {
      return false
    }

    // logo become optional field 👇
    // if (!logoUrl && !logoFile) {
    //   return false
    // }

    const value = salaryValue as { min: number; max: number }

    if (!value || !value.min || !value.max) {
      return false
    }

    // if (!salaryRate || !name || !jobTitle || !questionIds.length || !companyName || !companyWebsiteUrls?.length || !this.isValidWebsiteUrl(companyWebsiteUrls.at(0))) {
    if (!salaryRate || !jobTitle || !companyName || !companyWebsiteUrls?.length || !this.isValidWebsiteUrl(companyWebsiteUrls.at(0))) {
      return false
    }

    if ((!locationType || (locationType !== ELocationType.REMOTE)) && !location) {
      return false
    }

    if ((briefDescription?.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !briefDescription.includes('<img')) && (!jdFile && !jdFileUrl)) {
      return false
    }

    return true
  }

  static isValidWebsiteUrl(url?: string) {
    return _isValidWebsiteUrl(url)
  }
}
