import { Subject } from 'rxjs'
import { EMessage } from 'src/enums'
import { IMessage } from 'src/interfaces'

export const ToastService = new (
  class {
    private id = 0

    readonly message$ = new Subject<IMessage>()

    push(message: string | IMessage) {
      if (!message || (typeof message === 'object' && !message.content)) {
        return
      }

      const defaultMessage: IMessage = {
        id: ++this.id,
        open: true,
        severity: EMessage.SUCCESS,
        position: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 5000,
        content: message as string
      }

      if (typeof message === 'string') {
        return this.message$.next(defaultMessage)
      }

      this.message$.next({
        ...defaultMessage,
        ...message
      })
    }
  }
)()
