import { Subject } from 'rxjs'
import { EMessage } from 'src/enums'
import { IBannerMessage } from 'src/interfaces'

export const BannerService = new (
  class {
    private id = 0
    private displayedMessages: Set<string> = new Set()

    readonly message$ = new Subject<IBannerMessage>()

    reset = () => {
      this.message$.next({
        title: '',
        severity: EMessage.SUCCESS,
        content: undefined,
        open: false
      })

      this.displayedMessages = new Set()
    }

    push(message: IBannerMessage) {
      if (!message || (typeof message === 'object' && !message.content)) {
        return
      }

      const messageKey = JSON.stringify(message.id)

      if (message.id && this.displayedMessages.has(messageKey)) {
        return // Message is a duplicate, don't push it again
      }

      const defaultMessage: IBannerMessage = {
        id: ++this.id,
        title: 'Title is required',
        open: true,
        severity: EMessage.SUCCESS,
        position: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: undefined, // auto won't hide => if you want to hide, please set this value
        content: 'Content is required',
        actionText: 'Close',
        bannerClassName: ''
      }

      this.message$.next({
        ...defaultMessage,
        ...message
      })

      this.displayedMessages.add(messageKey)
    }
  }
)()
