import { IUserModel, IUserProfile } from 'src/interfaces'
import { TProfileSagaAction } from '../saga/profile'
import { AUTH_SET_CREDENTIALS } from './auth'

/* authentication actions */
export const PROFILE_UPDATE = Symbol('PROFILE_UPDATE')
export const PROFILE_TRAITS = Symbol('PROFILE_TRAITS')
export const PROFILE_COUNT_CREDIT = Symbol('PROFILE_COUNT_CREDIT')

/**
 * state
 */
export interface IProfileState {
  info: IUserProfile
  countCredits: number
  traits: {
    isNull?: boolean
    characteristics: string[]
    motivations: string[]
  }
}

/**
 * actions
 */
export type TProfileAction =
  | {
    type:
    | typeof PROFILE_UPDATE
    | typeof PROFILE_COUNT_CREDIT
    | typeof PROFILE_TRAITS
    | typeof AUTH_SET_CREDENTIALS
    value?:
    | IUserModel
    | string
    | number
    | null
    | {
      characteristics: string[]
      motivations: string[]
    }
  }
  | TProfileSagaAction
