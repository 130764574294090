import { FC } from 'react'
import { Spinner } from '../spinner'
import Style from './style.module.scss'

export const Loading: FC<{ show?: boolean }> = (props) => {
  return (
    <div
      className={Style.loading}
      style={{ display: props.show === false ? 'none' : undefined }}
    >
      <Spinner/>
    </div>
  )
}
