import { ClickAwayListener } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { ContactUs, NoMobile, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconQuestionAnswer } from 'src/icons'
import { OverlayService } from 'src/services'
import { PopupTourService } from 'src/services/tour/popup.service'
import { STContainer, STDropdown, STDropdownItem } from './styled'

interface IProps {
  /**
   * if provided, will be called when tutorial button is clicked and overwrites the default behavior
   * @returns
   */
  onTutorialClicked?: () => void
  hideTutorial?: boolean
}

export const HeaderQuestionAnswer: FC<IProps> = ({ onTutorialClicked, hideTutorial }) => {
  const { eventHandler } = useAnalytic('')
  const [isOpen, setIsOpen] = useState(false)

  const openContactForm = () => {
    OverlayService.setOverlay({
      open: true,
      content: <ContactUs/>
    })
  }

  const handleClick = useCallback(() => {
    eventHandler(ETrackingEvent.BTN_HELP)()
    setIsOpen(open => !open)
  }, [eventHandler])

  return (
    <STContainer onClick={handleClick} isOpen={isOpen}>
      <IconQuestionAnswer/>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(!isOpen)}>
          <STDropdown>
            {!hideTutorial && (
              <NoMobile>
                <STDropdownItem onClick={() => onTutorialClicked ? onTutorialClicked() : PopupTourService.show()}>
                  Onboarding Tutorials
                </STDropdownItem>
              </NoMobile>
            )}
            <STDropdownItem onClick={eventHandler(ETrackingEvent.BTN_HELP, openContactForm)}>
              Contact Us
            </STDropdownItem>
          </STDropdown>
        </ClickAwayListener>
      )}
    </STContainer>
  )
}
