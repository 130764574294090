import { ECampaignSubmissionOrder, EUserGuide, EViewCampaignOptions, EWorkInterests } from 'src/enums'
import { IObject } from '../object.interface'
import { ICompanyModel } from './company.model'
import { IFileUploadModel } from './file-upload.model'
import { ILinkedinEducation } from './linkedin-education.model'
import { ILinkedinWorkingExperience } from './linkedin-working-experience.model'
import { IModel } from './model'
import { ITraitFilterModel } from './trait-filter.model'
import { IUserStatModel } from './user-stat.model'
import { IVideoModel } from './video.model'

export enum ESalaryRange {
  RANGE = 'range',
  CUSTOM = 'custom',
  EXTRA_AMOUNT = 'extraAmount'
}

export enum ESalaryRate {
  PER_HOUR = 'perHour',
  PER_MONTH = 'perMonth',
  PER_YEAR = 'perYear'
}

export interface IUserModel extends IModel {
  email?: string
  username?: string
  password?: string
  userPhoneNo?: string
  firstName?: string
  lastName?: string
  fullName?: string
  bio?: string
  pfp?: IFileUploadModel
  pfv?: IVideoModel
  settings?: IObject
  videosWatched?: number
  company?: ICompanyModel
  companies?: ICompanyModel[]
  linkedInUrl?: string
  hasLinkedInUrl?: boolean
  scheduleUrl?: string
  lookupId?: string
  guide?: { [key in EUserGuide]: boolean | number }
  stats?: IUserStatModel
  traitFilter?: ITraitFilterModel
  linkedinEducations?: ILinkedinEducation[]
  linkedinWorkingExperiences?: ILinkedinWorkingExperience[]
  skills?: string
  /**
   * @deprecated use personalAttributes
   */
  characteristics?: string
  workInterests?: EWorkInterests[]
  willingToRelocate?: boolean
  personalAttributes?: string[]
}

export interface IAvatarUploaded {
  id: string
  lookupId: string
  tenantId: string
  userId: string
  url: string
  filename: string
  category: string
  description: string
  metadata: string
  createdAt: string
  updatedAt: string
  deactivatedAt: string
}

export interface IUserProfile {
  email?: string
  firstName?: string
  lastName?: string
  fullName?: string
  companyName?: string
  companyUrls?: string[]
  companyLogo?: string
  avatar?: string
  bio?: string
  scheduleUrl?: string
  lookupId?: string
}

export enum ETraitType {
  USER= 'USER',
  CAMPAIGN='CAMPAIGN'
}

export interface IUploadTrait {
  traits?: string[]
  type: ETraitType
  campaignId?: number
  orderOption?: ECampaignSubmissionOrder | string
  viewOption?: EViewCampaignOptions | string
}
