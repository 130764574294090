import { all, fork } from 'redux-saga/effects'
import { handler as auth } from './auth'
import { handler as candidate } from './candidate'
import { handler as campaign } from './campaign'

export const saga = function * () {
  yield all([
    fork(auth),
    fork(candidate),
    fork(campaign)
  ])
}
