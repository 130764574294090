import clsx from 'clsx'
import { FC } from 'react'
import { CollapseContent } from 'src/components'

interface IProps {
  personalAttributes?: string[]
  className?: string
}

export const CandidateSkillsNChars: FC<IProps> = ({ personalAttributes, className }) => {
  return (
    <div className={clsx('fx fx-column', className)}>
      <CollapseContent borderTop className="px-6" title="My Work Styles">
        <pre className="body2-regular m-0 mt-2 pb-4 neutral-900">{personalAttributes?.slice(0, 4)?.map(attribute => `- ${attribute}`).join('\n') || 'No content'}</pre>
      </CollapseContent>
      <CollapseContent borderTop className="px-6" title="My Motivators">
        <pre className="body2-regular m-0 mt-2 pb-4 neutral-900">{personalAttributes?.slice(4, 5)?.map(attribute => `- ${attribute}`).join('\n') || 'No content'}</pre>
      </CollapseContent>
    </div>
  )
}
