import React, { FC, useMemo } from 'react'
import Lottie from 'react-lottie'
import { useBehaviorMapper } from 'src/hooks'
import { LottiesModule } from 'src/store'

interface ICoinProps {
  // no here
  play?: boolean
  onCompleted?: () => void
}

export const Coin: FC<ICoinProps> = React.forwardRef(function RealCoin(
  props: ICoinProps,
  ref: any
) {
  const coinAnimationData = useBehaviorMapper(LottiesModule.coin$)
  const defaultOptions = useMemo(() => ({
    loop: false,
    autoplay: false,
    animationData: coinAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }), [coinAnimationData])
  return (
    <Lottie
      ref={ref}
      width={80}
      options={defaultOptions}
      isStopped={!props.play}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onCompleted?.()
        }
      ]}
    />
  )
})
