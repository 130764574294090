import { Fade } from '@mui/material'
import clsx from 'clsx'
import { FC, FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { finalize, from, of, switchMap, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { Button, Tooltip } from 'src/components'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { CampaignMutationService } from 'src/services'
import Style from './style.module.scss'

export const ChatGPTSummary: FC = () => {
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reGenerating, setReGenerating] = useState(false)
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const bodyPreRef = useRef<HTMLPreElement>(null)
  const unsubscribe$ = useUnsubscribe()

  const handleSummaryChange: FocusEventHandler<HTMLPreElement> = useCallback(async (e) => {
    const textContent = (e.target as HTMLPreElement).textContent
    if (textContent !== data.jobSummary) {
      setLoading(true)

      // just patch the data, don't save as draft
      CampaignMutationService.patchData({ jobSummary: textContent ?? '', _ignoreDraft: true })

      if (data.id) {
        setSaved(true)
        await CampaignApi.updateSummary(data.id, textContent ?? '')

        setTimeout(() => {
          setSaved(false)
        }, 2000)
      }
      setLoading(false)
    }
  }, [data.id, data.jobSummary])

  const handleReGenerate = useCallback(() => {
    setReGenerating(true)
    setLoading(true)

    from(CampaignApi.fetchSummary({
      jobTitle: data.jobTitle,
      jdFile: data.jdFile,
      jdFileUrl: data.jdFileUrl ?? undefined,
      location: data.location,
      briefDescription: data.briefDescription,
      salaryRange: data.salaryRange,
      salaryRate: data.salaryRate,
      salaryValue: data.salaryValue
    })).pipe(
      takeUntil(unsubscribe$),
      switchMap(({ data: { summary } }) => {
        CampaignMutationService.patchData({ jobSummary: summary ?? '', _ignoreDraft: true })

        if (data.id) {
          return from(CampaignApi.updateSummary(data.id, summary ?? ''))
        }

        return of(null)
      }),

      finalize(() => {
        setReGenerating(false)
        setLoading(false)
      })
    ).subscribe(() => {})
  }, [data.briefDescription, data.id, data.jdFile, data.jdFileUrl, data.jobTitle, data.location, data.salaryRange, data.salaryRate, data.salaryValue, unsubscribe$])

  const tooltipTitle = useMemo(() =>
    !data.jobSummary ? 'Job summary is generating...' : reGenerating ? 'You can\'t edit the summary while it\'s generating' : ''
  , [data.jobSummary, reGenerating])

  useEffect(() => {
    if (bodyPreRef.current) {
      // bodyPreRef.current.textContent = data.jobSummary ?? 'Generating...'
    }
  }, [data.jobSummary])

  return (
    <div className="fx fx-column">
      <div className={Style.container}>
        <div className={Style.header}>
          <div className={Style.headerTitleGroup}>
            <span className={Style.headerTitle}>Job Summary</span>
            <span className={Style.headerSubTitle}>Powered by KNOWME Insights</span>
          </div>

          <Button
            disabled={reGenerating}
            onClick={handleReGenerate}
            order="secondary"
            kmSize="medium"
            startIcon={(
              <svg className={clsx(reGenerating && Style.spin)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.653 10.9147C12.4283 10.7424 12.1073 10.7859 11.9359 11.0117C11.0055 12.238 9.56404 12.9714 8.00065 12.9714C5.45207 12.9714 3.35374 11.0337 3.08418 8.54398L3.54585 9.00777C3.74565 9.20862 4.0696 9.20862 4.2694 9.00777C4.45104 8.82519 4.46756 8.53948 4.31894 8.33815L4.2694 8.28046L2.90506 6.90904C2.72342 6.72645 2.43919 6.70985 2.2389 6.85924L2.18151 6.90904L0.81717 8.28046C0.617367 8.48131 0.617367 8.80693 0.81717 9.00777C0.998809 9.19036 1.28304 9.20695 1.48334 9.05757L1.54072 9.00777L2.05155 8.49333C2.30114 11.5763 4.86931 14 8.00065 14C9.88667 14 11.6276 13.1142 12.7495 11.6355C12.9209 11.4096 12.8777 11.0869 12.653 10.9147ZM15.1841 6.99223C14.9843 6.79139 14.6604 6.79139 14.4606 6.99223L13.9497 7.50599C13.6998 4.42337 11.1318 2 8.00065 2C6.19668 2 4.52335 2.81 3.39661 4.18113C3.21665 4.40011 3.24738 4.72427 3.46523 4.90516C3.68308 5.08605 4.00557 5.05516 4.18552 4.83618C5.11989 3.69915 6.5052 3.02857 8.00065 3.02857C10.549 3.02857 12.6472 4.96593 12.917 7.45533L12.4554 6.99223C12.2556 6.79139 11.9317 6.79139 11.7319 6.99223C11.5321 7.19307 11.5321 7.5187 11.7319 7.71954L13.0962 9.09097C13.296 9.29181 13.62 9.29181 13.8198 9.09097L15.1841 7.71954C15.3839 7.5187 15.3839 7.19307 15.1841 6.99223Z" fill="currentColor"/>
              </svg>
            )}
          >
            Generate Again
          </Button>
        </div>

        <Tooltip title={tooltipTitle}>
          <div className={Style.body}>
            <pre
              className={Style.bodyPre}
              contentEditable={!loading}
              placeholder="Enter job summary"
              onBlur={handleSummaryChange}
            >
              {data.jobSummary ?? 'Generating...'}
            </pre>
          </div>
        </Tooltip>
      </div>

      <Fade in={saved} timeout={300}>
        <span className={Style.savedSummary}>Summary Saved</span>
      </Fade>
    </div>
  )
}
