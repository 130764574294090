import { FC, useEffect, useState } from 'react'
import Style from './style.module.scss'

interface ICountdownProps {
  time?: number
}

export const Countdown: FC<ICountdownProps> = ({ time = 3000 }) => {
  const [countdown, setCountdown] = useState(time)

  useEffect(() => {
    setCountdown(time)
  }, [time])

  useEffect(() => {
    if (countdown === 0) {
      return
    }

    const timeout = setTimeout(() => {
      setCountdown((prev) => prev - 1000)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [countdown])

  return (
    <div className={Style.CountDown}>
      <p>{countdown / 1000}</p>
    </div>
  )
}
