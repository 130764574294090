import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

export const useNumberOfSkeletons = () => {
  const isMin1024 = useMediaQuery('(min-width: 1024px)')
  const isMin1580 = useMediaQuery('(min-width: 1580px)')
  const isMin1875 = useMediaQuery('(min-width: 1875px)')
  const isMin2242 = useMediaQuery('(min-width: 2242px)')

  return useMemo(() => {
    const numRows = 3
    if (isMin2242) return 6 * numRows
    if (isMin1875) return 5 * numRows
    if (isMin1580) return 4 * numRows
    if (isMin1024) return 3 * numRows
    return 2 * numRows
  }, [isMin1024, isMin1580, isMin1875, isMin2242])
}
