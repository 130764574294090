import { Box, Dialog } from '@mui/material'
import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { useAnalytic } from 'src/components'
import {
  useAppSelector,
  useBehaviorMapper,
  useIfMobileScreen,
  useWindowSize
} from 'src/hooks'
import { IconClose } from 'src/icons'
import { CompleteProfile } from 'src/partials'
import { OverlayService } from 'src/services'
import { getLayoutIsLoading } from 'src/store/selectors'
import Style from './style.module.scss'

export const Overlay: FC = () => {
  const isMobile = useIfMobileScreen()
  const loading = useAppSelector(getLayoutIsLoading)
  const { screen, oldScreen, setScreen } = useAnalytic('')
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)

  const handleClose = () => {
    if (loading || appOverlay.disabled) return

    return OverlayService.reset()
  }

  useEffect(() => {
    return () => {
      if (screen) {
        setScreen(oldScreen)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [windowWidth] = useWindowSize()

  const presets = {
    [CompleteProfile.name]: {
      width: '536px',
      transform: `scale(${Math.min(1, windowWidth / (536 + 32))})`
    }
  }

  return (
    <Dialog
      onClose={() => !appOverlay.disabled && handleClose()}
      open={!!appOverlay.open}
      className={clsx(Style.overlay, { [Style.hasContent]: !appOverlay.blank })}
      style={{
        ...(appOverlay.overlayBackground ? { background: appOverlay.overlayBackground } : {}),
        ...(appOverlay.isHiddenBackdropFilter ? { backdropFilter: 'unset' } : { backdropFilter: 'blur(16px)' })
      }}
    >
      <Box
        position="relative"
        m={isMobile ? 1 : 2}
        sx={{ maxHeight: 'calc(100% - 32px)' }}
        style={{
          ...(appOverlay.overlayStyle || {}),
          ...(presets?.[appOverlay.presetStyle || ''] || {})
        }}
      >
        {!appOverlay.disabled && (
          <div
            className={clsx(Style.closeIcon, {
              'fx fx-center': appOverlay.open,
              'd-none': !appOverlay.open
            })}
            style={appOverlay.closeStyle || {}}
            onClick={handleClose}
          >

            <IconClose style={{ ...(appOverlay.closeStyle || {}), ...(appOverlay.closeIconStyle || {}) }}/>

          </div>
        )}

        {appOverlay.content}
      </Box>
    </Dialog>
  )
}
