import { FC, ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAppSelector } from 'src/hooks'
import { getIsAuthenticated } from 'src/store/selectors'

export const NotAllowAuthenticated: FC<{ children?: ReactNode }> = (props) => {
  const history = useHistory()
  const location = useLocation<{ from: string }>()
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/', {
        from: location?.state?.from
      })
    }
  }, [history, isAuthenticated, location?.state?.from])

  return <>{props.children}</>
}
