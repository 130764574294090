import { UnregisterCallback } from 'history'
import React from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, finalize, take, tap } from 'rxjs'
import { ITourPopperProps } from 'src/components'
import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { OverlayService } from '../overlay.service'
import { PopupTourService } from './popup.service'

const MAX_STEP_TOTAL = 4

export const TARGET_PUBLIC_GUEST_CARD_JOB = new Target(
  'target-public-guest-card-job'
)

export const TARGET_PUBLIC_GUEST_FLIP_BUTTON = new Target(
  'target-public-guest-flip-button'
)
export const TARGET_PUBLIC_GUEST_FLIP_BUTTON_ANCHOR = new Target(
  'target-public-guest-flip-button-anchor'
)

export const TARGET_PUBLIC_GUEST_VIEW_DETAIL = new Target(
  'target-public-guest-view-detail'
)
export const TARGET_PUBLIC_GUEST_VIEW_DETAIL_ANCHOR = new Target(
  'target-public-guest-view-detail-anchor'
)

export const TARGET_PUBLIC_GUEST_VIDEO = new Target(
  'target-public-guest-view-video'
)

const STEP_CONFIGS: ITourPopperProps[] = [
  {
    title: 'Let’s take a look at your job posting on KnowMe',
    content: React.createElement(
      'span',
      null,
      'We duplicated your LinkedIn job posting and have interested applicants ready for you to evaluate. Click',
      React.createElement('b', null, ' Job Details'),
      React.createElement('span', null, ' to view your job listing.')
    ),
    currentStep: 0,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_CARD_JOB.getSelector(),
    arrowPosition: 'left',
    tip: 'Click on this job',
    ctaText: 'Next',
    onCtaClick: () => {
      (
        document.querySelector(
          TARGET_PUBLIC_GUEST_CARD_JOB.getSelector()
        ) as HTMLButtonElement
      )?.click()
    },
    onExitClick: () => {
      GuestViewJobTourService.stopTour(true)
    },
    zIndex: 0
  },
  {
    title: 'Congratulations! KnowMe has sourced talent interested in your role',
    content: React.createElement(
      'div',
      { className: 'flex flex-column gap-4' },
      React.createElement(
        'span',
        null,
        'The following videos are from applicants interested in this specific role.'
      ),
      React.createElement(
        'span',
        null,
        'KnowMe leverages AI to create KnowMe Insights. We analyze video and resume submissions from each applicant to provide you with in-depth job seeker insights.'
      )
    ),
    currentStep: 1,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_FLIP_BUTTON_ANCHOR.getSelector(),
    arrowPosition: 'left',
    tip: 'Click on this icon to view candidate insights',
    ctaText: 'Next',
    onCtaClick: () => {
      (
        document.querySelector(
          TARGET_PUBLIC_GUEST_FLIP_BUTTON.getSelector()
        ) as HTMLButtonElement
      )?.click()
    },
    onBack: (history) => {
      GuestViewJobTourService.previousStep()
      history?.goBack()
    },
    onExitClick: () => {
      GuestViewJobTourService.stopTour(true)
    }
  },
  {
    title: 'See Applicant Details',
    content: 'If you\'ve watched the applicant\'s video but still want more information, simply click on this icon to view further details. Get the full picture effortlessly!',
    arrowPosition: 'left',
    currentStep: 2,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_VIEW_DETAIL_ANCHOR.getSelector(),
    tip: 'Click on this arrow icon',
    onBack: () => {
      const target = document.querySelector(
        TARGET_PUBLIC_GUEST_FLIP_BUTTON.getSelector()
      ) as HTMLButtonElement

      target?.click()
      GuestViewJobTourService.goToStep(1)
    },
    ctaText: 'Next',
    onCtaClick: () => {
      (
        document.querySelector(
          TARGET_PUBLIC_GUEST_VIEW_DETAIL.getSelector()
        ) as HTMLButtonElement
      )?.click()
    },
    onExitClick: () => {
      GuestViewJobTourService.stopTour(true)
    }
  },
  {
    title: 'Let them know if you vibe with them',
    content: 'Take a closer look at this applicant\'s details. If you\'re feeling a connection, why wait? Sign up and connect with them instantly!',
    arrowPosition: 'right',
    currentStep: 3,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_PUBLIC_GUEST_VIDEO.getSelector(),
    onBack: (history) => {
      history?.goBack()
      GuestViewJobTourService.goToStep(2)
    },
    ctaText: 'End Tour',
    onCtaClick: () => {
      GuestViewJobTourService.nextStep()
    },
    onExitClick: () => {
      GuestViewJobTourService.stopTour(true)
    }
  }
]

export class GuestViewJobTourService {
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined

  public static get currentStep$(): WithOutNextComplete<
    typeof GuestViewJobTourService._currentStep$
  > {
    return GuestViewJobTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<
    typeof GuestViewJobTourService._enableTour$
  > {
    return GuestViewJobTourService._enableTour$
  }

  public static nextStep(onAfterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep + 1)

    if (currentStep + 1 === MAX_STEP_TOTAL) {
      PopupTourService.completeMission('guest-view-job')
        .pipe(
          take(1),
          tap(() => {
            this.stopTour()
          }),
          finalize(() => {
            onAfterNext?.()
          })
        )
        .subscribe()
    }
  }

  public static goToStep(step: number) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this._currentStep$.next(step)
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)
    OverlayService.reset()

    this.stopRouteListener()

    this._routeListener = history?.listen((location) => {
      if (!location.pathname.startsWith(`/${PUBLIC_CAMPAIGN_BASE_PATH}`)) {
        this.stopTour(true)
      }
    })
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }
}
