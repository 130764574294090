import { styled } from '@mui/material'

export const STShare = styled('div', { label: 'Share' })<{ isMobile: boolean }>(({ isMobile, theme }) => `
    position: relative;
    width: ${isMobile ? '100%' : '548px'};
    border-radius: 32px;
    padding: ${isMobile ? theme.spacing(3) : theme.spacing(5)};
    background-color: ${theme.colors['--color-neutral-theme-50']};
`)

export const STLoading = styled('div', { label: 'Loading' })(({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
  border-radius: 32px;
  align-items: center;
  background: rgba(36 37 45 / 40%);
`)

export const STPersonal = styled('div', {
  label: 'Personal'
})(({ theme }) => `
  margin: ${theme.spacing(2, 0)};
  word-break: break-word;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};

  div {
    color: ${theme.colors['--color-neutral-theme-700']};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)}
  }

  svg {
    flex-shrink: 0;
  }
`)

export const STUserSuggestion = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'UserSuggestion'
})<{ isShow: boolean; isMobile: boolean }>(({ isMobile, theme, ...props }) => `
    position: absolute;
    top: 64px;
    width: ${isMobile ? '100%' : '335px'};
    max-height: 220px;
    display: ${props.isShow ? 'flex' : 'none'};
    flex-direction: column;
    gap: ${theme.spacing(2.5)};
    overflow: auto;
    background-color: ${theme.colors['--color-neutral-theme-50']};
    box-shadow: 0px 24px 56px -12px rgba(37, 38, 46, 10);
    border-radius: 16px;
    padding: ${theme.spacing(2.5)};
    z-index: 2
`)

export const STUserSuggestionItem = styled('div', {
  shouldForwardProp: prop => prop !== 'isActive',
  label: 'UserSuggestionItem'
})<{ isActive: boolean }>(({ theme, ...props }) => `
    cursor: pointer;
    opacity: ${props.isActive ? 1 : 0.5};
    display: flex;
    align-items: center;

    >div: nth-of-type(1) {
      width: 48px;
      height: 48px;
      margin-right: ${theme.spacing(1.5)}
    }
`)
