import { IconButton, styled } from '@mui/material'

export const STTitleItem = styled('div', {
  shouldForwardProp: prop => prop !== 'isLastTitle',
  name: 'Item'
})<{isLastTitle?: boolean}>(({ theme, isLastTitle }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};

  h5 {
    color: ${isLastTitle ? theme.colors['--color-neutral-theme-700'] : theme.colors['--color-neutral-theme-250']};

    &:hover {
      color: ${theme.colors['--color-neutral-theme-700']};
    }
  }

  h5:nth-of-type(2) {
    display: ${isLastTitle ? 'none' : 'flex'};
  }

  cursor: pointer;
`)

export const STTitleText = styled('span', {
  shouldForwardProp: prop => prop !== 'isLastTitle',
  name: 'Item'
})<{isLastTitle?: boolean}>(({ theme, isLastTitle }) => `
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: ${isLastTitle ? theme.colors['--color-neutral-theme-700'] : theme.colors['--color-neutral-theme-250']};
  &:hover {
    color: ${isLastTitle ? theme.colors['--color-neutral-theme-250'] : theme.colors['--color-neutral-theme-700']};
  }
  cursor: pointer;
`)

export const STIconButtonBack = styled(IconButton, {
  name: 'IconButtonBack'
})(({ theme }) => `
  background-color: ${theme.colors['--color-neutral-theme-100']};
  width: 44px;
  height: 44px;
`)
