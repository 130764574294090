export const CameraService = new (
  class {
    private readonly trackConstraints: Record<string, MediaTrackConstraints> = {}

    private _isNeedFixOrientation = false
    private _track?: MediaStreamTrack

    private _getMediaStream() {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      })
    }

    private async _getTrack() {
      const mediaStream = await this._getMediaStream()

      this._track = mediaStream.getVideoTracks()?.[0]

      if (!('getCapabilities' in this._track)) {
        Object.assign(
          this._track,
          {
            getCapabilities: () => ({})
          }
        )
      }

      return this._track
    }

    private _saveTrackConstraints(ratioKey: string, trackConstraints: MediaTrackConstraints) {
      if (this._isNeedFixOrientation) {
        this._track?.stop?.()
      }
      this.trackConstraints[ratioKey] = trackConstraints
      return trackConstraints
    }

    async stop() {
      this._track?.stop?.()
      delete this._track
      const mediaStream = await this._getMediaStream()
      mediaStream.getTracks().forEach(
        (track) => track.readyState === 'live' && track.stop()
      )
    }

    /**
     * @important Do not modify
     */
    async getBestResolution(ratio = {
      width: 16,
      height: 9
    }): Promise<MediaTrackConstraintSet> {
      const ratioKey = `${ratio.width}:${ratio.height}`
      const track = await this._getTrack()

      if (this.trackConstraints[ratioKey]) {
        await track.applyConstraints(this.trackConstraints[ratioKey])
        return this._saveTrackConstraints(ratioKey, this.trackConstraints[ratioKey])
      }

      const getCapabilities = track.getCapabilities()
      const maxWidth = getCapabilities.width?.max || 0
      const maxHeight = getCapabilities.height?.max || 0
      const direction = ((maxWidth / ratio.width) < (maxHeight / ratio.height))
        ? 'width'
        : 'height'
      const size = ({
        width: maxWidth,
        height: maxHeight
      })[direction]

      const trackConstraints: MediaTrackConstraints = {}
      trackConstraints[direction] = size
      trackConstraints.aspectRatio = ratio.width / ratio.height

      await track.applyConstraints(trackConstraints)
      const settings = track.getSettings()
      const hasSettings = settings && ((settings.width || 0) > maxWidth || (settings.height || 0) > maxHeight)
      if (!hasSettings) {
        return this._saveTrackConstraints(ratioKey, trackConstraints)
      }

      /**
       * Fix orientation on mobile devices
       */
      this._isNeedFixOrientation = true
      trackConstraints.width = direction === 'width' ? maxHeight : maxWidth
      trackConstraints.height = trackConstraints.width / ratio.height * ratio.width
      trackConstraints.aspectRatio = ratio.height / ratio.width
      await track.applyConstraints(trackConstraints)
      return this._saveTrackConstraints(ratioKey, trackConstraints)
    }
  }
)()
