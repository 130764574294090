import { styled } from '@mui/material'
import { DefaultImg } from 'src/images'

export const STVideoPlayer = styled('div', {
  shouldForwardProp: prop => !['animatedImage', 'isShow', 'isShowVideo'].includes(prop as string),
  label: 'VideoPlayer'
})<{
  animatedImage?: string
  isShowVideo?: boolean
}>((props) => `
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: ${props.isShowVideo ? 0 : 2};

  background: linear-gradient(0deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  // &.showControls {
  //  video::-webkit-media-text-track-display {
  //    transform: translateY(-80px);
  //  }
  // }

  /** Video & Track Styles */
  video {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }

  video::cue {
    visibility: hidden;
  }

  // :hover {
  //   background: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), url('${props.animatedImage || DefaultImg}');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center center;
  // }
`)

export const STSpeaker = styled('div', {
  shouldForwardProp: prop => prop !== 'isLinkedin',
  label: 'Icon Speaker'
})<{isLinkedin?: boolean}>(({ isLinkedin }) => `
  position: absolute;
  z-index: 3;
  right: 8px;
  top: ${isLinkedin ? '78px' : '20px'};
  cursor: pointer;
`)

export const STSpinner = styled('div', {
  label: 'Icon Spinner'
})(() => `
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0px;
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 2;
`)

export const STPlay = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'Speaker'
})<{isShow?: boolean}>(({ isShow }) => `
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${isShow ? 'flex' : 'none'};
`)
