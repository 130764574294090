import { Typography, useTheme } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { Illustration1, Illustration2, Illustration3 } from 'src/components'
import { SignInUpLogoImg } from 'src/images'
import Style from './style.module.scss'

interface IProps {
  illustration?: 1 | 2 | 3
}

const ILLUSTRATION_PADDING_TEXT = 32

export const LeftSection: FC<IProps> = ({ illustration = 1 }) => {
  const theme = useTheme()
  const textRef = useRef<HTMLDivElement>(null)
  const [illustrationStyle, setIllustrationStyle] = useState<React.CSSProperties>()

  const calculateIllustrationStyle = () => {
    if (!textRef.current) {
      return
    }

    setIllustrationStyle({ top: textRef.current.offsetTop + textRef.current.offsetHeight + ILLUSTRATION_PADDING_TEXT, visibility: 'visible' })
  }

  useEffect(() => {
    calculateIllustrationStyle()
    window.addEventListener('resize', calculateIllustrationStyle)

    return () => {
      window.removeEventListener('resize', calculateIllustrationStyle)
    }
  }, [])

  return (
    <div className={Style.LeftSide}>

      <div>
        <img className={Style.logo} src={SignInUpLogoImg} alt="logo"/>
      </div>
      <div ref={textRef} className={Style.typographyContainer}>
        <Typography align="center" variant="h3" color={theme.colors['--color-neutral-theme-900']} className={Style.headTitle}>
          A modern way of discovering talent
        </Typography>
        <Typography align="center" variant="body1-medium" color={theme.colors['--color-neutral-theme-500']} className={Style.subTitle}>
          By viewing short video introductions known as "Video Vibe Checks”
        </Typography>
      </div>

      <div className="fx-1"/>
      <div className={Style.ImageSection} style={illustrationStyle}>
        {illustration === 1 && <Illustration1 className={Style.Img} width="100%" height="100%"/>}
        {illustration === 2 && <Illustration2 className={Style.Img} width="100%" height="100%"/>}
        {illustration === 3 && <Illustration3 className={Style.Img} width="100%" height="100%"/>}
      </div>
    </div>
  )
}
