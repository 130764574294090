import clsx from 'clsx'
import { FC, useCallback } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { EEnv } from 'src/constants'
import { IconSearch } from 'src/icons'
import { ErrorMessage } from '../message'
import Style from './style.module.scss'

interface IProps {
  id?: string
  location?: string
  onChange?: (location: string) => void
  error?: string
}

export const GoogleLocationSearch: FC<IProps> = (props) => {
  const handlePlaceSelected = useCallback((place: any) => {
    props.onChange?.(place?.formatted_address || '')
  }, [props])

  const handleInputChanged = useCallback((e: any) => {
    props.onChange?.(e.target.value)
  }, [props])

  return (
    <div>
      <div
        className={clsx(Style.container, {
          [Style.error]: props.error
        })}
      >
        <Autocomplete
          id={props.id}
          apiKey={EEnv.REACT_APP_GOOGLE_API_KEY}
          options={{
            types: ['(regions)'],
            componentRestrictions: { country: ['us', 'jm'] }
          }}
          defaultValue={props.location || ''}
          className={clsx(Style.inputSearch, 'body1-regular')}
          onPlaceSelected={handlePlaceSelected}
          onChange={handleInputChanged}
        />

        <IconSearch className={Style.iconSearch}/>
      </div>
      {
        props.error && (
          <ErrorMessage className="mt-2">
            {props.error}
          </ErrorMessage>
        )
      }
    </div>
  )
}
