import { IconButton } from '@mui/material'
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo
} from 'react'
import { ProfileApi, UploadApi } from 'src/api'
import {
  Button,
  Input,
  Textarea,
  useAnalytic
} from 'src/components'
import { RE_URL_WITH_OUT_PROTOCOL } from 'src/constants'
import { EMessage, ETrackingEvent } from 'src/enums'
import {
  useAppDispatch,
  useBehaviorMapper,
  useIfMobileL,
  useOldValidation,
  useUnsavedChanges
} from 'src/hooks'
import { IconPlus, IconTrash } from 'src/icons'
import { EFileUploadKind, ISettingsProfile } from 'src/interfaces'
import { ProfileService, SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { editProfileSchema } from 'src/validation'
import { STFloatAction } from '../security/styled'
import { Setting } from '../setting'
import { SettingTabService } from '../setting/tab.service'
import { CompanyAvatar } from './company-avatar'
import Style from './style.module.scss'
import { VVC } from './vcc'

const inputSx = {
  marginBottom: 0
}

const inputSxCompanyWebsite = {
  ...inputSx,
  width: '100%'
}

export const Profile: FC = () => {
  const dispatch = useAppDispatch()
  const isMobile = useIfMobileL()
  const { eventHandler } = useAnalytic('settings_public')
  const profile = useBehaviorMapper(ProfileService.settingProfile$)

  // const [openPreview, setOpenPreview] = useState(false)
  const { errors, setErrors, validateAt, validate } = useOldValidation<ISettingsProfile>()

  const isChanged = useMemo(() => profile && ProfileService.isSettingProfileChanged(profile), [profile])

  const resetFormData = useCallback(async () => {
    ProfileService.resetSettingProfile()
  }, [])

  const companyUrls = useMemo(() => {
    if (!Array.isArray(profile.companyUrls) || profile.companyUrls?.length === 0) {
      return ['']
    }

    return profile.companyUrls
  }, [profile.companyUrls])

  const addMoreCompanyLink = useCallback(() => {
    if (companyUrls.length > 3) return

    ProfileService.patch({ companyUrls: [...companyUrls, ''] })
  }, [companyUrls])

  const removeCompanyLink = (index: number) => {
    if (companyUrls.length === 1) return

    const newCompanyUrls = [...companyUrls]
    newCompanyUrls.splice(index, 1)
    ProfileService.patch({ companyUrls: newCompanyUrls })
  }

  const handleChangeCompanyLink = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target
    const newCompanyUrls = [...companyUrls]
    newCompanyUrls[index] = value
    ProfileService.patch({ companyUrls: newCompanyUrls })

    const isValid = RE_URL_WITH_OUT_PROTOCOL.test(value)
    const _errors = errors.companyUrls || []
    _errors[index] = isValid ? '' : 'Invalid URL'
    setErrors((prev) => ({ ...prev, companyUrls: _errors }))
  }

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    setErrors((prev) => ({ ...prev, [name]: '' }))
    ProfileService.patch({ [name]: value })
  }

  const handleBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: editProfileSchema,
      path: name,
      data: { [name]: value }
    })
  }

  const save = useCallback(async () => {
    try {
      if (!profile) return

      const isValid = await validate({
        schema: editProfileSchema,
        data: profile
      })

      if (!isValid) return

      dispatch(setLayoutLoading(true))

      const payload: Parameters<typeof ProfileApi.updatePublic>[0] = {
        // companyKey,
        companyName: profile.companyName,
        companyUrls: profile.companyUrls,
        scheduleUrl: profile.scheduleUrl,
        bio: profile.bio
      }

      if (profile.avatar?.file) {
        payload.companyKey = await UploadApi.upload({
          kind: EFileUploadKind.COMPANY_PHOTO,
          file: profile.avatar.file
        })
      }

      await ProfileApi.updatePublic(payload)

      dispatch({ type: AUTH_GET_PROFILE })

      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Bam! Profile Update Successful'
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }, [dispatch, profile, validate])

  useUnsavedChanges({ isChanged, onSave: save, onClose: () => { SettingTabService.setTab(0) }, onLeave: () => ProfileService.resetSettingProfile() })

  const handleAvatarChanged = useCallback((file: File) => {
    const src = URL.createObjectURL(file)
    ProfileService.patch({ avatar: { src, file } })
  }, [])

  return (
    <Setting>
      <div className={Style.STContainer}>
        <div className={Style.STProfileBox}>
          <div className={Style.top}>
            <div className={Style.left}>
              <CompanyAvatar
                src={profile.avatar?.src}
                onFileChanged={handleAvatarChanged}
              />

              <div className={Style.inputGroup}>
                <span className={Style.title}>Company Name</span>
                <Input
                  name="companyName"
                  placeholder="Write your company name"
                  value={profile.companyName || ''}
                  error={errors.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={inputSx}
                  onClick={eventHandler(ETrackingEvent.INPUT_COMPANY)}
                  autoComplete="off"
                  required
                  labelClassName={Style.customLabel}
                />
              </div>
              <div className={Style.inputGroup}>
                <span className={Style.title}>Company Link</span>
                {companyUrls.map((url, index) => (
                  <div key={index} className={Style.companyWebsites_inputWrapper}>
                    <Input
                      name="companyUrls"
                      placeholder="Add your company’s website, LinkedIn,...."
                      value={profile.companyUrls?.[index] || ''}
                      error={errors.companyUrls?.[index] || ''}
                      onChange={(e) => handleChangeCompanyLink(e, index)}
                      sx={inputSxCompanyWebsite}
                      onClick={eventHandler(ETrackingEvent.INPUT_WEBSITE)}
                      autoComplete="off"
                      required
                      fullWidth
                      labelClassName={Style.customLabel}
                    />
                    {index > 0 && (
                      <IconButton
                        className={Style.companyWebsites_buttonRemoveWebsite}
                        onClick={() => removeCompanyLink(index)}
                      >
                        <IconTrash width={18.5} height={20.25}/>
                      </IconButton>
                    )}
                  </div>
                ))}
                <Button
                  className={Style.companyWebsites_buttonMore}
                  order="secondary"
                  startIcon={<IconPlus width={16} height={16}/>}
                  disabled={companyUrls.length >= 3}
                  onClick={addMoreCompanyLink}
                  kmSize="medium"
                >
                  More
                </Button>
              </div>

              <div className={Style.inputGroup}>
                <span className={Style.title}>X Factor</span>
                <Textarea
                  name="bio"
                  placeholder="Stand out to talent!"
                  value={profile.bio || ''}
                  error={errors.bio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onClick={eventHandler(ETrackingEvent.INPUT_COOL_FACTOR)}
                  autoComplete="off"
                  disableResize
                />
              </div>
            </div>
            <div>
              <VVC video={profile.video}/>
            </div>
          </div>
        </div>
      </div>

      {isChanged && (
        <STFloatAction isMobile={isMobile}>
          <Button
            order="secondary"
            {...(isMobile ? { sx: { flex: 1 } } : { width: 177 })}
            onClick={eventHandler(ETrackingEvent.BTN_CANCEL, () => {
              setErrors({})
              resetFormData()
            })}
          >
            Cancel
          </Button>
          <Button
            {...(isMobile ? { sx: { flex: 1 } } : { width: 177 })}
            onClick={eventHandler(ETrackingEvent.BTN_UPDATE, () => save())}
            disabled={errors.companyUrls?.some((error) => !!error)}
            className={Style.customeSubmitBtn}
          >
            Update
          </Button>
        </STFloatAction>
      )}
    </Setting>
  )
}
