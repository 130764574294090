import { styled } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
    position: relative;
    padding: ${theme.spacing(10, 5)};
    border-radius: 32px;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors['--color-neutral-theme-50']};

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(5)};

    img {
      animation: show 1s;
    }

    @keyframes show {
      0%   {opacity: 0; transform: scale(0)}
      100% {opacity: 1; transform: scale(1)}
    }
`)
