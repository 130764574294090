import { FC } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { ListSandBox } from './list'
import { MyJobsSandbox } from './my-jobs'

export const Sandbox: FC = () => {
  const currentStep = useBehaviorMapper(ShareJobTourService.currentStep$)

  if (currentStep === 0) {
    return <MyJobsSandbox/>
  }

  return <ListSandBox/>
}
