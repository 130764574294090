import { styled } from '@mui/material/styles'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  aspect-ratio: 343/601;
`)

export const STContent = styled('div', {
  label: 'Content'
})(({ theme }) => `
  display: flex;
  padding: 24px 16px;
  height: 88px;
  background-color: ${theme.colors['--color-neutral-theme-50']};
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
`)
