import { useMemo } from 'react'
import { IconCircleClose, IconFileDocx, IconFilePdf2 } from 'src/icons'
import { shortenFilename } from 'src/utils'
import Style from './style.module.scss'

// function downloadFile(file: File) {
//   // create a temporary anchor element
//   const anchor = document.createElement('a')
//   anchor.href = URL.createObjectURL(file)
//   anchor.target = '_blank'
//   anchor.download = file.name

//   // programmatically trigger a click event
//   anchor.click()

//   // clean up resources
//   URL.revokeObjectURL(anchor.href)
// }

interface IProps {
  url?: string | null
  fileName?: string
  onRemove?: () => void
}

export const AttachedFile = (props: IProps) => {
  const handleClick = () => {
    if (props.url) {
      return window.open(props.url, '_blank', 'noreferrer')
    }
  }

  const isPdf = useMemo(() => {
    return props.fileName?.endsWith('.pdf')
  }, [props.fileName])

  return (
    <div
      role="button"
      className={Style.STResumeFile}
      onClick={handleClick}
    >
      {isPdf ? <IconFilePdf2 className={Style.iconFile}/> : <IconFileDocx className={Style.iconFile}/>}
      <span className="body2-bold">{shortenFilename(props.fileName)}</span>
      <IconCircleClose
        className={Style.close}
        onClick={(e) => {
          e.stopPropagation()
          props.onRemove?.()
        }}
      />
    </div>
  )
}
