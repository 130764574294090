import { isCompanyEmail } from 'company-email-validator'

const BYPASS_COMPANY_EMAILS = [
  'ssspecialist805@gmail.com',
  'jmuncy4@icloud.com'
]

export const validCompanyEmail = (email: string): boolean => {
  return BYPASS_COMPANY_EMAILS.includes(email) || isCompanyEmail(email)
}
