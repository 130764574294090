import { INotificationState, INotificationAction, NOTIFICATION_NEW_INTRO_CANDIDATE, NOTIFICATION_NEW_LIKE_CANDIDATE } from '../types/notification'

export const initState: INotificationState = {
  newLikeCandidate: false,
  newIntroCandidate: false
}

export const reducer = (state = initState, action: INotificationAction) => {
  switch (action.type) {
    case NOTIFICATION_NEW_LIKE_CANDIDATE:
      return {
        ...state,
        newLikeCandidate: action.value
      }
    case NOTIFICATION_NEW_INTRO_CANDIDATE:
      return {
        ...state,
        newIntroCandidate: action.value
      }

    default:
      return state
  }
}
