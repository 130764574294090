import { IRouterOption } from 'src/router'
import { Information } from './components/information'
import { Notification } from './components/notification'
import { Profile } from './components/profile'
import { Security } from './components/security'
import { ESettingRoutes } from './routes.enum'

export { ESettingRoutes }

export const SettingRoutes: IRouterOption[] = [
  {
    path: '/setting/profile',
    component: Profile,
    name: ESettingRoutes.PROFILE,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/setting/information',
    component: Information,
    name: ESettingRoutes.INFORMATION,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/setting/security',
    component: Security,
    name: ESettingRoutes.SECURITY,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/setting/notification',
    component: Notification,
    name: ESettingRoutes.NOTIFICATION,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
