import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { useBehaviorMapper, useIsAtGuestOnboarding, useIsAtGuestView } from 'src/hooks'
import { IconArrowRight, IconConfetti } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import { ERecordFor, ProfileService } from 'src/services'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import Style from './style.module.scss'

export const MissionVVC: FC = () => {
  const settingProfile = useBehaviorMapper(ProfileService.settingProfile$)
  const isAtGuestView = useIsAtGuestView()
  const isAtGuestOnboarding = useIsAtGuestOnboarding()
  const history = useHistory()
  const guestOnboardingCreateJobCompleted = useBehaviorMapper(GuestOnboardingCreateJobTourService.completed$)

  const handleMissionVVCClicked = useCallback(() => {
    PopupTourService.hide()

    if (isAtGuestOnboarding) {
      PopupTourService.showVVCPopup()
      return
    }

    history.push(generate(ERoutes.RECORDING, { type: ERecordFor.PFV }))
  }, [history, isAtGuestOnboarding])

  if (settingProfile.video || isAtGuestView || !guestOnboardingCreateJobCompleted) return null

  return (
    <div className={Style.container} onClick={handleMissionVVCClicked}>
      <IconConfetti width="40px" height="40px" className="fx-shrink-0"/>

      <span className="meta-bold color-neutral-theme-700 fx-1">
        Create a video vibe check to earn <span className="color-cyan-700">{CREDIT_FOR_FIRST_TIME_VVC} credits</span> for free
      </span>
      <IconArrowRight
        width="18px"
        height="18px"
        className="svg-color-cyan-600"
      />
    </div>
  )
}
