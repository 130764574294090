
import { styled } from '@mui/material'
import { IconWrapperProps } from '.'

export const STIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
  name: 'Icon wrapper'
})<IconWrapperProps>(({ width, height, opacity, color, background, active, disabled }) => `
    position: relative;
    overflow: hidden;
    width: ${width || 48}px;
    height: ${height || 48}px;
    cursor: pointer;
    border-radius: 99px;
    background: ${background || 'transparent'};
    opacity: ${opacity || 1};
    pointer-events: ${disabled ? 'none' : 'all'};
    display: flex;
    align-items:center;
    justify-content: center;

    &.icon-active svg path {
      fill: white;
      transition: .25s ease-in;
    }

    svg {
      position: relative;
      z-index: 2;

      path {
        fill: ${color || ''}
      }
    }

    span.ripple {
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 500ms linear;
      background-color: ${active?.background};
    }

    @keyframes ripple {
      to {
        transform: scale(4);
        opacity: 0;
      }
    }
`)
