// (() => {
//   // redirect to the new domain
//   const url = new URL('https://jobs.knowmebest.com')
//   url.pathname = '/hiring' + window.location.pathname
//   url.search = window.location.search
//   window.location.href = url.href
// })()
// export {}

/**
 * polyfill
 */
import 'src/polyfill'
import 'src/utils/axios.config'

// load firebase
import 'src/services/firebase'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { EEnv } from './constants'
import { register, unregister } from './registerServiceWorker'
import reportWebVitals from './reportWebVitals'

import 'overlayscrollbars/overlayscrollbars.css'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/effect-coverflow'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-flip'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'
import './index.css'
import './styles/_autoload.scss'

// fix iphone 100vh
const windowHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--window-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', windowHeight)
windowHeight()

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (EEnv.NODE_ENV === 'production' && EEnv.REACT_APP_ENABLE_SW) {
  register()
} else {
  unregister()
}
