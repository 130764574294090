import { Typography, useTheme } from '@mui/material'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components'
import { LikeCardEmptyImg } from 'src/images'
import Style from './style.module.scss'

export const Empty: FC = () => {
  const theme = useTheme()
  const history = useHistory()

  const goToVibes = useCallback(() => {
    history.push('/vibes')
  }, [history])

  return (
    <div className={Style.container}>
      <img src={LikeCardEmptyImg} alt="card"/>

      <div className={Style.content}>
        <Typography variant="h5" color={theme.colors['--color-neutral-theme-250']}>
          You haven’t liked any vibe checks
        </Typography>
        <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-250']}>
          Go to “Vibes” and like your favorite talents
        </Typography>

        <Button className={Style.vibeBtn} onClick={goToVibes}>Vibe Now</Button>
      </div>
    </div>
  )
}
