export const NOTIFICATION_NEW_LIKE_CANDIDATE = Symbol('NOTIFICATION_NEW_LIKE_CANDIDATE')
export const NOTIFICATION_NEW_INTRO_CANDIDATE = Symbol('NOTIFICATION_NEW_INTRO_CANDIDATE')

export interface INotificationState {
  newLikeCandidate?: boolean
  newIntroCandidate?: boolean
}

export interface INotificationAction {
  type: typeof NOTIFICATION_NEW_LIKE_CANDIDATE | typeof NOTIFICATION_NEW_INTRO_CANDIDATE
  value?: boolean
}
