import { Placement, State, createPopper } from '@popperjs/core'
import { useEffect, useRef, useState } from 'react'

interface UsePopperOptions {
  selector: string
  position: Placement
  shouldScroll?: boolean
  onUpdate?: (state: State) => void
}

export const usePopper = ({ selector, position, shouldScroll, onUpdate }: UsePopperOptions) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const popperInstanceRef = useRef<ReturnType<typeof createPopper> | null>(null)
  const [placementPosition, setPlacePosition] = useState<Placement | undefined>(position)

  useEffect(() => {
    const reference = document.querySelector(selector) as HTMLElement
    if (reference) {
      setReferenceElement(reference)
    }
  }, [selector])

  useEffect(() => {
    if (popperElement && referenceElement) {
      if (shouldScroll) {
        referenceElement?.parentElement?.scrollIntoView({ behavior: 'smooth' })
      }

      popperInstanceRef.current = createPopper(referenceElement, popperElement, {
        placement: position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 32] // Adjust the offset as needed
            }
          },
          {
            name: 'onUpdate',
            enabled: true,
            phase: 'main',
            fn: ({ state }) => {
              onUpdate?.(state)
            }
          }
        ],
        strategy: 'fixed',
        onFirstUpdate: (state) => {
          setPlacePosition(state.placement)
        }
      })

      return () => {
        if (popperInstanceRef.current) {
          popperInstanceRef.current.destroy()
        }
      }
    }
  }, [popperElement, referenceElement, position, shouldScroll, onUpdate])

  return { setPopperElement, currentPopperInstance: popperInstanceRef.current, placementPosition }
}
