import { Typography } from '@mui/material'
import { ChangeEventHandler, FC, FormEventHandler, useCallback, useMemo, useState } from 'react'
import { PasswordApi } from 'src/api'
import { Button, Input, NotAllowAuthenticated, useAnalytic } from 'src/components'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useOldValidation, useQueryParams } from 'src/hooks'
import { IconLock } from 'src/icons'
import { Footer } from 'src/layout/layout-container/footer'
import { SnackbarService } from 'src/services'
import { AUTH_LOGIN_SUCCESS } from 'src/store/types'
import { EventUtils, getApiErrorMessage } from 'src/utils'
import { resetPasswordSchema } from 'src/validation'
import { Header } from '../header'
import { LeftSection } from '../left-section'
import Style from './style.module.scss'

interface IResetPasswordForm {
  newPassword?: string
  confirmPassword?: string
}

export const ResetPassword: FC = () => {
  const { token } = useQueryParams()
  const dispatch = useAppDispatch()

  const [formValues, setFormValues] = useState<IResetPasswordForm>({ newPassword: '', confirmPassword: '' })
  const { errors, validateAt, validate } = useOldValidation<IResetPasswordForm>()
  const { eventHandler } = useAnalytic('pswd_reset4')

  const handleChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: resetPasswordSchema,
      path: name,
      data: { ...formValues, [name]: value }
    })
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, formValues])

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(async (e) => {
    try {
      EventUtils.preventDefault(e)
      const isValid = await validate({ schema: resetPasswordSchema, data: formValues })
      if (!isValid || !formValues.newPassword) return

      const response = await PasswordApi.resetPasswordRequest({ password: formValues.newPassword, token: token as string })

      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: response.data.profile
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    }
  }, [formValues, token, validate, dispatch])

  const isInValid = useMemo(() =>
    Object.keys(errors)?.length <= 0 ||
    Object.values(errors).some((err) => err) ||
    Object.values(formValues).some((value) => !value)
  , [errors, formValues])

  return (
    <NotAllowAuthenticated>

      <>
        <div className={Style.Header}>
          <Header/>
        </div>
        <div className={Style.Container}>
          <LeftSection illustration={3}/>
          <div className={Style.RightSide}>
            <div className={Style.STSetPasswordWrapper}>
              <form className={Style.STForm} onSubmit={handleSubmit}>
                <div className={Style.LoginHeader}>
                  <Typography className={Style.Title}>Reset password</Typography>
                  <Typography align="center" className={Style.Description}>
                    Your new password must be different from previously used passwords.
                  </Typography>
                </div>
                <div className={Style.FormInput}>
                  <Input
                    label="New Password"
                    id="newPassword"
                    type="password"
                    name="newPassword"
                    placeholder="Enter your password"
                    value={formValues.newPassword || ''}
                    onChange={handleChangeInput}
                    onClick={eventHandler(ETrackingEvent.INPUT_PSWD_NEW)}
                    error={errors.newPassword}
                    isCheckPassword
                    required
                    labelClassName={Style.Label}
                    startIcon={<IconLock/>}
                  />
                  <Input
                    label="Confirm Your Password"
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    placeholder="Enter your password"
                    value={formValues.confirmPassword || ''}
                    onChange={handleChangeInput}
                    onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
                    error={errors.confirmPassword}
                    required
                    labelClassName={Style.Label}
                    startIcon={<IconLock/>}
                  />

                  <Button
                    fullWidth
                    type="submit"
                    disabled={isInValid}
                    onClick={eventHandler(ETrackingEvent.BTN_CHANGE_PSWD)}
                  >
                    Reset Password
                  </Button>
                </div>
              </form>
            </div>
            <Footer/>
          </div>
        </div>
      </>
    </NotAllowAuthenticated>
  )
}
