
const ErrorView = () => (
  <div>
    Oh snap! Your browser failed to record your video.
    <br/>
    <br/>
    Please restart it and try again 👍
  </div>
)

export default ErrorView
