import { Typography } from '@mui/material'
import { FC } from 'react'
import { Button } from 'src/components'
import { IconClose, IconVideoAdd } from 'src/icons'
import partyPopper from 'src/images/party-popper.svg'
import Style from './style.module.scss'

interface IVVCAdProps {
  onClose: () => void
  onOk: () => void
}
export const VVCAd: FC<IVVCAdProps> = ({ onOk, onClose }) => {
  return (
    <div className={Style.vvcAd}>
      <button className={Style.btnClose} onClick={onClose}>
        <IconClose/>
      </button>
      <div className="fx flex-row gap-8px">
        <img src={partyPopper} alt="party-popper" draggable={false}/>
        <Typography variant="meta-bold" color="#24252D">Make a video vibe check now to earn a premium placement and
          <span className={Style.creditText}> 25 credits</span>
        </Typography>
      </div>

      <Button
        fullWidth
        startIcon={<IconVideoAdd/>}
        className={Style.recordBtn}
        onClick={onOk}
      >Record Now
      </Button>
    </div>
  )
}
