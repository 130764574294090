import { useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useUnsubscribeEffect, useWindowSize } from 'src/hooks'
import { NavigationService } from 'src/services'

export const useIfHasSpaceForThumbnailPicker = (requiredSpacing: number) => {
  const [hasSpace, setHasSpace] = useState(false)
  const [space, setSpace] = useState(0)
  const [target, setTarget] = useState<HTMLDivElement | null>()
  const size = useWindowSize()
  const [forceRecal, setForceRecal] = useState(0)

  useEffect(() => {
    if (!target) return

    const rect = target.getBoundingClientRect()
    const _space = window.innerWidth - (rect.left + rect.width)
    setHasSpace(_space > requiredSpacing)
    setSpace(_space)
  }, [requiredSpacing, target, size, forceRecal])

  useUnsubscribeEffect((u$) => {
    NavigationService.isSizebarCollapsed$
      .pipe(takeUntil(u$))
      .subscribe(() => {
        setForceRecal(recal => recal + 0.1)
      })
  }, [])

  return {
    ref: setTarget,
    hasSpace,
    space
  }
}
