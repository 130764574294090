import clsx from 'clsx'
import { FC } from 'react'
import { ReactComponent as SmallLogo } from './small-logo.svg'
import Style from './style.module.scss'

interface IProps {
  className?: string
}

export const VideoProcessingSpinner: FC<IProps> = ({ className }) => {
  return (
    <div className={clsx('fx flex-column fx-jc-center fx-ai-center relative gap-4', className)}>
      <div className="fx flex-column fx-jc-center fx-ai-center relative">
        <div className={Style.loader}/>
        <SmallLogo className={Style.smallLogo}/>
      </div>
      <span className={clsx(Style.text, 'meta-medium text-center color-neutral-theme-50')}>
        Sorry, the video is being processed. This won't take long.
      </span>
    </div>
  )
}
