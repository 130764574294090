import { Skeleton, useTheme } from '@mui/material'
import { FC } from 'react'
import Style from './style.module.scss'

export const Applicant: FC = () => {
  const theme = useTheme()

  return (
    <div className={Style.container}>
      <Skeleton
        animation="wave"
        sx={{
          bgcolor: theme.colors['--color-neutral-theme-200'],
          width: '100%',
          height: '100%',
          // fix border radius in safari
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
        }}
        variant="rectangular"
      />
      <div className={Style.content}>
        <Skeleton
          width={164}
          height={20}
          animation="wave"
          sx={{
            bgcolor: theme.colors['--color-neutral-theme-200'],
            borderRadius: '12px'
          }}
          variant="rectangular"
        />
        <Skeleton
          width={80}
          height={12}
          animation="wave"
          sx={{
            bgcolor: theme.colors['--color-neutral-theme-200'],
            borderRadius: '12px'
          }}
          variant="rectangular"
        />
      </div>
    </div>
  )
}
