import React, { FC, useMemo } from 'react'
import Lottie from 'react-lottie'
import { useBehaviorMapper } from 'src/hooks'
import { LottiesModule } from 'src/store'

interface IEarnCreditProps {
  play?: boolean
}

export const EarnCredit: FC<IEarnCreditProps> = React.forwardRef(function EarnCredit(
  props: IEarnCreditProps,
  ref: any
) {
  const earnCredit = useBehaviorMapper(LottiesModule.earnCredit$)
  const defaultOptions = useMemo(() => ({
    loop: true,
    autoplay: true,
    animationData: earnCredit,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }), [earnCredit])
  return (
    <Lottie
      ref={ref}
      width={60}
      options={defaultOptions}
    />
  )
})
