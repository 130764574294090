import { FC } from 'react'
import { IconSignInUpLogo } from 'src/images'
import Style from './style.module.scss'

export const Header: FC = () => {
  return (
    <div className={Style.publicHeader}>
      <div className={Style.logo}>
        <IconSignInUpLogo/>

      </div>

    </div>
  )
}
