import { Avatar, Box, Typography, useTheme } from '@mui/material'
import { ChangeEventHandler, FC, useState } from 'react'

import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { DefaultCompany } from 'src/images'
import Style from './style.module.scss'

interface IProps {
  src?: string
  onFileChanged?: (file: File) => void
}

export const CompanyAvatar: FC<IProps> = ({ src, onFileChanged }) => {
  const theme = useTheme()
  const { eventHandler } = useAnalytic('')
  const [isAvatarError, setIsAvatarError] = useState(false)

  const handleAvatar: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.[0]
    if (!file) return
    const ext = file.name.split('.').pop()
    if (!['jpg', 'jpeg', 'png'].includes(ext?.toLowerCase() || '')) {
      setIsAvatarError(true)
      return
    }

    onFileChanged?.(file)
    setIsAvatarError(false)
  }

  return (
    <>
      <div className={Style.STAvatar}>
        <Box display="flex" flexDirection="row" width="100%" gap={2} alignItems="center">
          <Avatar
            src={src || DefaultCompany}
            alt="avatar"
            sx={{ width: 150, height: 150, borderRadius: 2 }}
          />
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            gap={1}
          >
            <Button
              order="secondary"
              field="input"
              size="small"
              sx={{ borderRadius: 3, height: 'auto', paddingY: '8px' }}
              accept="image/jpeg,image/png"
              handleChange={handleAvatar}
              event={eventHandler(ETrackingEvent.BTN_PROFILE_PIC_CHANGE)}
            >
              Upload Company Logo
            </Button>
            <Typography variant="body2-regular" fontSize={12}>PNG, JPG (max.800x800px)</Typography>
          </Box>
        </Box>
      </div>

      <Typography
        variant="subtitle2"
        marginTop="-20px"
        mb={1}
        color={theme.colors['--color-negative-500']}
      >
        {isAvatarError ? 'Only .png and .jpeg files are allowed' : ''}
      </Typography>
    </>
  )
}
