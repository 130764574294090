import { isNil, isNumber, isObject } from 'lodash'
import { useMemo } from 'react'
import { ESalaryRate } from 'src/interfaces'
import { formatMoney } from 'src/utils'

const mapSalaryRate = {
  [ESalaryRate.PER_HOUR]: 'hour',
  [ESalaryRate.PER_MONTH]: 'month',
  [ESalaryRate.PER_YEAR]: 'year'
}

export const useCampaignSalary = (data?: { salaryRate?: ESalaryRate; salaryValue?: number | { max?: number; min?: number } | string }) => {
  const salaryRate = useMemo(
    () => data?.salaryRate && mapSalaryRate[data.salaryRate],
    [data?.salaryRate]
  )

  const salaryValue = useMemo(() => {
    try {
      const currency = '$'
      if (isNil(data?.salaryValue)) {
        return 'Not Provided'
      }

      if (isNumber(data?.salaryValue)) {
        return `${formatMoney(data?.salaryValue, currency)} / ${salaryRate}`
      }

      if (isObject(data?.salaryValue)) {
        return `${formatMoney(data?.salaryValue?.min, currency)} - ${formatMoney(data?.salaryValue?.max, currency)} / ${salaryRate}`
      }

      return `${data?.salaryValue} / ${salaryRate}`
    } catch (error) {
      return JSON.stringify(data?.salaryValue)
    }
  }, [data?.salaryValue, salaryRate])

  return salaryValue
}
