import clsx from 'clsx'
import { FC } from 'react'
import { NoTabletOrMobile, TabletAndMobile, useSpotlight } from 'src/components'
import { IconVideoTutorial } from 'src/icons'
import { IconUploadVideo } from 'src/images'
import { RecordingService } from 'src/services'
import { ActionTeleprompter } from '../action-teleprompter'
import Style from './style.module.scss'

interface IProps {
  show: boolean
  onOpenSample: () => void
  recordingService: RecordingService
  onUploadButtonClick: () => void
}

export const ActionMenu: FC<IProps> = (props) => {
  const { spotRef: spotRefUpload } = useSpotlight({ stepIndex: 0, shape: 'square' })
  const { spotRef: spotRefSample } = useSpotlight({ stepIndex: 1 })

  return (
    <>
      <div
        className={clsx(Style.actions, { 'd-none': !props.show })}
      >
        <div className={Style.actionItem}>
          <button onClick={props.onOpenSample} ref={spotRefSample} id="target-sample">
            <IconVideoTutorial/>
          </button>
          <p>Samples</p>
        </div>

        <ActionTeleprompter
          recordingService={props.recordingService}
        />

        <NoTabletOrMobile>
          <div className={Style.actionItem}>
            <button
              className={Style.UploadVideoBtn}
              onClick={props.onUploadButtonClick}
              ref={spotRefUpload}
              id="target-upload"
            >
              <img
                src={IconUploadVideo}
                alt="upload video"
                width={64}
                height={64}
              />
            </button>
            <p>Upload</p>
          </div>
        </NoTabletOrMobile>
      </div>

      {props.show && (
        <TabletAndMobile>
          <div className={clsx(Style.actionItem, Style.actionItemUploadVideo)}>
            <button
              className={Style.UploadVideoBtn}
              ref={spotRefUpload}
              id="target-upload"
              onClick={props.onUploadButtonClick}
            >
              <img
                src={IconUploadVideo}
                alt="upload video"
                width={64}
                height={64}
              />
            </button>
            <p>Upload</p>
          </div>
        </TabletAndMobile>
      )}
    </>
  )
}
