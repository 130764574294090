import { merge } from 'lodash'
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { AnalyticApi } from 'src/api'
import { useAsRef } from 'src/hooks'
import { getApiErrorCode } from 'src/utils'
import { axiosHiringApi } from 'src/utils/axios.defaults'

interface IAnalyticContext {
  screen: string
  setScreen?: (screen: string) => void
  rootContextData?: TContextData
  setRootContextData?: (data: TContextData) => void
}

const AnalyticContext = createContext<IAnalyticContext>({
  screen: '',
  rootContextData: {}
})

type TEventContext = {
  key: string
  contextData: TContextData | ((...args: any[]) => TContextData)
}

type TContextData = Record<string, unknown>

type TEventHandler = {
  (event: string, handler?: (...args: any[]) => any): (...args: any[]) => any
  (event: TEventContext, handler?: (...args: any[]) => any): (...args: any[]) => any
}

export const useAnalytic = (openScreen?: string, rootContextData?: TContextData) => {
  const { screen, setScreen, rootContextData: _rootContextData, setRootContextData } = useContext(AnalyticContext)
  const currentScreenRef = useRef<string>(screen)
  const currentRootContextDataRef = useRef<TContextData | undefined>(_rootContextData)

  useEffect(() => {
    if (setScreen && openScreen && openScreen !== currentScreenRef.current) {
      setScreen(openScreen)
    }
  }, [openScreen, setScreen])

  useEffect(() => {
    if (rootContextData && setRootContextData && rootContextData !== currentRootContextDataRef.current) {
      setRootContextData?.(rootContextData)
    }
  }, [rootContextData, setRootContextData])

  useEffect(() => {
    if (screen) {
      currentScreenRef.current = screen
    }
  }, [screen])

  useEffect(() => {
    if (_rootContextData) {
      currentRootContextDataRef.current = _rootContextData
    }
  }, [_rootContextData])

  const analytic = useCallback((event: string, data?: TContextData) => AnalyticApi.create({
    screen: currentScreenRef.current,
    event,
    data: (data || currentRootContextDataRef.current) ? merge({}, currentRootContextDataRef.current, data) : undefined
  }), [])

  const eventHandler: TEventHandler = useCallback((event: string | TEventContext, handler?: (...args: any[]) => any) => {
    return (...args: any[]) => {
      // asynchronous
      if (typeof event === 'string') {
        analytic(event)
      } else {
        if (event.contextData instanceof Function) {
          analytic(event.key, event.contextData(...args))
        } else {
          analytic(event.key, event.contextData)
        }
      }

      if (typeof handler === 'function') {
        return handler(...args)
      }
    }
  }, [analytic])

  return {
    screen,
    oldScreen: currentScreenRef.current,
    setScreen: useCallback(
      (screen: string) => setScreen && setScreen(screen),
      [setScreen]
    ),
    analytic,
    eventHandler
  }
}

export const AnalyticProvider: FC<{ children?: ReactNode }> = (props) => {
  const { pathname } = useLocation()
  const [screen, setScreen] = useState('')
  const [rootContextData, setRootContextData] = useState<TContextData | undefined>(undefined)

  useEffect(() => {
    if (screen) {
      AnalyticApi.create({
        screen,
        event: 'enter'
      })
    }

    return () => {
      if (screen) {
        AnalyticApi.create({
          screen,
          event: 'leave'
        })
      }
    }
  }, [screen])

  useEffect(() => {
    const interceptorId = axiosHiringApi.interceptors.response.use(undefined, (error) => {
      const errorCode = getApiErrorCode(error)
      if (errorCode) {
        AnalyticApi.create({
          screen,
          event: errorCode
        })
      }

      throw error
    })

    return () => {
      axiosHiringApi.interceptors.response.eject(interceptorId)
    }
  }, [screen])

  const screenRef = useAsRef(screen)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!screenRef.current) {
        setScreen(pathname)
      }
    }, 500)

    const currentScreen = screenRef.current
    return () => {
      clearTimeout(timer)
      if (currentScreen) {
        setScreen('')
      }
    }
  }, [pathname, screenRef])

  return (
    <AnalyticContext.Provider value={{ screen, setScreen, rootContextData, setRootContextData }}>
      {props.children}
    </AnalyticContext.Provider>
  )
}
