import { styled } from '@mui/material'

export const STRedeemCode = styled('div', { label: 'Share' })<{ isMobile: boolean }>(({ isMobile, theme }) => `
    position: relative;
    display: flex;
    width: ${isMobile ? '100%' : '279px'};
    border-radius: 16px;
    padding: ${isMobile ? theme.spacing(3) : theme.spacing(2)};
    background-color: ${theme.colors['--color-primary-400']};
    box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);
    overflow: hidden;
`)

export const STUserSuggestion = styled('div', {
  shouldForwardProp: prop => prop !== 'isShow',
  label: 'UserSuggestion'
})<{ isShow: boolean; isMobile: boolean }>(({ isMobile, theme, ...props }) => `
    position: absolute;
    top: 64px;
    width: ${isMobile ? '100%' : '335px'};
    max-height: 220px;
    display: ${props.isShow ? 'flex' : 'none'};
    flex-direction: column;
    gap: ${theme.spacing(2.5)};
    overflow: auto;
    background-color: ${theme.colors['--color-neutral-theme-50']};
    box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.1);
    border-radius: 16px;
    padding: ${theme.spacing(2.5)};
    z-index: 2
`)

export const STUserSuggestionItem = styled('div', {
  shouldForwardProp: prop => prop !== 'isActive',
  label: 'UserSuggestionItem'
})<{ isActive: boolean }>(({ theme, ...props }) => `
    cursor: pointer;
    opacity: ${props.isActive ? 1 : 0.5};
    display: flex;
    align-items: center;

    >div: nth-of-type(1) {
      width: 48px;
      height: 48px;
      margin-right: ${theme.spacing(1.5)}
    }
`)

export const STGifWrapper = styled('div', { label: 'GifWrapper' })(({ theme }) => `
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -12px;
  right: -48px;
  width: 180px;
`)

export const STCountWrapper = styled('div', { label: 'CountWrapper' })(({ theme }) => `
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 6px);
  transform: translate(-50%, -50%);
`)
