import { PROFILE_COUNT_CREDIT, PROFILE_TRAITS, TProfileAction } from '../types'

export const setTraits = (value: {
  characteristics: string[]
  motivations: string[]
}): TProfileAction => ({
  type: PROFILE_TRAITS,
  value
})

export const setCountCredit = (value: number): TProfileAction => ({
  type: PROFILE_COUNT_CREDIT,
  value
})
