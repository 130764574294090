import {
  CSSProperties,
  FC,
  ReactNode,
  SyntheticEvent,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import { useLocation } from 'react-router'
import { GuestOnboardingTourApi, GuestViewTourApi } from 'src/api/tour'
import { NoTabletOrMobile } from 'src/components'
import { TourPopup } from 'src/components/tour/components/popup'
import { useAppDispatch, useAppSelector, useBehaviorMapper, useIsAtGuestOnboarding, useIsAtGuestView } from 'src/hooks'
import { NavigationService } from 'src/services'
import {
  setLayoutAside,
  setLayoutHeader,
  setLayoutScrollToBottom
} from 'src/store/actions'
import {
  getIsAuthenticated,
  getLayoutIsScrollToBottom
} from 'src/store/selectors'
import { Aside } from './aside'
import { Header } from './header'
import Style from './style.module.scss'

export const LayoutContainer: FC<{ children?: ReactNode }> = (props) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const layoutChildrenRef = useRef<HTMLDivElement>(null)
  const isAtRecordingPage = useBehaviorMapper(NavigationService.isAtRecordingPage$)

  const isPreviewCampaign = useMemo(
    () => location.pathname.includes('campaigns/summary/preview'),
    [location.pathname]
  )

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement
    const scrollBottom =
      Math.floor(element.scrollHeight - element.scrollTop) <=
      element.clientHeight

    if (scrollBottom) {
      return dispatch(setLayoutScrollToBottom(true))
    }

    if (isScrollToBottom) {
      return dispatch(setLayoutScrollToBottom(false))
    }
  }

  useLayoutEffect(() => {
    dispatch(setLayoutAside(!!isAuthenticated))
    dispatch(setLayoutHeader(!!isAuthenticated))
  }, [isAuthenticated, dispatch])

  const isAtGuestView = useIsAtGuestView()
  const isAtGuestOnboarding = useIsAtGuestOnboarding()

  const recordingStyle = useMemo(() => {
    if (!isAtRecordingPage) return

    return {
      backgroundColor: '#fff'
    }
  }, [isAtRecordingPage])

  const layoutContentRecordingStyle: CSSProperties = useMemo(() => {
    if (!isAtRecordingPage) return {}

    return {
      position: 'relative'
    }
  }, [isAtRecordingPage])

  if (isPreviewCampaign) {
    return (
      <section className={Style.layoutContainer}>
        <section className={Style.layoutContent}>
          <div className={Style.layoutChildren} onScroll={handleScroll}>
            {props.children}
          </div>
        </section>
      </section>
    )
  }

  return (
    <section className={Style.layoutContainer}>
      <NoTabletOrMobile>
        <Aside/>
      </NoTabletOrMobile>

      <section className={Style.layoutContent} style={layoutContentRecordingStyle}>
        <Header/>

        <div
          className={Style.layoutChildren}
          style={recordingStyle}
          onScroll={handleScroll}
          ref={layoutChildrenRef}
        >
          {props.children}

          {isAuthenticated && !isAtGuestView && (
            <TourPopup className={Style.tourPopup}/>
          )}

          {isAtGuestView && (
            <TourPopup
              className={Style.tourPopup}
              provider={GuestViewTourApi}
            />
          )}

          {isAtGuestOnboarding && (
            <TourPopup
              className={Style.tourPopup}
              provider={GuestOnboardingTourApi}
            />
          )}
        </div>
      </section>
    </section>
  )
}
