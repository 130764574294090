import {
  ILayoutAction,
  ILayoutState,
  LAYOUT_RESET_CONFIG,
  LAYOUT_SET_AD,
  LAYOUT_SET_ASIDE,
  LAYOUT_SET_HEADER,
  LAYOUT_SET_LOADING,
  LAYOUT_SET_MOBILE_ASIDE_OPEN,
  LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER,
  LAYOUT_SET_PAGE_COMPONENT,
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM,
  LAYOUT_SET_SHARING_TOKEN,
  LAYOUT_SET_SHOULD_SHOW_LOADING
} from '../types'

const initState: ILayoutState = {
  isHeader: true,
  isAside: true,
  pageTitle: null,
  isScrollToBottom: false,
  shouldShowLoading: true,
  isLoading: false,
  sharingToken: null,
  shouldShowShareButtonHeaderMobile: true,
  pageComponent: null,
  isAd: false
}

export const reducer = (state = initState, action: ILayoutAction) => {
  switch (action.type) {
    case LAYOUT_RESET_CONFIG:
      return { ...state }
    case LAYOUT_SET_ASIDE:
      return {
        ...state,
        isAside: action.value
      }
    case LAYOUT_SET_HEADER:
      return {
        ...state,
        isHeader: action.value
      }
    case LAYOUT_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.value
      }
    case LAYOUT_SET_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScrollToBottom: action.value
      }
    case LAYOUT_SET_LOADING:
      return {
        ...state,
        isLoading: action.value
      }
    case LAYOUT_SET_SHOULD_SHOW_LOADING:
      return {
        ...state,
        shouldShowLoading: action.value
      }
    case LAYOUT_SET_SHARING_TOKEN:
      return {
        ...state,
        sharingToken: action.value
      }
    case LAYOUT_SET_MOBILE_ASIDE_OPEN:
      return {
        ...state,
        isMobileAsideOpen: action.value
      }
    case LAYOUT_SET_MOBILE_SHOW_SHARE_BUTTON_HEADER:
      return {
        ...state,
        shouldShowShareButtonHeaderMobile: action.value
      }
    case LAYOUT_SET_PAGE_COMPONENT:
      return {
        ...state,
        pageComponent: action.value
      }
    case LAYOUT_SET_AD:
      return {
        ...state,
        isAd: action.value
      }
    default:
      return state
  }
}
