import { object, string } from 'yup'
import { validCompanyEmail } from './email'

export const homeSchema = object().shape({
  email: string().required('This field is required!').test(
    'error_email_domain',
    'Enter your work email',
    (email) => email ? validCompanyEmail(email) : false
  )
})
