import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { BorderLinearProgress } from 'src/components'
import { EUploadStatus } from 'src/services/upload.service'

import { useIntersection } from 'src/hooks'
import Style from './style.module.scss'

interface IProps {
  uploadStatus: EUploadStatus
  percentage: number
}

export const VideoUploadProgress: FC<IProps> = ({ uploadStatus, percentage }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const inViewPort = useIntersection(ref, '0px')
  const [isShow, setIsShow] = useState(uploadStatus === EUploadStatus.UPLOADING || uploadStatus === EUploadStatus.COMPLETED)

  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    const _isShow = uploadStatus === EUploadStatus.UPLOADING
    if (_isShow) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      setIsShow(true)
      return
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      setIsShow(false)
    }, 2000)
  }, [uploadStatus])

  return (isShow)
    ? (
      <>
        <div ref={setRef} className={clsx(Style.container)}>
          <div className="flex fx-row fx-jc-space-between fx-ai-center">
            <span className="meta-regular color-neutral-theme-50">
              {uploadStatus === EUploadStatus.COMPLETED ? 'Video is uploaded successfully' : 'Video is being uploaded...'}
            </span>
            <span className={clsx('meta-bold', uploadStatus === EUploadStatus.COMPLETED ? 'color-positive-500' : 'color-primary-400')}>{percentage}%</span>
          </div>

          <BorderLinearProgress variant="determinate" value={percentage}/>
        </div>

        {!inViewPort && (
          <div className={clsx(Style.container, Style.containerOutsideViewport)}>
            <div className="flex fx-row fx-jc-space-between fx-ai-center gap-4">
              <span className="meta-regular color-neutral-theme-50">
                {uploadStatus === EUploadStatus.COMPLETED ? 'Video is uploaded successfully' : 'Video is being uploaded...'}
              </span>
              <span className={clsx('meta-bold', uploadStatus === EUploadStatus.COMPLETED ? 'color-positive-500' : 'color-primary-400')}>{percentage}%</span>
            </div>

            <BorderLinearProgress variant="determinate" value={percentage}/>
          </div>
        )}
      </>
    )
    : null
}
