export enum ECampaignSubmissionOrder {
  NEWEST_FIRST= 'NEWEST_FIRST',
  LATEST_FIRST= 'LATEST_FIRST',
  LIKED_FIRST= 'LIKED_FIRST'
}

export enum EViewCampaignOptions {
  VVC= 'VVC',
  SEXE= 'SEXE'
}

export enum ECampaignStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}
