import { Button } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { IButtonProps } from '.'

const ButtonBase = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'kmSize'
})<IButtonProps>(({ theme, kmSize, ...props }) => css`
  width: ${props.width ? props.width + 'px' : 'auto'};
  height: ${kmSize === 'large' ? `${props.height}px` : 'auto'};

  ${{ ...theme.typography['body2-bold'] }};

  padding: ${kmSize === 'large' ? theme.spacing(0, 2) : theme.spacing(1, 2)};
  text-transform: unset;
  border-radius: ${kmSize === 'large' ? '16px' : '12px'};

  ${kmSize === 'medium' && css`
    ${{ ...theme.typography['body2-bold'] }};
    line-height: 21px;
  `}

  &.MuiButton-fullWidth {
    width: 100% !important
  }
`)

export const ButtonPrimary = styled(ButtonBase)<IButtonProps>(({ theme, ...props }) => `
  color: ${theme.colors['--color-neutral-theme-700']} !important;
  background-color: ${props.background || `${theme.colors['--color-primary-400']} !important`} ;
  border: 1px solid ${theme.colors['--color-primary-600']};

  &:hover {
    background-color: ${props.background || `${theme.colors['--color-primary-300']} !important`} ;
  }

  &:focus {
    background-color: ${props.background || `${theme.colors['--color-primary-300']} !important`} ;
  }

  &:active {
    background-color: ${props.background || `${theme.colors['--color-primary-400']} !important`} ;
  }

  &:disabled {
    color: ${theme.colors['--color-neutral-theme-50']} !important;
    background-color: ${theme.colors['--color-neutral-theme-200']} !important;
    border: 1px solid transparent;
  }
`)

export const ButtonOutlineBlack = styled(ButtonBase)<IButtonProps>(({ theme, background }) => `
  box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-600']};

  color: ${theme.colors['--color-neutral-theme-900']} !important;
  background-color: transparent !important;

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
  }

  &:active {
    border: none;
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-400']};
    color: ${theme.colors['--color-neutral-theme-400']} !important;
  }
`)

export const ButtonBlack = styled(ButtonBase)<IButtonProps>(({ theme, ...props }) => `
  color: ${theme.colors['--color-white']} !important;
  background-color: ${props.background || `${theme.colors['--color-neutral-theme-900']} !important`} ;
  border: 1px solid ${theme.colors['--color-neutral-theme-700']};

  &:hover {
    background-color: ${props.background || `${theme.colors['--color-neutral-theme-700']} !important`} ;
  }

  &:focus {
    background-color: ${props.background || `${theme.colors['--color-neutral-theme-700']} !important`} ;
  }

  &:active {
    background-color: ${props.background || `${theme.colors['--color-neutral-theme-700']} !important`} ;
  }

  &:disabled {
    color: ${theme.colors['--color-white']} !important;
    background-color: ${theme.colors['--color-neutral-theme-600']} !important;
    border: 1px solid transparent;
  }
`)

export const ButtonDangerOutlined = styled(ButtonBase)<IButtonProps>(({ theme, background }) => `
  box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-negative-400']};

  color: ${theme.colors['--color-negative-500']} !important;
  background-color: ${background || theme.colors['--color-neutral-theme-50']} !important;

  &:hover {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-100']} !important;
  }

  &:focus {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-50']} !important;
  }

  &:active {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-200']} !important;
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-250']};
    color: ${theme.colors['--color-neutral-theme-250']} !important;
  }
`)

export const ButtonDanger = styled(ButtonBase)<IButtonProps>(({ theme, background }) => `
  box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-negative-400']};

  color: ${background || theme.colors['--color-neutral-theme-50']} !important;
  background-color: ${theme.colors['--color-negative-500']} !important;

  &:hover {
    border: none;
    background-color: ${theme.colors['--color-negative-400']} !important;
  }

  &:focus {
    border: none;
    background-color: ${theme.colors['--color-negative-400']} !important;
  }

  &:active {
    border: none;
    background-color: ${theme.colors['--color-negative-400']} !important;
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-250']};
    color: ${theme.colors['--color-neutral-theme-250']} !important;
  }
`)

export const ButtonSecondary = styled(ButtonBase)<IButtonProps>(({ theme, background }) => `
  box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-200']};

  color: ${theme.colors['--color-neutral-theme-700']} !important;
  background-color: ${background || theme.colors['--color-neutral-theme-50']} !important;

  &:hover {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-100']} !important;
  }

  &:focus {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-50']} !important;
  }

  &:active {
    border: none;
    background-color: ${theme.colors['--color-neutral-theme-200']} !important;
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${background ? 'transparent' : theme.colors['--color-neutral-theme-200']};
    color: ${theme.colors['--color-neutral-theme-200']} !important;
  }
`)

export const ButtonTertiary = styled(ButtonBase)<IButtonProps>(({ theme }) => `
  color: ${theme.colors['--color-neutral-theme-700']} !important;
  background-color: transparent;

  &:hover {
    background-color: ${theme.colors['--color-neutral-theme-100']} !important;
  }

  &:focus {
    background-color: ${theme.colors['--color-neutral-theme-100']} !important;
  }

  &:active {
    background-color: ${theme.colors['--color-neutral-theme-200']} !important;
  }

  &:disabled {
    color: ${theme.colors['--color-neutral-theme-200']} !important;
  }
`)
