import { IRouterOption } from 'src/router'
import { LayoutSwitcher } from './components'
import { EPaymentHistoryRoutes } from './routes.enum'

export { EPaymentHistoryRoutes }

export const PaymentHistoryRoutes: IRouterOption[] = [
  {
    path: '/payment-history',
    component: LayoutSwitcher,
    name: EPaymentHistoryRoutes.PAYMENT_HISTORY,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
