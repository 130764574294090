import { createSelector } from 'reselect'
import { TAppState, TSelectorResult } from 'src/store'
import { ILayoutState } from '../types/layout'

export const getLayoutIsAside: TSelectorResult<ILayoutState['isAside']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isAside
)

export const getLayoutIsHeader: TSelectorResult<ILayoutState['isHeader']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isHeader
)

export const getLayoutPageTitle: TSelectorResult<ILayoutState['pageTitle']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.pageTitle
)

export const getLayoutPageComponent: TSelectorResult<ILayoutState['pageComponent']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.pageComponent
)

export const getLayoutIsScrollToBottom: TSelectorResult<ILayoutState['isScrollToBottom']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isScrollToBottom
)

export const getLayoutShouldShowLoading: TSelectorResult<ILayoutState['shouldShowLoading']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.shouldShowLoading
)

export const getLayoutIsLoading: TSelectorResult<ILayoutState['isLoading']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isLoading
)

export const getLayoutSharingToken: TSelectorResult<ILayoutState['sharingToken']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.sharingToken
)

export const getLayoutMobileAsideOpen: TSelectorResult<ILayoutState['isMobileAsideOpen']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isMobileAsideOpen
)

export const getLayoutShouldShowShareButtonHeaderMobile: TSelectorResult<ILayoutState['shouldShowShareButtonHeaderMobile']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.shouldShowShareButtonHeaderMobile
)

export const getLayoutIsAd: TSelectorResult<ILayoutState['isAd']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isAd
)
