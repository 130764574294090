import clsx from 'clsx'
import { motion } from 'framer-motion'
import { isString } from 'lodash'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components/button'
import { IconArrowThinDown } from 'src/icons'
import { IMission, PopupTourService } from 'src/services/tour/popup.service'
import { useMissionExpand } from '../popup/expand.context'
import Style from './style.module.scss'

export const Mission: FC<IMission> = (props) => {
  const { expanding, setExpanding } = useMissionExpand()
  const history = useHistory()

  const isExpand = useMemo(() => expanding === props.id && !props.disabled, [expanding, props.disabled, props.id])

  return (
    <div className={Style.container}>
      <div className="flex fx-row fx-ai-center gap-2 pointer" onClick={() => props.disabled ? null : setExpanding(isExpand ? undefined : props.id)}>
        {props.checked && !isExpand && (
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="fx-shrink-0">
            <circle cx="7" cy="7.5" r="6.5" fill="#F7FB6F" stroke="#181920"/>
            <path d="M3.51194 8.04557L3.58787 7.9805L3.51194 8.04557L5.51194 10.3789C5.66901 10.5622 5.93099 10.5622 6.08806 10.3789L10.4881 5.24557C10.6373 5.07144 10.6373 4.79457 10.4881 4.62044C10.331 4.4372 10.069 4.4372 9.91194 4.62044L5.8 9.41771L4.08806 7.42044C3.93099 7.2372 3.66901 7.2372 3.51194 7.42044C3.36269 7.59457 3.36269 7.87144 3.51194 8.04557Z" fill="#181920" stroke="#181920" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}

        <div className="fx flex-column fx-1">
          <span className={clsx('body2-bold fx-1 capitalize', props.checked && !isExpand ? 'color-neutral-theme-900' : props.disabled ? 'color-neutral-theme-300' : 'color-neutral-theme-900')}>{props.title}</span>
          {props.disabled && isString(props.disabled) ? <span className="meta-regular color-neutral-theme-300">{props.disabled}</span> : null}
        </div>

        <div className="fx-shrink-0 pointer">
          <IconArrowThinDown className={clsx(Style.chevronDown, isExpand ? Style.upsideDown : '')}/>
        </div>
      </div>

      <motion.div
        layout
        className={clsx(Style.body, isExpand ? '' : Style.height0)}
      >
        {!props.checked && props.creditAmount !== 0 && (
          <div className="flex fx-row gap-1 fx-ai-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="fx-shrink-0">
              <path d="M4.66667 13.3333H7.33333C7.33333 14.0667 6.73333 14.6667 6 14.6667C5.26667 14.6667 4.66667 14.0667 4.66667 13.3333ZM3.33333 12C3.33333 12.3667 3.63333 12.6667 4 12.6667H8C8.36667 12.6667 8.66667 12.3667 8.66667 12C8.66667 11.6333 8.36667 11.3333 8 11.3333H4C3.63333 11.3333 3.33333 11.6333 3.33333 12ZM11 6.33333C11 8.88 9.22667 10.24 8.48667 10.6667H3.51333C2.77333 10.24 1 8.88 1 6.33333C1 3.57333 3.24 1.33333 6 1.33333C8.76 1.33333 11 3.57333 11 6.33333ZM14.2467 4.91333L13.3333 5.33333L14.2467 5.75333L14.6667 6.66667L15.0867 5.75333L16 5.33333L15.0867 4.91333L14.6667 4L14.2467 4.91333ZM12.6667 4L13.2933 2.62667L14.6667 2L13.2933 1.37333L12.6667 0L12.04 1.37333L10.6667 2L12.04 2.62667L12.6667 4Z" fill="#3AD98A"/>
            </svg>

            <span className="meta-regular color-neutral-theme-300">
              Complete tour to earn <span className="meta-bold color-positive-500">{props.creditAmount} {props.creditAmount > 1 ? 'credits' : 'credit'}</span>
            </span>
          </div>
        )}

        <div className="flex fx-column gap-3 mt-2">
          <span className="meta-regular color-neutral-theme-900">{props.description}</span>
          <Button order="primary" sx={{ padding: '8px 16px', height: 'unset' }} onClick={() => PopupTourService.onTriggerMission(props.id, history)}>
            {props.checked ? 'Resume Tour' : 'Take a Tour'}
          </Button>
        </div>
      </motion.div>
    </div>
  )
}
