import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { Loading } from 'src/components'
import { IThemeProps, TTheme, toCssVariables } from 'src/constants'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import { BuyMoreCreditsMobile } from 'src/partials'
import { CreditService, OverlayService } from 'src/services'
import { getLayoutIsLoading, getLayoutShouldShowLoading, getThemeMode } from 'src/store/selectors'
import { BannerContainer } from './banner-container'
import { Overlay } from './overlay'
import { RouterView } from './router-view'
import { SnackbarContainer } from './snackbar-container'
import Style from './style.module.scss'
import { ToastContainer } from './toast-container'

export * from './overlay'

const createThemeVariables = makeStyles<TTheme>(
  theme => ({
    '@global': {
      ':root': ({ paletteMode }: IThemeProps) => toCssVariables(paletteMode)
    }
  })
)

export const AppLayout: FC = () => {
  const paletteMode = useAppSelector(getThemeMode)
  const isLoading = useAppSelector(getLayoutIsLoading)
  const shouldShowLoading = useAppSelector(getLayoutShouldShowLoading)
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)
  const showBuyMoreCreditsModal = useBehaviorMapper(CreditService.showBuyMoreCreditsMobileModal)
  createThemeVariables({ paletteMode })

  return (
    <div className={clsx(Style.layout, { [Style.loading]: isLoading })}>
      <SnackbarContainer/>
      <BannerContainer/>
      <ToastContainer/>

      <main className="fx-1 fx fx-column">
        <RouterView/>
      </main>

      {appOverlay.open && <Overlay/>}

      <BuyMoreCreditsMobile open={showBuyMoreCreditsModal} onClose={CreditService.handleCloseBuyMoreCreditsMobileModal}/>

      <Loading show={isLoading && shouldShowLoading}/>
    </div>
  )
}
