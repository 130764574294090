import { UnregisterCallback } from 'history'
import React from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, Subscription, finalize, take, tap } from 'rxjs'
import { ITourPopperProps } from 'src/components'
import { EEnv } from 'src/constants'
import { ELocationType } from 'src/enums'
import { ICampaignDetail } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { setLayoutLoading } from 'src/store/actions'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { CampaignMutationService } from '../campaign/campaign-mutation.service'
import { CampaignService } from '../campaign/campaign.service'
import { OverlayService } from '../overlay.service'
import { ProfileService } from '../profile.service'
import { PopupTourService } from './popup.service'

// #region Target ids for tour
export const TARGET_CREATE_JOB_BUTTON = new Target('target-button-create-job')
export const TARGET_ENTER_JOB_DETAIL = new Target('target-enter-job-detail')
export const TARGET_SHOW_HUMAN_SIDE = new Target('target-show-human-side')
export const TARGET_POTENTIAL_CANDIDATES = new Target('target-potential-candidates')
export const TARGET_DESCRIPTION = new Target('target-description')
export const TARGET_COMPANY_DETAILS = new Target('target-company-details')
export const TARGET_POST_JOB = new Target('target-post-job')
export const TARGET_SHOW_ON_KNOWME = new Target('target-show-on-knowme')

const MAX_JOB_STEPS = 5

const COMMON_STEP_CONFIGS: ITourPopperProps[] = [
  {
    title: 'Let’s post a job',
    content: 'Create your first job and watch talent come to you effortlessly!',
    currentStep: 0,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_CREATE_JOB_BUTTON.getSelector(),
    arrowPosition: 'left',
    // tip: 'Click on Create Job',
    ctaText: 'Next',
    onCtaClick: () => (document.querySelector(TARGET_CREATE_JOB_BUTTON.getSelector()) as HTMLButtonElement)?.click(),
    shouldScroll: false,
    onExitClick: () => CreateJobTourService.stopTour(true)
  },
  {
    title: 'Show your human side!',
    content: React.createElement('div', { className: 'fx flex-column gap-4 body2-regular color-neutral-theme-900' },
      React.createElement('div', { className: 'fx flex-column' },
        React.createElement('span', null, '🎬 Lights, Camera, Hire!'),
        React.createElement('span', null, 'Share your story with a captivating video – introduce yourself, talk about the job, and show people why it would be great to work for you.'))
    ),
    currentStep: 1,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_SHOW_HUMAN_SIDE.getSelector(),
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: (history) => {
      history?.goBack()
      CreateJobTourService.previousStep()
    },
    arrowPosition: 'left',
    shouldScroll: true,
    onExitClick: () => CreateJobTourService.stopTour(true),
    zIndex: 999
  },
  {
    title: 'Enter your job details',
    content: 'Let\'s start by sharing some essential job details. No pressure if you\'re not ready to post a job yet – you can save a draft and come back to it later.',
    currentStep: 2,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_ENTER_JOB_DETAIL.getSelector(),
    arrowPosition: 'right',
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: () => CreateJobTourService.previousStep(),
    // tip: 'Fill out Job Label, Job Title, Salary & Location',
    shouldScroll: true,
    onExitClick: () => CreateJobTourService.stopTour(true)
  },
  {
    title: 'Control where people see your job listing',
    content: React.createElement('span', null, 'By toggling this on, we\'ll amplify your reach by posting your job on ',
      React.createElement('a', { className: 'color-cyan-600 text-decorator-underline', href: EEnv.REACT_APP_CANDIDATE_WEB_DNS, target: '_blank' }, 'KnowMe candidate website\'s marketplace'), '. Let\'s get those applications flowing!'),
    currentStep: 3,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_SHOW_ON_KNOWME.getSelector(),
    arrowPosition: 'bottom',
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: () => CreateJobTourService.previousStep(),
    shouldScroll: false,
    onExitClick: () => CreateJobTourService.stopTour(true),
    zIndex: 999
  },
  {
    title: 'You’ve made it!',
    content: 'Preview and post your job right here.',
    currentStep: 4,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_POST_JOB.getSelector(),
    arrowPosition: 'bottom',
    // tip: 'Post Job',
    ctaText: 'End Tour',
    onCtaClick: (history, dispatch) => {
      dispatch?.(setLayoutLoading(true))
      CreateJobTourService.nextStep(() => {
        dispatch?.(setLayoutLoading(false))
        history?.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
      })
    },
    onBack: () => CreateJobTourService.previousStep(),
    shouldScroll: false,
    onExitClick: () => CreateJobTourService.stopTour(true),
    zIndex: 999
  }
]

// #endregion
const STEP_CONFIGS: ITourPopperProps[] = COMMON_STEP_CONFIGS
const STEP_CONFIGS_RESUME: ITourPopperProps[] = [
  {
    ...COMMON_STEP_CONFIGS[0],
    tip: undefined,
    ctaText: 'Next',
    onCtaClick: () => {
      (document.querySelector(TARGET_CREATE_JOB_BUTTON.getSelector()) as HTMLButtonElement)?.click()
    }
  },
  {
    ...COMMON_STEP_CONFIGS[1],
    tip: undefined,
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: (history) => {
      history?.goBack()
      CreateJobTourService.previousStep()
    },
    onExitClick: () => CreateJobTourService.stopTour(true)
  },
  {
    ...COMMON_STEP_CONFIGS[2],
    tip: undefined,
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: () => CreateJobTourService.previousStep(),
    onExitClick: () => CreateJobTourService.stopTour(true)
  },
  {
    ...COMMON_STEP_CONFIGS[3],
    tip: undefined,
    ctaText: 'Next',
    onCtaClick: () => {
      CreateJobTourService.nextStep()
    },
    onBack: () => CreateJobTourService.previousStep(),
    onExitClick: () => CreateJobTourService.stopTour(true)
  },
  {
    ...COMMON_STEP_CONFIGS[4]
  }
]

export class CreateJobTourService {
  public MAX_JOB_STEPS = 7
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _campaignSubscrition?: Subscription = undefined
  private static _routeListener?: UnregisterCallback = undefined

  public static get currentStep$(): WithOutNextComplete<typeof CreateJobTourService._currentStep$> {
    return CreateJobTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<typeof CreateJobTourService._enableTour$> {
    return CreateJobTourService._enableTour$
  }

  public static nextStep(afterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()

    if (this.isLastStep(currentStep)) {
      PopupTourService
        .completeMission('create-job')
        .pipe(
          take(1),
          tap(() => {
            if (!ProfileService.settingProfile$.getValue().video) {
              PopupTourService.showVVCPopup()
            }
          }),
          finalize(() => afterNext?.())
        ).subscribe()
      return
    }

    this._currentStep$.next(currentStep + 1)
    // this._handleCampaignMutation(CampaignMutationService.data$.getValue())
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static getStepConfig() {
    const checked = PopupTourService.missions$.getValue().find((m) => m.id === 'create-job')?.checked
    if (checked) {
      return STEP_CONFIGS_RESUME[this._currentStep$.getValue()]
    }

    return STEP_CONFIGS[this._currentStep$.getValue()]
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    if (this._campaignSubscrition) {
      this._campaignSubscrition.unsubscribe()
      this._campaignSubscrition = undefined
    }

    this.stopRouteListener()

    const routeNewCampaign = generate(ERoutes.NEW_CAMPAIGN)
    const routeCampaignMyJobs = generate(ERoutes.CAMPAIGN_MY_JOBS)
    const routeRecording = generate(ERoutes.RECORDING)

    this._routeListener = history?.listen((location) => {
      if (CreateJobTourService.currentStep$.getValue() === 0 && routeCampaignMyJobs === location.pathname) {
        return
      }

      if (!([routeNewCampaign, routeRecording].some(tourPath => location.pathname.startsWith(tourPath)))) {
        this.stopTour(true)
      }
    })

    OverlayService.reset()

    // this._campaignSubscrition = CampaignMutationService.data$.subscribe((data) => this._handleCampaignMutation(data))
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)
  }

  public static isLastStep(stepIndex: number) {
    return stepIndex === MAX_JOB_STEPS - 1
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()

    if (this._campaignSubscrition) {
      this._campaignSubscrition.unsubscribe()
      this._campaignSubscrition = undefined
    }

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static validateBeforeCreatingJob() {
    const data = CampaignMutationService.data$.getValue()
    let backToStep = -1
    let fieldError: string | null = null

    if (this.checkForStep1(data) !== null) {
      backToStep = 1
      fieldError = this.checkForStep1(data)
    } else if (this.checkForStep2(data) !== null) {
      backToStep = 2
      fieldError = this.checkForStep2(data)
    } else if (this.checkForStep3(data) !== null) {
      backToStep = 3
      fieldError = this.checkForStep3(data)
    } else if (this.checkForStep4(data) !== null) {
      backToStep = 4
      fieldError = this.checkForStep4(data)
    } else if (this.checkForStep5(data) !== null) {
      backToStep = 5
      fieldError = this.checkForStep5(data)
    }

    if (backToStep !== -1) {
      this._currentStep$.next(backToStep)
    }

    return fieldError
  }

  private static checkForStep2(data: ICampaignDetail) {
    const salaryValue = data.salaryValue as { min: number; max: number }
    if (!salaryValue || !salaryValue.min || !salaryValue.max) {
      return 'Salary'
    }

    if (!data.jobTitle) return 'Job Title'

    if (!data.salaryRate) return 'Salary Rate'

    if (!data.locationType || (data.locationType !== ELocationType.REMOTE && !data.location)) {
      return 'Location and Type'
    }

    return null
  }

  private static checkForStep1(data: ICampaignDetail) {
    if (data.uploadVideoFile || data.uploadVideo) {
      return null
    }

    return 'Video Vibe Check'
  }

  private static checkForStep3(data: ICampaignDetail) {
    // if (!data.questionIds?.length) {
    //   return 'Question'
    // }

    return null
  }

  private static checkForStep4(data: ICampaignDetail) {
    return !data.briefDescription ? 'Job Description' : null
  }

  private static checkForStep5(data: ICampaignDetail) {
    if (!data.companyName) {
      return 'Company Name'
    }

    // logo become optional field 👇
    // if (!data.logoFile && !data.logoUrl) {
    //   return 'Company Logo'
    // }

    if (!data.companyWebsiteUrls?.length || !CampaignService.isValidWebsiteUrl(data.companyWebsiteUrls.at(0))) {
      return 'Company Website'
    }

    return null
  }
}
