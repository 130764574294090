import clsx, { ClassValue } from 'clsx'
import MicRecorder from 'mic-recorder-to-mp3'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IReactMicStopEvent, ReactMic, ReactMicStopEvent } from 'react-mic'
import { from, map, takeUntil } from 'rxjs'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconStartAudio, IconStopAudio } from 'src/icons'
import { backgroundImage } from 'src/utils'
import Style from './style.module.scss'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

interface IProps extends Omit<ComponentProps<typeof ReactMic>, 'record' | 'onStop'> {
  canvasClasses?: ClassValue
  noVisual?: boolean
  backgroundSrc?: string
  teleprompterActive?: boolean
  onStop: (event: IReactMicStopEvent) => any
  onStartRecording?: () => any
}

export const RecorderAudio: FC<IProps> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const [record, setRecord] = useState(false)
  const [length, setLength] = useState(0)
  const counterRef = useRef<ReturnType<typeof setInterval>>()

  const onStartRecordingRef = useAsRef(props.onStartRecording)
  const onStopRef = useAsRef(props.onStop)
  const onStop = useCallback((e: ReactMicStopEvent) => {
    const event = { ...e } as IReactMicStopEvent
    if (event.blobURL) {
      URL.revokeObjectURL(event.blobURL)
      delete event.blobURL
    }

    Mp3Recorder.stop()
    from(Mp3Recorder.getMp3())
      .pipe(
        takeUntil(unsubscribe$),
        map((args) => (args as any[])[1] as unknown as Blob)
      )
      .subscribe((blob) => {
        event.blob = blob
        event.options.mimeType = blob.type
        onStopRef.current?.(event)
      })
  }, [onStopRef, unsubscribe$])

  useEffect(() => {
    setLength(0)

    if (record) {
      Mp3Recorder.start().then(
        () => onStartRecordingRef.current?.()
      )
    }

    if (record) {
      if (counterRef.current) {
        clearInterval(counterRef.current)
      }

      counterRef.current = setInterval(
        () => setLength((prev) => prev + 1),
        1000
      )

      return () => {
        clearInterval(counterRef.current)
      }
    }
  }, [onStartRecordingRef, record])

  useEffect(() => {
    document.getElementsByTagName('canvas')[0]?.removeAttribute('width')
    document.getElementsByTagName('canvas')[0]?.removeAttribute('height')
  }, [])

  const txtCounter = useMemo(() => {
    const min = Math.floor(length / 60)
    const sec = length % 60
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
  }, [length])

  return (
    <div
      className={clsx(Style.recorderAudio, props.className, {
        [Style.TeleprompterActive]: props.teleprompterActive
      })}
      style={{ backgroundImage: backgroundImage(props.backgroundSrc) }}
    >
      <ReactMic
        {...props}
        record={record}
        channelCount={2}
        onStop={onStop}
        className={clsx(props.canvasClasses, {
          'd-none': props.noVisual
        })}
        strokeColor={props.strokeColor || '#ffffff'}
        backgroundColor={props.backgroundColor || '#4e505f'}
      />

      <span
        className={Style.iconContainer}
        onClick={() => setRecord((prev) => !prev)}
      >
        {!record ? <IconStartAudio/> : <IconStopAudio/>}
        {record
          ? (
            <span className={clsx(Style.counter, 'meta f-medium')}>
              {txtCounter}
            </span>
          )
          : null}
      </span>
    </div>
  )
}
