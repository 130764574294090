export class Target {
  constructor(private readonly value: string) {}

  public getSelector() {
    return `#${this.value}`
  }

  public getValue() {
    return this.value
  }
}

export const TARGET_WALLET = new Target('hiring-manager-credit-wallet')

export const calculateXY = (selector1: string, selector2: string) => {
  const elem1 = document.querySelector(selector1)
  const elem2 = document.querySelector(selector2)

  if (!elem1 || !elem2) {
    console.error('not found element for selector', selector1, 'and', selector2)
    return [0, 0]
  }

  const rect1 = elem1.getBoundingClientRect()
  const rect2 = elem2.getBoundingClientRect()

  const distanceX = rect2.left - rect1.left
  const distanceY = rect2.top - rect1.top

  return [distanceX, distanceY]
}
