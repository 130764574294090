import { AxiosError } from 'axios'
import { EErrorCode } from 'src/constants'
import { IObject } from 'src/interfaces'
import { browserHistory } from 'src/router'

/**
 * Get axios response error message
 * @param {AxiosError} e
 */
export const getApiErrorMessage = (e: any, defaultMessage = 'Network Error') => {
  const errorCode = getApiErrorCode(e)
  if ([
    EErrorCode.PROFILE_NOT_COMPLETED,
    EErrorCode.INSUFFICIENT_CREDITS
  ].includes(errorCode)) {
    return
  }

  const getMessage = () => {
    const error = e as AxiosError<IObject, IObject>

    try {
      if (error.response && typeof error.response === 'object') {
        const response = error.response
        return (
          response.data &&
          (
            response.data.message ||
            response.data.error ||
            JSON.stringify(response.data, null, 2)
          )
        )
      }

      return typeof error === 'string'
        ? error
        : error?.message || JSON.stringify(error, null, 2)
    } catch (err) {
      return String(error)
    }
  }

  return getMessage() || defaultMessage
}

/**
 * Get axios response error message
 * @param {AxiosError} error
 * @return {string}
 */
export const getApiErrorCode = (error: any) => {
  const data = error?.response?.data
  return data?.details?.errorCode
}

/**
 * Get axios response error message
 * @param {AxiosError} error
 */
export const navigateByAxiosError = (error: AxiosError) => {
  const status = error.response?.status
  browserHistory.push(`/${status}`)
}
