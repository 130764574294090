import { Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { motion } from 'framer-motion'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi, CampaignApi } from 'src/api'
import { AlertMessage, Button, Checkbox, Input } from 'src/components'
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useAppDispatch, useOldValidation, useUnsubscribe } from 'src/hooks'
import { IconInfo } from 'src/icons'
import { IOverlayProps } from 'src/interfaces/overlay-props.interface'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { AUTH_LOGIN_SUCCESS } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { homeSchema, signInSchema } from 'src/validation'
import { HintContent } from '../register-email'
import Style from './style.module.scss'

const svgCheckStyle = { width: '14px', height: '14px' }

interface IProps {
  hashId: string
  campaignId: number
  name?: string
}

export const JobClaimedRestrictModal: FC<IProps> & IOverlayProps = (props) => {
  const theme = useTheme()
  const { errors, validateAt, validate } = useOldValidation<{ email?: string; password?: string }>()
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [stage, setStage] = useState<'email_verify' | 'sign_in'>('email_verify')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [rememberMe, setRememberMe] = useState(false)
  const [sameCompany, setSameCompany] = useState(false)
  const [error, setError] = useState<string>('')
  const [signInError, setSignInError] = useState('')
  const [loading, setLoading] = useState(false)

  const previousVerifiedEmailRef = useRef<string>('')

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { name, value } = e.target

    if (stage === 'email_verify') {
      if (name === 'email' && value && previousVerifiedEmailRef.current && value === previousVerifiedEmailRef.current) {
        setError('')
        setSignInError('')
        setEmail(value)
        setStage('sign_in')
        return
      }

      validateAt({
        schema: homeSchema,
        path: name,
        data: { [name]: value }
      })

      setError('')
      setSignInError('')
      setEmail(value)
      return
    }

    if (stage === 'sign_in') {
      if (name === 'email') {
        setStage('email_verify')
        return
      }

      validateAt({
        schema: signInSchema,
        path: name,
        data: { [name]: value }
      })

      setError('')
      setSignInError('')
      setPassword(value)
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    if (stage === 'email_verify') {
      const isValid = await validate({ schema: homeSchema, data: { email } })
      if (!isValid) {
        return
      }

      setLoading(true)
      from(CampaignApi.verifyAccount(props.hashId, { email, sessionId: AnalyticApi._session }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            setError(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => {
            setLoading(false)
          })
        )
        .subscribe((response) => {
          const data = response.data
          if (!data) {
            OverlayService.reset()
            history.push(generate(ERoutes.SIGN_UP, { emailSent: 'true' }), email)
            return
          }

          setSameCompany(data.sameCompany)
          previousVerifiedEmailRef.current = email
          setStage('sign_in')
        })
    }

    if (stage === 'sign_in') {
      const isValid = await validate({ schema: signInSchema, data: { email, password } })
      if (!isValid) {
        return
      }

      setLoading(true)
      from(AuthApi.login({ email, password }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            setSignInError(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe((response) => {
          OverlayService.reset()
          dispatch({
            type: AUTH_LOGIN_SUCCESS,
            payload: {
              ...response.data.profile,
              campaignId: sameCompany ? props.campaignId : undefined,
              fromGuestView: true
            }
          })
        })
    }
  }

  return (
    <div className={Style.container}>
      <div className="fx flex-column fx-ai-center gap-14px">
        <div className="fx flex-column">
          <Typography
            variant="body2-bold"
            align="center"
            color={theme.colors['--color-neutral-theme-300']}
          >
            This job is managed by {props.name ? (<Typography variant="body2-bold" textTransform="capitalize" color={theme.colors['--color-neutral-theme-900']}>{props.name}</Typography>) : 'someone else'}. If you work in the same company as them, verify your email to access.
          </Typography>
        </div>
      </div>

      <form className="fx flex-column fx-ai-center gap-4" onSubmit={handleSubmit}>

        {signInError && (
          <AlertMessage
            className={Style.ErrorMsg}
            message={signInError}
            icon={<IconInfo/>}
          />
        )}

        <div className="w-100 fx flex-column gap-2">
          <span className="body2-bold color-neutral-theme-500">Email</span>
          <Input
            name="email"
            autoComplete="new-password"
            placeholder="name@company.com"
            type="email"
            fullWidth
            mb={0}
            disabled={loading}
            required
            onChange={handleChangeInput}
          />
          {errors.email && (
            <Typography className={Style.STSignUpEmailHint}>
              {HintContent}
            </Typography>
          )}
          {error && (
            <Typography className={Style.STSignUpEmailHint}>
              <IconInfo/> {error}
            </Typography>
          )}
        </div>

        <motion.div
          layout
          className="w-100 fx flex-column gap-2"
        >
          {stage === 'sign_in' && (
            <>
              <span className="body2-bold color-neutral-theme-500">Password</span>
              <Input
                name="password"
                placeholder="Enter your password"
                type="password"
                autoFocus
                fullWidth
                disabled={loading}
                mb={0}
                required
                onChange={handleChangeInput}
              />
            </>
          )}
        </motion.div>

        <Button
          fullWidth
          disabled={loading || !!errors.email || !email || (stage === 'sign_in' && (!!errors.password || !password))}
          type="submit"
        >
          <span className="body1-bold">
            {stage === 'email_verify' ? 'Sign Up' : 'Sign In'}
          </span>
        </Button>
        {stage === 'email_verify' && (
          <Typography
            align="center"
            variant="body2-regular"
            color={theme.colors['--color-neutral-theme-300']}
          >
            By signing up, you agree to KnowMe’s <a href={PRIVACY_POLICY_URL} target="_blank" className="text-decorator-underline color-neutral-theme-300 font-medium" rel="noreferrer">Privacy Policy</a> & <a href={TERMS_AND_SERVICE_URL} className="color-neutral-theme-300 text-decorator-underline font-medium">Terms of Service</a>
          </Typography>
        )}

        {stage === 'sign_in' && (
          <div className="fx flex-row fx-jc-space-between fx-ai-center w-100">
            <Checkbox
              checked={rememberMe}
              onChange={() => { setRememberMe(!rememberMe) }}
              label="Remember me"
              iconProps={svgCheckStyle}
              className={Style.checkBoxRemember}
            />

            <a
              href={generate(ERoutes.FORGOT_PASSWORD)}
              target="_blank"
              className="body2-regular text-decorator-underline pointer hover-color-cyan-500"
              rel="noreferrer"
            >Forgot password
            </a>
          </div>
        )}
      </form>
    </div>
  )
}

JobClaimedRestrictModal.getOverlayState = (hashId: string, campaignId: number, name?: string) => ({
  content: <JobClaimedRestrictModal hashId={hashId} campaignId={campaignId} name={name}/>,
  open: true,
  blank: true,
  overlayBackground: 'rgba(0, 0, 0, 0.25)',
  disabled: true
})
