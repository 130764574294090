import { Typography } from '@mui/material'
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AuthApi, ViewApi } from 'src/api'
import { Button, Input, NotAllowAuthenticated, useAnalytic } from 'src/components'
import { EMessage, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useOldValidation } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutSharingToken } from 'src/store/actions'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { signInSchema } from 'src/validation'
import { STContainer, STForm } from './styled'

export const Home: FC = () => {
  const history = useHistory()
  const { sharingToken } = useParams<{sharingToken: string}>()

  const { errors, validate, removeError } = useOldValidation<{password: string; email: string}>()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const dispatch = useAppDispatch()
  const { eventHandler } = useAnalytic('share_landing')

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    e.persist()
    const { value } = e.target
    removeError()
    setPassword(value)
  }

  const loadCandidate = useCallback(async (sharingToken: string) => {
    try {
      const { data } = await ViewApi.share(sharingToken)
      setEmail(data.email || '')
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })

      history.push('/')
    }
  }, [history])

  useEffect(() => {
    if (sharingToken) {
      dispatch(setLayoutSharingToken(sharingToken))
      loadCandidate(sharingToken)
    }
  }, [dispatch, loadCandidate, sharingToken])

  const handleNext = async (e: any) => {
    try {
      e.preventDefault()
      dispatch(setLayoutLoading(true))
      const isValid = await validate({ schema: signInSchema, data: { email, password } })
      if (!isValid || !sharingToken || !password || !email) return

      const payload = {
        email,
        password
      }

      await AuthApi.login(payload)
      dispatch({ type: AUTH_GET_PROFILE })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally { dispatch(setLayoutLoading(false)) }
  }

  return (
    <NotAllowAuthenticated>
      <STContainer>
        <Typography variant="h3">Enter your password</Typography>
        <STForm onSubmit={handleNext}>
          <Input
            required
            type="password"
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PSWD)}
            autoComplete="off"
            error={errors.password}
          />
          <Button
            fullWidth
            onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT, handleNext)}
          >
            Next
          </Button>
        </STForm>
      </STContainer>
    </NotAllowAuthenticated>
  )
}
