import { Box, styled, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const STContainer = styled('div', {
  label: 'Container'
})(({ theme }) => `
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: ${theme.spacing(5)};
    border-radius: 32px;
    background-color: ${theme.colors['--color-neutral-theme-50']};
`)

export const STInfo = styled('div', {
  label: 'Info'
})(() => `
  display: flex;
  align-items: center;
  justify-content: space-between;
`)

export const STAddCredit = styled('div', {
  label: 'AddCredit'
})(({ theme }) => `
  height: 56px;
  padding: ${theme.spacing(0, 2.5)};
  border-radius: 16px;
  background: ${theme.colors['--color-neutral-theme-100']};

  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};

  svg {
    cursor: pointer;
  }
`)

export const STTotal = styled('div', {
  label: 'Total'
})(({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
`)

export const STStripe = styled('div', {
  label: 'Stripe'
})(({ theme }) => `
  margin-top: ${theme.spacing(4)}
`)

export const STCartElement = styled(Box, {
  label: 'CartElement'
})(() => `
  display: flex;
  flex-direction: column;
`)

export const useStyles = makeStyles<Theme>((theme) => ({
  StripeInput: {
    border: 'none',
    borderRadius: '16px',
    padding: theme.spacing(2.25),
    color: theme.colors['--color-neutral-theme-700'],
    backgroundColor: theme.colors['--color-neutral-theme-100']
  },

  StripeInputError: {
    border: `1px solid ${theme.colors['--color-negative-500']}`,
    borderRadius: '16px',
    padding: theme.spacing(2.25),
    color: theme.colors['--color-neutral-theme-700'],
    backgroundColor: theme.colors['--color-neutral-theme-100']
  }
}))
